import type { NavigationLink, ShopNavigationLink } from 'src/types/navigation'

export const linksUnauthorized: readonly Readonly<NavigationLink>[] = [
  {
    name: 'sign-in-header',
    link: '/login/',
  },
  {
    name: 'registration',
    link: '/registration/',
  },
] as const

export const linksAuthorized: readonly Readonly<NavigationLink>[] = [
  {
    name: 'personal-details',
    link: '/profile/',
  },
  {
    name: 'order-history',
    link: '/order/history/',
  },
] as const

export const linkAdmin: NavigationLink = {
  name: 'administration',
  link: '/admin/',
} as const

export const linkLogout: NavigationLink = {
  name: 'logout',
  link: '/logout/',
} as const

export const linksOne: readonly Readonly<NavigationLink>[] = [
  {
    name: 'payment',
    link: '/shipping/#payment',
  },
  {
    name: 'shipping',
    link: '/shipping/',
  },
  {
    name: 'about-us',
    link: '/about/',
  },
] as const

export const linksTwo: readonly Readonly<NavigationLink>[] = [
  {
    name: 'partners',
    link: '/partners/',
  },
  {
    name: 'about-wishlist',
    link: '/about/#wishlist',
  },
  /* {
    name: 'our-location',
    link: '/our-location'
  } */
] as const

export const shopNavigation: readonly Readonly<ShopNavigationLink>[] = [
  {
    name: 'projects',
    path: 'projects',
    link: 'project',
  },
  {
    name: 'categories',
    path: 'categories',
    link: 'category',
  },
] as const
