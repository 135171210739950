import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'

import { getLocale } from 'src/redux/selectors/intl'
import { getOrderById } from 'src/redux/selectors/order-history'

import {
  reducerSetOrderHistoryExpanded,
  reducerFetchOrderHistoryDetails,
} from 'src/redux/reducers/order-history'

import { fetchRequest, fetchSuccess, fetchFailure, callAction } from './fields'

import type { FieldsAction } from 'src/types/common'
import type { ApiOrderHistory, ApiOrderHistoryDetails } from 'src/types/api'

const RESOURCE_URL = `${BACKEND_URL}/api/order`

const path = ['orderHistory'] as const

export function fetchOrderHistory(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(RESOURCE_URL, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiOrderHistory>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchOrderHistory error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchOrderHistoryDetails(orderId: number): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const order = getOrderById(state, orderId)

    if (order !== undefined) {
      return Promise.reject('Order not found.')
    }

    const locale = getLocale(state)

    return fetch(`${RESOURCE_URL}/${orderId}?locale=${locale}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiOrderHistoryDetails>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            callAction({
              ...result,
              action: getFunctionName({ reducerFetchOrderHistoryDetails }),
              path,
            })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchOrderHistoryDetails error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function setOrderHistoryExpanded(
  orderId: number,
  expanded: boolean
): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        expanded,
        orderId,
        action: getFunctionName({ reducerSetOrderHistoryExpanded }),
        path,
      })
    )
  }
}
