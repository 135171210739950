import { createSelector } from 'reselect'

import type { AdminProductsFieldsState } from 'src/redux/reducers/admin-products'

import type { Product } from 'src/types/admin'
import type { ReduxState } from 'src/types/common'
import type { ComboboxOption } from 'src/types/fields'

function getState(state: ReduxState): AdminProductsFieldsState {
  return state.fields.adminProducts
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getProducts(state: ReduxState): readonly Readonly<Product>[] {
  return getState(state).list
}

export const getProductsOptions = createSelector(
  [getProducts],
  function getProductsOptions(products): readonly Readonly<ComboboxOption>[] {
    return products.map(function mapper({ id, name }): ComboboxOption {
      return {
        value: id.toString(),
        name,
      }
    })
  }
)
