import * as React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

import { getIsAdmin, getIsFetching } from 'src/redux/selectors/auth'

import LoadingPage from 'src/components/loading'
import ForbiddenView from 'src/components/forbidden'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  isAdmin: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    isAdmin: getIsAdmin(state),
  }
}

function RouteAdmin(props: RouteProps): JSX.Element {
  const { isAdmin, isFetching } = useSelector(selector, shallowEqual)

  if (isAdmin) {
    return <Route {...props} />
  }

  if (isFetching) {
    return <LoadingPage />
  }

  return <ForbiddenView />
}

export default React.memo(RouteAdmin)
