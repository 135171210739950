import * as React from 'react'
import { useSelector } from 'react-redux'

import { getProduct } from 'src/redux/selectors/shop'

import ButtonToWishlist from 'src/components/common/button-to-wishlist'

import HeadlineDesktop from './headline-desktop'
import HeadlineMobile from './headline-mobile'
import Breadcrumbs from './breadcrumbs'
import ProductImages from './images'
import ProductPrice from './price'
import ProductParameters from './parameters'
import ProductDescription from './description'
import ProductDiscount from './discount'
import ButtonAddToCart from './button-add-to-cart'

import * as styles from 'src/styles/product.module.css'

import type { ShopProduct } from 'src/types/user'

function ProductInfo(): JSX.Element {
  const product: Readonly<ShopProduct> = useSelector(getProduct)

  return (
    <div
      className={styles.wrapper}
      itemScope
      itemType='http://schema.org/Product'
    >
      <Breadcrumbs product={product} />

      <HeadlineDesktop />

      <div className={styles.container}>
        <ProductImages />

        <div className={styles.containerInfo}>
          <HeadlineMobile />

          <ProductPrice />

          <ProductParameters />

          <ProductDescription />

          <div className={styles.buttons}>
            <ProductDiscount />

            <ButtonAddToCart />

            <ButtonToWishlist className={styles.heart} productId={product.id} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ProductInfo)
