import * as React from 'react'
import { Route, Switch, match } from 'react-router-dom'

import AdminHeader from 'src/admin/header'

import AdminView from 'src/admin'

import AdminProjectsView from 'src/admin/projects'
import AdminProjectEditView from 'src/admin/project-edit'

import AdminCategoriesView from 'src/admin/categories'
import AdminCategoryEditView from 'src/admin/category-edit'

import AdminProductsView from 'src/admin/products'
import AdminProductEditView from 'src/admin/product-edit'

import AdminOrdersView from 'src/admin/orders'
import AdminOrderEditView from 'src/admin/order-edit'

import AdminNotFoundView from 'src/admin/notfound'

interface Props {
  match: match
}

function RoutesAdmin({ match }: Props): JSX.Element {
  React.useEffect(function effect() {
    document.title = 'Администрирование'
  }, [])

  return (
    <div>
      <AdminHeader />

      <Switch>
        <Route
          path={`${match.url}/projects/create`}
          component={AdminProjectEditView}
        />
        <Route
          path={`${match.url}/projects/:id/edit`}
          component={AdminProjectEditView}
        />
        <Route path={`${match.url}/projects`} component={AdminProjectsView} />

        <Route
          path={`${match.url}/categories/create`}
          component={AdminCategoryEditView}
        />
        <Route
          path={`${match.url}/categories/:id/edit`}
          component={AdminCategoryEditView}
        />
        <Route
          path={`${match.url}/categories`}
          component={AdminCategoriesView}
        />

        <Route
          path={`${match.url}/products/create`}
          component={AdminProductEditView}
        />
        <Route
          path={`${match.url}/products/:id/edit`}
          component={AdminProductEditView}
        />
        <Route path={`${match.url}/products`} component={AdminProductsView} />

        <Route
          path={`${match.url}/orders/:id/edit`}
          component={AdminOrderEditView}
        />
        <Route path={`${match.url}/orders`} component={AdminOrdersView} />

        <Route exact path={`${match.url}`} component={AdminView} />

        <Route component={AdminNotFoundView} status={404} />
      </Switch>

      <link
        rel='stylesheet'
        href='https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css'
        integrity='sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk'
        crossOrigin='anonymous'
      />

      <style>{'html { font-size: 100%; } '}</style>
    </div>
  )
}

export default React.memo(RoutesAdmin)
