import * as React from 'react'
import { useSelector } from 'react-redux'

import { getOrderProducts } from 'src/redux/selectors/admin-order'

import Product from './product'

import type { OrderProduct } from 'src/types/admin'

type StateProps = readonly Readonly<OrderProduct>[]

function Products(): JSX.Element {
  const products: StateProps = useSelector(getOrderProducts)

  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor='products'
      >
        Товары
      </label>

      <div className='col'>
        <div className='table-responsive'>
          <table className='table table-sm table-bordered'>
            <thead>
              <tr>
                <th>Название</th>
                <th>
                  Модифи
                  <wbr />
                  каторы
                </th>
                <th>Актуальная цена</th>
                <th>Кол-во</th>
                <th>Итого</th>
              </tr>
            </thead>

            <tbody>
              {products.map(function mapper(product): JSX.Element {
                return <Product key={product.id} product={product} />
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Products)
