import * as React from 'react'

import { addToLazyload } from 'src/lazyload'

function LazyImage(
  props: React.ImgHTMLAttributes<HTMLImageElement>
): JSX.Element {
  const ref = React.useRef<HTMLImageElement>(null)

  React.useEffect(
    function effect() {
      if (ref.current !== null) {
        addToLazyload(ref.current)
      }
    },
    [props.src]
  )

  const { src, alt, ...rest } = props

  return (
    <img
      {...rest}
      src='/svg/transparent.svg'
      alt={alt}
      data-lazy
      data-src={src}
      ref={ref}
    />
  )
}

export default React.memo(LazyImage)
