import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import history from 'src/const/history'

import { getIsLoggedIn } from 'src/redux/selectors/auth'

import { addWishlistProduct } from 'src/redux/actions/wishlist'

import SvgHeart from 'src/components/svg/heart'

interface OwnProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  productId: number
}

function ButtonToWishlist({ productId, ...props }: OwnProps): JSX.Element {
  const intl = useIntl()

  const dispatch = useDispatch()

  const isLoggedIn: boolean = useSelector(getIsLoggedIn)

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      isLoggedIn
        ? dispatch(addWishlistProduct(productId))
        : history.push(`/${intl.locale}/wishlist/`)
    },
    [productId, isLoggedIn, intl.locale, dispatch]
  )

  return (
    <button
      {...props}
      aria-label='Add to wishlist'
      onClick={onClick}
      type='button'
    >
      <SvgHeart />
    </button>
  )
}

export default React.memo(ButtonToWishlist)
