import * as React from 'react'
import { createPortal } from 'react-dom'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { getFieldOverlay } from 'src/redux/selectors/shop'

import { changeCheckbox } from 'src/redux/actions/fields'

import * as styles from 'src/styles/overlay-test.module.css'

function OverlayTest(): JSX.Element {
  const container = React.useMemo<HTMLElement | null>(function memo() {
    return document.getElementById('modal')
  }, [])

  const dispatch = useDispatch()

  const field = useSelector(getFieldOverlay)

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      window.localStorage.setItem('overlayVisibility', '')

      dispatch(changeCheckbox({ path: field.path, checked: false }))
    },
    [field.path, dispatch]
  )

  const element = (
    <div className={styles.shadow}>
      <div className={styles.container}>
        <div className={styles.container}>
          <FormattedMessage
            id='overlay-test-info'
            defaultMessage='Overlay test info'
          />
        </div>

        <button className={styles.button} onClick={onClick} type='button'>
          OK
        </button>
      </div>
    </div>
  )

  if (container !== null) {
    return createPortal(element, container)
  }

  return element
}

export default React.memo(OverlayTest)
