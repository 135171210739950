import * as React from 'react'
import { useSelector } from 'react-redux'

import { getLocale, getLocalesList } from 'src/redux/selectors/admin-locale'

import Locale from './locale'

import { ReduxState } from 'src/types/common'

interface StateProps {
  localeList: readonly string[]
  current: string
}

function selector(state: ReduxState): StateProps {
  return {
    localeList: getLocalesList(state),
    current: getLocale(state).value,
  }
}

interface OwnProps {
  disabled: boolean
  onChange?(locale: string): void
}

function LocaleSwitch({ disabled, onChange }: OwnProps): JSX.Element {
  const { localeList, current } = useSelector(selector)

  return (
    <div className='btn-group' data-toggle='buttons'>
      {localeList.map(function mapper(locale): JSX.Element {
        return (
          <Locale
            key={locale}
            slug={locale}
            selected={current === locale}
            disabled={disabled}
            onChange={onChange}
          />
        )
      })}
    </div>
  )
}

export default React.memo(LocaleSwitch)
