import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { Payment, paymentMethods } from 'src/const/payment'

import { getLocale } from 'src/redux/selectors/intl'

import {
  getOrderId,
  getOrderProductsCost,
  getOrderShippingCost,
} from 'src/redux/selectors/order'

import {
  getFieldZip,
  getFieldCity,
  getFieldEmail,
  getFieldPhone,
  getFieldCountry,
  getFieldAddress,
  getFieldLastName,
  getFieldFirstName,
} from 'src/redux/selectors/delivery'

import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

const method = paymentMethods[Payment.PayPal]

interface StateProps {
  locale: string
  orderId: number
  productsCost: number
  shippingCost: number
  firstName: string
  lastName: string
  phone: string
  country: string
  city: string
  zip: string
  address: string
  email: string
}

function selector(state: ReduxState): StateProps {
  return {
    locale: getLocale(state),
    orderId: getOrderId(state),
    productsCost: getOrderProductsCost(state),
    shippingCost: getOrderShippingCost(state),

    firstName: getFieldLastName(state).value,
    lastName: getFieldFirstName(state).value,
    country: getFieldCountry(state).value,
    address: getFieldAddress(state).value,
    phone: getFieldPhone(state).value,
    email: getFieldEmail(state).value,
    city: getFieldCity(state).value,
    zip: getFieldZip(state).value,
  }
}

function FormPaypal(): JSX.Element {
  const {
    locale,
    orderId,
    productsCost,
    shippingCost,
    firstName,
    lastName,
    country,
    address,
    phone,
    email,
    city,
    zip,
  }: StateProps = useSelector(selector, shallowEqual)

  return (
    <div className={utility.dn}>
      <input name='cmd' type='hidden' value='_cart' />
      <input name='upload' type='hidden' value='1' />
      <input name='lc' type='hidden' value={locale} />
      <input
        name='currency_code'
        type='hidden'
        value={locale === 'ru' ? 'RUB' : 'USD'}
      />
      <input name='business' type='hidden' value={method.account} />
      <input name='notify_url' type='hidden' value={method.notifyUrl} />
      <input name='return' type='hidden' value={method.returnUrl} />
      <input
        name='cancel_return'
        type='hidden'
        value={`${method.cancelUrl}?order=${orderId}`}
      />
      <input name='rm' type='hidden' value='0' />
      <input name='charset' type='hidden' value='utf-8' />
      <input name='invoice' type='hidden' value={orderId} />

      <input
        type='hidden'
        name='item_name_1'
        value={`Ice-Pick Shop. Order #${orderId} Invoice`}
      />
      <input type='hidden' name='amount_1' value={productsCost} />
      <input type='hidden' name='quantity_1' value='1' />
      <input name='no_shipping' type='hidden' value='1' />
      <input name='shipping_1' type='hidden' value={shippingCost} />

      <input name='email' type='hidden' value={email} />
      <input name='first_name' type='hidden' value={firstName} />
      <input name='last_name' type='hidden' value={lastName} />
      <input name='night_phone_a' type='hidden' value={phone} />
      <input name='country' type='hidden' value={country} />
      <input name='city' type='hidden' value={city} />
      <input name='address1' type='hidden' value={address} />
      <input name='zip' type='hidden' value={zip} />
    </div>
  )
}

export default React.memo(FormPaypal)
