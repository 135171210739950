import { connect } from 'react-redux'

import InputHtml, { AdminInputHtmlProps } from 'src/admin/common/input-html'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminProduct: { isFetching, desc },
  },
}: ReduxState): AdminInputHtmlProps {
  return {
    disabled: isFetching,
    field: desc,
  }
}

export default connect(mapStateToProps)(InputHtml)
