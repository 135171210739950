import * as React from 'react'
import { useSelector } from 'react-redux'

import { getProduct } from 'src/redux/selectors/shop'

import * as styles from 'src/styles/product.module.css'

import type { ReduxState } from 'src/types/common'

function selector(state: ReduxState): string {
  return getProduct(state).desc
}

function ProductDescription(): JSX.Element {
  const desc: string = useSelector(selector)

  const htmlDesc = React.useMemo(
    function memo() {
      return { __html: desc }
    },
    [desc]
  )

  return (
    <div
      className={styles.desc}
      dangerouslySetInnerHTML={htmlDesc}
      itemProp='description'
    />
  )
}

export default React.memo(ProductDescription)
