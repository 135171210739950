import * as React from 'react'

interface Props {
  progress: number
}

function UploadingProgress({ progress }: Props): JSX.Element {
  const style = React.useMemo<React.HTMLAttributes<HTMLDivElement>['style']>(
    function memo() {
      return { width: `${progress}%` }
    },
    [progress]
  )

  if (progress === 0) {
    return <></>
  }

  return (
    <div className='progress w-100'>
      <div
        role='progressbar'
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
        className='progress-bar progress-bar-striped progress-bar-animated'
        style={style}
      />
    </div>
  )
}

export default React.memo(UploadingProgress)
