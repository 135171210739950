import * as React from 'react'

import IntlLink from 'src/components/common/intl-link'

import SvgSocialVk from 'src/components/svg/social-vk'
import SvgSocialTw from 'src/components/svg/social-tw'
import SvgSocialSt from 'src/components/svg/social-st'
import SvgSocialYt from 'src/components/svg/social-yt'
import SvgSocialIn from 'src/components/svg/social-in'
import SvgSocialFb from 'src/components/svg/social-fb'
import SvgMail from 'src/components/svg/mail'

import * as styles from 'src/styles/footer.module.css'

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

function FooterSocials(props: Props): JSX.Element {
  return (
    <div {...props}>
      <a
        aria-label='VKontakte'
        className={styles.socialIcon}
        href='https://vk.com/icepicklodge'
        rel='noopener noreferrer'
        target='_blank'
        itemProp='sameAs'
      >
        <SvgSocialVk />
      </a>

      <a
        aria-label='Twitter'
        className={styles.socialIcon}
        href='https://twitter.com/IcePickLodge'
        rel='noopener noreferrer'
        target='_blank'
        itemProp='sameAs'
      >
        <SvgSocialTw />
      </a>

      <a
        aria-label='Steam'
        className={styles.socialIcon}
        href='https://steamcommunity.com/groups/icepicklodge'
        rel='noopener noreferrer'
        target='_blank'
        itemProp='sameAs'
      >
        <SvgSocialSt />
      </a>

      <a
        aria-label='Youtube'
        className={styles.socialIcon}
        href='https://youtube.com/icepicklodge'
        rel='noopener noreferrer'
        target='_blank'
        itemProp='sameAs'
      >
        <SvgSocialYt />
      </a>

      <a
        aria-label='Instagram'
        className={styles.socialIcon}
        href='https://instagram.com/icepicklodge'
        rel='noopener noreferrer'
        target='_blank'
        itemProp='sameAs'
      >
        <SvgSocialIn />
      </a>

      <a
        aria-label='Facebook'
        className={styles.socialIcon}
        href='https://facebook.com/icepicklodge'
        rel='noopener noreferrer'
        target='_blank'
        itemProp='sameAs'
      >
        <SvgSocialFb />
      </a>

      <IntlLink
        aria-label='Feedback'
        className={styles.socialIcon}
        to='/feedback/'
      >
        <SvgMail />
      </IntlLink>
    </div>
  )
}

export default React.memo(FooterSocials)
