import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getProjectSelected,
  getProjectsContainsProductsByCategoryId,
} from 'src/redux/selectors/shop'

import { setShopSection } from 'src/redux/actions/shop'

import ShopSectionsItem from './item'

import * as styles from 'src/styles/shop-sections.module.css'

import type { ReduxState } from 'src/types/common'
import type { Category, Project } from 'src/types/user'

interface StateProps {
  isFetching: boolean
  projects: readonly Readonly<Project>[]
  projectSelected: number
}

interface OwnProps {
  category: Readonly<Category> | undefined
}

function ShopSectionsCategory({ category }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onSelect = React.useCallback(
    function callback(projectId: number, categoryId: number) {
      dispatch(setShopSection(projectId, categoryId))
    },
    [dispatch]
  )

  const { isFetching, projects, projectSelected }: StateProps = useSelector(
    function selector(state: ReduxState): StateProps {
      return {
        isFetching: getIsFetching(state),
        projectSelected: getProjectSelected(state),

        projects: getProjectsContainsProductsByCategoryId(
          state,
          category !== undefined ? category.id : 0
        ),
      }
    },
    shallowEqual
  )

  return (
    <div role='radiogroup' className={styles.container}>
      {category !== undefined && (
        <div className={styles.listName}>{category.name}</div>
      )}

      {isFetching === false &&
        category !== undefined &&
        projects.map(function mapper(project): JSX.Element {
          return (
            <ShopSectionsItem
              key={project.id}
              name={project.name}
              selected={projectSelected === project.id}
              projectId={project.id}
              categoryId={category.id}
              onSelect={onSelect}
            />
          )
        })}
    </div>
  )
}

export default React.memo(ShopSectionsCategory)
