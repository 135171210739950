import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as showdown from 'showdown'
import classnames from 'classnames'

import { BACKEND_URL } from 'src/config'

import Separator from 'src/components/separator'

import * as styles from 'src/styles/location.module.css'
import * as utility from 'src/styles/utility.module.css'

const classList: readonly Readonly<[string, string]>[] = [
  ['p', styles.text],
] as const

const converter = new showdown.Converter({
  openLinksInNewWindow: true,
  extensions: Object.entries(classList).map(function mapper([key, className]) {
    return {
      type: 'output',
      regex: new RegExp(`<${key}(.*?)>`, 'g'),
      replace: `<${key} class="${className}" $1>`,
    }
  }),
})

function LocationView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'our-location' })
    },
    [intl]
  )

  const [open, setOpen] = React.useState(false)

  const toggleLightbox = React.useCallback<React.MouseEventHandler>(
    function callback() {
      setOpen(function set(open) {
        return !open
      })
    },
    []
  )

  const srcMap = React.useMemo<string>(
    function memo() {
      return intl.locale === 'ru'
        ? `${BACKEND_URL}/img/pickup-map-ru.png`
        : `${BACKEND_URL}/img/pickup-map-en.png`
    },
    [intl.locale]
  )

  const contentHtml = React.useMemo(
    function memo() {
      return {
        __html: converter.makeHtml(
          intl.formatMessage({ id: 'our-location-content' })
        ),
      }
    },
    [intl]
  )

  return (
    <div className={classnames(styles.container, utility.defaultPage)}>
      <div className={styles.content}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage id='our-location' defaultMessage='Out location' />
        </h2>

        <Separator />

        <div className={styles.row}>
          <div className={styles.left}>
            <button onClick={toggleLightbox} type='button'>
              <img src={srcMap} alt='map' />
            </button>
          </div>

          <div className={styles.right} dangerouslySetInnerHTML={contentHtml} />
        </div>
      </div>

      {open && (
        <button
          className={styles.lightBox}
          onClick={toggleLightbox}
          type='button'
        >
          <img src={srcMap} alt='map' />

          <span role-label='Close' className={styles.lighBoxClose}>
            &times;
          </span>
        </button>
      )}
    </div>
  )
}

export default React.memo(LocationView)
