import * as React from 'react'

interface Props {
  disabled: boolean
  onUpload(files: readonly File[]): void
}

function ButtonUpload({ disabled, onUpload }: Props): JSX.Element {
  const ref = React.useRef<HTMLInputElement>(null)

  const onButtonClick = React.useCallback(function callback() {
    if (ref.current !== null) {
      ref.current.click()
    }
  }, [])

  const onChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    function callback(event) {
      if (event.target.files !== null) {
        onUpload(Array.from(event.target.files))
      }

      event.target.value = ''
    },
    [onUpload]
  )

  if (disabled) {
    return <></>
  }

  return (
    <>
      <button
        title='Нажмите сюда чтобы загрузить новые изображения, либо просто перетащите файлы на диалоговое окно'
        className='btn btn-danger mr-auto'
        onClick={onButtonClick}
        type='button'
      >
        Загрузить
      </button>

      <input
        ref={ref}
        type='file'
        accept='image/*'
        className='d-none'
        onChange={onChange}
      />
    </>
  )
}

export default React.memo(ButtonUpload)
