import * as React from 'react'
import { useSelector } from 'react-redux'

import { getCategoryById } from 'src/redux/selectors/admin-categories'

import type { ReduxState } from 'src/types/common'
import type { Category as CategoryItem } from 'src/types/user'

interface OwnProps {
  id: number
  index: number
  disabled: boolean
  onRemove(index: number): void
}

type StateProps = Readonly<CategoryItem> | undefined

function Category({ id, index, disabled, onRemove }: OwnProps): JSX.Element {
  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      onRemove(index)
    },
    [index, onRemove]
  )

  const category: StateProps = useSelector(function selector(
    state: ReduxState
  ) {
    return getCategoryById(state, id)
  })

  return (
    <div className='btn-group mt-3 mr-3' role='group'>
      <span className='btn btn-secondary'>
        {category !== undefined ? category.name : id}
      </span>

      <button
        className='btn btn-secondary'
        disabled={disabled}
        onClick={onClick}
        type='button'
      >
        <span aria-hidden='true'>×</span>
      </button>
    </div>
  )
}

export default React.memo(Category)
