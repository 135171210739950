import * as React from 'react'
import { useSelector } from 'react-redux'

import { shippingOptions } from 'src/const/shipping'

import { getFieldShippingMethod } from 'src/redux/selectors/admin-order-search'

import InputSelect from 'src/components/common/input-select'

interface Props {
  disabled: boolean
  onChange(): void
}

function SearchByShipping({ disabled, onChange }: Props): JSX.Element {
  const field = useSelector(getFieldShippingMethod)

  return (
    <InputSelect
      field={field}
      options={shippingOptions}
      disabled={disabled}
      className='form-control'
      onChange={onChange}
    />
  )
}

export default React.memo(SearchByShipping)
