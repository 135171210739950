import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getCategorySelected,
  getCategoriesContainsProductsByProjectId,
} from 'src/redux/selectors/shop'

import { setShopSection } from 'src/redux/actions/shop'

import ShopSectionsItem from './item'

import * as styles from 'src/styles/shop-sections.module.css'

import type { ReduxState } from 'src/types/common'
import type { Project, Category } from 'src/types/user'

interface OwnProps {
  project: Readonly<Project> | undefined
}

interface StateProps {
  isFetching: boolean
  categories: readonly Readonly<Category>[]
  categorySelected: number
}

function ShopSectionsProject({ project }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onSelect = React.useCallback(
    function callback(projectId: number, categoryId: number) {
      dispatch(setShopSection(projectId, categoryId))
    },
    [dispatch]
  )

  const { isFetching, categories, categorySelected } = useSelector(
    function selector(state: ReduxState): StateProps {
      return {
        isFetching: getIsFetching(state),
        categorySelected: getCategorySelected(state),

        categories: getCategoriesContainsProductsByProjectId(
          state,
          project !== undefined ? project.id : 0
        ),
      }
    },
    shallowEqual
  )

  return (
    <div role='radiogroup' className={styles.container}>
      {project !== undefined && (
        <div className={styles.listName}>{project.name}</div>
      )}

      {isFetching === false &&
        project !== undefined &&
        categories.map(function mapper(category): JSX.Element {
          return (
            <ShopSectionsItem
              key={category.id}
              name={category.name}
              selected={categorySelected === category.id}
              projectId={project.id}
              categoryId={category.id}
              onSelect={onSelect}
            />
          )
        })}
    </div>
  )
}

export default React.memo(ShopSectionsProject)
