import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classnames from 'classnames'

import { BACKEND_URL } from 'src/config'

import Separator from 'src/components/separator'

import * as styles from 'src/styles/size-table.module.css'
import * as utility from 'src/styles/utility.module.css'

function SizeTable(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'size-table' })
    },
    [intl.locale, intl]
  )

  const shirtImage = React.useMemo<string>(
    function memo() {
      return intl.locale === 'ru'
        ? `${BACKEND_URL}/svg/shirt-ru.svg`
        : `${BACKEND_URL}/svg/shirt-en.svg`
    },
    [intl.locale]
  )

  return (
    <div className={classnames(styles.container, utility.defaultPage)}>
      <div className={styles.content}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage
            id='size-table'
            defaultMessage='Table of shirt sizes'
          />
        </h2>

        <Separator />

        <div className={styles.row}>
          <div>
            <h3 className={styles.subheader}>
              <FormattedMessage
                id='size-table-mens'
                defaultMessage='Table of shirt sizes for mens'
              />
            </h3>

            <table className={styles.table}>
              <tbody>
                <tr>
                  <td />
                  <td>XS</td>
                  <td>S</td>
                  <td>M</td>
                  <td>L</td>
                  <td>XL</td>
                  <td>XXL</td>
                  <td>XXXL</td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id='size-table-size-rus'
                      defaultMessage='Shirt sizes (rus)'
                    />
                  </td>
                  <td>44-46</td>
                  <td>46-48</td>
                  <td>48-50</td>
                  <td>50-52</td>
                  <td>52-54</td>
                  <td>54-56</td>
                  <td>56-58</td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id='size-table-chest-size'
                      defaultMessage='Chest shirt size'
                    />
                  </td>
                  <td>96</td>
                  <td>100</td>
                  <td>104</td>
                  <td>108</td>
                  <td>112</td>
                  <td>116</td>
                  <td>120</td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id='size-table-width'
                      defaultMessage='Width shirt size'
                    />
                  </td>
                  <td>46</td>
                  <td>48</td>
                  <td>50</td>
                  <td>52</td>
                  <td>54</td>
                  <td>56</td>
                  <td>58</td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id='size-table-length'
                      defaultMessage='Length shirt size'
                    />
                  </td>
                  <td>65</td>
                  <td>67</td>
                  <td>68</td>
                  <td>71</td>
                  <td>74</td>
                  <td>74</td>
                  <td>74</td>
                </tr>
              </tbody>
            </table>

            <h3 className={styles.subheader}>
              <FormattedMessage
                id='size-table-womens'
                defaultMessage='Table of shirt sizes for womens'
              />
            </h3>

            <table className={styles.table}>
              <tbody>
                <tr>
                  <td />
                  <td>XXS</td>
                  <td>XS</td>
                  <td>S</td>
                  <td>M</td>
                  <td>L</td>
                  <td>XL</td>
                  <td>XXL</td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id='size-table-size-rus'
                      defaultMessage='Shirt sizes (rus)'
                    />
                  </td>
                  <td>36-38</td>
                  <td>38-40</td>
                  <td>40-42</td>
                  <td>42-44</td>
                  <td>44-46</td>
                  <td>46-48</td>
                  <td>48-50</td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id='size-table-chest-size'
                      defaultMessage='Chest shirt size'
                    />
                  </td>
                  <td>65</td>
                  <td>65</td>
                  <td>67</td>
                  <td>68</td>
                  <td>71</td>
                  <td>74</td>
                  <td>74</td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id='size-table-width'
                      defaultMessage='Width shirt size'
                    />
                  </td>
                  <td>38</td>
                  <td>40</td>
                  <td>42</td>
                  <td>44</td>
                  <td>46</td>
                  <td>48</td>
                  <td>50</td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id='size-table-length'
                      defaultMessage='Length shirt size'
                    />
                  </td>
                  <td>61</td>
                  <td>61</td>
                  <td>62</td>
                  <td>64</td>
                  <td>64</td>
                  <td>64</td>
                  <td>64</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <img className={styles.svg} src={shirtImage} alt='Shirt sizes' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SizeTable)
