export default `**Address:**  
Moscow,  
Vorotnikovsky lane, house 8, building 1

**Contacts:**  
Phone: +7 (953) 358-98-84  
E-mail: shop@ice-pick.com  
[https://vk.com/icepickshop](https://vk.com/icepickshop)

**Business Hours:**  
Mon-Fri: from 12:00 to 20:00  
Sat-Sun: closed

**How to Find the Shop (Efficiently):**  
Get to the Mayakovskaya metro station, first train car. Exit 2: Tverskaya str., Sadovaya — Triumfalnaya str. After exiting, follow Tverskaya — Yamskaya until you reach "Mosohttorg". Turn right into an arch by a drugstore, then right again. You'll see a grey door. This is the one.`
