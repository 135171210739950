import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import { getIsFetching, getFieldIsDone } from 'src/redux/selectors/auth'

import { resetUserPassword, clearAuthError } from 'src/redux/actions/auth'

import { changeCheckbox } from 'src/redux/actions/fields'

import Separator from 'src/components/separator'

import ErrorMessage from 'src/components/auth-common/error-message'
import InputEmail from 'src/components/auth-common/input-email'
import InputPassword from 'src/components/auth-common/input-password'
import InputPasswordConfirm from 'src/components/auth-common/input-password-confirm'
import ButtonSubmit from 'src/components/auth-common/button-submit'

import * as styles from 'src/styles/auth-reset.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'
import type { InputCheckboxField } from 'src/types/fields'

interface OwnProps {
  location: Location
}

interface StateProps {
  isDone: Readonly<InputCheckboxField>
  isFetching: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    isDone: getFieldIsDone(state),
  }
}

function ResetView({ location: { search } }: OwnProps): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'reset-password' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  const { isDone, isFetching }: StateProps = useSelector(selector, shallowEqual)

  React.useEffect(
    function effect() {
      return function cleanup() {
        dispatch(changeCheckbox({ path: isDone.path, checked: false }))

        dispatch(clearAuthError())
      }
    },
    [isDone.path, dispatch]
  )

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(resetUserPassword(search.substr(1)))
    },
    [search, dispatch]
  )

  if (isDone.checked) {
    return <Redirect to={`/${intl.locale}/login/`} />
  }

  return (
    <form
      className={classnames(utility.defaultPage, styles.container)}
      onSubmit={onSubmit}
    >
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage id='reset-password' defaultMessage='Reset password' />
      </h2>

      <Separator />

      <ErrorMessage className={styles.error} />

      <p className={styles.text}>
        <FormattedMessage
          id='reset-password-details'
          defaultMessage='Reset password details'
        />
      </p>

      <InputEmail
        disabled={isFetching}
        className={styles.field}
        inputClassName={styles.input}
      />

      <InputPassword
        disabled={isFetching}
        className={styles.field}
        inputClassName={styles.input}
      />

      <InputPasswordConfirm
        disabled={isFetching}
        className={styles.field}
        inputClassName={styles.input}
      />

      <ButtonSubmit
        label='send'
        disabled={isFetching}
        className={styles.submit}
        loadingClassName={styles.submitLoading}
      />
    </form>
  )
}

export default React.memo(ResetView)
