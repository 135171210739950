import * as React from 'react'

import { rangeMap } from 'src/utils/range'

interface Props {
  page: number
  totalPage: number

  onPrev: React.MouseEventHandler
  onNext: React.MouseEventHandler
  onChange: React.ChangeEventHandler<HTMLSelectElement>
}

function Pagination({
  page,
  totalPage,
  onPrev,
  onNext,
  onChange,
}: Props): JSX.Element {
  return (
    <nav aria-label='Files pagination'>
      <ul className='pagination input-group justify-content-end'>
        <li className='page-item'>
          <button
            aria-label='Previous'
            className='page-link'
            disabled={page === 0}
            onClick={onPrev}
            type='button'
          >
            <span aria-hidden='true'>&laquo;</span>
          </button>
        </li>

        <select
          className='form-control flex-grow-0 flex-shrink-0 w-auto'
          value={page + 1}
          onChange={onChange}
          onBlur={onChange}
        >
          {rangeMap(totalPage, function mapper(index) {
            index += 1

            return (
              <option key={index} value={index}>
                {index}
              </option>
            )
          })}
        </select>

        <li className='page-item'>
          <button
            aria-label='Next'
            className='page-link'
            disabled={page >= totalPage - 1}
            onClick={onNext}
            type='button'
          >
            <span aria-hidden='true'>&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default React.memo(Pagination)
