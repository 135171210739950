import { Shipping } from 'src/const/shipping'

import type {
  TextField,
  FieldsPayload,
  ComboboxField,
  InputTextField,
} from 'src/types/fields'

import type { ApiAuth, ApiShippingCost } from 'src/types/api'

export interface DeliveryFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  cost: number
  currentMethod: TextField<Shipping>
  // Fields
  firstName: InputTextField
  lastName: InputTextField
  phone: InputTextField
  country: ComboboxField
  city: InputTextField
  zip: InputTextField
  address: InputTextField
  email: InputTextField
}

export const initialFields: DeliveryFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  cost: 0,
  currentMethod: {
    path: ['delivery', 'currentMethod'],
    value: Shipping.RussianPost,
  },
  // Fields
  firstName: {
    path: ['delivery', 'firstName'],
    id: 'firstName',
    label: 'first-name',
    required: true,
    value: '',
  },
  lastName: {
    path: ['delivery', 'lastName'],
    id: 'lastName',
    label: 'last-name',
    required: true,
    value: '',
  },
  phone: {
    path: ['delivery', 'phone'],
    id: 'phone',
    type: 'tel',
    label: 'phone-number',
    required: true,
    value: '',
  },
  country: {
    path: ['delivery', 'country'],
    id: 'country',
    label: 'country',
    required: true,
    value: '',
  },
  city: {
    path: ['delivery', 'city'],
    id: 'city',
    label: 'city',
    required: true,
    value: '',
  },
  zip: {
    path: ['delivery', 'zip'],
    id: 'zip',
    label: 'zip',
    required: true,
    value: '',
  },
  address: {
    path: ['delivery', 'address'],
    id: 'address',
    label: 'address',
    required: true,
    value: '',
  },
  email: {
    path: ['delivery', 'email'],
    id: 'email',
    type: 'email',
    label: 'email',
    required: true,
    value: '',
  },
}

export function authLogin(
  draft: DeliveryFieldsState,
  { user }: FieldsPayload<ApiAuth>
): void {
  draft.email.value = user.email || ''
  draft.firstName.value = user.first_name || ''
  draft.lastName.value = user.last_name || ''
  draft.phone.value = user.phone || ''
  draft.country.value = user.country || ''
  draft.address.value = user.address || ''
  draft.city.value = user.city || ''
  draft.zip.value = user.zip || ''
}

export function fetchSuccess(
  draft: DeliveryFieldsState,
  { cost }: FieldsPayload<ApiShippingCost>
): void {
  draft.isFetching = false
  draft.error = ''

  draft.cost = cost
}
