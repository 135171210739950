import * as React from 'react'

export interface ErrorMessageProps {
  error: string
}

export default function ErrorMessage({
  error,
}: ErrorMessageProps): JSX.Element {
  if (error === '') {
    return <></>
  }

  return (
    <div className='alert alert-danger' role='alert'>
      {error}
    </div>
  )
}
