import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getCategories,
  getFieldCategory,
} from 'src/redux/selectors/admin-product-edit'

import {
  getCategoriesOptions,
  getIsFetching as getIsFetchingCategories,
} from 'src/redux/selectors/admin-categories'

import {
  addCategoryToProduct,
  removeCategoryFromProduct,
} from 'src/redux/actions/admin-product'

import InputSelect from 'src/components/common/input-select'

import Category from './category'

import type { ReduxState } from 'src/types/common'
import type { ComboboxField, ComboboxOption } from 'src/types/fields'

interface StateProps {
  categories: readonly number[]
  disabled: boolean
  options: readonly Readonly<ComboboxOption>[]
  field: Readonly<ComboboxField>
}

function selector(state: ReduxState): StateProps {
  return {
    categories: getCategories(state),
    disabled: getIsFetching(state) || getIsFetchingCategories(state),
    options: getCategoriesOptions(state),
    field: getFieldCategory(state),
  }
}

function Categories(): JSX.Element {
  const dispatch = useDispatch()

  const onChange = React.useCallback(
    function callback(id: string) {
      if (id !== '') {
        dispatch(addCategoryToProduct(Number(id)))
      }
    },
    [dispatch]
  )

  const onRemove = React.useCallback(
    function callback(index: number) {
      dispatch(removeCategoryFromProduct(index))
    },
    [dispatch]
  )

  const { categories, disabled, options, field }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={field.id}
      >
        Категории
      </label>

      <div className='col-sm-10'>
        <InputSelect
          field={field}
          options={options}
          disabled={disabled}
          className='form-control'
          onChange={onChange}
        />

        <div className='row col-12'>
          {categories.map(function mapper(id, index): JSX.Element {
            return (
              <Category
                key={id}
                id={id}
                index={index}
                disabled={disabled}
                onRemove={onRemove}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Categories)
