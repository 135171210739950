import * as React from 'react'
import { useDispatch } from 'react-redux'

import { changeInput } from 'src/redux/actions/fields'

import type { InputNumberField } from 'src/types/fields'

interface OwnProps {
  field: Readonly<InputNumberField>
  title?: string
  disabled?: boolean
  className?: string
  onChange?(value: number, path: readonly string[]): void
  onFocus?: React.FocusEventHandler
}

export default function InputNumber({
  field,
  disabled,
  onChange,
  ...props
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onInputChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    function callback({ target: { value } }) {
      const number = parseFloat(value) || 0

      dispatch(
        changeInput({
          path: field.path,
          value: number,
        })
      )

      if (typeof onChange === 'function') {
        onChange(number, field.path)
      }
    },
    [field.path, onChange, dispatch]
  )

  return (
    <input
      {...props}
      id={field.id}
      type='number'
      value={Number.isNaN(field.value) ? '' : field.value}
      disabled={field.disabled || disabled}
      required={field.required}
      placeholder={field.placeholder}
      onChange={onInputChange}
    />
  )
}
