import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getSimilar } from 'src/redux/selectors/shop'

import SimilarListItem from './similar'

import * as styles from 'src/styles/product.module.css'

import type { Product } from 'src/types/user'

type StateProps = readonly Readonly<Product>[]

function ProductSimilars(): JSX.Element {
  const similar: StateProps = useSelector(getSimilar)

  if (similar.length === 0) {
    return <></>
  }

  return (
    <div
      className={styles.similarList}
      itemScope
      itemType='http://schema.org/OfferCatalog'
    >
      <h4 className={styles.similarListHeader} itemProp='name'>
        <FormattedMessage id='similar' defaultMessage='Similar' />
      </h4>

      <section className={styles.similarListContent}>
        {similar.map(function mapper(product): JSX.Element {
          return <SimilarListItem key={product.id} product={product} />
        })}
      </section>
    </div>
  )
}

export default React.memo(ProductSimilars)
