import * as React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { getProduct } from 'src/redux/selectors/shop'

import * as styles from 'src/styles/product.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

function selector(state: ReduxState): string {
  return getProduct(state).name
}

function HeadlineMobile(): JSX.Element {
  const name: string = useSelector(selector)

  return <h2 className={classnames(utility.mobileHide, styles.name)}>{name}</h2>
}

export default React.memo(HeadlineMobile)
