import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { getProduct } from 'src/redux/selectors/shop'

import SelectParameter from './select-parameter'

import * as styles from 'src/styles/product.module.css'

import type { ReduxState } from 'src/types/common'
import type { ProductParameter } from 'src/types/user'

interface StateProps {
  parameters: readonly Readonly<ProductParameter>[]
  current: ReadonlyArray<readonly [number, number]>
}

function selector(state: ReduxState): StateProps {
  const { parameters, currentParameters } = getProduct(state)

  return {
    parameters,
    current: currentParameters,
  }
}

function ProductParameters(): JSX.Element {
  const { parameters, current } = useSelector(selector, shallowEqual)

  if (parameters.length === 0) {
    return <></>
  }

  return (
    <div className={styles.parameters}>
      {parameters.map(function mapper(parameter, index): JSX.Element {
        return (
          <SelectParameter
            key={parameter.id}
            value={current[index][1]}
            parameter={parameter}
          />
        )
      })}
    </div>
  )
}

export default React.memo(ProductParameters)
