import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { shippingMethods, Shipping } from 'src/const/shipping'

import { getDeliverySelectedMethod } from 'src/redux/selectors/delivery'

import ShippingMethod from './method'
import ShippingError from './shipping-error'

import * as styles from 'src/styles/order-shipping.module.css'

function ShippingMethods(): JSX.Element {
  const current: Shipping = useSelector(getDeliverySelectedMethod)

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>
        <FormattedMessage
          id='delivery-method'
          defaultMessage='Delivery method'
        />
      </h2>

      <section>
        {Object.values(shippingMethods).map(function mapper(method) {
          return (
            <ShippingMethod
              key={method.name}
              method={method}
              selected={current === method.method}
            />
          )
        })}
      </section>

      <ShippingError />
    </div>
  )
}

export default React.memo(ShippingMethods)
