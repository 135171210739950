import * as React from 'react'
import { useSelector } from 'react-redux'

import { getWishlistCount } from 'src/redux/selectors/admin-product-edit'

function InfoWishlist(): JSX.Element {
  const count: number = useSelector(getWishlistCount)

  return (
    <div className='form-group row'>
      <span className='col-sm-2 text-md-right col-form-label'>
        В списках желаемого
      </span>

      <span className='col-sm-2 col-form-label text-center alert-success mx-3 d-flex align-items-center justify-content-center'>
        {count}
      </span>
    </div>
  )
}

export default React.memo(InfoWishlist)
