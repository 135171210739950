import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { shopSorting } from 'src/const/shop-sorting'

import { getFieldSorting } from 'src/redux/selectors/shop'

import InputSelectIntl from 'src/components/common/input-select-intl'

import * as styles from 'src/styles/shop.module.css'

import type { ComboboxField } from 'src/types/fields'

type StateProps = Readonly<ComboboxField>

function SelectSorting(): JSX.Element {
  const field: StateProps = useSelector(getFieldSorting)

  return (
    <label className={styles.controls} htmlFor={field.id}>
      <FormattedMessage id='sort-by' defaultMessage='Sort by' />

      <InputSelectIntl
        className={styles.controlsCombobox}
        options={shopSorting}
        field={field}
      />
    </label>
  )
}

export default React.memo(SelectSorting)
