import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import * as styles from 'src/styles/order-shipping.module.css'

function Headline(): JSX.Element {
  return (
    <h2 className={styles.dataHeader}>
      <FormattedMessage id='recipient-data' defaultMessage='Recipient data' />
    </h2>
  )
}

export default React.memo(Headline)
