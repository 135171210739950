import type { AdminLocaleFieldsState } from 'src/redux/reducers/admin-locale'

import type { TextField } from 'src/types/fields'
import type { ReduxState } from 'src/types/common'
import type { AdminLocaleMenuData } from 'src/redux/reducers/admin-locale'

function getState(state: ReduxState): AdminLocaleFieldsState {
  return state.fields.adminLocale
}

export function getLocale(state: ReduxState): Readonly<TextField> {
  return getState(state).current
}

export function getLocalesList(state: ReduxState): readonly string[] {
  return getState(state).localeList
}

export function getLocales(
  state: ReduxState
): Record<string, AdminLocaleMenuData> {
  return getState(state).locales
}

export function getLocaleDataBySlug(
  state: ReduxState,
  slug: string
): AdminLocaleMenuData {
  return getLocales(state)[slug]
}
