import * as React from 'react'

function AdminNotFoundView(): JSX.Element {
  return (
    <div className='jumbotron'>
      <div className='container'>
        <h1 className='display-3'>404 Not Found</h1>

        <p>
          Запрошенная страница не найдена, или её функционал ещё не реализован
        </p>
      </div>
    </div>
  )
}

export default React.memo(AdminNotFoundView)
