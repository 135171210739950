import type { FieldsPayload } from 'src/types/fields'
import type { WishProductsMap } from 'src/types/user'

import type {
  ApiResponseShop,
  ApiResponseWishlist,
  ApiWishlistAddProduct,
  ApiWishlistRemoveProduct,
} from 'src/types/api'

export interface WishlistFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  productList: number[]
  products: WishProductsMap
}

export const initialFields: WishlistFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  productList: [],
  products: new Map(),
}

export function shopInit(
  draft: WishlistFieldsState,
  result: ApiResponseShop
): void {
  draft.productList = result.wishlist
}

export function fetchSuccess(
  draft: WishlistFieldsState,
  { products }: FieldsPayload<ApiResponseWishlist>
): void {
  draft.isFetching = false
  draft.error = ''

  const map: WishProductsMap = new Map()

  for (const product of products) {
    map.set(product.id, product)
  }

  const list = products.map(function mapper({ id }): number {
    return id
  })

  draft.productList = list
  draft.products = map
}

export function reducerAddProductToWishlist(
  draft: WishlistFieldsState,
  { id }: FieldsPayload<ApiWishlistAddProduct>
): void {
  if (!draft.productList.includes(id)) {
    draft.productList.push(id)
  }
}

export function reducerRemoveProductFromWishlist(
  draft: WishlistFieldsState,
  { product }: FieldsPayload<ApiWishlistRemoveProduct>
): void {
  draft.products.delete(product)

  const index = draft.productList.indexOf(product)

  if (index !== -1) {
    draft.productList.splice(index, 1)
  }
}
