import { createAction } from 'redux-actions'

import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'

import { SHOP_INIT } from 'src/redux/action-types'

import { getLocale } from 'src/redux/selectors/intl'
import { getFieldSearch } from 'src/redux/selectors/shop'

import {
  reducerSelectShopSection,
  reducerSelectProductParameter,
  reducerFetchShopProductDetails,
} from 'src/redux/reducers/shop'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchComplete,
  callAction,
  setIn,
} from './fields'

import type { FieldsAction } from 'src/types/common'
import type { ProductParameter } from 'src/types/user'

import type {
  ApiShopProduct,
  ApiShopProducts,
  ApiResponseShop,
} from 'src/types/api'

const shopInit = createAction<ApiResponseShop>(SHOP_INIT)

const path = ['shop'] as const

export function fetchShopInitial(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState())

    return fetch(`${BACKEND_URL}/api/initial?locale=${locale}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiResponseShop>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(shopInit(result))
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchShopInitial error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchProjectProducts(link: string): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState())

    return fetch(`${BACKEND_URL}/api/project?locale=${locale}&link=${link}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiShopProducts>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchProjectProducts error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchCategoryProducts(link: string): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState())

    return fetch(`${BACKEND_URL}/api/category?locale=${locale}&link=${link}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiShopProducts>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchCategoryProducts error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchSearchProducts(search: string): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState())

    return fetch(
      `${BACKEND_URL}/api/search?locale=${locale}&search=${search}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
        },
      }
    )
      .then<ApiShopProducts>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchSearchProducts error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function setShopSection(
  projectId: number,
  categoryId: number
): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        projectId,
        categoryId,
        action: getFunctionName({ reducerSelectShopSection }),
        path,
      })
    )
  }
}

export function setShopSearchFlag(flag: boolean): FieldsAction {
  return function thunk(dispatch, getState) {
    const field = getFieldSearch(getState())

    dispatch(setIn({ path: field.path, isFetching: flag }))
  }
}

export function fetchProduct(link: string): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState())

    return fetch(`${BACKEND_URL}/api/product?locale=${locale}&link=${link}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiShopProduct>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            callAction({
              ...result,
              action: getFunctionName({ reducerFetchShopProductDetails }),
              path,
            })
          )
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchProduct error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function changeProductParameter(
  parameter: Readonly<ProductParameter>,
  paramId: number,
  valueId: number
): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        parameter,
        paramId,
        valueId,
        action: getFunctionName({ reducerSelectProductParameter }),
        path,
      })
    )
  }
}
