import { connect } from 'react-redux'

import InputCheckbox, {
  AdminInputCheckboxProps,
} from 'src/admin/common/input-checkbox'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminProduct: { isFetching, weekSale },
  },
}: ReduxState): AdminInputCheckboxProps {
  return {
    disabled: isFetching,
    field: weekSale,
  }
}

export default connect(mapStateToProps)(InputCheckbox)
