import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { getOrderStatus } from 'src/redux/selectors/order'

import { cancelPaypalOrder } from 'src/redux/actions/order'

import LoadingPage from 'src/components/loading'
import Separator from 'src/components/separator'

import * as styles from 'src/styles/order-cancel.module.css'
import * as utility from 'src/styles/utility.module.css'

function OrderCancelPaypal(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'cancel-invoice' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  const { search } = useLocation()

  React.useEffect(
    function effect() {
      dispatch(cancelPaypalOrder(search.slice(1)))
    },
    [search, dispatch]
  )

  const status: number = useSelector(getOrderStatus)

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage id='cancel-invoice' defaultMessage='Cancel invoice' />
      </h2>

      <Separator />

      {status === 0 ? (
        <LoadingPage />
      ) : status > 0 ? (
        <div className={styles.status}>
          <FormattedMessage
            id='cancel-invoice-success'
            defaultMessage='Cancel invoice success'
          />
        </div>
      ) : (
        <div className={styles.status}>
          <p>
            <FormattedMessage
              id='cancel-invoice-fail'
              defaultMessage='Cancel invoice fail'
            />
          </p>
        </div>
      )}
    </div>
  )
}

export default React.memo(OrderCancelPaypal)
