import { connect } from 'react-redux'

import { getOrderShippingAddress } from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

const mapStateToProps = (state: ReduxState): InfoFieldProps => ({
  id: 'shippingAddress',
  name: 'Адрес',
  value: getOrderShippingAddress(state),
})

const InfoShippingAddress = connect(mapStateToProps)(InfoField)

export default InfoShippingAddress
