import type { Project } from 'src/types/admin'
import type { FieldsPayload } from 'src/types/fields'
import type { ApiAdminProjects } from 'src/types/api'

export interface AdminProjectsFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  list: Project[]
}

export const initialFields: AdminProjectsFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  list: [],
}

export function fetchSuccess(
  draft: AdminProjectsFieldsState,
  { list }: FieldsPayload<ApiAdminProjects>
): void {
  draft.isFetching = false
  draft.error = ''
  draft.list = list
}
