import * as React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { convertPrice } from 'src/utils/currency'

import { getCurrencyUsd } from 'src/redux/selectors/shop'
import { getLocale } from 'src/redux/selectors/intl'

import type { ReduxState } from 'src/types/common'

interface PriceUsdProps {
  value: number
  currency: number
}

function PriceUsdComponent({ value, currency }: PriceUsdProps): JSX.Element {
  const price = convertPrice(value, currency)

  return (
    <span>
      <FormattedNumber
        currencyDisplay='symbol'
        currency='USD'
        style='currency'
        value={price}
      />
    </span>
  )
}

const PriceUsd = React.memo(PriceUsdComponent)

interface StateProps {
  currency: number
  locale: string
}

function selector(state: ReduxState): StateProps {
  return {
    locale: getLocale(state),
    currency: getCurrencyUsd(state),
  }
}

interface OwnProps {
  value?: string | number
}

function PriceSchema({ value }: OwnProps): JSX.Element {
  const { locale, currency } = useSelector(selector, shallowEqual)

  value = parseFloat(value as string)

  if (!value) {
    return (
      <span>
        <FormattedMessage
          id='price-unavailable'
          defaultMessage='Price is unavailable'
        />
      </span>
    )
  }

  switch (locale) {
    case 'ru':
      return (
        <span>
          <FormattedNumber value={value} /> руб.
        </span>
      )
    default:
      return currency !== 0 ? (
        <PriceUsd value={value} currency={currency} />
      ) : (
        <span>
          <FormattedNumber
            currencyDisplay='symbol'
            currency='RUB'
            style='currency'
            value={value}
          />
        </span>
      )
  }
}

export default React.memo(PriceSchema)
