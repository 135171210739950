import * as React from 'react'
import { match } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { getIsFetching, getProjectByLink } from 'src/redux/selectors/shop'

import { fetchProjectProducts } from 'src/redux/actions/shop'

import LoadingPage from 'src/components/loading'
import SelectSorting from 'src/components/shop/select-sorting'

import ShopHeader from 'src/components/shop-header'
import ShopProductsList from 'src/components/shop/products-list'
import ShopSectionsProject from 'src/components/shop-sections/project'

import * as styles from 'src/styles/shop.module.css'

import type { ReduxState } from 'src/types/common'
import type { Project } from 'src/types/user'

interface StateProps {
  isFetching: boolean
  project: Readonly<Project> | undefined
}

interface MatchProps {
  link: string
}

interface OwnProps {
  match: match<MatchProps>
}

function ProjectView({ match }: OwnProps): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'title' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchProjectProducts(match.params.link))
    },
    [intl.locale, match.params.link, dispatch]
  )

  const { project, isFetching } = useSelector(function selector(
    state: ReduxState
  ): StateProps {
    return {
      isFetching: getIsFetching(state),
      project: getProjectByLink(state, match.params.link),
    }
  },
  shallowEqual)

  React.useEffect(
    function effect() {
      if (project !== undefined) {
        document.title = project.name
      }
    },
    [project]
  )

  return (
    <div className={styles.container}>
      <ShopHeader />

      <div className={styles.row}>
        <div className={styles.content}>
          <SelectSorting />

          {isFetching ? <LoadingPage /> : <ShopProductsList />}
        </div>

        <ShopSectionsProject project={project} />
      </div>
    </div>
  )
}

export default React.memo(ProjectView)
