import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import {
  getFieldSale,
  getFieldPrice,
  getIsFetching,
} from 'src/redux/selectors/admin-product-edit'

import InputNumber from 'src/components/common/input-number'

import type { ReduxState } from 'src/types/common'
import type { InputNumberField } from 'src/types/fields'

interface StateProps {
  disabled: boolean
  price: InputNumberField
  sale: InputNumberField
}

function selector(state: ReduxState): StateProps {
  return {
    disabled: getIsFetching(state),
    price: getFieldPrice(state),
    sale: getFieldSale(state),
  }
}

function InputPrice(): JSX.Element {
  const { sale, price, disabled }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={price.id}
      >
        {price.label}
      </label>

      <div className='col-sm-4'>
        <InputNumber
          className='form-control'
          disabled={disabled}
          field={price}
        />
      </div>

      <label
        className='col-form-label col-sm-2 text-sm-right'
        htmlFor={sale.id}
      >
        {sale.label}
      </label>

      <div className='col-sm-4'>
        <InputNumber
          className='form-control'
          disabled={disabled}
          field={sale}
        />
      </div>
    </div>
  )
}

export default React.memo(InputPrice)
