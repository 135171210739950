import * as React from 'react'

import { BACKEND_URL } from 'src/config'

function AdminFileManagerUploading(): JSX.Element {
  return (
    <div className='col-sm-2 my-2 p-1'>
      <div className='p-1 bg-primary'>
        <img src={`${BACKEND_URL}/svg/uploading.svg`} alt='Uploading...' />
      </div>
    </div>
  )
}

export default React.memo(AdminFileManagerUploading)
