import { produce } from 'immer'

import { getIn, mergeDeep } from 'src/utils/object'

import {
  // Hooks
  AUTH_LOGIN,
  AUTH_LOGOUT,
  UPDATE_PROFILE,
  SHOP_INIT,

  // Request
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_COMPLETE,
  CALL_ACTION,

  // Input
  SET_IN,
  CHANGE_FILE,
  CHANGE_LIST,
  CHANGE_INPUT,
  CHANGE_CHECKBOX,
} from 'src/redux/action-types'

import type {
  FieldsState,
  FieldsReducer,
  FieldsCallAction,
  FetchFailurePayload,
  InputTextField,
  InputFileField,
  InputNumberField,
  InputCheckboxField,
  FieldsPayload,
  ChangeInputField,
  ChangeInputFileField,
  ChangeInputCheckboxField,
  ListField,
  ChangeListField,
  FieldsAction,
} from 'src/types/fields'

import * as auth from './auth'
import * as cart from './cart'
import * as shop from './shop'
import * as order from './order'
import * as orderHistory from './order-history'
import * as delivery from './delivery'
import * as feedback from './feedback'
import * as personal from './personal'
import * as wishlist from './wishlist'

import * as adminLocale from './admin-locale'
import * as adminCategory from './admin-category'
import * as adminCategories from './admin-categories'
import * as adminProject from './admin-project'
import * as adminProjects from './admin-projects'
import * as adminProduct from './admin-product'
import * as adminProducts from './admin-products'
import * as adminOrder from './admin-order'
import * as adminOrders from './admin-orders'
import * as adminOrderSearch from './admin-order-search'

import type { ApiAuth, ApiResponseShop } from 'src/types/api'

const reducersFields = {
  // Admin
  adminLocale,
  adminCategory,
  adminCategories,
  adminProject,
  adminProjects,
  adminProduct,
  adminProducts,
  adminOrder,
  adminOrders,
  adminOrderSearch,
  // User
  auth,
  cart,
  shop,
  order,
  orderHistory,
  delivery,
  feedback,
  personal,
  wishlist,
} as const

type ReducersFields = typeof reducersFields

function pluck<
  T extends Record<string, FieldsReducer>,
  V extends keyof T[keyof T] & keyof FieldsReducer,
  R extends { [K in keyof T]: V extends keyof T[K] ? T[K][V] : never }
>(objects: T, key: V): R {
  return Object.entries(objects).reduce<R>(function reducer(acc, [name, data]) {
    acc[name as keyof R] = data[key] as R[keyof R]

    return acc
  }, {} as R)
}

const initialState = pluck(reducersFields, 'initialFields')

// Automatically get type from state
export type FieldsStateType = typeof initialState

// Create alias
type DraftState = {
  -readonly [K in keyof FieldsStateType]: FieldsStateType[K]
}

function defaultFetchRequest(
  draft: FieldsState,
  { path: _path, ...rest }: FieldsPayload
): void {
  draft.isFetching = true

  if (draft.error !== undefined) {
    draft.error = ''
  }

  Object.assign(draft, rest)
}

function defaultFetchSuccess(
  draft: FieldsState,
  { path: _path, ...rest }: FieldsPayload
): void {
  draft.isFetching = false

  if (draft.error !== undefined) {
    draft.error = ''
  }

  Object.assign(draft, rest)
}

function defaultFetchFailure(
  draft: FieldsState,
  { path: _path, ...rest }: FieldsPayload<FetchFailurePayload>
): void {
  draft.isFetching = false

  if (draft.success !== undefined) {
    draft.success = ''
  }

  Object.assign(draft, rest)
}

interface ActionHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (draft: DraftState, payload: any): void
}

const handlers: Record<string, ActionHandler | undefined> = {
  // Hooks
  [AUTH_LOGIN](draft: DraftState, payload: ApiAuth): void {
    for (const name in reducersFields) {
      if (Object.hasOwnProperty.call(reducersFields, name)) {
        type K = keyof DraftState & keyof ReducersFields

        const data: FieldsReducer | undefined = reducersFields[name as K]

        const handler =
          data !== undefined
            ? data['authLogin' as keyof FieldsReducer]
            : undefined

        if (handler instanceof Function) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          handler(draft[name as K], payload as any)
        }
      }
    }
  },

  [AUTH_LOGOUT](draft: DraftState): void {
    for (const name in reducersFields) {
      if (Object.prototype.hasOwnProperty.call(reducersFields, name)) {
        type K = keyof DraftState & keyof ReducersFields

        const data: FieldsReducer | undefined = reducersFields[name as K]

        const handler =
          data !== undefined
            ? data['authLogout' as keyof FieldsReducer]
            : undefined

        if (handler instanceof Function) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          handler(draft[name as K], undefined as any)
        } else {
          ;(draft[name as K] as FieldsState) = reducersFields[
            name as K
          ].initialFields
        }
      }
    }
  },

  [UPDATE_PROFILE](draft: DraftState, payload: ApiAuth): void {
    for (const name in reducersFields) {
      if (Object.hasOwnProperty.call(reducersFields, name)) {
        type K = keyof DraftState & keyof ReducersFields

        const data: FieldsReducer | undefined = reducersFields[name as K]

        const handler =
          data !== undefined
            ? data['profileUpdate' as keyof FieldsReducer]
            : undefined

        if (handler instanceof Function) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          handler(draft[name as K], payload as any)
        }
      }
    }
  },

  [SHOP_INIT](draft: DraftState, payload: ApiResponseShop): void {
    for (const name in reducersFields) {
      if (Object.hasOwnProperty.call(reducersFields, name)) {
        type K = keyof DraftState & keyof ReducersFields

        const data: FieldsReducer | undefined = reducersFields[name as K]

        const handler =
          data !== undefined
            ? data['shopInit' as keyof FieldsReducer]
            : undefined

        if (handler instanceof Function) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          handler(draft[name as K], payload as any)
        }
      }
    }
  },

  // Request
  [FETCH_REQUEST](draft: DraftState, payload: FieldsPayload): void {
    const path = payload.path[0]

    const data: FieldsReducer | undefined =
      reducersFields[path as keyof ReducersFields]

    const handler = (data && data.fetchRequest) || defaultFetchRequest

    const reducer = getIn(draft, payload.path)

    if (reducer !== undefined) {
      handler(reducer, payload)
    }
  },

  [FETCH_SUCCESS](draft: DraftState, payload: FieldsPayload): void {
    const path = payload.path[0]

    const data: FieldsReducer | undefined =
      reducersFields[path as keyof ReducersFields]

    const handler = (data && data.fetchSuccess) || defaultFetchSuccess

    handler(draft[path as keyof DraftState], payload)
  },

  [FETCH_FAILURE](draft: DraftState, payload: FieldsPayload): void {
    const path = payload.path[0]

    const data: FieldsReducer | undefined =
      reducersFields[path as keyof ReducersFields]

    const handler = (data && data.fetchFailure) || defaultFetchFailure

    handler(draft[path as keyof DraftState], payload)
  },

  [FETCH_COMPLETE](draft: DraftState, { path, ...rest }: FieldsPayload): void {
    mergeDeep(getIn(draft, path), {
      isFetching: false,
      ...rest,
    })
  },

  [CALL_ACTION](
    draft: DraftState,
    payload: FieldsPayload<FieldsCallAction>
  ): void {
    const path = payload.path[0]

    const data: FieldsReducer | undefined =
      reducersFields[path as keyof ReducersFields]

    if (!payload.action) {
      console.warn('Action should be contains "action" parameter.')

      return
    }

    const handler =
      data !== undefined
        ? data[payload.action as keyof FieldsReducer]
        : undefined

    if (!(handler instanceof Function)) {
      console.warn(
        `Action parameter "${payload.action}" is not a function name.`
      )

      return
    }

    handler(draft[path as keyof DraftState], payload)
  },

  // Inputs
  [SET_IN](draft: DraftState, { path, ...rest }: FieldsPayload): void {
    mergeDeep(getIn(draft, path), rest)
  },

  [CHANGE_INPUT](
    draft: DraftState,
    { path, value }: FieldsPayload<ChangeInputField>
  ) {
    const field = getIn<InputTextField | InputNumberField>(draft, path)

    field.value = value
  },

  [CHANGE_CHECKBOX](
    draft: DraftState,
    { path, checked }: FieldsPayload<ChangeInputCheckboxField>
  ) {
    const field = getIn<InputCheckboxField>(draft, path)

    field.checked = checked
  },

  [CHANGE_FILE](
    draft: DraftState,
    { path, file }: FieldsPayload<ChangeInputFileField>
  ) {
    const field = getIn<InputFileField>(draft, path)

    field.value = (file !== null && file.name) || ''
    field.file = file
  },

  [CHANGE_LIST](
    draft: DraftState,
    { path, list }: FieldsPayload<ChangeListField>
  ): void {
    const field = getIn<ListField>(draft, path)

    field.list = list
  },
}

// Reducer
export default produce(function producer(
  draft: FieldsStateType = initialState,
  action: FieldsAction
): FieldsStateType {
  const handler = handlers[action.type]

  if (handler !== undefined) {
    handler(draft, action.payload)
  }

  return draft
})
