import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import { getIsFetching, getFieldIsDone } from 'src/redux/selectors/auth'

import { changeCheckbox } from 'src/redux/actions/fields'

import { registerUser, clearAuthError } from 'src/redux/actions/auth'

import Separator from 'src/components/separator'

import ErrorMessage from 'src/components/auth-common/error-message'
import InputEmail from 'src/components/auth-common/input-email'
import InputPassword from 'src/components/auth-common/input-password'
import InputPasswordConfirm from 'src/components/auth-common/input-password-confirm'
import ButtonSubmit from 'src/components/auth-common/button-submit'

import CheckboxPrivacyPolicy from './checkbox-privacy-policy'

import * as styles from 'src/styles/auth-registration.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'
import type { InputCheckboxField } from 'src/types/fields'

interface StateProps {
  isDone: Readonly<InputCheckboxField>
  isFetching: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    isDone: getFieldIsDone(state),
  }
}

function RegistrationView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({
        id: 'registration',
        defaultMessage: 'Registration',
      })
    },
    [intl]
  )

  const dispatch = useDispatch()

  const { isFetching, isDone } = useSelector(selector, shallowEqual)

  React.useEffect(
    function effect() {
      return function cleanup() {
        dispatch(clearAuthError())

        dispatch(changeCheckbox({ path: isDone.path, checked: false }))
      }
    },
    [isDone.path, dispatch]
  )

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(registerUser())
    },
    [dispatch]
  )

  if (isDone.checked) {
    return (
      <div className={classnames(utility.defaultPage, styles.container)}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage id='success' defaultMessage='Success' />
        </h2>

        <Separator />

        <p className={styles.text}>
          <FormattedMessage
            id='registration-success'
            defaultMessage='Registration success'
          />
        </p>
      </div>
    )
  }

  return (
    <form
      className={classnames(utility.defaultPage, styles.container)}
      onSubmit={onSubmit}
    >
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage id='registration' defaultMessage='Registration' />
      </h2>

      <Separator />

      <ErrorMessage className={styles.error} />

      <InputEmail
        disabled={isFetching}
        className={classnames(utility.mt2, styles.field)}
        inputClassName={styles.input}
      />

      <InputPassword
        disabled={isFetching}
        className={styles.field}
        inputClassName={styles.input}
      />

      <InputPasswordConfirm
        disabled={isFetching}
        className={styles.field}
        inputClassName={styles.input}
      />

      <CheckboxPrivacyPolicy disabled={isFetching} />

      <ButtonSubmit
        label='sign-up'
        disabled={isFetching}
        className={styles.submit}
        loadingClassName={styles.submitLoading}
      />
    </form>
  )
}

export default React.memo(RegistrationView)
