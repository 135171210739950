import 'whatwg-fetch'

import * as React from 'react'
import { render } from 'react-dom'

import 'src/styles/root.css'

import App from 'src/app'
import registerServiceWorker from 'src/registerServiceWorker'

import { initLazyload } from 'src/lazyload'

if (!('Intl' in window)) {
  require('intl')
}

render(<App />, document.getElementById('root'))

initLazyload()

registerServiceWorker()
