import aboutUsContent from './about-ru'
import aboutWishlistContent from './about-wishlist-ru'
import paymentInfoContent from './payment-ru'
import shippingInfoContent from './shipping-ru'
import privacyPolicyContent from './privacy-policy-ru'
import ourLocationContent from './location-ru'

export default {
  locale: 'ru',
  messages: {
    title: 'Ice-Pick Shop',
    subtitle: 'Официальный магазин Ice-Pick Lodge',
    menu: 'Меню',
    error: 'Ошибка',
    next: 'Далее',
    copyright: '© Все права защищены Ice-Pick Lodge 2017',
    projects: 'Проекты',
    categories: 'Категории',
    featured: 'Товары недели',
    cart: 'Корзина',
    wishlist: 'Список желаемого',
    payment: 'Оплата',
    shipping: 'Доставка',
    'about-us': 'О нас',
    'about-wishlist': 'Как работает список желаемого?',
    partners: 'Партнёры',
    administration: 'Администрирование',
    'privacy-policy': 'Политика конфиденциальности',
    'our-location': 'Как нас найти?',

    'overlay-test-info':
      'Сайт работает в тестовом режиме. Если что-то пойдёт не так, пожалуйста, напишите нам на shop@ice-pick.com.',

    'shop-is-closed': 'Магазин закрыт',
    'shop-is-closed-desc':
      'Магазин больше не работает. Возможно, он когда-нибудь вернется (но это не точно).',
    'shop-is-closed-info': 'Cледите за новостями в наших сообществах!',
    'shop-is-closed-feedback':
      'Если у вас остались какие-то вопросы, то пишите нам на',

    account: 'Аккаунт',
    support: 'Поддержка',
    amp: 'и',

    'about-us-content': aboutUsContent,
    'about-wishlist-header': 'Как работает вишлист?',
    'about-wishlist-content': aboutWishlistContent,

    'our-location-content': ourLocationContent,

    'privacy-policy-content': privacyPolicyContent,
    'payment-info-content': paymentInfoContent,
    'shipping-info-content': shippingInfoContent,

    'partners-info':
      'Эти компании — не только наши партнёры, но и друзья, поэтому они не просто исполняют заказы, а шлифуют и полируют их так, как шлифуют и полируют только для друзей. Так что за качество (и блеск) товаров мы ручаемся.',

    'page-not-found': 'Страница не найдена',
    'page-not-found-details':
      'Страница, к которой вы запросили доступ, не найдена.',
    'access-denied': 'Доступ запрещён',
    'access-denied-details':
      'Страница, к которой вы запросили доступ, недоступна для вас. Возможно, стоит проверить, авторизованы ли вы?',

    registration: 'Регистрация',
    'password-confirm': 'Подтвердите пароль',
    'accept-terms': 'Принимаю условия',
    'privacy-policy-name': '«СОГЛАШЕНИЯ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ»',
    'sign-up': 'Зарегистрироваться',
    success: 'Успех!',
    'registration-success':
      'Вы успешно зарегистрированы. Мы выслали вам на почту письмо с потверждением регистрации.',
    activate: 'Активация e-mail',

    'sign-in-header': 'Вход',
    email: 'E-mail',
    password: 'Пароль',
    'forgot-password': 'Забыли пароль?',
    'forgot-password-details':
      'Введите e-mail, на который зарегистрирован ваш аккаунт, и мы вышлем вам инструкцию по восстановлению пароля.',
    'sign-in': 'Войти',
    logout: 'Выйти',

    'reset-password': 'Изменить пароль',
    'reset-password-details': 'Введите e-mail вашего аккаунта и новый пароль.',

    feedback: 'Обратная связь',
    'your-name': 'Ваше имя',
    message: 'Сообщение',
    send: 'Отправить',

    'sort-by': 'Сортировка:',
    'sort-option-1': 'без сортировки',
    'sort-option-2': 'по возрастанию цены',
    'sort-option-3': 'по убыванию цены',
    'sort-option-4': 'только доступные',

    search: 'Поиск',
    'search.placeholder': 'Поиск...',
    'found-count': `{count, plural,
      =0 {Ничего не найдено}
      one {Найден {count} товар}
      few {Найдено {count} товара}
      many {Найдено {count} товаров}
    }`,

    'price-unavailable': 'Цена не указана',
    'in-stock': 'В наличии',
    'out-of-stock': 'Отсутствует',
    'add-to-cart': 'В корзину',
    similar: 'Возможно заинтересует',
    reviews: 'Отзывы',

    'wishlist-empty': 'В вашем списке желаемого нет товаров.',
    'wishlist-unavailable':
      'Список желаемого доступен только зарегистрированным пользователям.',
    'cart-empty': 'В вашей корзине нет товаров.',
    'cart-unavailable':
      'У вас в корзине есть товары, недоступные для заказа. Либо их вовсе нет в продаже, либо у нас на складе осталось меньше, чем вы заказали.',

    'order-placement': 'Оформление заказа',
    'total-cost': 'Итого:',
    'place-order': 'Оформить заказ',

    'delivery-method': 'Способ доставки',
    'russian-post': 'почта России (по всему миру)',
    'boxberry-default':
      'пункт выдачи Boxberry (по России; выберите удобный вам <a href="http://boxberry.ru/find_an_office/" target="_blank">тут</a> и укажите его в комментарии к заказу)',
    'boxberry-courier': 'курьер Boxberry (по России)',
    'customer-pick-up':
      'самовывоз со склада.<br>(адрес: г. Москва, Воротниковский пер., д8, с1<br><a href="/ru/our-location">Как нас найти</a>)',

    'personal-details': 'Мои данные',
    'personal-details-success': 'Данные успешно сохранены',
    save: 'Сохранить',
    'recipient-data': 'Данные получателя',
    'first-name': 'Имя',
    'last-name': 'Фамилия',
    'phone-number': 'Телефон',
    country: 'Страна',
    city: 'Город',
    zip: 'Индекс',
    address: 'Адрес',

    'phone-number-mobile': 'Мобильный телефон',

    'order-history': 'История заказов',
    'order-history-empty': 'В вашей истории пока нет заказов.',
    'order-number': 'Заказ №{number} от {date}',
    'order-repeat': 'Повторить заказ',

    'order-status-unknown': 'Unknown',
    'order-status-0': 'Текущий',
    'order-status-1': 'В обработке',
    'order-status-2': 'Отменён',
    'order-status-3': 'Обработан',
    'order-status-4': 'Готов к отправке',
    'order-status-5': 'Отправлен',
    'order-status-6': 'Особый',

    'shipping-cost': 'Доставка:',
    'products-cost': 'Стоимость товаров:',

    'payment-method': 'Способ оплаты',
    'order-comment': 'Комментарий к заказу',
    'to-pay': 'К оплате',

    'internal-server-error': 'Ошибка сервера',
    'unknown-payment-system':
      'Выбранная вами платёжная система не распознана сервером. Обратитесь в поддержку.',
    'unknown-delivery-method':
      'Выбранный вами способ доставки не был распознан сервером. Обратитесь в поддержку.',
    'invalid-currency-rate':
      'Курс валюты не смог быть получен на сервере. Обратитесь в поддержку.',
    'order-cannot-be-processed': 'Заказ недоступен для обработки.',

    'invoice-status': 'Статус платежа',
    'invoice-success':
      'Ваш заказ успешно оформлен и будет доставлен в самое ближайшее время на больших орлах.',
    // Paypal
    'invoice-fail-paypal-1':
      'Платёж был отменён (ваши средства возвращены обратно).',
    'invoice-fail-paypal-2':
      'Что-то пошло не так, попробуйте оформить заказ заново.',
    'invoice-fail-paypal-3':
      'При повторном возникновении этой ошибки обратитесь в поддержку.',
    // Other
    'invoice-fail-1': 'К сожалению, что-то пошло не так.',
    'invoice-fail-2':
      'Скорее всего, мы получили ваши деньги, но не получили информации о заказе.',
    'invoice-fail-3': 'Пожалуйста, напишите об этой ситуации в поддержку.',

    'cancel-invoice': 'Отмена платежа',
    'cancel-invoice-success':
      'Ваш заказ был отменён. Все товары снова доступны в корзине.',
    'cancel-invoice-fail':
      'Заказ уже не может быть отменён. Вам придётся сделать новый.',

    // Partners
    'partner-1-name': 'Wild & Nuclear — мастерская',
    'partner-1-desc':
      'Наши многорукие друзья, ваяющие всё объёмное из всех попадающихся под руку материалов, от пластика для 3D-принтера до ткани. Делают разнообразные ролевые артефакты.',

    'partner-2-name': 'Культура печати — типография',
    'partner-2-desc':
      'Официальный партнёр, печатающий нам всё, что печатается на бумаге. Не боятся творческих задач, необычных фактур и вырубки странных форм.',

    pick_up_city: 'Москва',
    pick_up_address: 'Воротниковский пер., д8, с1',

    'size-table': 'Таблица размеров',
    'size-table-mens': 'Мужские',
    'size-table-womens': 'Женские',
    'size-table-size-rus': 'Размеры RUS',
    'size-table-chest-size': 'обхват груди, см.',
    'size-table-width': 'ширина, см.',
    'size-table-length': 'длина, см',
  },
}
