import * as React from 'react'
import { useSelector } from 'react-redux'

import { getIsFetching } from 'src/redux/selectors/admin-order'

function ButtonSubmit(): JSX.Element {
  const disabled = useSelector(getIsFetching)

  return (
    <div className='col-sm-12 text-center'>
      <button className='btn btn-primary' disabled={disabled} type='submit'>
        Применить
      </button>
    </div>
  )
}

export default React.memo(ButtonSubmit)
