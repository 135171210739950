import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Payment, PaymentMethod, paymentMethods } from 'src/const/payment'

import { getFieldPayment } from 'src/redux/selectors/order'

import { changeInput } from 'src/redux/actions/fields'

import LazyImage from 'src/components/lazyimage'

import * as styles from 'src/styles/order-payment.module.css'

interface OwnProps {
  selected: boolean
  type: Payment
}

function PaymentMethodsListItem({ type, selected }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const field = useSelector(getFieldPayment)

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      dispatch(changeInput({ path: field.path, value: type }))
    },
    [type, field.path, dispatch]
  )

  const method = React.useMemo<PaymentMethod>(
    function memo() {
      return paymentMethods[type]
    },
    [type]
  )

  return (
    <button className={styles.item} onClick={onClick} type='button'>
      <div aria-selected={selected} className={styles.itemImage}>
        <LazyImage src={method.image} alt={method.name} />
      </div>

      {method.name}
    </button>
  )
}

export default React.memo(PaymentMethodsListItem)
