import type {
  FieldsPayload,
  InputTextField,
  InputLinkField,
  InputFileField,
  InputCheckboxField,
} from 'src/types/fields'

import type { ApiAdminProject } from 'src/types/api'

export interface AdminProjectFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Project id
  id: number
  // Fields
  name: InputTextField
  link: InputLinkField
  image: InputFileField
  visible: InputCheckboxField
}

export const initialFields: AdminProjectFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Project id
  id: 0,
  // Fields
  name: {
    path: ['adminProject', 'name'],
    id: 'name',
    label: 'Название',
    required: true,
    value: '',
  },
  link: {
    path: ['adminProject', 'link'],
    id: 'link',
    link: '/project/',
    label: 'Ссылка',
    required: true,
    value: '',
  },
  image: {
    path: ['adminProject', 'image'],
    id: 'image',
    link: '/img/projects/',
    label: 'Изображение',
    file: null,
    value: '',
  },
  visible: {
    path: ['adminProject', 'visible'],
    id: 'visible',
    label: 'Видимость',
    description: 'Показывать на сайте',
    checked: true,
    value: '1',
  },
}

export function fetchSuccess(
  draft: AdminProjectFieldsState,
  { project }: FieldsPayload<ApiAdminProject>
): void {
  draft.isFetching = false
  draft.error = ''

  draft.id = project.id || 0
  draft.name.value = project.name ? project.name.value : ''
  draft.link.value = project.link || ''
  draft.image.value = project.image ? project.image.value : ''
  draft.visible.checked = Boolean(Number(project.status) & 1)
}

export function reducerAdminClearProjectFields(
  draft: AdminProjectFieldsState,
  _payload: FieldsPayload
): void {
  Object.assign(draft, initialFields)
}
