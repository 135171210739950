import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { getIsLoggedIn } from 'src/redux/selectors/auth'

import {
  getProducts,
  getIsFetching,
  getProductList,
} from 'src/redux/selectors/wishlist'

import Separator from 'src/components/separator'
import LoadingPage from 'src/components/loading'

import WishListItem from './wish-list-item'

import * as styles from 'src/styles/wishlist.module.css'

import type { ReduxState } from 'src/types/common'
import type { WishProductsMap } from 'src/types/user'

interface StateProps {
  isFetching: boolean
  isLoggedIn: boolean
  productList: readonly number[]
  products: WishProductsMap
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    isLoggedIn: getIsLoggedIn(state),
    productList: getProductList(state),
    products: getProducts(state),
  }
}

function WishList(): JSX.Element {
  const {
    isFetching,
    isLoggedIn,
    productList,
    products,
  }: StateProps = useSelector(selector, shallowEqual)

  const list = React.useMemo<readonly number[]>(
    function memo() {
      return productList.filter(function filter(id): boolean {
        return products.has(id)
      })
    },
    [productList, products]
  )

  return (
    <div>
      <h2 className={styles.title}>
        <FormattedMessage id='wishlist' defaultMessage='Wishlist' />
      </h2>

      <Separator />

      {isFetching ? (
        <LoadingPage />
      ) : isLoggedIn === false ? (
        <p className={styles.empty}>
          <FormattedMessage
            id='wishlist-unavailable'
            defaultMessage='Wishlist is unavailable'
          />
        </p>
      ) : (
        productList.length === 0 && (
          <p className={styles.empty}>
            <FormattedMessage
              id='wishlist-empty'
              defaultMessage='Wishlist is empty'
            />
          </p>
        )
      )}

      {!isFetching && productList.length > 0 && (
        <section className={styles.container}>
          {list.map(function mapper(id): JSX.Element {
            return <WishListItem key={id} id={id} />
          })}
        </section>
      )}
    </div>
  )
}

export default React.memo(WishList)
