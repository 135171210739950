import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BACKEND_URL } from 'src/config'

import { getIsFetching } from 'src/redux/selectors/admin-project-edit'

import {
  swapProductImages,
  removeProductImage,
} from 'src/redux/actions/admin-product'

import LazyImage from 'src/components/lazyimage'

const styleButton: React.HTMLAttributes<HTMLButtonElement>['style'] = {
  right: 0,
  left: 'auto',
} as const

interface OwnProps {
  index: number
  image: string
  onDragOver: React.DragEventHandler
}

function ProductImage({ index, image, onDragOver }: OwnProps): JSX.Element {
  const onDragStart = React.useCallback<React.DragEventHandler>(
    function onDragStart(event) {
      event.dataTransfer.setData('index', index.toString())
    },
    [index]
  )

  const dispatch = useDispatch()

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      dispatch(removeProductImage(index))
    },
    [index, dispatch]
  )

  const onDrop = React.useCallback<React.DragEventHandler>(
    function onDrop(event) {
      event.preventDefault()

      const fromIndex = Number(event.dataTransfer.getData('index'))

      dispatch(swapProductImages(fromIndex, index))
    },
    [index, dispatch]
  )

  const disabled: boolean = useSelector(getIsFetching)

  return (
    <div
      className='col-sm-2 img-thumbnail mt-3 mr-3 position-relative'
      draggable
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <LazyImage
        className='overflow-hidden'
        src={`${BACKEND_URL}/img/products/thumb/${image}`}
        alt={image}
      />

      <button
        style={styleButton}
        className='btn btn-danger position-absolute fixed-bottom'
        disabled={disabled}
        onClick={onClick}
        type='button'
      >
        <span aria-hidden='true'>×</span>
      </button>
    </div>
  )
}

export default React.memo(ProductImage)
