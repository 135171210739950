import { connect } from 'react-redux'

import { shippingMethodAsString } from 'src/const/shipping'

import { getOrderShippingMethod } from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

function mapStateToProps(state: ReduxState): InfoFieldProps {
  const shipping = getOrderShippingMethod(state)

  return {
    id: 'shippingMethod',
    name: 'Способ доставки',
    value: shipping ? shippingMethodAsString(shipping) : shipping,
  }
}

const InfoShippingMethod = connect(mapStateToProps)(InfoField)

export default InfoShippingMethod
