import * as React from 'react'

import { BACKEND_URL } from 'src/config'

import InputFile from 'src/components/common/input-file'

import type { InputFileField } from 'src/types/fields'

export interface AdminInputImageProps {
  disabled: boolean
  field: InputFileField
  show: boolean
}

export default function InputImage({
  show,
  field,
  disabled,
}: AdminInputImageProps): JSX.Element {
  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={field.id}
      >
        {field.label}
      </label>

      <div className='col-sm-10'>
        <InputFile
          className='form-control-file mt-1'
          disabled={disabled}
          field={field}
        />

        {show && field.value.length > 0 && (
          <div className='col-sm-3 img-thumbnail mt-3'>
            <img
              src={`${BACKEND_URL}${field.link}${field.value}`}
              alt='Project'
            />
          </div>
        )}
      </div>
    </div>
  )
}
