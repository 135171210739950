import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import {
  linksOne,
  linksTwo,
  linkAdmin,
  linkLogout,
  linksAuthorized,
  linksUnauthorized,
} from 'src/const/navigation'

import {
  getIsAdmin,
  getUserEmail,
  getIsLoggedIn,
} from 'src/redux/selectors/auth'

import { logoutUser } from 'src/redux/actions/auth'

import NavPanelLink from './nav-panel-link'
import LangList from './lang-list'

import * as styles from 'src/styles/header.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isAdmin: boolean
  userEmail: string
  isLoggedIn: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    isAdmin: getIsAdmin(state),
    userEmail: getUserEmail(state),
    isLoggedIn: getIsLoggedIn(state),
  }
}

interface OwnProps {
  visible: boolean
  onClick(): void
}

function NavPanel({ visible, onClick }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onLogout = React.useCallback<React.MouseEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(logoutUser())
    },
    [dispatch]
  )

  const { userEmail, isLoggedIn, isAdmin }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  return (
    <div aria-expanded={visible} className={styles.nav}>
      <h3 className={styles.navHeader}>
        <FormattedMessage id='menu' defaultMessage='Menu' />
      </h3>

      {isLoggedIn ? (
        <ul className={classnames(styles.navLinks, styles.navLinksPersonal)}>
          <li className={styles.navLink}>{userEmail}</li>

          {linksAuthorized.map(function mapper(item) {
            return (
              <NavPanelLink
                key={item.link}
                item={item}
                onClick={onClick}
                className={styles.navLinkChild}
              />
            )
          })}

          {isAdmin && (
            <NavPanelLink
              className={styles.navLinkChild}
              onClick={onClick}
              item={linkAdmin}
            />
          )}

          <NavPanelLink
            className={styles.navLinkChild}
            item={linkLogout}
            onClick={onLogout}
          />
        </ul>
      ) : (
        <ul className={classnames(styles.navLinks, styles.navLinksPersonal)}>
          {linksUnauthorized.map(function mapper(item): JSX.Element {
            return (
              <NavPanelLink key={item.link} item={item} onClick={onClick} />
            )
          })}
        </ul>
      )}

      <ul className={styles.navLinks}>
        {linksOne.map(function mapper(item): JSX.Element {
          return <NavPanelLink key={item.link} item={item} onClick={onClick} />
        })}
      </ul>

      <ul className={styles.navLinks}>
        {linksTwo.map(function mapper(item): JSX.Element {
          return <NavPanelLink key={item.link} item={item} onClick={onClick} />
        })}
      </ul>

      <div className={styles.navMobileLangs}>
        <LangList />
      </div>
    </div>
  )
}

export default React.memo(NavPanel)
