import { BACKEND_URL } from 'src/config'

import { getLaravelErrorMessage } from 'src/utils/laravel'

// import { Shipping } from 'src/const/shipping'

// import { getMessageById } from 'src/redux/selectors/intl'

import {
  getDeliveryCurrentMethod,
  // getDeliverySelectedMethod,
} from 'src/redux/selectors/delivery'

import {
  getFieldZip,
  // getFieldCity,
  // getFieldAddress,
  getFieldCountry,
} from 'src/redux/selectors/delivery'

// import {
//   getFieldZip as getPersonalFieldZip,
//   getFieldCity as getPersonalFieldCity,
//   getFieldAddress as getPersonalFieldAddress,
//   getFieldCountry as getPersonalFieldCountry,
// } from 'src/redux/selectors/personal-fields'

// import { changeInput } from './fields'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchComplete,
} from './fields'

import type {
  Dispatch,
  GetState,
  FieldsAction,
  DebouncedFieldsAction,
} from 'src/types/common'

import type { ApiShippingCost, LaravelResponse } from 'src/types/api'

// import type { FieldsPayload, ChangeInputField } from 'src/types/fields'

const path = ['delivery'] as const

function fetchDeliveryCost(debounce = 2500): DebouncedFieldsAction {
  const thunk = function thunk(
    dispatch: Dispatch,
    getState: GetState
  ): Promise<void> {
    const state = getState()

    const fields = {
      method: getDeliveryCurrentMethod(state),
      country: getFieldCountry(state).value,
      zip: getFieldZip(state).value,
    }

    const search = Object.entries(fields).reduce<string[]>(function reducer(
      acc,
      [field, value]
    ) {
      if ((value as string).length > 0) {
        acc.push(`${field}=${encodeURI(value as string)}`)
      }

      return acc
    },
    [])

    const query = search.length > 0 ? `?${search.join('&')}` : ''

    return fetch(`${BACKEND_URL}/api/delivery${query}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiShippingCost | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success !== undefined) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchDeliveryCost error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }

  thunk.meta = {
    debounce: {
      key: `deliveryCostCalc`,
      time: debounce,
      leading: false,
      trailing: true,
    },
  }

  return thunk
}

export function getShippingCost(): FieldsAction {
  return function thunk(dispatch, getState) {
    const state = getState()

    const country = getFieldCountry(state).value
    const zip = getFieldZip(state).value

    if (country.length > 0 && zip.length > 0) {
      dispatch(fetchRequest({ path }))

      dispatch(fetchDeliveryCost())
    } else {
      dispatch(fetchComplete({ path }))
    }
  }
}

// export function changeDeliveryAddress(shippingMethod: Shipping): FieldsAction {
//   return function thunk(dispatch, getState) {
//     const state = getState()

//     const zip = getFieldZip(state)
//     const city = getFieldCity(state)
//     const country = getFieldCountry(state)
//     const address = getFieldAddress(state)

//     const payloads: FieldsPayload<ChangeInputField>[] = []

//     if (shippingMethod === Shipping.PickUp) {
//       payloads.push(
//         { path: city.path, value: getMessageById(state, 'pick_up_city') },
//         { path: zip.path, value: '127006' },
//         { path: address.path, value: getMessageById(state, 'pick_up_address') },
//         { path: country.path, value: 'RU' }
//       )
//     } else {
//       const currentMethod = getDeliverySelectedMethod(state)

//       if (currentMethod === Shipping.PickUp) {
//         payloads.push(
//           { path: city.path, value: getPersonalFieldCity(state).value },
//           { path: zip.path, value: getPersonalFieldZip(state).value },
//           { path: address.path, value: getPersonalFieldAddress(state).value },
//           { path: country.path, value: getPersonalFieldCountry(state).value }
//         )
//       }
//     }

//     for (const payload of payloads) {
//       dispatch(changeInput(payload))
//     }
//   }
// }
