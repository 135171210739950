import * as React from 'react'
import classnames from 'classnames'

import LazyImage from 'src/components/lazyimage'

interface Props {
  src: string
  file: string
  selected: boolean
  onSelect(file: string): void
}

function File({ src, file, selected, onSelect }: Props): JSX.Element {
  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      onSelect(file)
    },
    [file, onSelect]
  )

  return (
    <div className='col-sm-2 my-2 p-1'>
      <button
        role='checkbox'
        aria-checked={selected}
        className={classnames('p-1', {
          'bg-primary': selected,
        })}
        onClick={onClick}
        type='button'
      >
        <LazyImage src={src} alt={file} />
      </button>
    </div>
  )
}

export default React.memo(File)
