import { connect } from 'react-redux'

import { getPriceWithCurrency } from 'src/utils/currency'

import {
  getOrderLocale,
  getOrderShippingCost,
} from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

function mapStateToProps(state: ReduxState): InfoFieldProps {
  const locale: string = getOrderLocale(state)
  const shippingCost: number = getOrderShippingCost(state)

  return {
    id: 'shippingCost',
    name: 'Стоимость доставки',
    value: shippingCost
      ? getPriceWithCurrency(locale, shippingCost.toString())
      : '—',
  }
}

const InfoShippingCost = connect(mapStateToProps)(InfoField)

export default InfoShippingCost
