import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { BACKEND_URL } from 'src/config'

import LazyImage from 'src/components/lazyimage'
import Price from 'src/components/price'

import IntlLink from 'src/components/common/intl-link'
import ButtonToCart from 'src/components/common/button-to-cart'
import ButtonToWishlist from 'src/components/common/button-to-wishlist'

import * as styles from 'src/styles/order-history.module.css'

import type { ShopProduct } from 'src/types/user'

interface OwnProps {
  product: Readonly<ShopProduct>
}

function OrderHistoryProduct({ product }: OwnProps): JSX.Element {
  return (
    <div className={styles.product}>
      <IntlLink className={styles.productImage} to={`/product/${product.link}`}>
        <LazyImage
          src={`${BACKEND_URL}/img/products/thumb/${product.image}`}
          alt={product.name}
        />
      </IntlLink>

      <div className={styles.productDetails}>
        <IntlLink
          className={styles.productName}
          to={`/product/${product.link}`}
        >
          {product.name}
        </IntlLink>

        <div aria-invalid={!product.inStock} className={styles.productStock}>
          <FormattedMessage
            id={product.inStock ? 'in-stock' : 'out-of-stock'}
            defaultMessage='In stock / Out of stock'
          />
        </div>

        <div className={styles.actions}>
          <ButtonToCart
            className={styles.actionsIcon}
            disabled={!product.inStock}
            productId={product.id}
          />

          <ButtonToWishlist
            className={styles.actionsIcon}
            productId={product.id}
          />

          <div className={styles.productPrice}>
            <div
              className={
                product.sale !== null ? styles.productPriceSale : undefined
              }
            >
              <Price value={product.price} />
            </div>

            {product.sale !== null && <Price value={product.sale} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(OrderHistoryProduct)
