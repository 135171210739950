import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { shopNavigation } from 'src/const/navigation'

import { getFieldNavigation } from 'src/redux/selectors/shop'

import { changeInput } from 'src/redux/actions/fields'

import ShopNavigationItem from './navigation-item'

import * as styles from 'src/styles/shop-header.module.css'

function ShopNavigation(): JSX.Element {
  const dispatch = useDispatch()

  const field = useSelector(getFieldNavigation)

  const setShopNavigation = React.useCallback(
    function callback(value: string) {
      dispatch(
        changeInput({
          path: field.path,
          value: field.value !== value ? value : '',
        })
      )
    },
    [field, dispatch]
  )

  const onBlur = React.useCallback<React.FocusEventHandler>(
    function callback(event) {
      if (!event.currentTarget.contains(event.relatedTarget as HTMLElement)) {
        setShopNavigation('')
      }
    },
    [setShopNavigation]
  )

  const onKeyDown = React.useCallback<React.KeyboardEventHandler>(
    function callback(event) {
      switch (event.key) {
        case 'Escape':
          setShopNavigation('')
          break

        default:
          break
      }
    },
    [setShopNavigation]
  )

  const onSelect = React.useCallback(
    function callback(path: string): void {
      setShopNavigation(path)
    },
    [setShopNavigation]
  )

  return (
    <div // eslint-disable-line jsx-a11y/interactive-supports-focus
      role='menubar'
      className={styles.nav}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    >
      {shopNavigation.map(function mapper(item): JSX.Element {
        return (
          <ShopNavigationItem
            key={item.link}
            item={item}
            current={field.value === item.path}
            onSelect={onSelect}
          />
        )
      })}
    </div>
  )
}

export default React.memo(ShopNavigation)
