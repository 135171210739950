import type { LaravelResponse } from 'src/types/api'

export function getLaravelErrorMessage(
  response: Readonly<LaravelResponse>
): string {
  if (response.errors !== undefined) {
    for (const p in response.errors) {
      if (Object.prototype.hasOwnProperty.call(response.errors, p)) {
        const error = response.errors[p]

        if (Array.isArray(error)) {
          return error.join(' ')
        }

        return error ? error.toString() : ''
      }
    }
  }

  return response.message || ''
}
