import { connect } from 'react-redux'

import InputLink, { AdminInputLinkProps } from 'src/admin/common/input-link'

import type { ReduxState } from 'src/types/common'

/* const styleInput = {
  minWidth: '25%'
} */

function mapStateToProps({
  fields: {
    adminProduct: { isFetching, link },
  },
}: ReduxState): AdminInputLinkProps {
  return {
    disabled: isFetching,
    field: link,
  }
}

export default connect(mapStateToProps)(InputLink)
