import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getIsFetching } from 'src/redux/selectors/admin-orders'

import { fetchOrderList } from 'src/redux/actions/admin-order'

import SearchById from './search-by-id'
import SearchByEmail from './search-by-email'
import SearchByFirstName from './search-by-firstname'
import SearchByLastName from './search-by-lastname'
import SearchByCountry from './search-by-country'
import SearchByShipping from './search-by-shipping'
import SearchByStatus from './search-by-status'
import SearchByDate from './search-by-date'

function AdminOrdersSearch(): JSX.Element {
  const dispatch = useDispatch()

  const { search } = useLocation()

  const onChange = React.useCallback(
    function callback() {
      dispatch(fetchOrderList(search))
    },
    [search, dispatch]
  )

  const disabled: boolean = useSelector(getIsFetching)

  return (
    <tr onChange={undefined}>
      <th>
        <SearchById disabled={disabled} onChange={onChange} />
      </th>

      <th>
        <SearchByEmail disabled={disabled} onChange={onChange} />
      </th>

      <th>
        <SearchByFirstName disabled={disabled} onChange={onChange} />
      </th>

      <th>
        <SearchByLastName disabled={disabled} onChange={onChange} />
      </th>

      <th>
        <SearchByCountry disabled={disabled} onChange={onChange} />
      </th>

      <th>
        <SearchByShipping disabled={disabled} onChange={onChange} />
      </th>

      <th>
        <SearchByStatus disabled={disabled} onChange={onChange} />
      </th>

      <th>
        <SearchByDate disabled={disabled} onChange={onChange} />
      </th>
    </tr>
  )
}

export default React.memo(AdminOrdersSearch)
