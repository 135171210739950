import * as React from 'react'
import { Switch, Route, match } from 'react-router-dom'

import NotFoundView from 'src/components/notfound'

import OrderCancelPaypal from './cancel-paypal'
import OrderCancelRobokassa from './cancel-robokassa'

interface Props {
  match: match
}

function OrderCancelView({ match }: Props): JSX.Element {
  return (
    <Switch>
      <Route path={`${match.url}/paypal`} component={OrderCancelPaypal} />
      <Route path={`${match.url}/robokassa`} component={OrderCancelRobokassa} />

      <Route component={NotFoundView} status={404} />
    </Switch>
  )
}

export default React.memo(OrderCancelView)
