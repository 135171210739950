import * as React from 'react'

import InputTextarea from 'src/components/common/input-textarea'

import type { InputTextField } from 'src/types/fields'

export interface AdminTextareaProps {
  field: InputTextField
  disabled: boolean
}

export default function AdminTextarea({
  field,
  disabled,
}: AdminTextareaProps): JSX.Element {
  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={field.id}
      >
        {field.label}
      </label>

      <div className='col-sm-10'>
        <InputTextarea
          className='form-control'
          disabled={disabled}
          field={field}
        />
      </div>
    </div>
  )
}
