import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getOrders,
  getOrdersTotal,
  getProductCount,
} from 'src/redux/selectors/admin-orders'

import { fetchOrderList } from 'src/redux/actions/admin-order'
import { fetchProductList } from 'src/redux/actions/admin-product'

import SearchByProduct from './search-by-product'
import AdminOrdersPagination from './pagination'
import AdminOrdersSearch from './search'
import AdminOrdersItem from './item'

import type { ReduxState } from 'src/types/common'
import type { OrderShort } from 'src/types/admin'

interface StateProps {
  orders: readonly Readonly<OrderShort>[]
  total: number
  productCount: number
}

function selector(state: ReduxState): StateProps {
  return {
    orders: getOrders(state),
    total: getOrdersTotal(state),
    productCount: getProductCount(state),
  }
}

interface OwnProps {
  location: Location
}

function AdminOrdersView({ location: { search } }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchProductList())
    },
    [dispatch]
  )

  React.useEffect(
    function effect() {
      dispatch(fetchOrderList(search))
    },
    [search, dispatch]
  )

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function onSubmit(event) {
      event.preventDefault()

      dispatch(fetchOrderList(search))
    },
    [search, dispatch]
  )

  const { total, orders, productCount } = useSelector(selector, shallowEqual)

  return (
    <div className='p-3'>
      <h1 className='text-center'>Заказы</h1>

      <AdminOrdersPagination />

      <form onSubmit={onSubmit}>
        <button className='d-none' />

        <div className='form-group row'>
          <div className='col-form-label col-sm-2 alert-primary'>
            Найдено заказов: {total}
          </div>

          <div className='col-form-label col-sm-2 bg-light'>
            {productCount > 0 && `Количество товара: ${productCount}`}
          </div>

          <SearchByProduct />
        </div>

        <div className='table-responsive'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>#</th>
                <th>E-mail</th>
                <th>Имя</th>
                <th>Фамилия</th>
                <th>Страна</th>
                <th>Способ доставки</th>
                <th>Статус</th>
                <th>Дата</th>
              </tr>

              <AdminOrdersSearch />
            </thead>

            <tbody>
              {orders.map(function mapper(item): JSX.Element {
                return <AdminOrdersItem key={item.id} item={item} />
              })}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  )
}

export default React.memo(AdminOrdersView)
