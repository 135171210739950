import * as React from 'react'
import { produce } from 'immer'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getFieldVariants,
} from 'src/redux/selectors/admin-product-edit'

import { changeList } from 'src/redux/actions/fields'

import VariantImage from './variant-image'
import FileManager from './filemanager'

import type { ListField } from 'src/types/fields'
import type { ReduxState } from 'src/types/common'

import type {
  ProductEditVariant,
  ProductEditVariantValue,
} from 'src/types/admin'

interface OwnProps {
  paramId: number
  valueId: number
  affects_price: number
  changes_image: number
  value: ProductEditVariantValue
}

interface StateProps {
  disabled: boolean
  variants: ListField<ProductEditVariant>
}

function selector(state: ReduxState): StateProps {
  return {
    variants: getFieldVariants(state),
    disabled: getIsFetching(state),
  }
}

function VariantValue({
  value,
  paramId,
  valueId,
  affects_price,
  changes_image,
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const { variants, disabled } = useSelector(selector, shallowEqual)

  const updateParameters = React.useCallback(
    function callback(list: ProductEditVariant[]) {
      dispatch(
        changeList({
          path: variants.path,
          list,
        })
      )
    },
    [variants.path, dispatch]
  )

  const changeInput = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    function callback({
      target: {
        value: paramValue,
        dataset: { param },
      },
    }) {
      if (param === undefined) {
        return
      }

      const value = { value: paramValue }

      const list = produce(variants.list, function producer(draft) {
        if (typeof value === 'object' && param === 'name') {
          if (draft[paramId].values[valueId][param] === null) {
            draft[paramId].values[valueId][param] = { value: '' }
          }

          draft[paramId].values[valueId][param] = Object.assign(
            draft[paramId].values[valueId][param],
            value
          )
        } else if (param === 'price_delta') {
          draft[paramId].values[valueId][param] = parseInt(paramValue, 10)
        }
      })

      updateParameters(list)
    },
    [paramId, valueId, variants.list, updateParameters]
  )

  const removeValue = React.useCallback(
    function callback() {
      if (
        window.confirm(
          'Вы действительно хотите удалить данный параметр?\nВосстановление будет невозможно!'
        )
      ) {
        const list = produce(variants.list, function producer(draft) {
          draft[paramId].values.splice(valueId, 1)
        })

        updateParameters(list)
      }
    },
    [paramId, valueId, variants.list, updateParameters]
  )

  const removeImage = React.useCallback(
    function callback() {
      const list = produce(variants.list, function producer(draft) {
        draft[paramId].values[valueId].image = undefined
      })

      updateParameters(list)
    },
    [paramId, valueId, variants.list, updateParameters]
  )

  const [keys, setKeys] = React.useState(function state() {
    return {
      paramId: -1,
      valueId: -1,
    }
  })

  const openFileManager = React.useCallback(
    function callback() {
      setKeys({
        paramId,
        valueId,
      })
    },
    [paramId, valueId]
  )

  const onFileManagerClose = React.useCallback(function callback() {
    setKeys({
      paramId: -1,
      valueId: -1,
    })
  }, [])

  const onSelectImages = React.useCallback(
    function callback(files) {
      if (files.length < 1) {
        return false
      }

      if (files.length > 1) {
        alert('За одной опцией можно закрепить только одно изображение')

        return false
      }

      const file = files[0]

      setKeys(function set(keys) {
        const list = produce(variants.list, function producer(draft) {
          draft[keys.paramId].values[keys.valueId].image = file
        })

        updateParameters(list)

        return {
          paramId: -1,
          valueId: -1,
        }
      })
    },
    [variants.list, updateParameters]
  )

  return (
    <tr>
      <td>
        <input
          className='form-control'
          disabled={disabled}
          value={value.name ? value.name.value : ''}
          data-param='name'
          onChange={changeInput}
        />

        <button
          className='btn btn-danger ml-3'
          disabled={disabled}
          onClick={removeValue}
          type='button'
        >
          Удалить вариант
        </button>
      </td>

      {changes_image > 0 && (
        <td>
          {value.image ? (
            <VariantImage
              image={value.image}
              disabled={disabled}
              onClick={removeImage}
            />
          ) : (
            <button
              className='btn btn-primary'
              disabled={disabled}
              onClick={openFileManager}
              type='button'
            >
              Добавить
            </button>
          )}
        </td>
      )}

      {affects_price > 0 && (
        <td>
          <input
            className='form-control'
            disabled={disabled}
            value={value.price_delta}
            data-param='price_delta'
            onChange={changeInput}
          />
        </td>
      )}

      <FileManager
        open={keys.paramId !== -1}
        path='/img/products'
        onSelect={onSelectImages}
        onClose={onFileManagerClose}
      />
    </tr>
  )
}

export default React.memo(VariantValue)
