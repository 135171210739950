import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { getCountries } from 'src/redux/selectors/intl'
import { getFieldCountry } from 'src/redux/selectors/personal-fields'

import InputSelect from 'src/components/common/input-select'

import * as styles from 'src/styles/personal-details.module.css'

import type { ReduxState } from 'src/types/common'
import type { ComboboxField, ComboboxOption } from 'src/types/fields'

interface StateProps {
  field: Readonly<ComboboxField>
  options: readonly Readonly<ComboboxOption>[]
}

function selector(state: ReduxState): StateProps {
  return {
    field: getFieldCountry(state),
    options: getCountries(state),
  }
}

interface Props {
  disabled: boolean
}

function SelectCountry({ disabled }: Props): JSX.Element {
  const { field, options } = useSelector(selector, shallowEqual)

  return (
    <div className={styles.field}>
      <label htmlFor={field.id}>
        <FormattedMessage id='country' defaultMessage='Country' />
      </label>

      <InputSelect
        className={styles.input}
        disabled={disabled}
        options={options}
        field={field}
      />
    </div>
  )
}

export default React.memo(SelectCountry)
