import type { Product } from 'src/types/admin'
import type { FieldsPayload } from 'src/types/fields'
import type { ApiAdminProducts } from 'src/types/api'

export interface AdminProductsFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  list: Product[]
}

export const initialFields: AdminProductsFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  list: [],
}

export function fetchSuccess(
  draft: AdminProductsFieldsState,
  { list }: FieldsPayload<ApiAdminProducts>
): void {
  draft.isFetching = false
  draft.error = ''
  draft.list = list
}
