import * as React from 'react'

interface Props {
  disabled: boolean
  onClick: React.MouseEventHandler
}

function ButtonSelect({ disabled, onClick }: Props): JSX.Element {
  return (
    <button
      className='btn btn-primary'
      disabled={disabled}
      onClick={onClick}
      type='button'
    >
      Выбрать
    </button>
  )
}

export default React.memo(ButtonSelect)
