import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { partners } from 'src/const/partners'

import Separator from 'src/components/separator'

import PartnersListItem from './partners-list-item'

import * as styles from 'src/styles/partners.module.css'
import * as utility from 'src/styles/utility.module.css'

function PartnersList(): JSX.Element {
  return (
    <div>
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage id='partners' defaultMessage='Partners' />
      </h2>

      <Separator />

      <p className={styles.text}>
        <FormattedMessage id='partners-info' defaultMessage='Partners info' />
      </p>

      <section className={styles.content}>
        {partners.map(function (partner): JSX.Element {
          return <PartnersListItem key={partner.name} partner={partner} />
        })}
      </section>
    </div>
  )
}

export default React.memo(PartnersList)
