import * as React from 'react'

import SvgLoading from 'src/components/svg/loading'

import File from './file'
import Uploading from './uploading'
import Pagination from './pagination'

const pageSize = 12

interface Props {
  uploading: readonly Readonly<File>[]
  selected: readonly string[]
  loading: boolean
  files: readonly string[]
  path: string
  url: string

  onSelect(file: string): void
}

function Files({
  url,
  path,
  files,
  loading,
  selected,
  uploading,
  onSelect,
}: Props): JSX.Element {
  const [page, setPage] = React.useState<number>(0)

  const list = React.useMemo<readonly string[]>(
    function memo() {
      const past = page * pageSize

      return files.slice(past, past + pageSize)
    },
    [page, files]
  )

  const onPrev = React.useCallback(function callback() {
    setPage(function set(page) {
      return Math.max(0, page - 1)
    })
  }, [])

  const totalPage = Math.ceil(files.length / pageSize)

  const onNext = React.useCallback(
    function callback() {
      setPage(function set(page) {
        return Math.min(page + 1, totalPage)
      })
    },
    [totalPage]
  )

  const onChange = React.useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >(function callback(event) {
    setPage(Number(event.target.value) - 1)
  }, [])

  if (loading) {
    return (
      <div className='row justify-content-center'>
        <div role='img' aria-label='Loading…' className='col-lg-6 p-5'>
          <SvgLoading />
        </div>
      </div>
    )
  }

  const root = `${url}${path}`

  return (
    <>
      <Pagination
        page={page}
        totalPage={totalPage}
        onPrev={onPrev}
        onNext={onNext}
        onChange={onChange}
      />

      <div className='row'>
        {uploading.map(function mapper(_file, index): JSX.Element {
          return <Uploading key={index} />
        })}

        {list.map(function mapper(file): JSX.Element {
          return (
            <File
              key={file}
              file={file}
              src={`${root}/thumb/${file}`}
              selected={selected.includes(file)}
              onSelect={onSelect}
            />
          )
        })}
      </div>
    </>
  )
}

export default React.memo(Files)
