import type { IntlState, LocaleMenuData } from 'src/redux/reducers/intl'

import type { ReduxState } from 'src/types/common'
import type { ComboboxOption } from 'src/types/fields'

function getState(state: ReduxState): IntlState {
  return state.intl
}

export function getLocale(state: ReduxState): string {
  return getState(state).locale
}

export function getMessages(state: ReduxState): Record<string, string> {
  return getState(state).messages
}

export function getMessageById(state: ReduxState, id: string): string {
  return getMessages(state)[id] || id
}

export function getLangList(state: ReduxState): readonly string[] {
  return getState(state).langList
}

export function getLangBySlug(state: ReduxState, slug: string): LocaleMenuData {
  return getState(state).langs[slug]
}

export function getCountries(
  state: ReduxState
): readonly Readonly<ComboboxOption>[] {
  return getState(state).countries
}

export function getCountryByValue(
  state: ReduxState,
  value: string
): Readonly<ComboboxOption> | undefined {
  return getCountries(state).find(function find(country): boolean {
    return country.value === value
  })
}
