import { connect } from 'react-redux'

import InputText, { AdminInputTextProps } from 'src/admin/common/input-text'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminProduct: { isFetching, weight },
  },
}: ReduxState): AdminInputTextProps {
  return {
    disabled: isFetching,
    field: weight,
  }
}

export default connect(mapStateToProps)(InputText)
