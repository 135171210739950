import type { OrderHistory, ProductsMap, OrderHistoryMap } from 'src/types/user'
import type { ApiOrderHistory, ApiOrderHistoryDetails } from 'src/types/api'
import type { FieldsPayload } from 'src/types/fields'

export interface OrderHistoryFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  orderList: OrderHistory[]
  orders: OrderHistoryMap
  products: ProductsMap
}

export const initialFields: OrderHistoryFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  orderList: [],
  orders: new Map(),
  products: new Map(),
}

export function fetchSuccess(
  draft: OrderHistoryFieldsState,
  { orders }: FieldsPayload<ApiOrderHistory>
): void {
  draft.isFetching = false
  draft.error = ''

  draft.orderList = orders
  draft.orders = new Map()
}

export function reducerFetchOrderHistoryDetails(
  draft: OrderHistoryFieldsState,
  { order }: FieldsPayload<ApiOrderHistoryDetails>
): void {
  draft.isFetching = false
  draft.error = ''

  draft.orders.set(order.id, order)
}

export function reducerSetOrderHistoryExpanded(
  draft: OrderHistoryFieldsState,
  { orderId, expanded }: FieldsPayload<{ orderId: number; expanded: boolean }>
): void {
  const order = draft.orderList.find(function findIndex({ id }): boolean {
    return id === orderId
  })

  if (order !== undefined) {
    order.expanded = expanded
  }
}
