import * as React from 'react'
import { createPortal } from 'react-dom'

import { BACKEND_URL } from 'src/config'

import {
  resultHandler,
  uploadHandler,
  FILE_MANAGER_URL,
} from 'src/utils/filemanager'

import FileManager from 'src/admin/filemanager'

interface Props {
  open: boolean
  path: string
  onClose(): void
  onSelect(files: string[]): void
}

function ProductFileManager(props: Props): JSX.Element {
  const container = React.useMemo(function setContainer() {
    return document.getElementById('modal')
  }, [])

  if (!props.open || container === null) {
    return <></>
  }

  return createPortal(
    <FileManager
      url={BACKEND_URL}
      path={props.path}
      apiUrl={FILE_MANAGER_URL}
      resultHandler={resultHandler}
      uploadHandler={uploadHandler}
      onSelect={props.onSelect}
      onClose={props.onClose}
    />,
    container
  )
}

export default React.memo(ProductFileManager)
