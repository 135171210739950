import * as React from 'react'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { OrderStatus } from 'src/const/order-status'

import { getOrderById } from 'src/redux/selectors/order-history'

import {
  setOrderHistoryExpanded,
  fetchOrderHistoryDetails,
} from 'src/redux/actions/order-history'

import SvgLoading from 'src/components/svg/loading'

import OrderHistoryListItemInfo from './orders-list-item-info'

import * as styles from 'src/styles/order-history.module.css'

import type { ReduxState } from 'src/types/common'
import type { OrderHistory } from 'src/types/user'

function getTextStatus(intl: IntlShape, status: OrderStatus): string {
  switch (status) {
    case OrderStatus.Current:
    case OrderStatus.Pending:
    case OrderStatus.Canceled:
    case OrderStatus.Processed:
    case OrderStatus.Shipping:
    case OrderStatus.Shipped:
    case OrderStatus.Special:
      return intl.formatMessage({ id: `order-status-${status}` })
    default:
      return intl.formatMessage({ id: 'order-status-unknown' })
  }
}

interface OwnProps {
  order: Readonly<OrderHistory>
}

function OrderHistoryListItem({ order }: OwnProps): JSX.Element {
  const intl = useIntl()

  const formattedValues = React.useMemo(
    function memo() {
      return {
        number: order.id,
        date: `${intl.formatDate(order.date)} ${intl.formatTime(order.date)}`,
      }
    },
    [intl, order]
  )

  const status = React.useMemo<string>(
    function memo() {
      return getTextStatus(intl, order.status)
    },
    [intl, order.status]
  )

  const dispatch = useDispatch()

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      const expanded = !order.expanded

      dispatch(setOrderHistoryExpanded(order.id, expanded))

      if (expanded) {
        dispatch(fetchOrderHistoryDetails(order.id))
      }
    },
    [order, dispatch]
  )

  const details = useSelector(function selector(state: ReduxState) {
    return getOrderById(state, order.id)
  })

  return (
    <article data-expanded={order.expanded} className={styles.item}>
      <button className={styles.itemHeader} onClick={onClick} type='button'>
        <span>
          <FormattedMessage
            id='order-number'
            defaultMessage='Order #'
            values={formattedValues}
          />
          &nbsp; [{status}]
        </span>

        <span aria-expanded={order.expanded} className={styles.itemArrow}>
          ►
        </span>
      </button>

      {order.expanded &&
        (details === undefined ? (
          <div role='img' aria-label='Loading…' className={styles.itemLoading}>
            <SvgLoading />
          </div>
        ) : (
          <OrderHistoryListItemInfo order={details} />
        ))}
    </article>
  )
}

export default React.memo(OrderHistoryListItem)
