import * as React from 'react'
import { match } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getProductId,
  getIsFetching,
} from 'src/redux/selectors/admin-product-edit'

import {
  saveProduct,
  fetchProduct,
  getProductDependencies,
  clearAdminProductFields,
} from 'src/redux/actions/admin-product'

import AdminLocaleSwitch from 'src/admin/locale-switch'

import Headline from './headline'
import ErrorMessage from './error-message'
import SelectProject from './select-project'
import Categories from './categories'
import InputName from './input-name'
import InputLink from './input-link'
import Images from './images'
import InputDesc from './input-desc'
import InputPrice from './input-price'
import Variants from './variants'
import InputWeight from './input-weight'
import InputQuantity from './input-quantity'
import InfoWishlist from './info-wishlist'
import CheckboxVisible from './checkbox-visible'
import CheckboxWeekSale from './checkbox-week-sale'
import ButtonSave from './button-save'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  id: number
}

function selector(state: ReduxState): StateProps {
  return {
    id: getProductId(state),
    isFetching: getIsFetching(state),
  }
}

interface MatchProps {
  id?: string
}

interface OwnProps {
  match: match<MatchProps>
}

function AdminProductsEditView({ match }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const { id, isFetching }: StateProps = useSelector(selector, shallowEqual)

  React.useEffect(
    function effect() {
      dispatch(getProductDependencies())

      return function cleanup() {
        if (id !== 0) {
          dispatch(clearAdminProductFields())
        }
      }
    },
    [id, dispatch]
  )

  const onProductChange = React.useCallback(
    function callback() {
      if (match.params.id) {
        dispatch(fetchProduct(match.params.id))
      }
    },
    [match.params.id, dispatch]
  )

  React.useEffect(onProductChange, [onProductChange])

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function onSubmit(event) {
      event.preventDefault()

      dispatch(saveProduct())
    },
    [dispatch]
  )

  return (
    <div className='container p-3'>
      <Headline />

      <div className='mb-3 text-center'>
        <AdminLocaleSwitch disabled={isFetching} onChange={onProductChange} />
      </div>

      <ErrorMessage />

      <form onSubmit={onSubmit}>
        <SelectProject />

        <Categories />

        <InputName />

        <InputLink />

        <Images />

        <InputDesc />

        <InputPrice />

        <Variants />

        <InputWeight />

        <InputQuantity />

        <InfoWishlist />

        <CheckboxVisible />

        <CheckboxWeekSale />

        <ButtonSave />
      </form>
    </div>
  )
}

export default React.memo(AdminProductsEditView)
