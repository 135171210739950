import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getFieldEmail } from 'src/redux/selectors/feedback-fields'

import InputText from 'src/components/common/input-text'

import * as styles from 'src/styles/feedback.module.css'

function InputEmail(): JSX.Element {
  const field = useSelector(getFieldEmail)

  return (
    <div className={styles.field}>
      <label htmlFor={field.id}>
        <FormattedMessage id='email' defaultMessage='Email' />
      </label>

      <InputText className={styles.input} field={field} />
    </div>
  )
}

export default React.memo(InputEmail)
