import * as React from 'react'

import IntlLink from 'src/components/common/intl-link'

import * as styles from 'src/styles/home.module.css'

import type { Category } from 'src/types/user'

interface Props {
  category: Readonly<Category>
}

function CategoriesListItem({ category }: Props): JSX.Element {
  return (
    <article
      className={styles.category}
      itemProp='itemListElement'
      itemScope
      itemType='http://schema.org/OfferCatalog'
    >
      <IntlLink
        to={`/category/${category.link}`}
        className={styles.categoryLink}
        itemProp='url'
      >
        <span itemProp='name'>{category.name}</span>
      </IntlLink>
    </article>
  )
}

export default React.memo(CategoriesListItem)
