import { createSelector } from 'reselect'

import type { AdminCategoriesFieldsState } from 'src/redux/reducers/admin-categories'

import type { Category } from 'src/types/admin'
import type { ReduxState } from 'src/types/common'
import type { ComboboxOption } from 'src/types/fields'

function getState(state: ReduxState): AdminCategoriesFieldsState {
  return state.fields.adminCategories
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getCategories(
  state: ReduxState
): readonly Readonly<Category>[] {
  return getState(state).list
}

export function getCategoryById(
  state: ReduxState,
  categoryId: number
): Readonly<Category> | undefined {
  return getCategories(state).find(function find({ id }): boolean {
    return id === categoryId
  })
}

export const getCategoriesOptions = createSelector(
  [getCategories],
  function getCategoriesOptions(list): readonly Readonly<ComboboxOption>[] {
    return list.map(function mapper({ id, name }): ComboboxOption {
      return {
        value: id.toString(),
        name,
      }
    })
  }
)

export function getCategoryOptionByValue(
  state: ReduxState,
  value: number
): Readonly<ComboboxOption> | undefined {
  const search = value.toString()

  return getCategoriesOptions(state).find(function find({ value }): boolean {
    return value === search
  })
}
