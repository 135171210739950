import * as React from 'react'
import { Switch, Route, match } from 'react-router-dom'

import NotFoundView from 'src/components/notfound'

import OrderResultPaypal from './result-paypal'
import OrderResultRobokassa from './result-robokassa'

interface MatchProps {
  url: string
}

interface Props {
  match: match<MatchProps>
}

function OrderResultView({ match }: Props): JSX.Element {
  return (
    <Switch>
      <Route path={`${match.url}/paypal`} component={OrderResultPaypal} />
      <Route path={`${match.url}/robokassa`} component={OrderResultRobokassa} />

      <Route component={NotFoundView} status={404} />
    </Switch>
  )
}

export default React.memo(OrderResultView)
