import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'

import { BACKEND_URL } from 'src/config'

import LazyImage from 'src/components/lazyimage'
import Price from 'src/components/price'
import PriceSchema from 'src/components/price-schema'

import IntlLink from 'src/components/common/intl-link'
import ButtonToCart from 'src/components/common/button-to-cart'
import ButtonToWishlist from 'src/components/common/button-to-wishlist'

import * as styles from 'src/styles/shop.module.css'

import type { Product } from 'src/types/user'

interface Props {
  product: Readonly<Product>
}

function ShopProductsListItem({ product }: Props): JSX.Element {
  return (
    <article
      className={styles.item}
      itemProp='itemListElement'
      itemScope
      itemType='http://schema.org/Product'
    >
      <IntlLink
        className={styles.itemImage}
        to={`/product/${product.link}`}
        itemProp='url'
      >
        <LazyImage
          src={`${BACKEND_URL}/img/products/thumb/${product.image}`}
          alt={product.name}
        />

        <link
          itemProp='image'
          href={`${BACKEND_URL}/img/products/${product.image}`}
        />
      </IntlLink>

      <div className={styles.itemInfo}>
        <IntlLink
          className={styles.itemName}
          to={`/product/${product.link}`}
          itemProp='name'
        >
          {product.name}
        </IntlLink>

        <div itemProp='offers' itemScope itemType='http://schema.org/Offer'>
          <div
            className={classnames(styles.itemInStock, {
              [styles.itemInStockOut]: !product.inStock,
            })}
          >
            <link
              itemProp='availability'
              href={
                product.inStock
                  ? 'http://schema.org/InStock'
                  : 'http://schema.org/OutOfStock'
              }
            />

            <FormattedMessage
              id={product.inStock ? 'in-stock' : 'out-of-stock'}
              defaultMessage='In stock / Out of stock'
            />
          </div>

          <div className={styles.itemPrice}>
            <span
              className={classnames({
                [styles.itemPriceSale]: product.sale !== null,
              })}
            >
              {product.sale !== null ? (
                <Price value={product.price} />
              ) : (
                <PriceSchema value={product.price} />
              )}
            </span>
            &nbsp;
            {product.sale !== null && <PriceSchema value={product.sale} />}
          </div>
        </div>

        <div className={styles.itemButtons}>
          <ButtonToCart
            className={styles.itemButtonCart}
            disabled={!product.inStock}
            productId={product.id}
          />

          <ButtonToWishlist
            className={styles.itemButtonHeart}
            productId={product.id}
          />
        </div>
      </div>
    </article>
  )
}

export default React.memo(ShopProductsListItem)
