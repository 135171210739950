import * as React from 'react'
import { useDispatch } from 'react-redux'

import { changeFile } from 'src/redux/actions/fields'

import type { InputTextField } from 'src/types/fields'

interface OwnProps extends React.InputHTMLAttributes<HTMLInputElement> {
  field: Readonly<InputTextField>
}

export default function InputFile({
  field,
  disabled,
  ...props
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    function callback({ target: { files } }) {
      const file = files !== null && files.length > 0 ? files[0] : null

      dispatch(
        changeFile({
          path: field.path,
          file,
        })
      )
    },
    [field.path, dispatch]
  )

  return (
    <input
      {...props}
      id={field.id}
      type='file'
      disabled={field.disabled || disabled}
      required={field.required}
      onChange={onChange}
    />
  )
}
