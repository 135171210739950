import * as React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getProjects } from 'src/redux/selectors/admin-projects'

import { fetchProjectList } from 'src/redux/actions/admin-project'

import AdminProjectsItem from './item'

import type { Project } from 'src/types/admin'

type StateProps = readonly Readonly<Project>[]

function AdminProjectsView(): JSX.Element {
  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchProjectList())
    },
    [dispatch]
  )

  const projects: StateProps = useSelector(getProjects)

  return (
    <div className='container p-3'>
      <div className='d-flex align-items-center mb-2'>
        <h1 className='mr-2 mb-0'>Проекты</h1>

        <div>
          <Link className='btn btn-outline-primary' to='/admin/projects/create'>
            Создать
          </Link>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Название</th>
              <th>Ссылка</th>
            </tr>
          </thead>

          <tbody>
            {projects.map(function mapper(project): JSX.Element {
              return <AdminProjectsItem key={project.id} item={project} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default React.memo(AdminProjectsView)
