import type { OrderHistoryFieldsState } from 'src/redux/reducers/order-history'

import type { ReduxState } from 'src/types/common'
import type { OrderHistory, OrderHistoryMap } from 'src/types/user'

function getState(state: ReduxState): OrderHistoryFieldsState {
  return state.fields.orderHistory
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getOrderList(
  state: ReduxState
): readonly Readonly<OrderHistory>[] {
  return getState(state).orderList
}

export function getOrdersCount(state: ReduxState): number {
  return getOrderList(state).length
}

export function getOrders(state: ReduxState): OrderHistoryMap {
  return getState(state).orders
}

export function getOrderById(
  state: ReduxState,
  orderId: number
): Readonly<OrderHistory> | undefined {
  return getOrders(state).get(orderId)
}
