import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getFieldPrivacyPolicy } from 'src/redux/selectors/auth'

import IntlLink from 'src/components/common/intl-link'
import InputCheckbox from 'src/components/common/input-checkbox'

import * as styles from 'src/styles/auth-registration.module.css'

interface Props {
  disabled?: boolean
}

function CheckboxPrivacyPolicy({ disabled }: Props): JSX.Element {
  const field = useSelector(getFieldPrivacyPolicy)

  return (
    <div className={styles.checkbox}>
      <InputCheckbox
        className={styles.checkboxInput}
        disabled={disabled}
        field={field}
      />

      <div>
        <label htmlFor={field.id}>
          <FormattedMessage id='accept-terms' defaultMessage='Accept terms' />
        </label>{' '}
        <IntlLink to='/privacy-policy'>
          <FormattedMessage
            id='privacy-policy-name'
            defaultMessage='Privacy policy'
          />
        </IntlLink>
      </div>
    </div>
  )
}

export default React.memo(CheckboxPrivacyPolicy)
