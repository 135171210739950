import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import {
  getProductId,
  getCurrentQuantity,
} from 'src/redux/selectors/admin-product-edit'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  id: number
  quantity: number
}

function selector(state: ReduxState): StateProps {
  return {
    id: getProductId(state),
    quantity: getCurrentQuantity(state),
  }
}

function InfoQuantity(): JSX.Element {
  const { id, quantity }: StateProps = useSelector(selector, shallowEqual)

  if (id === 0) {
    return <></>
  }

  return (
    <span className='col-sm-2 col-form-label text-center alert-info mx-3'>
      {quantity}
    </span>
  )
}

export default React.memo(InfoQuantity)
