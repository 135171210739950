import type { AuthFieldsState } from 'src/redux/reducers/auth'

import type { ReduxState } from 'src/types/common'
import type { InputTextField, InputCheckboxField } from 'src/types/fields'

function getState(state: ReduxState): AuthFieldsState {
  return state.fields.auth
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getIsLoggedIn(state: ReduxState): boolean {
  return getState(state).isLoggedIn
}

export function getIsAdmin(state: ReduxState): boolean {
  return getState(state).isAdmin
}

export function getError(state: ReduxState): string {
  return getState(state).error
}

export function getUserEmail(state: ReduxState): string {
  return getState(state).emailStr
}

export function getFieldEmail(state: ReduxState): InputTextField {
  return getState(state).email
}

export function getFieldPassword(state: ReduxState): InputTextField {
  return getState(state).password
}

export function getFieldPasswordConfirm(state: ReduxState): InputTextField {
  return getState(state).passwordConfirm
}

export function getFieldPrivacyPolicy(state: ReduxState): InputCheckboxField {
  return getState(state).privacyPolicy
}

export function getFieldIsDone(state: ReduxState): InputCheckboxField {
  return getState(state).isDone
}

export function getIsDone(state: ReduxState): boolean {
  return getFieldIsDone(state).checked
}
