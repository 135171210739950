import * as React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import history from 'src/const/history'
import { linkAdmin, linkLogout, linksAuthorized } from 'src/const/navigation'

import { getIsAdmin } from 'src/redux/selectors/auth'

import { logoutUser } from 'src/redux/actions/auth'

import IntlLink from 'src/components/common/intl-link'
import IntlNavLink from 'src/components/common/intl-nav-link'

import * as styles from 'src/styles/personal-links.module.css'

function PersonalLinks(): JSX.Element {
  const dispatch = useDispatch()

  const onLogout = React.useCallback<React.MouseEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(logoutUser())

      history.push('/')
    },
    [dispatch]
  )

  const isAdmin: boolean = useSelector(getIsAdmin)

  return (
    <ul className={styles.links}>
      {linksAuthorized.map(function mapper(item): JSX.Element {
        return (
          <li key={item.link} className={styles.item}>
            <IntlNavLink to={item.link} className={styles.link}>
              <FormattedMessage id={item.name} defaultMessage='Link desc' />
            </IntlNavLink>
          </li>
        )
      })}

      {isAdmin && (
        <li className={styles.item}>
          <Link to={linkAdmin.link} className={styles.link}>
            <FormattedMessage
              id={linkAdmin.name}
              defaultMessage='Administration'
            />
          </Link>
        </li>
      )}

      <li className={styles.item}>
        <IntlLink
          to={linkLogout.link}
          className={styles.link}
          onClick={onLogout}
        >
          <FormattedMessage id={linkLogout.name} defaultMessage='Logout' />
        </IntlLink>
      </li>
    </ul>
  )
}

export default React.memo(PersonalLinks)
