import { changeInput } from './fields'

import { getFieldCurrencyUsd } from 'src/redux/selectors/shop'

import type { FieldsAction } from 'src/types/common'
import type { ApiCurrency } from 'src/types/api'

const MOEX_URL = 'https://iss.moex.com'

export function fetchCurrencyUsd(): FieldsAction {
  return function thunk(dispatch, getState) {
    return fetch(
      `${MOEX_URL}/iss/engines/currency/markets/selt/boards/CETS/securities/USD000UTSTOM.json`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      }
    )
      .then<ApiCurrency>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        const index = result.marketdata.columns.indexOf('MARKETPRICE')
        const value = result.marketdata.data[0][index]

        dispatch(
          changeInput({
            path: getFieldCurrencyUsd(getState()).path,
            value: parseFloat(value) || 0,
          })
        )

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchCurrencyUsd error:', err)
      })
  }
}
