import type { Category } from 'src/types/admin'
import type { FieldsPayload } from 'src/types/fields'
import type { ApiAdminCategories } from 'src/types/api'

export interface AdminCategoriesFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  list: Category[]
}

export const initialFields: AdminCategoriesFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  list: [],
}

export function fetchSuccess(
  draft: AdminCategoriesFieldsState,
  { list }: FieldsPayload<ApiAdminCategories>
): void {
  draft.isFetching = false
  draft.error = ''
  draft.list = list
}
