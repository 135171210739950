import * as React from 'react'

function SvgCart(): JSX.Element {
  return (
    <svg viewBox='-41 164.9 512 512'>
      <path d='M-27.6,179.9c0,8.3,6.7,15,15,15h47.1c0.6,3.8,53.5,349.9,54.1,353.8c3.3,21.3,20.7,36.9,41.9,38.1 c-5.2,8.9-8.1,19.1-8.1,30.1c0,33.1,26.9,60,60,60s60-26.9,60-60c0-10.9-2.9-21.2-8.1-30h86.1c-5.1,8.8-8.1,19.1-8.1,30 c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60c0-11.5-3.2-22.2-8.9-31.3c5.2-2.3,8.9-7.6,8.9-13.7c0-8.3-6.7-15-15-15H133.1 c-7.5,0-13.7-5.3-14.8-12.7l-2.6-17.3h312.2c7.4,0,13.7-5.4,14.8-12.7c1.8-11.7,26.6-216.5,28.2-227.4c1.4-9.1-10-18.5-19.1-18.5 l-374.9,0.5l-14.7-91.2c-1.1-7.3-7.4-12.7-14.8-12.7h-60C-20.9,164.9-27.6,171.6-27.6,179.9z M342.4,616.9c0-16.5,13.5-30,30-30 s30,13.5,30,30s-13.5,30-30,30S342.4,633.4,342.4,616.9z M152.4,616.9c0-16.5,13.5-30,30-30s30,13.5,30,30s-13.5,30-30,30 S152.4,633.4,152.4,616.9z M431.3,381.9H370l7.7-83.2h63.2L431.3,381.9z M359.3,497.8l8.1-88.2h60.6l-10.2,88.2H359.3z M277.9,409.6h61.8l-8.1,88.2h-53.7V409.6z M277.9,298.8h72l-7.7,83.2h-64.4V298.8z M187.5,409.6h62.7v88.2h-54.5L187.5,409.6z M177.3,298.8h72.9v83.2H185L177.3,298.8z M98.6,409.6h61.2l8.1,88.2h-56L98.6,409.6z M81.9,298.8h67.7l7.7,83.2H94.4L81.9,298.8z' />

      <circle cx='372.4' cy='616.9' r='12.5' />

      <circle cx='182.4' cy='616.9' r='12.5' />
    </svg>
  )
}

export default React.memo(SvgCart)
