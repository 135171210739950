import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import { SHOP_IS_CLOSED } from 'src/config'

import { linksUnauthorized } from 'src/const/navigation'
import { getIsLoggedIn, getUserEmail } from 'src/redux/selectors/auth'
import { getProductCount as getCartProductCount } from 'src/redux/selectors/cart'
import { getProductCount as getWishlistProductCount } from 'src/redux/selectors/wishlist'

import IntlLink from 'src/components/common/intl-link'

import SvgHeart from 'src/components/svg/heart'
import SvgCart from 'src/components/svg/cart'

import HeaderImage from './image'
import HeaderImageClosed from './closed'
import NavPanel from './nav-panel'
import LangList from './lang-list'

import * as styles from 'src/styles/header.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

const HeaderShopImage = SHOP_IS_CLOSED ? HeaderImageClosed : HeaderImage

interface StateProps {
  userEmail: string
  isLoggedIn: boolean
  cartCount: number
  wishlistCount: number
}

function selector(state: ReduxState): StateProps {
  return {
    userEmail: getUserEmail(state),
    isLoggedIn: getIsLoggedIn(state),
    cartCount: getCartProductCount(state),
    wishlistCount: getWishlistProductCount(state),
  }
}

function Header(): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false)

  const toggleNavigation = React.useCallback(function callback() {
    setOpen(function set(open) {
      return !open
    })
  }, [])

  const {
    userEmail,
    isLoggedIn,
    cartCount,
    wishlistCount,
  }: StateProps = useSelector(selector, shallowEqual)

  return (
    <div
      id='primary-header'
      className={styles.container}
      itemScope
      itemType='http://schema.org/WPHeader'
    >
      <header
        id='primary-header-panel'
        className={classnames(styles.header, utility.zi2)}
      >
        <div className={classnames(utility.f1, styles.content)}>
          <button
            aria-label='Toggle menu'
            className={classnames(
              styles.link,
              styles.icon,
              styles.toggleMenuButton
            )}
            onClick={toggleNavigation}
            type='button'
          >
            <span data-expanded={open} className={styles.toggleMenuSpan} />
          </button>

          <IntlLink className={styles.title} to='/'>
            <h1 className={styles.titleH1} itemProp='name'>
              <FormattedMessage id='title' defaultMessage='Title' />
            </h1>

            <h2 className={styles.titleH2} itemProp='description'>
              <FormattedMessage id='subtitle' defaultMessage='Subtitle' />
            </h2>
          </IntlLink>
        </div>

        <NavPanel visible={open} onClick={toggleNavigation} />

        <div
          className={styles.content}
          itemProp='mainEntity'
          itemScope
          itemType='http://schema.org/SiteNavigationElement'
        >
          <div className={styles.linksListWide}>
            {isLoggedIn === false ? (
              linksUnauthorized.map(function mapper(item): JSX.Element {
                return (
                  <IntlLink
                    key={item.link}
                    className={styles.linkWide}
                    to={item.link}
                  >
                    <FormattedMessage
                      id={item.name}
                      defaultMessage={item.name}
                    />
                  </IntlLink>
                )
              })
            ) : (
              <IntlLink className={styles.linkWide} to='/profile/'>
                {userEmail}
              </IntlLink>
            )}
          </div>

          <IntlLink
            className={classnames(styles.link, {
              [styles.linkLast]: !isLoggedIn,
            })}
            to='/cart/'
          >
            <div role='img' aria-hidden className={styles.icon}>
              <SvgCart />
            </div>

            <span className={styles.linkText}>
              <FormattedMessage id='cart' defaultMessage='Cart' />
            </span>

            {cartCount > 0 && (
              <div className={styles.linkCounter}>{cartCount}</div>
            )}
          </IntlLink>

          <IntlLink
            className={classnames(styles.link, {
              [styles.wideHide]: !isLoggedIn,
              [styles.linkLast]: isLoggedIn,
            })}
            to='/wishlist/'
          >
            <div role='img' aria-hidden className={styles.icon}>
              <SvgHeart />
            </div>

            <span className={styles.linkText}>
              <FormattedMessage id='wishlist' defaultMessage='Wishlist' />
            </span>

            {wishlistCount > 0 && (
              <div className={styles.linkCounter}>{wishlistCount}</div>
            )}
          </IntlLink>
        </div>
      </header>

      <HeaderShopImage />

      <div className={styles.wideLangs}>
        <LangList />
      </div>
    </div>
  )
}

export default React.memo(Header)
