import { roundToTwo } from './number'

export function convertPrice(price: number, currency: number): number {
  return roundToTwo(price / currency)
}

export function convertPriceByLocale(
  locale: string,
  price: number,
  currency: number
): number {
  return locale !== 'ru' && currency ? convertPrice(price, currency) : price
}

export function getPriceWithCurrency(
  locale: string,
  ...prices: readonly string[]
): string {
  let price = 0

  for (const str of prices) {
    price += parseFloat(str)
  }

  price = roundToTwo(price)

  return locale === 'ru' ? `${price} ₽` : `$${price}`
}
