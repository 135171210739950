import * as React from 'react'
import { useSelector } from 'react-redux'

import { getProduct } from 'src/redux/selectors/shop'

import * as styles from 'src/styles/product.module.css'

import type { ShopProduct } from 'src/types/user'

function ProductDiscount(): JSX.Element {
  const product: Readonly<ShopProduct> = useSelector(getProduct)

  if (
    product.sale === null ||
    product.sale === undefined ||
    product.price === null ||
    product.price === undefined
  ) {
    return <></>
  }

  return (
    <div className={styles.discount}>
      {Math.round((product.sale / product.price) * 100) - 100}%
    </div>
  )
}

export default React.memo(ProductDiscount)
