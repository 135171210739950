import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getProjectsContainsProducts,
} from 'src/redux/selectors/shop'

import ShopSearchProject from './search-project'

import * as styles from 'src/styles/shop-sections.module.css'

import type { ReduxState } from 'src/types/common'
import type { Project } from 'src/types/user'

interface StateProps {
  isFetching: boolean
  projects: readonly Readonly<Project>[]
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    projects: getProjectsContainsProducts(state),
  }
}

function ShopSections(): JSX.Element {
  const { isFetching, projects }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  return (
    <div role='radiogroup' className={styles.container}>
      {isFetching ||
        projects.map(function mapper(project): JSX.Element {
          return <ShopSearchProject key={project.id} project={project} />
        })}
    </div>
  )
}

export default React.memo(ShopSections)
