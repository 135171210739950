import * as React from 'react'
import { match } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { getLocale } from 'src/redux/selectors/intl'
import { getIsFetching, getCategoryByLink } from 'src/redux/selectors/shop'

import { fetchCategoryProducts } from 'src/redux/actions/shop'

import LoadingPage from 'src/components/loading'
import SelectSorting from 'src/components/shop/select-sorting'

import ShopHeader from 'src/components/shop-header'
import ShopProductsList from 'src/components/shop/products-list'
import ShopSectionsCategory from 'src/components/shop-sections/category'

import * as styles from 'src/styles/shop.module.css'

import type { ReduxState } from 'src/types/common'
import type { Category } from 'src/types/user'

interface StateProps {
  locale: string
  isFetching: boolean
  category: Readonly<Category> | undefined
}

interface MatchProsp {
  link: string
}

interface OwnProps {
  match: match<MatchProsp>
}

function CategoryView({ match }: OwnProps): JSX.Element {
  const { locale, category, isFetching } = useSelector(function selector(
    state: ReduxState
  ): StateProps {
    return {
      isFetching: getIsFetching(state),
      locale: getLocale(state),
      category: getCategoryByLink(state, match.params.link),
    }
  },
  shallowEqual)

  React.useEffect(
    function effect() {
      if (category !== undefined) {
        document.title = category.name
      }
    },
    [category]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchCategoryProducts(match.params.link))
    },
    [match.params.link, locale, dispatch]
  )

  return (
    <div className={styles.container}>
      <ShopHeader />

      <div className={styles.row}>
        <div className={styles.content}>
          <SelectSorting />

          {isFetching ? <LoadingPage /> : <ShopProductsList />}
        </div>

        <ShopSectionsCategory category={category} />
      </div>
    </div>
  )
}

export default React.memo(CategoryView)
