import * as React from 'react'

import { uploadFile, getFilesFromDir } from './functions'

import Header from './header'
import ButtonUpload from './button-upload'
import UploadingProgress from './progress'
import ButtonUploadingCancel from './button-uploading-cancel'
import ButtonSelect from './button-select'
import ErrorMessage from './error-message'
import Files from './files'

import type {
  FileManagerListResult,
  FileManagerUploadResult,
} from 'src/utils/filemanager'

const styleModalFade: React.HTMLAttributes<HTMLDivElement>['style'] = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'block',
  overflowY: 'auto',
}

const styleModalContent: React.HTMLAttributes<HTMLDivElement>['style'] = {
  width: 'auto',
  position: 'absolute',
  top: `${2}rem`,
  left: `${2}rem`,
  right: `${2}rem`,
}

interface Props {
  url: string
  path: string
  apiUrl: string

  resultHandler(result: FileManagerListResult): string[]
  uploadHandler(result: FileManagerUploadResult): string

  onClose(): void
  onSelect(files: readonly string[]): void
}

function FileManager(props: Props): JSX.Element {
  const xhr = React.useRef<XMLHttpRequest | null>(null)
  const [error, setError] = React.useState('')
  const [files, setFiles] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(
    function effect() {
      let isSubscribed = true

      getFilesFromDir<FileManagerListResult>(props.apiUrl, props.path)
        // Cancel asynchronous task in a useEffect cleanup function
        .then(function onfulfilled(result) {
          if (isSubscribed) {
            return result
          }

          throw new Error('unsubscribed')
        })
        .then(props.resultHandler)
        .then(setFiles)
        .finally(function end() {
          if (isSubscribed) {
            setLoading(false)
          }
        })
        .catch(function onrejected(error: Error | string) {
          if (isSubscribed) {
            setError(error instanceof Error ? error.message : error)
          }
        })

      return function cleanup() {
        isSubscribed = false

        if (xhr.current !== null) {
          xhr.current.abort()
          xhr.current = null
        }
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const [uploading, setUploading] = React.useState<File[]>([])

  const addToUploading = React.useCallback(function callback(
    files: readonly File[]
  ) {
    setUploading(function set(uploading) {
      uploading.unshift(...files)

      return Array.from(uploading)
    })
  },
  [])

  const dropFiles = React.useCallback(
    function callback(event: DragEvent) {
      event.stopPropagation()
      event.preventDefault()

      if (
        event.type === 'drop' &&
        event.dataTransfer &&
        event.dataTransfer.files
      ) {
        addToUploading(Array.from(event.dataTransfer.files))
      }
    },
    [addToUploading]
  )

  React.useEffect(
    function effect() {
      document.body.addEventListener('dragover', dropFiles)
      document.body.addEventListener('drop', dropFiles)

      return function cleanup() {
        document.body.removeEventListener('dragover', dropFiles)
        document.body.removeEventListener('drop', dropFiles)
      }
    },
    [dropFiles]
  )

  const onKeyDown = React.useCallback(
    function callback(event: KeyboardEvent) {
      switch (event.key) {
        case 'Escape':
          setUploading(function set(uploading) {
            if (uploading.length === 0) {
              props.onClose()
            }

            return uploading
          })
          break

        default:
          break
      }
    },
    [props]
  )

  React.useEffect(
    function effect() {
      document.addEventListener('keydown', onKeyDown)

      return function cleanup() {
        document.removeEventListener('keydown', onKeyDown)
      }
    },
    [onKeyDown]
  )

  const [selected, setSelected] = React.useState<string[]>([])
  const [progress, setProgress] = React.useState(0)

  React.useEffect(
    function startUploading() {
      if (xhr.current === null && uploading.length > 0) {
        uploadFile<FileManagerUploadResult>({
          xhr,
          apiUrl: props.apiUrl,
          path: props.path,
          file: uploading[uploading.length - 1],
          thumb: 350,
          setProgress,
        })
          .then(props.uploadHandler)
          .then(function onfulfilled(file) {
            setFiles(function set(files) {
              files.unshift(file)

              return Array.from(files)
            })

            setSelected(function set(selected) {
              selected.push(file)

              return Array.from(selected)
            })

            setUploading(function set(uploading) {
              uploading.pop()

              return Array.from(uploading)
            })

            return files
          })
          .finally(function end() {
            setProgress(0)
          })
          .catch(function onrejected(error: Error | string) {
            setUploading([])
            setError(error instanceof Error ? error.message : error)
          })
      }
    },
    [uploading] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onSelectFile = React.useCallback(function onSelect(file) {
    setSelected(function set(selected) {
      const index = selected.indexOf(file)

      if (index === -1) {
        selected.push(file)
      } else {
        selected.splice(index, 1)
      }

      return Array.from(selected)
    })
  }, [])

  const onSelect = React.useCallback(
    function callback() {
      setSelected(function setSelected(selected) {
        props.onSelect(selected)

        return selected
      })
    },
    [props]
  )

  const onCancel = React.useCallback(function callback() {
    if (xhr.current !== null) {
      xhr.current.abort()
      xhr.current = null

      setUploading([])
      setProgress(0)
    }
  }, [])

  return (
    <div className='modal fade show' style={styleModalFade}>
      <div className='modal-content' style={styleModalContent}>
        <Header disabled={uploading.length > 0} onClose={props.onClose} />

        <div className='modal-footer'>
          <ButtonUpload
            disabled={uploading.length > 0}
            onUpload={addToUploading}
          />

          <UploadingProgress progress={progress} />

          <ButtonUploadingCancel
            disabled={uploading.length === 0}
            onClick={onCancel}
          />

          <ButtonSelect
            disabled={selected.length === 0 || uploading.length > 0}
            onClick={onSelect}
          />
        </div>

        <ErrorMessage error={error} />

        <div className='modal-body bg-light'>
          <div className='container-fluid'>
            <Files
              url={props.url}
              path={props.path}
              files={files}
              loading={loading}
              selected={selected}
              uploading={uploading}
              onSelect={onSelectFile}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(FileManager)
