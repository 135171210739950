import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Separator from 'src/components/separator'

import * as styles from 'src/styles/header.module.css'

function HeaderImageClosed(): JSX.Element {
  return (
    <div className={styles.shopClosed}>
      <div className={styles.shopClosedContent}>
        <h2 className={styles.shopClosedHeader}>
          <FormattedMessage
            id='shop-is-closed'
            defaultMessage='The shop is closed'
          />
        </h2>

        <Separator />

        <p className={styles.shopClosedDesc}>
          <FormattedMessage
            id='shop-is-closed-desc'
            defaultMessage='Alas, the Ice-Pick shop is currently closed for an indefinite period of time.'
          />

          <br />

          <FormattedMessage
            id='shop-is-closed-info'
            defaultMessage='Follow the news at our various communities on the web!'
          />
        </p>

        <p className={styles.shopClosedDesc}>
          <FormattedMessage
            id='shop-is-closed-feedback'
            defaultMessage='If you have any questions, please mail them to'
          />
          &nbsp;
          <a
            href='mailto:support@ice-pick.com'
            rel='noopener noreferrer'
            target='_blank'
          >
            support@ice-pick.com
          </a>
        </p>
      </div>
    </div>
  )
}

export default React.memo(HeaderImageClosed)
