import * as React from 'react'

import { BACKEND_URL } from 'src/config'

import LazyImage from 'src/components/lazyimage'

import IntlLink from 'src/components/common/intl-link'

import * as styles from 'src/styles/home.module.css'

import type { Project } from 'src/types/user'

interface Props {
  project: Readonly<Project>
}

function ProjectsListItem({ project }: Props): JSX.Element {
  return (
    <article
      className={styles.project}
      itemProp='itemListElement'
      itemScope
      itemType='http://schema.org/OfferCatalog'
    >
      <IntlLink
        to={`/project/${project.link}`}
        className={styles.projectLink}
        itemProp='url'
      >
        <LazyImage
          src={`${BACKEND_URL}/img/projects/${project.image}`}
          alt={project.name}
        />

        <link
          itemProp='image'
          href={`${BACKEND_URL}/img/projects/${project.image}`}
        />

        <meta itemProp='name' content={project.name} />
      </IntlLink>
    </article>
  )
}

export default React.memo(ProjectsListItem)
