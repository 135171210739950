import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import { Shipping } from 'src/const/shipping'

import {
  getDeliveryCost,
  getDeliverySelectedMethod,
} from 'src/redux/selectors/delivery'

import { getIsUnavailable } from 'src/redux/selectors/cart'

import Separator from 'src/components/separator'

import DeliveryMethodsList from './methods'
import RecipientData from './recipient-data'
import DeliveryTotalCost from './total-cost'

import * as styles from 'src/styles/order-shipping.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  cost: number
  method: Shipping
  isRedirect: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    cost: getDeliveryCost(state),
    method: getDeliverySelectedMethod(state),
    isRedirect: getIsUnavailable(state),
  }
}

function OrderDeliveryView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'delivery-method' })
    },
    [intl]
  )

  React.useEffect(function effect() {
    if (document.getElementById('boxberry-script') === null) {
      const script = document.createElement('script')

      script.src = '//points.boxberry.ru/js/boxberry.js'
      script.id = 'boxberry-script'
      document.head.appendChild(script)
    }
  }, [])

  const { isRedirect }: StateProps = useSelector(selector, shallowEqual)

  // const history = useHistory()

  const onSubmit = React.useCallback<React.FormEventHandler>(function onSubmit(
    event
  ) {
    event.preventDefault()

    // if (cost > 0 || method === Shipping.PickUp) {
    //   if (
    //     event.target instanceof HTMLElement &&
    //     event.target.dataset.action !== undefined
    //   ) {
    //     history.push(event.target.dataset.action)
    //   }
    // }
  },
  [])

  if (isRedirect) {
    return <Redirect to={`/${intl.locale}/cart/`} />
  }

  return (
    <form
      className={classnames(utility.defaultPage, styles.container)}
      data-action='/order/payment'
      onSubmit={onSubmit}
    >
      <div className={utility.mobileHide}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage
            id='order-placement'
            defaultMessage='Order placement'
          />
        </h2>

        <Separator />
      </div>

      <div className={styles.containerWide}>
        <DeliveryMethodsList />

        <RecipientData />
      </div>

      <DeliveryTotalCost />
    </form>
  )
}

export default React.memo(OrderDeliveryView)
