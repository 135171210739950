import * as React from 'react'
import { useDispatch } from 'react-redux'

import { changeCheckbox } from 'src/redux/actions/fields'

import type { InputCheckboxField } from 'src/types/fields'

interface OwnProps {
  field: Readonly<InputCheckboxField>
  disabled?: boolean
  className?: string
  onChange?(checked: boolean): void
}

export default function InputCheckbox({
  field,
  disabled,
  onChange,
  ...props
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onCheckboxChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    function callback({ target: { checked } }) {
      dispatch(
        changeCheckbox({
          path: field.path,
          checked,
        })
      )

      if (typeof onChange === 'function') {
        onChange(checked)
      }
    },
    [field.path, onChange, dispatch]
  )

  return (
    <input
      {...props}
      id={field.id}
      type='checkbox'
      value={field.value}
      checked={field.checked}
      disabled={field.disabled || disabled}
      required={field.required}
      onChange={onCheckboxChange}
    />
  )
}
