import { connect } from 'react-redux'

import InputFile, { AdminInputImageProps } from 'src/admin/common/input-image'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminProject: { isFetching, image, id },
  },
}: ReduxState): AdminInputImageProps {
  return {
    disabled: isFetching,
    field: image,
    show: id > 0,
  }
}

export default connect(mapStateToProps)(InputFile)
