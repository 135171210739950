import * as React from 'react'
import { NavLink, Link } from 'react-router-dom'
import classnames from 'classnames'

const logoStyle: React.HTMLAttributes<HTMLDivElement>['style'] = {
  width: `${1.875}rem`,
  marginRight: `${0.5}rem`,
} as const

function AdminHeader(): JSX.Element {
  const [open, setOpen] = React.useState(false)

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      setOpen(function set(open) {
        return !open
      })
    },
    []
  )

  return (
    <header className='navbar navbar-expand-lg navbar-dark bg-dark'>
      <Link className='navbar-brand' to='/admin/'>
        <div className='d-inline-block align-top' style={logoStyle}>
          <img src='/favicon-192x192.png' alt='Logo' />
        </div>
        Ice-Pick Shop
      </Link>

      <button
        className={classnames('navbar-toggler', {
          collapsed: !open,
        })}
        data-toggle='collapse'
        data-target='#navbarSupportedContent'
        aria-controls='navbarSupportedContent'
        aria-expanded={open}
        aria-label='Toggle navigation'
        onClick={onClick}
        type='button'
      >
        <span className='navbar-toggler-icon' />
      </button>

      <div
        className={classnames('navbar-collapse collapse', {
          show: open,
        })}
      >
        <ul className='navbar-nav'>
          <li className='nav-item'>
            <NavLink className='nav-link' to='/admin/projects/'>
              Проекты
            </NavLink>
          </li>

          <li className='nav-item'>
            <NavLink className='nav-link' to='/admin/categories/'>
              Категории
            </NavLink>
          </li>

          <li className='nav-item'>
            <NavLink className='nav-link' to='/admin/products/'>
              Товары
            </NavLink>
          </li>

          <li className='nav-item'>
            <NavLink className='nav-link' to='/admin/orders/'>
              Заказы
            </NavLink>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default React.memo(AdminHeader)
