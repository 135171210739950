import { connect } from 'react-redux'

import { getPriceWithCurrency } from 'src/utils/currency'

import {
  getOrderLocale,
  getOrderProductsCost,
  getOrderShippingCost,
} from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

function mapStateToProps(state: ReduxState): InfoFieldProps {
  const locale: string = getOrderLocale(state)
  const productsCost: number = getOrderProductsCost(state)
  const shippingCost: number = getOrderShippingCost(state) || 0

  return {
    id: 'totalCost',
    name: 'Итого',
    value: getPriceWithCurrency(
      locale,
      productsCost.toString(),
      shippingCost.toString()
    ),
  }
}

const InfoTotalCost = connect(mapStateToProps)(InfoField)

export default InfoTotalCost
