import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { LocaleMenuData } from 'src/redux/reducers/intl'
import { getLangBySlug } from 'src/redux/selectors/intl'
import { changeLang } from 'src/redux/actions/intl'

import * as styles from 'src/styles/header.module.css'

import type { ReduxState } from 'src/types/common'

interface OwnProps {
  selected: boolean
  slug: string
}

function LangListItem({ slug, selected }: OwnProps): JSX.Element {
  const intl = useIntl()
  const location = useLocation()

  const lang: Readonly<LocaleMenuData> = useSelector(function selector(
    state: ReduxState
  ) {
    return getLangBySlug(state, slug)
  })

  const link = React.useMemo<string>(
    function memo() {
      return (
        (selected
          ? location.pathname
          : location.pathname.replace(`/${intl.locale}`, `/${lang.slug}`)) +
        location.search +
        location.hash
      )
    },
    [selected, lang.slug, intl.locale, location]
  )

  const alternate = React.useMemo<string>(
    function memo() {
      return (
        location.pathname.replace(`/${intl.locale}`, `/${lang.slug}`) +
        location.search +
        location.hash
      )
    },
    [intl.locale, lang.slug, location]
  )

  const dispatch = useDispatch()

  const onClick = React.useCallback(
    function callback() {
      dispatch(changeLang(lang.slug))
    },
    [lang.slug, dispatch]
  )

  return (
    <Link
      to={link}
      aria-current={selected}
      className={styles.langItem}
      onClick={onClick}
    >
      <abbr className={styles.langItemAbbr} lang={lang.slug} title={lang.name}>
        {lang.abbr}
      </abbr>

      {selected || (
        <link rel='alternate' hrefLang={lang.slug} href={alternate} />
      )}
    </Link>
  )
}

export default React.memo(LangListItem)
