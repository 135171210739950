import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import SvgLoading from 'src/components/svg/loading'

import * as styles from 'src/styles/personal-details.module.css'

interface Props {
  disabled: boolean
}

function ButtonSubmit({ disabled }: Props): JSX.Element {
  return (
    <button className={styles.submit} disabled={disabled} type='submit'>
      {disabled ? (
        <div role='img' aria-label='Loading…' className={styles.actionLoading}>
          <SvgLoading />
        </div>
      ) : (
        <FormattedMessage id='save' defaultMessage='Save' />
      )}
    </button>
  )
}

export default React.memo(ButtonSubmit)
