import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getProductId,
} from 'src/redux/selectors/admin-product-edit'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  id: number
  disabled: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    disabled: getIsFetching(state),
    id: getProductId(state),
  }
}

function ButtonSave(): JSX.Element {
  const { id, disabled } = useSelector(selector, shallowEqual)

  return (
    <div className='form-group row'>
      <div className='col-sm-12 text-center'>
        <button className='btn btn-primary' disabled={disabled} type='submit'>
          {id > 0 ? 'Применить' : 'Создать'}
        </button>
      </div>
    </div>
  )
}

export default React.memo(ButtonSave)
