import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { Partner } from 'src/const/partners'

import LazyImage from 'src/components/lazyimage'

import SvgSocialVk from 'src/components/svg/social-vk'
import SvgSocialTw from 'src/components/svg/social-tw'
import SvgSocialSt from 'src/components/svg/social-st'
import SvgSocialYt from 'src/components/svg/social-yt'
import SvgSocialIn from 'src/components/svg/social-in'
import SvgSocialFb from 'src/components/svg/social-fb'

import * as styles from 'src/styles/partners.module.css'

interface SocialSvgProps {
  type: string
}

function SocialSvgComponent({ type }: SocialSvgProps): JSX.Element {
  switch (type) {
    case 'vk':
      return <SvgSocialVk />

    case 'twitter':
      return <SvgSocialTw />

    case 'steam':
      return <SvgSocialSt />

    case 'youtube':
      return <SvgSocialYt />

    case 'instagram':
      return <SvgSocialIn />

    case 'facebook':
      return <SvgSocialFb />

    default:
      return <svg />
  }
}

const SocialSvg = React.memo(SocialSvgComponent)

interface Props {
  partner: Readonly<Partner>
}

function PartnersListItem({ partner }: Props): JSX.Element {
  return (
    <article className={styles.item}>
      <div className={styles.itemImage}>
        <LazyImage src={partner.image} alt={partner.name} />
      </div>

      <div className={styles.itemInfo}>
        <h3 className={styles.itemHeader}>
          <FormattedMessage id={partner.name} defaultMessage='Partner name' />
        </h3>

        <div className={styles.itemDesc}>
          <FormattedMessage
            id={partner.desc}
            defaultMessage='Partner description'
          />
        </div>

        <div className={styles.itemEmail}>
          E-mail:
          <a className={styles.itemEmailLink} href={`mailto:${partner.email}`}>
            {partner.email}
          </a>
        </div>

        {partner.link !== undefined && (
          <p>
            <a href={partner.link} rel='noopener noreferrer' target='_blank'>
              {partner.link}
            </a>
          </p>
        )}

        {partner.phoneNumber !== undefined && (
          <div>
            <FormattedMessage id='phone-number' defaultMessage='Phone number' />
            : {partner.phoneNumber}
          </div>
        )}

        {partner.phoneNumberMobile !== undefined && (
          <div>
            <FormattedMessage
              id='phone-number-mobile'
              defaultMessage='Phone number (mobile)'
            />
            : {partner.phoneNumberMobile}
          </div>
        )}

        {partner.telegram !== undefined && <p>{partner.telegram}</p>}

        <ul className={styles.itemSocials}>
          {partner.socials.map(function mapper(social): JSX.Element {
            return (
              <a
                key={social.link}
                href={social.link}
                className={styles.itemSocialsLink}
                rel='noopener noreferrer'
                target='_blank'
              >
                <SocialSvg type={social.type} />
              </a>
            )
          })}
        </ul>
      </div>
    </article>
  )
}

export default React.memo(PartnersListItem)
