import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BACKEND_URL } from 'src/config'

import { getFieldCurrentImage } from 'src/redux/selectors/shop'

import { changeInput } from 'src/redux/actions/fields'

import LazyImage from 'src/components/lazyimage'

import * as styles from 'src/styles/product.module.css'

interface OwnProps {
  index: number
  image: string
  name: string
}

function ProductImage({ image, name, index }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const field = useSelector(getFieldCurrentImage)

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      dispatch(changeInput({ path: field.path, value: image }))
    },
    [image, field.path, dispatch]
  )

  return (
    <button className={styles.imageListItem} onClick={onClick} type='button'>
      <LazyImage
        src={`${BACKEND_URL}/img/products/thumb/${image}`}
        alt={`${name} ${index}`}
      />

      <link itemProp='image' href={`${BACKEND_URL}/img/products/${image}`} />
    </button>
  )
}

export default React.memo(ProductImage)
