import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { Payment, paymentMethods } from 'src/const/payment'

import { getLocale } from 'src/redux/selectors/intl'

import {
  getOrderId,
  getOrderAmount,
  getOrderExpires,
  getOrderSignature,
} from 'src/redux/selectors/order'

import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

const method = paymentMethods[Payment.Robokassa]

interface StateProps {
  locale: string
  orderId: number
  amount: number
  expires: string
  signature: string
}

function selector(state: ReduxState): StateProps {
  return {
    locale: getLocale(state),
    orderId: getOrderId(state),
    amount: getOrderAmount(state),
    expires: getOrderExpires(state),
    signature: getOrderSignature(state),
  }
}

function FormRobokassa(): JSX.Element {
  const {
    locale,
    orderId,
    amount,
    expires,
    signature,
  }: StateProps = useSelector(selector, shallowEqual)

  return (
    <div className={utility.dn}>
      <input name='MrchLogin' type='hidden' value={method.account} />
      <input name='OutSum' type='hidden' value={amount} />
      <input
        name='OutSumCurrency'
        type='hidden'
        value={locale === 'ru' ? '' : 'USD'}
      />
      <input name='InvId' type='hidden' value={orderId} />
      <input
        name='Desc'
        type='hidden'
        value={`Ice-Pick Shop. Order #${orderId} Invoice`}
      />
      <input name='Encoding' type='hidden' value='utf-8' />
      <input name='SignatureValue' type='hidden' value={signature} />
      <input name='ExpirationDate' type='hidden' value={expires} />
    </div>
  )
}

export default React.memo(FormRobokassa)
