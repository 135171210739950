import type { TextField } from 'src/types/fields'

export interface AdminLocaleMenuData {
  key: string
  name: string
}

export interface AdminLocaleFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Fields
  current: TextField
  localeList: string[]
  locales: Record<string, AdminLocaleMenuData>
}

export const initialFields: AdminLocaleFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Fields
  current: {
    path: ['adminLocale', 'current'],
    value: 'ru',
  },
  localeList: ['ru', 'en'],
  locales: {
    ru: {
      key: 'ru',
      name: 'RU',
    },
    en: {
      key: 'en',
      name: 'EN',
    },
  },
}
