import { changeInput } from './fields'

import { BOXBERRY_API_TOKEN } from 'src/config'

import { Shipping } from 'src/const/shipping'

import {
  getFieldZip,
  getFieldCity,
  getFieldAddress,
} from 'src/redux/selectors/delivery'

import { getDeliverySelectedMethod } from 'src/redux/selectors/delivery'

import type { FieldsAction } from 'src/types/common'

export const pickupPointSign = 'ПВЗ: '

interface ResultProps {
  address: string
}

export function onSelectPickupPoint(result: ResultProps): FieldsAction {
  return function thunk(dispatch, getState) {
    const state = getState()
    const city = getFieldCity(state)
    const zip = getFieldZip(state)
    const address = getFieldAddress(state)

    const list = result.address.split(', ')

    dispatch(
      changeInput({
        path: zip.path,
        value: list[0],
      })
    )

    dispatch(
      changeInput({
        path: city.path,
        value: list[1],
      })
    )

    dispatch(
      changeInput({
        path: address.path,
        value: pickupPointSign + list.splice(2, list.length).join(', '),
      })
    )
  }
}

declare global {
  interface Window {
    boxberry: {
      open(
        callback: (result: ResultProps) => void,
        token: string,
        city: string
      ): void
    }
  }
}

function openBoxberry(
  city: string,
  callback: (result: ResultProps) => void
): boolean {
  if ('boxberry' in window) {
    window.boxberry.open(callback, BOXBERRY_API_TOKEN, city)

    return true
  }

  return false
}

export function openBoxberryByFocus(): FieldsAction {
  return function thunk(dispatch, getState) {
    const state = getState()
    const method = getDeliverySelectedMethod(state)

    if (method !== Shipping.Boxberry) {
      return false
    }

    const city = getFieldCity(state)

    return openBoxberry(city.value, function (result) {
      dispatch(onSelectPickupPoint(result))
    })
  }
}

export function openBoxberryBySubmit(event: React.FormEvent): FieldsAction {
  return function thunk(dispatch, getState) {
    const state = getState()
    const method = getDeliverySelectedMethod(state)
    const address = getFieldAddress(state)

    if (
      method !== 'boxberry' ||
      address.value.lastIndexOf(pickupPointSign) === 0
    ) {
      return false
    }

    event.preventDefault()

    const city = getFieldCity(state)

    return openBoxberry(city.value, function (result) {
      dispatch(onSelectPickupPoint(result))
    })
  }
}
