import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'
import { getLaravelErrorMessage } from 'src/utils/laravel'

import { reducerAdminClearOrderFields } from 'src/redux/reducers/admin-order'

import {
  getFieldId,
  getFieldDate,
  getFieldEmail,
  getFieldStatus,
  getFieldCountry,
  getFieldProduct,
  getFieldLastName,
  getFieldFirstName,
  getFieldShippingMethod,
} from 'src/redux/selectors/admin-order-search'

import {
  getOrderId,
  getFieldStatus as getOrderFieldStatus,
  getFieldComment,
  getFieldTrackCode,
  getFieldShippingCost,
} from 'src/redux/selectors/admin-order'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchComplete,
  callAction,
} from './fields'

import type { FieldsAction } from 'src/types/common'

import type {
  ServerSuccess,
  ApiAdminOrder,
  ApiAdminOrders,
  LaravelResponse,
} from 'src/types/api'

const RESOURCE_URL = `${BACKEND_URL}/api/admin/orders`

const path = ['adminOrder'] as const

export function fetchOrder(orderId: string): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(`${RESOURCE_URL}/${orderId}/edit`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAdminOrder | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchOrder error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function updateOrder(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()

    const fields = {
      trackCode: getFieldTrackCode(state).value,
      managerComment: getFieldComment(state).value,
      deliveryCostActual: getFieldShippingCost(state).value,
    }

    const data = new FormData()

    for (const [field, value] of Object.entries(fields)) {
      if (value.length > 0) {
        data.append(field, value)
      }
    }

    const status = getOrderFieldStatus(state).value

    data.append('status', status)
    data.append('_method', 'PATCH')

    const orderId = getOrderId(state)

    return fetch(`${RESOURCE_URL}/${orderId}`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ServerSuccess | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(fetchComplete({ path }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('updateOrder error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function notifyOrderTrackCode(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const orderId = getOrderId(state)
    const trackCode = getFieldTrackCode(state).value

    const data = new FormData()

    data.append('id', orderId.toString())
    data.append('trackCode', trackCode)

    return fetch(`${RESOURCE_URL}/notify`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ServerSuccess | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(fetchComplete({ path }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('updateOrder error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function clearAdminOrderFields(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        action: getFunctionName({ reducerAdminClearOrderFields }),
        path,
      })
    )
  }
}

const pathAdminOrders = ['adminOrders'] as const

export function fetchOrderList(params = ''): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path: pathAdminOrders }))

    const state = getState()

    const fields: Record<string, string> = {
      id: getFieldId(state).value,
      date: getFieldDate(state).value,
      email: getFieldEmail(state).value,
      method: getFieldShippingMethod(state).value,
      status: getFieldStatus(state).value,
      country: getFieldCountry(state).value,
      product: getFieldProduct(state).value,
      lastName: getFieldLastName(state).value,
      firstName: getFieldFirstName(state).value,
    }

    const search = Object.entries(fields).reduce<string[]>(function reducer(
      acc,
      [field, value]
    ) {
      if (value.length > 0) {
        acc.push(`${field}=${encodeURI(value)}`)
      }

      return acc
    },
    [])

    let queryStr = ''

    if (search.length > 0) {
      queryStr = `${params !== '' ? '&' : '?'}${search.join('&')}`
    }

    return fetch(RESOURCE_URL + params + queryStr, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAdminOrders | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path: pathAdminOrders,
            })
          )
        } else {
          dispatch(
            fetchFailure({
              path: pathAdminOrders,
              error: getLaravelErrorMessage(result),
            })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchOrderList error:', err)

        dispatch(
          fetchFailure({
            path: pathAdminOrders,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}
