import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Payment } from 'src/const/payment'

import { getPaymentSelectedMethod } from 'src/redux/selectors/order'

import PaymentMethodsListItem from './methods-list-item'

import * as styles from 'src/styles/order-payment.module.css'

function PaymentMethodsList(): JSX.Element {
  const current: Payment = useSelector(getPaymentSelectedMethod)

  return (
    <div className={styles.list}>
      <h2 className={styles.listHeader}>
        <FormattedMessage id='payment-method' defaultMessage='Payment method' />
      </h2>

      <section>
        {Object.keys(Payment).map(function mapper(type): JSX.Element {
          return (
            <PaymentMethodsListItem
              key={type}
              type={type as Payment}
              selected={current === type}
            />
          )
        })}
      </section>
    </div>
  )
}

export default React.memo(PaymentMethodsList)
