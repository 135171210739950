import history from 'src/const/history'
import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'
import { getLaravelErrorMessage } from 'src/utils/laravel'

import {
  reducerAdminClearProductFields,
  reducerAdminSwapImagesProduct,
  reducerAdminAddImagesToProduct,
  reducerAdminRemoveImageFromProduct,
  reducerAdminAddCategoryToProduct,
  reducerAdminRemoveCategoryFromProduct,
} from 'src/redux/reducers/admin-product'

import { getLocale } from 'src/redux/selectors/admin-locale'

import {
  getProductId,
  getFieldProject,
  getCategories,
  getFieldName,
  getFieldDesc,
  getFieldLink,
  getImages,
  getFieldPrice,
  getFieldSale,
  getFieldWeight,
  getFieldQuantity,
  getFieldQuantityHardset,
  getFieldVisible,
  getFieldWeekSale,
  getFieldVariants,
} from 'src/redux/selectors/admin-product-edit'

import { fetchProjectList } from './admin-project'
import { fetchCategoriesList } from './admin-category'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchComplete,
  callAction,
} from './fields'

import type { FieldsAction } from 'src/types/common'

import type {
  ApiAdminProduct,
  LaravelResponse,
  ApiAdminProducts,
  ServerSuccess,
} from 'src/types/api'

const RESOURCE_URL = `${BACKEND_URL}/api/admin/products`

const path = ['adminProduct'] as const

export function saveProduct(): FieldsAction {
  return function thunk(dispatch, getState) {
    const productId = getProductId(getState())

    const action = productId === 0 ? createProduct() : updateProduct()

    dispatch(action)
  }
}

function createProduct(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const locale = getLocale(state).value
    const project = getFieldProject(state).value
    const categories = getCategories(state)
    const name = getFieldName(state).value
    const desc = getFieldDesc(state).value
    const link = getFieldLink(state).value
    const images = getImages(state)
    const price = getFieldPrice(state).value
    const sale = getFieldSale(state).value
    const weight = getFieldWeight(state).value
    const quantity = getFieldQuantity(state).value
    const variants = getFieldVariants(state).list
    const visible = getFieldVisible(state).checked
    const weekSale = getFieldWeekSale(state).checked

    const data = new FormData()

    data.append('locale', locale)
    data.append('project_id', project)

    for (const category of categories) {
      data.append('categories[]', category.toString())
    }

    data.append('name', name)
    data.append('desc', desc)
    data.append('link', link)

    for (const image of images) {
      data.append('images[]', image)
    }

    data.append('price', price.toString())
    data.append('sale', sale.toString())
    data.append('weight', weight)
    data.append('quantity', quantity.toString())

    if (variants.length > 0) {
      data.append('parameters', JSON.stringify(variants))
    }

    if (visible) {
      data.append('visible', '1')
    }

    if (weekSale) {
      data.append('week_sale', '1')
    }

    return fetch(RESOURCE_URL, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiAdminProduct | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(fetchSuccess({ ...result, path }))

          history.push(`/admin/products/${result.product.id}/edit`)
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('createProduct error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

function updateProduct(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const id = getProductId(state)
    const locale = getLocale(state).value
    const project = getFieldProject(state).value
    const categories = getCategories(state)
    const name = getFieldName(state).value
    const desc = getFieldDesc(state).value
    const link = getFieldLink(state).value
    const images = getImages(state)
    const price = getFieldPrice(state).value
    const sale = getFieldSale(state).value
    const weight = getFieldWeight(state).value
    const quantity = getFieldQuantity(state).value
    const quantityHardset = getFieldQuantityHardset(state).checked
    const variants = getFieldVariants(state).list
    const visible = getFieldVisible(state).checked
    const weekSale = getFieldWeekSale(state).checked

    const data = new FormData()

    data.append('id', id.toString())
    data.append('locale', locale)
    data.append('project_id', project.toString())

    for (const category of categories) {
      data.append('categories[]', category.toString())
    }

    data.append('name', name)
    data.append('desc', desc)
    data.append('link', link)

    for (const image of images) {
      data.append('images[]', image)
    }

    data.append('price', price.toString())
    data.append('sale', sale ? sale.toString() : '0')
    data.append('weight', weight)
    data.append('quantity', quantity.toString())

    if (quantityHardset) {
      data.append('quantity_hardset', '1')
    }

    data.append('parameters', JSON.stringify(variants))

    if (visible) {
      data.append('visible', '1')
    }

    if (weekSale) {
      data.append('week_sale', '1')
    }

    data.append('_method', 'PATCH')

    return fetch(`${RESOURCE_URL}/${id}`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ServerSuccess | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(fetchComplete({ path }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('updateProduct error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchProduct(productId: string): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState()).value

    return fetch(`${RESOURCE_URL}/${productId}/edit?locale=${locale}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAdminProduct | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(
            fetchFailure({
              path,
              error: getLaravelErrorMessage(result),
            })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchProduct error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function getProductDependencies(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchProjectList())

    dispatch(fetchCategoriesList())
  }
}

export function addCategoryToProduct(categoryId: number): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        categoryId,
        action: getFunctionName({ reducerAdminAddCategoryToProduct }),
        path,
      })
    )
  }
}

export function removeCategoryFromProduct(index: number): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        index,
        action: getFunctionName({ reducerAdminRemoveCategoryFromProduct }),
        path,
      })
    )
  }
}

export function addImagesToProduct(files: readonly string[]): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        files,
        action: getFunctionName({ reducerAdminAddImagesToProduct }),
        path,
      })
    )
  }
}

export function swapProductImages(from: number, to: number): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        to,
        from,
        action: getFunctionName({ reducerAdminSwapImagesProduct }),
        path,
      })
    )
  }
}

export function removeProductImage(index: number): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        index,
        action: getFunctionName({ reducerAdminRemoveImageFromProduct }),
        path,
      })
    )
  }
}

export function clearAdminProductFields(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        action: getFunctionName({ reducerAdminClearProductFields }),
        path,
      })
    )
  }
}

const pathAdminProducts = ['adminProducts'] as const

export function fetchProductList(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path: pathAdminProducts }))

    return fetch(RESOURCE_URL, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAdminProducts | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path: pathAdminProducts,
            })
          )
        } else {
          dispatch(
            fetchFailure({
              path: pathAdminProducts,
              error: getLaravelErrorMessage(result),
            })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchProductList error:', err)

        dispatch(
          fetchFailure({
            path: pathAdminProducts,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}
