import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { getCountries } from 'src/redux/selectors/intl'
import { getFieldCountry } from 'src/redux/selectors/delivery'

import { getShippingCost } from 'src/redux/actions/delivery'

import InputSelect from 'src/components/common/input-select'

import * as styles from 'src/styles/order-shipping.module.css'

import type { ReduxState } from 'src/types/common'
import type { ComboboxField, ComboboxOption } from 'src/types/fields'

interface StateProps {
  field: Readonly<ComboboxField>
  options: readonly Readonly<ComboboxOption>[]
}

function selector(state: ReduxState): StateProps {
  return {
    field: getFieldCountry(state),
    options: getCountries(state),
  }
}

function SelectCountry(): JSX.Element {
  const dispatch = useDispatch()

  const onClick = React.useCallback(
    function callback() {
      dispatch(getShippingCost())
    },
    [dispatch]
  )

  const { field, options }: StateProps = useSelector(selector, shallowEqual)

  return (
    <div className={styles.dataField}>
      <label htmlFor={field.id}>
        {field.label !== undefined && <FormattedMessage id={field.label} />}
      </label>

      <InputSelect
        field={field}
        className={styles.dataInput}
        options={options}
        onChange={onClick}
      />
    </div>
  )
}

export default React.memo(SelectCountry)
