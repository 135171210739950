import type { CartFieldsState } from 'src/redux/reducers/cart'

import type { ReduxState } from 'src/types/common'

import type {
  CartProduct,
  OrderProduct,
  OrderProductsMap,
} from 'src/types/user'

function getState(state: ReduxState): CartFieldsState {
  return state.fields.cart
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getProductList(
  state: ReduxState
): readonly Readonly<CartProduct>[] {
  return getState(state).productList
}

export function getProductCount(state: ReduxState): number {
  return getProductList(state).length
}

export function getProducts(state: ReduxState): OrderProductsMap {
  return getState(state).products
}

export function getCartCost(state: ReduxState): number {
  return getState(state).total
}

export function getUnavailable(state: ReduxState): readonly number[] {
  return getState(state).unavailable
}

export function getIsUnavailable(state: ReduxState): boolean {
  return getUnavailable(state).length > 0
}

export function getProductById(
  state: ReduxState,
  productId: number
): Readonly<OrderProduct> | undefined {
  return getProducts(state).get(productId)
}

export function getCartProductById(
  state: ReduxState,
  productId: number
): Readonly<CartProduct> | undefined {
  return getProductList(state).find(function find({ id }) {
    return id === productId
  })
}
