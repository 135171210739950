import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { getFieldShipping } from 'src/redux/selectors/delivery'

import {
  getShippingCost,
  // changeDeliveryAddress,
} from 'src/redux/actions/delivery'

import { changeInput } from 'src/redux/actions/fields'

import LazyImage from 'src/components/lazyimage'

import * as styles from 'src/styles/order-shipping.module.css'

import type { ShippingMethod } from 'src/const/shipping'

interface OwnProps {
  method: Readonly<ShippingMethod>
  selected: boolean
}

function DeliveryMethodsListItem({ method, selected }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const field = useSelector(getFieldShipping)

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      // dispatch(changeDeliveryAddress(method.method))

      dispatch(changeInput({ path: field.path, value: method.method }))

      dispatch(getShippingCost())
    },
    [field.path, method.method, dispatch]
  )

  return (
    <button className={styles.item} onClick={onClick} type='button'>
      <div aria-current={selected} className={styles.itemImage}>
        <LazyImage src={method.image} alt={method.name} />
      </div>

      <div aria-current={selected} className={styles.itemName}>
        <FormattedMessage id={method.name} />
      </div>
    </button>
  )
}

export default React.memo(DeliveryMethodsListItem)
