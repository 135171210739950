import type {
  ApiResponseCart,
  ApiResponseShop,
  ApiCartAddProduct,
  ApiCartChangeProductCount,
  ApiCartRemoveProduct,
} from 'src/types/api'

import type {
  CartProduct,
  OrderProduct,
  OrderProductsMap,
  ProductParametersMap,
} from 'src/types/user'

import type { FieldsPayload } from 'src/types/fields'

export interface CartFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  id: number
  productList: CartProduct[]
  products: OrderProductsMap
  unavailable: number[]
  total: number
}

export const initialFields: CartFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  id: 0,
  productList: [],
  products: new Map(),
  unavailable: [],
  total: 0,
}

function calcTotalCost(draft: CartFieldsState): void {
  const total = draft.productList.reduce(function reducer(sum, product) {
    const productProto = draft.products.get(product.id) as OrderProduct

    let priceDelta = 0

    if (product.parameters) {
      priceDelta = product.parameters.reduce(function reducer(
        acc,
        [valueId, paramId]
      ) {
        const parameter = productProto.parameters[paramId]

        if (parameter !== undefined && parameter.affects_price) {
          const priceDelta = parameter.values[valueId].price_delta

          acc +=
            typeof priceDelta === 'string'
              ? parseFloat(priceDelta)
              : priceDelta || 0
        }

        return acc
      },
      priceDelta)
    }

    return (
      sum +
      ((productProto.sale || productProto.price || 0) + priceDelta) *
        product.count
    )
  }, 0)

  draft.total = total
}

function mergeUnavailable(
  draft: CartFieldsState,
  unavailable: readonly number[]
): number[] {
  return Array.from(new Set(unavailable.concat(draft.unavailable)))
}

export function shopInit(
  draft: CartFieldsState,
  { basket }: ApiResponseShop
): void {
  if (draft.productList.length === 0) {
    draft.productList = basket
  }
}

export function fetchSuccess(
  draft: CartFieldsState,
  cart: FieldsPayload<ApiResponseCart>
): void {
  draft.isFetching = false
  draft.error = ''

  const unavailable: number[] = []
  const products: OrderProductsMap = new Map()

  for (const product of cart.products) {
    products.set(product.id, product)

    if (product.inStock === false) {
      unavailable.push(product.id)
    }
  }

  draft.unavailable = mergeUnavailable(draft, unavailable)

  const productList = cart.products.map(function mapper(product): CartProduct {
    return {
      id: product.id,
      count: product.count,
      parameters: product.currentParameters,
    }
  })

  draft.id = cart.order
  draft.productList = productList
  draft.products = products

  calcTotalCost(draft)
}

function findProductIndex(
  draft: CartFieldsState,
  productId: number,
  parameters?: ProductParametersMap
): number {
  return draft.productList.findIndex(function findIndex(item): boolean {
    return (
      item.id === productId &&
      JSON.stringify(item.parameters) === JSON.stringify(parameters)
    )
  })
}

export function reducerAddCartProduct(
  draft: CartFieldsState,
  { id: productId, parameters }: FieldsPayload<ApiCartAddProduct>
): void {
  if (typeof parameters === 'string') {
    parameters = JSON.parse(parameters)
  }

  const index = findProductIndex(draft, productId, parameters)

  if (index === -1) {
    draft.productList.push({
      id: productId,
      parameters,
      count: 1,
    })
  }
}

export function reducerChangeCartProductCount(
  draft: CartFieldsState,
  { product, parameters, count }: FieldsPayload<ApiCartChangeProductCount>
): void {
  count = Number(count) || 1

  if (typeof parameters === 'string') {
    parameters = JSON.parse(parameters)
  }

  const index = findProductIndex(draft, product, parameters)

  if (index !== -1) {
    draft.productList[index].count = count
  }

  const item = draft.products.get(product)

  if (item !== undefined) {
    item.count = count
  }

  calcTotalCost(draft)
}

export function reducerRemoveCartProduct(
  draft: CartFieldsState,
  { product, parameters }: FieldsPayload<ApiCartRemoveProduct>
): void {
  if (typeof parameters === 'string') {
    parameters = JSON.parse(parameters)
  }

  const index = findProductIndex(draft, product, parameters)

  if (index !== -1) {
    draft.productList.splice(index, 1)
    draft.products.delete(product)
  }

  calcTotalCost(draft)
}

export function reducerSetCartUnavailable(
  draft: CartFieldsState,
  { unavailable }: FieldsPayload<{ unavailable: number[] }>
): void {
  draft.unavailable = mergeUnavailable(draft, unavailable)
}
