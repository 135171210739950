import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getIsFetching, getDeliveryError } from 'src/redux/selectors/delivery'

import * as styles from 'src/styles/order-shipping.module.css'

import type { ReduxState } from 'src/types/common'

function selector(state: ReduxState): string {
  return getIsFetching(state) ? '' : getDeliveryError(state)
}

function ShippingError(): JSX.Element {
  const error: string = useSelector(selector)

  if (error === '') {
    return <></>
  }

  return (
    <div>
      <h3 className={styles.header}>
        <FormattedMessage id='error' defaultMessage='Error' />
      </h3>

      <p>{error}</p>
    </div>
  )
}

export default React.memo(ShippingError)
