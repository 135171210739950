import * as React from 'react'
import { match } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  saveCategory,
  fetchCategory,
  clearAdminCategoryFields,
} from 'src/redux/actions/admin-category'

import AdminLocaleSwitch from 'src/admin/locale-switch'

import Headline from './headline'
import ErrorMessage from './error-message'
import InputName from './input-name'
import InputLink from './input-link'
import CheckboxVisible from './checkbox-visible'
import ButtonSave from './button-save'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  id: number
}

function selector({
  fields: {
    adminCategory: { isFetching, id },
  },
}: ReduxState): StateProps {
  return {
    isFetching,
    id,
  }
}

interface MatchProps {
  id?: string
}

interface OwnProps {
  match: match<MatchProps>
}

function AdminProjectsEditView({ match }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const { id, isFetching } = useSelector(selector, shallowEqual)

  React.useEffect(
    function effect() {
      return function cleanup() {
        if (id !== 0) {
          dispatch(clearAdminCategoryFields())
        }
      }
    },
    [id, dispatch]
  )

  const onCategoryChange = React.useCallback(
    function callback() {
      if (match.params.id) {
        dispatch(fetchCategory(match.params.id))
      }
    },
    [match.params.id, dispatch]
  )

  React.useEffect(onCategoryChange, [onCategoryChange])

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(saveCategory())
    },
    [dispatch]
  )

  return (
    <div className='container p-3'>
      <Headline />

      <div className='mb-3 text-center'>
        <AdminLocaleSwitch disabled={isFetching} onChange={onCategoryChange} />
      </div>

      <ErrorMessage />

      <form onSubmit={onSubmit}>
        <InputName />

        <InputLink />

        <CheckboxVisible />

        <ButtonSave />
      </form>
    </div>
  )
}

export default React.memo(AdminProjectsEditView)
