import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import * as styles from 'src/styles/notfound.module.css'

function NotFoundView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'page-not-found' })
    },
    [intl]
  )

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>404 Not Found</h2>

      <h3 className={styles.subheader}>
        <FormattedMessage id='page-not-found' defaultMessage='Page not found' />
      </h3>

      <p className={styles.text}>
        <FormattedMessage
          id='page-not-found-details'
          defaultMessage='Page not found details'
        />
      </p>
    </div>
  )
}

export default React.memo(NotFoundView)
