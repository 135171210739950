import * as React from 'react'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import history from 'src/const/history'
import { store } from 'src/redux/store'

import Intl from './intl'
import Routes from './routes'

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Intl>
          <Routes />
        </Intl>
      </Router>
    </Provider>
  )
}

export default React.memo(App)
