import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { SHOP_IS_CLOSED } from 'src/config'

import { getLocale } from 'src/redux/selectors/intl'
import { getOverlayVisibility } from 'src/redux/selectors/shop'

import { changeLang } from 'src/redux/actions/intl'
import { fetchShopInitial } from 'src/redux/actions/shop'

import RouteUser from './route-user'
import RouteGuest from './route-guest'
import RouteLocale from './route-locale'

import Header from 'src/components/header'
import Footer from 'src/components/footer'
import HomeView from 'src/components/home'
import EmptyPage from 'src/components/home/empty'
import LoginView from 'src/components/login'
import AboutView from 'src/components/about'
import ResetView from 'src/components/reset'
import CartView from 'src/components/cart'
import SearchView from 'src/components/search'
import RestoreView from 'src/components/restore'
import ProductView from 'src/components/product'
import ProjectView from 'src/components/project'
import LocationView from 'src/components/location'
import WishlistView from 'src/components/wishlist'
import CategoryView from 'src/components/category'
import PartnersView from 'src/components/partners'
import NotFoundView from 'src/components/notfound'
import FeedbackView from 'src/components/feedback'
import ActivateView from 'src/components/activate'
import ForbiddenView from 'src/components/forbidden'
import OrderErrorView from 'src/components/order-error'
import OrderResultView from 'src/components/order-result'
import OrderCancelView from 'src/components/order-cancel'
import PaymentInfoView from 'src/components/payment-info'
import RegistrationView from 'src/components/registration'
import DeliveryInfoView from 'src/components/delivery-info'
import OrderHistoryView from 'src/components/order-history'
import OrderPaymentView from 'src/components/order-payment'
import OrderDeliveryView from 'src/components/order-delivery'
import PrivacyPolicyView from 'src/components/privacy-policy'
import PersonalDetailsView from 'src/components/personal-details'

import OverlayTest from 'src/components/overlay-test'
import SizeTable from 'src/components/size-table'

import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

const HomePage = SHOP_IS_CLOSED ? EmptyPage : HomeView

interface RouteTemaplate {
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>
}

const routes: readonly Readonly<RouteTemaplate>[] = [
  { path: '/:lang/cart', component: CartView },
  { path: '/:lang/wishlist', component: WishlistView },
  { path: '/:lang/order/result', component: OrderResultView },
  { path: '/:lang/order/cancel', component: OrderCancelView },
  { path: '/:lang/order/error', component: OrderErrorView },
  { path: '/:lang/payment', component: PaymentInfoView },
  { path: '/:lang/shipping', component: DeliveryInfoView },
  { path: '/:lang/about', component: AboutView },
  { path: '/:lang/our-location', component: LocationView },
  { path: '/:lang/partners', component: PartnersView },
  { path: '/:lang/feedback', component: FeedbackView },
  { path: '/:lang/activate', component: ActivateView },
  { path: '/:lang/privacy-policy', component: PrivacyPolicyView },
  { path: '/:lang/forbidden', component: ForbiddenView },
  { path: '/:lang/size-table', component: SizeTable },
] as const

const routesUser: readonly Readonly<RouteTemaplate>[] = [
  { path: '/:lang/order/history', component: OrderHistoryView },
  { path: '/:lang/profile', component: PersonalDetailsView },
] as const

const routesGuest: readonly Readonly<RouteTemaplate>[] = [
  { path: '/:lang/login', component: LoginView },
  { path: '/:lang/restore', component: RestoreView },
  { path: '/:lang/reset', component: ResetView },
  { path: '/:lang/registration', component: RegistrationView },
] as const

const routesShop: readonly Readonly<RouteTemaplate>[] = [
  { path: '/:lang/category/:link', component: CategoryView },
  { path: '/:lang/project/:link', component: ProjectView },
  { path: '/:lang/search/:search', component: SearchView },
  { path: '/:lang/product/:link', component: ProductView },
  { path: '/:lang/order/shipping', component: OrderDeliveryView },
  { path: '/:lang/order/payment', component: OrderPaymentView },
] as const

interface StateProps {
  locale: string
  overlayVisibility: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    overlayVisibility: getOverlayVisibility(state),
    locale: getLocale(state),
  }
}

interface OwnProps {
  location: Location
}

function RoutesUser({ location: { pathname } }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const [, setLocale] = React.useState<string>('')

  const { locale, overlayVisibility }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  React.useEffect(
    function effect() {
      if (pathname.indexOf(locale) !== 1) {
        dispatch(changeLang(pathname.substr(1, 2)))

        return
      }

      if (!SHOP_IS_CLOSED) {
        setLocale(function set(prev) {
          if (locale !== prev) {
            dispatch(fetchShopInitial())
          }

          return locale
        })
      }
    },
    [pathname, locale, dispatch]
  )

  return (
    <div className={utility.wrapper}>
      <Header />

      <Switch>
        {!SHOP_IS_CLOSED &&
          routesShop.map(function mapper(route): JSX.Element {
            return (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
              />
            )
          })}

        {routesUser.map(function mapper(route): JSX.Element {
          return (
            <RouteUser
              key={route.path}
              path={route.path}
              component={route.component}
            />
          )
        })}

        {routesGuest.map(function mapper(route): JSX.Element {
          return (
            <RouteGuest
              key={route.path}
              path={route.path}
              component={route.component}
            />
          )
        })}

        {routes.map(function mapper(route): JSX.Element {
          return (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
            />
          )
        })}

        <Route exact path='/:lang' component={HomePage} />

        <Route exact path='/' component={RouteLocale} />

        <Route component={NotFoundView} status={404} />
      </Switch>

      {overlayVisibility ? <OverlayTest /> : <></>}

      <Footer />
    </div>
  )
}

export default React.memo(RoutesUser)
