import type { ComboboxOption } from 'src/types/fields'

export enum Shipping {
  RussianPost = 'russian_post',
  Boxberry = 'boxberry',
  BoxberryCourier = 'boxberry.courier',
  // PickUp = 'customer_pick_up',
}

export interface ShippingMethod {
  readonly name: string
  readonly image: string
  readonly method: Shipping
}

export const shippingMethods: Record<Shipping, ShippingMethod> = {
  [Shipping.RussianPost]: {
    name: 'russian-post',
    method: Shipping.RussianPost,
    image: '/img/delivery-russian-post.png',
  },
  [Shipping.Boxberry]: {
    name: 'boxberry-default',
    method: Shipping.Boxberry,
    image: '/img/delivery-none.png',
  },
  [Shipping.BoxberryCourier]: {
    name: 'boxberry-courier',
    method: Shipping.BoxberryCourier,
    image: '/img/delivery-courier.png',
  } /* ,
  [Shipping.pickUp]: {
    name: 'customer-pick-up',
    method: Shipping.pickUp,
    image: '/img/delivery-customer-pick-up.png'
  } */,
} as const

export function shippingMethodAsString(method: Shipping): string {
  switch (method) {
    case Shipping.RussianPost:
      return 'Почта России'
    case Shipping.Boxberry:
      return 'Boxberry ПВЗ'
    case Shipping.BoxberryCourier:
      return 'Boxberry курьер'
    // case Shipping.PickUp:
    //   return 'Самовывоз со склада'
    default:
      return method
  }
}

export const shippingOptions: readonly Readonly<ComboboxOption>[] = [
  {
    value: Shipping.RussianPost,
    name: shippingMethodAsString(Shipping.RussianPost),
  },
  {
    value: Shipping.Boxberry,
    name: shippingMethodAsString(Shipping.Boxberry),
  },
  {
    value: Shipping.BoxberryCourier,
    name: shippingMethodAsString(Shipping.BoxberryCourier),
  },
  // { value: Shipping.PickUp, name: shippingMethodAsString(Shipping.PickUp) },
] as const
