import * as React from 'react'

function SvgSocialFacebook(): JSX.Element {
  return (
    <svg viewBox='0 0 64 64'>
      <path d='M61.2,0H3.6C1.6,0,0,0.9,0,2.8v57.6c0,2,1.6,3.6,3.6,3.6H34V38h-7v-8h7v-8C34,13.6,39.1,9,46.5,9 C50.1,9,53,9.3,54,9.4v8.7l-4.7,0c-4.1,0-4.3,1.9-4.3,4.8V30h9.3l-1.3,8H45v26h16.2c2,0,2.8-1.6,2.8-3.6V2.8C64,0.9,63.1,0,61.2,0z' />
    </svg>
  )
}

export default React.memo(SvgSocialFacebook)
