import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { repeatOrder } from 'src/redux/actions/order'

interface OwnProps {
  orderId: number
  disabled?: boolean
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  staticContext?: React.Context<any>
}

function ButtonOrderRepeat({
  orderId,
  staticContext: _staticContext,
  ...rest
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      dispatch(repeatOrder(orderId))
    },
    [orderId, dispatch]
  )

  return (
    <button {...rest} onClick={onClick}>
      <FormattedMessage id='order-repeat' defaultMessage='Repeat order' />
    </button>
  )
}

export default React.memo(ButtonOrderRepeat)
