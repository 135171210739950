import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { getIsFetching } from 'src/redux/selectors/auth'

import { loginUser, clearAuthError } from 'src/redux/actions/auth'

import Separator from 'src/components/separator'
import IntlLink from 'src/components/common/intl-link'

import ErrorMessage from 'src/components/auth-common/error-message'
import InputEmail from 'src/components/auth-common/input-email'
import InputPassword from 'src/components/auth-common/input-password'
import ButtonSubmit from 'src/components/auth-common/button-submit'

import * as styles from 'src/styles/auth-login.module.css'
import * as utility from 'src/styles/utility.module.css'

function LoginView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'sign-in-header' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      return function cleanup() {
        dispatch(clearAuthError())
      }
    },
    [dispatch]
  )

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(loginUser())
    },
    [dispatch]
  )

  const isFetching: boolean = useSelector(getIsFetching)

  return (
    <form
      className={classnames(utility.defaultPage, styles.container)}
      onSubmit={onSubmit}
    >
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage id='sign-in-header' defaultMessage='Sign In' />
      </h2>

      <Separator />

      <ErrorMessage className={styles.error} />

      <div className={utility.mt2}>
        <InputEmail
          disabled={isFetching}
          className={styles.field}
          inputClassName={styles.input}
        />
      </div>

      <InputPassword
        disabled={isFetching}
        className={styles.field}
        inputClassName={styles.input}
      />

      <div className={styles.field}>
        <IntlLink to='/restore'>
          <FormattedMessage
            id='forgot-password'
            defaultMessage='Forgot password'
          />
        </IntlLink>
      </div>

      <ButtonSubmit
        label='sign-in'
        disabled={isFetching}
        className={styles.submit}
        loadingClassName={styles.submitLoading}
      />
    </form>
  )
}

export default React.memo(LoginView)
