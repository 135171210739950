import { createSelector } from 'reselect'

import type { ShopFieldsState } from 'src/redux/reducers/shop'

import type { ReduxState } from 'src/types/common'

import type {
  Category,
  Project,
  Product,
  ShopProduct,
  ProductsCount,
  NavigationItem,
} from 'src/types/user'

import type {
  ComboboxField,
  NumberField,
  InputTextField,
  FieldLoading,
  TextField,
  InputCheckboxField,
} from 'src/types/fields'

function getState(state: ReduxState): ShopFieldsState {
  return state.fields.shop
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getCategories(
  state: ReduxState
): readonly Readonly<Category>[] {
  return getState(state).categories
}

export function getCategoryByLink(
  state: ReduxState,
  link: string
): Readonly<Category> | undefined {
  return getCategories(state).find(function find(category) {
    return category.link === link
  })
}

export function getProjects(state: ReduxState): readonly Readonly<Project>[] {
  return getState(state).projects
}

export function getProjectById(
  state: ReduxState,
  projectId: number
): Readonly<Project> | undefined {
  return getProjects(state).find(function find(item) {
    return item.id === projectId
  })
}

export function getProjectByLink(
  state: ReduxState,
  link: string
): Project | undefined {
  return getProjects(state).find(function find(item) {
    return item.link === link
  })
}

export function getWeekProducts(
  state: ReduxState
): readonly Readonly<Product>[] {
  return getState(state).week
}

export function getProducts(state: ReduxState): readonly Readonly<Product>[] {
  return getState(state).products
}

export function getProductsCount(state: ReduxState): number {
  return getProducts(state).length
}

export function getProduct(state: ReduxState): Readonly<ShopProduct> {
  return getState(state).product
}

export function getSimilar(state: ReduxState): readonly Readonly<Product>[] {
  return getState(state).similar
}

export function getNavigationLinks(
  state: ReduxState,
  path: 'projects' | 'categories'
): readonly Readonly<NavigationItem>[] {
  switch (path) {
    case 'projects':
      return getState(state).projects

    case 'categories':
      return getState(state).categories

    default: {
      const unknown: never = path

      throw new Error(`Unknown path "${unknown}"`)
    }
  }
}

// Sorting and filter

export function getProjectSelected(state: ReduxState): number {
  return getState(state).projectSelected
}

export function getCategorySelected(state: ReduxState): number {
  return getState(state).categorySelected
}

const getFilteredProducts = createSelector(
  [getProducts, getProjectSelected, getCategorySelected],
  function getFilteredProducts(
    products,
    projectId,
    categoryId
  ): readonly Readonly<Product>[] {
    if (projectId === 0 || categoryId === 0) {
      return products
    }

    return products.filter(function filter(product) {
      return (
        product.project_id === projectId &&
        product.categories.includes(categoryId)
      )
    })
  }
)

export const getFilteredAndSortedProducts = createSelector(
  [getFilteredProducts, getFieldSortingValue],
  function getFilteredAndSortedProducts(
    products,
    sorting
  ): readonly Readonly<Product>[] {
    switch (sorting) {
      case 'asc':
        return [...products].sort(function sort(left, right) {
          return (
            (left.sale || left.price || 0) - (right.sale || right.price || 0)
          )
        })
      case 'desc':
        return [...products].sort(function sort(left, right) {
          return (
            (right.sale || right.price || 0) - (left.sale || left.price || 0)
          )
        })
      case 'available':
        return products.filter(function filter(product) {
          return product.inStock
        })
      default:
        return products
    }
  }
)

export function getProductCounts(state: ReduxState): ProductsCount {
  return getState(state).counts
}

interface GetProductCountProps {
  projectId: number
  categoryId: number
}

export function getProductCountByProjectAndCategory(
  state: ReduxState,
  { projectId, categoryId }: GetProductCountProps
): number {
  const counts = getProductCounts(state)
  const project = counts.get(projectId)

  return project !== undefined ? project.get(categoryId) || 0 : 0
}

function getCategoryId(state: ReduxState, categoryId: number): number {
  return categoryId
}

export const getProjectsContainsProductsByCategoryId = createSelector(
  [getProjects, getProductCounts, getCategoryId],
  function getProjectsContainsProductsByCategoryId(
    projects,
    counts,
    categoryId
  ): readonly Readonly<Project>[] {
    if (categoryId === 0) {
      return []
    }

    return projects.filter(function filter(project) {
      const item = counts.get(project.id)

      return item !== undefined && item.get(categoryId) !== undefined
    })
  }
)

function getProjectId(state: ReduxState, projectId: number): number {
  return projectId
}

export const getCategoriesContainsProductsByProjectId = createSelector(
  [getCategories, getProductCounts, getProjectId],
  function getCategoriesContainsProductsByProjectId(
    categories,
    counts,
    projectId
  ): readonly Readonly<NavigationItem>[] {
    if (projectId === 0) {
      return []
    }

    const assoc = counts.get(projectId)

    if (assoc === undefined) {
      return []
    }

    return categories.filter(function filter(category) {
      return assoc.get(category.id) !== undefined
    })
  }
)

// Search

export const getProjectsContainsProducts = createSelector(
  [getProjects, getProductCounts],
  function getProjectsContainsProducts(
    projects,
    counts
  ): readonly Readonly<Project>[] {
    return projects.filter(function filter(project) {
      return counts.get(project.id) !== undefined
    })
  }
)

/* Fields */

export function getFieldOverlay(state: ReduxState): InputCheckboxField {
  return getState(state).overlayVisibility
}

export function getOverlayVisibility(state: ReduxState): boolean {
  return getFieldOverlay(state).checked
}

export function getFieldCurrentImage(state: ReduxState): TextField {
  return getState(state).currentImage
}

export function getProductCurrentImage(state: ReduxState): string {
  return getFieldCurrentImage(state).value
}

export function getFieldCurrencyUsd(state: ReduxState): NumberField {
  return getState(state).currencyUsd
}

export function getCurrencyUsd(state: ReduxState): number {
  return getFieldCurrencyUsd(state).value
}

export function getFieldNavigation(state: ReduxState): Readonly<TextField> {
  return getState(state).navigation
}

export function getNavigationCurrent(state: ReduxState): string {
  return getFieldNavigation(state).value
}

export function getFieldSorting(state: ReduxState): ComboboxField {
  return getState(state).sorting
}

export function getFieldSortingValue(state: ReduxState): string {
  return getFieldSorting(state).value
}

export function getFieldSearch(
  state: ReduxState
): Readonly<InputTextField & FieldLoading> {
  return getState(state).search
}

export function getSearchValue(state: ReduxState): string {
  return getFieldSearch(state).value
}

export function getIsSearching(state: ReduxState): boolean {
  return getFieldSearch(state).isFetching
}
