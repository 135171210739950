import * as React from 'react'

function SvgHeart(): JSX.Element {
  return (
    <svg viewBox='-112 236.4 369.5 369.5'>
      <path d='M229.6,282.3l-2.2-2.5c-18.2-20.2-42.6-31.3-68.6-31.3s-50.4,11.1-68.6,31.3l-17.5,19.4l-17.5-19.4 c-18.2-20.2-42.6-31.3-68.6-31.3s-50.4,11.1-68.6,31.3l-2.2,2.5c-17.9,19.8-27.8,46.1-27.8,74.1c0,27.9,9.9,54.2,27.8,74.1 l0.1,0.1L63.5,589.8c2.4,2.5,5.7,4,9.2,4s6.8-1.5,9.2-4l147.7-159.2l0.1-0.1c17.9-19.8,27.8-46.1,27.8-74.1 C257.4,328.5,247.5,302.2,229.6,282.3z M211.1,413.7L72.6,562.9L-65.8,413.7c-28.5-31.6-28.5-83,0.1-114.5l2.2-2.5 c13.4-14.9,31.2-23.1,50-23.1s36.6,8.2,50,23.1l26.8,29.6c2.4,2.6,5.7,4.1,9.3,4.1c3.5,0,6.9-1.5,9.3-4.1l26.8-29.7 c13.4-14.9,31.2-23.1,50-23.1s36.6,8.2,50.1,23.1l2.2,2.5C239.6,330.7,239.6,382.1,211.1,413.7z' />

      <path d='M145.9,312.9c24.3,2.1,38.7,11.9,48.3,29.6c1.2,2.3,5.4,3.3,5.2,0.7c-3.2-39.7-32.8-42-53.1-34.9 C143.8,309.2,143.3,312.7,145.9,312.9z' />
    </svg>
  )
}

export default React.memo(SvgHeart)
