import * as React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getProducts } from 'src/redux/selectors/admin-products'

import { fetchProductList } from 'src/redux/actions/admin-product'

import AdminProductsItem from './item'

import type { Product } from 'src/types/admin'

type StateProps = readonly Readonly<Product>[]

function AdminProductsView(): JSX.Element {
  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchProductList())
    },
    [dispatch]
  )

  const products: StateProps = useSelector(getProducts)

  return (
    <div className='container p-3'>
      <div className='d-flex align-items-center mb-2'>
        <h1 className='mr-2 mb-0'>Товары</h1>

        <div>
          <Link className='btn btn-outline-primary' to='/admin/products/create'>
            Создать
          </Link>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Название</th>
              <th>Ссылка</th>
              <th>Цена</th>
              <th>Со скидкой</th>
              <th>Кол-во</th>
            </tr>
          </thead>

          <tbody>
            {products.map(function mapper(product): JSX.Element {
              return <AdminProductsItem key={product.id} product={product} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default React.memo(AdminProductsView)
