import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fetchUser } from 'src/redux/actions/auth'
import { fetchCurrencyUsd } from 'src/redux/actions/currency'

import ScrollToTop from './scroll-to-top'

import RouteAdmin from './route-admin'

import RoutesUser from './routes-user'
import RoutesAdmin from './routes-admin'

function Routes(): JSX.Element {
  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchUser())
      dispatch(fetchCurrencyUsd())
    },
    [dispatch]
  )

  return (
    <ScrollToTop>
      <Switch>
        <RouteAdmin path='/admin' component={RoutesAdmin} />
        <Route component={RoutesUser} />
      </Switch>
    </ScrollToTop>
  )
}

export default React.memo(Routes)
