import { connect } from 'react-redux'

import InputLink, { AdminInputLinkProps } from 'src/admin/common/input-link'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminCategory: { isFetching, link },
  },
}: ReduxState): AdminInputLinkProps {
  return {
    disabled: isFetching,
    field: link,
  }
}

export default connect(mapStateToProps)(InputLink)
