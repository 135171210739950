import * as React from 'react'
import { useSelector } from 'react-redux'

import { getCategoryId } from 'src/redux/selectors/admin-category-edit'

function Headline(): JSX.Element {
  const id: number = useSelector(getCategoryId)

  return (
    <h1 className='mb-3 text-center'>
      {id > 0 ? 'Редактировать категорию' : 'Создать новую категорию'}
    </h1>
  )
}

export default React.memo(Headline)
