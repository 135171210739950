export interface PartnerSocial {
  readonly type: string
  readonly link: string
}

export interface Partner {
  readonly name: string
  readonly link?: string
  readonly image: string
  readonly email: string
  readonly desc: string
  readonly socials: readonly Readonly<PartnerSocial>[]
  readonly phoneNumber?: string
  readonly phoneNumberMobile?: string
  readonly telegram?: string
}

export const partners: readonly Readonly<Partner>[] = [
  {
    name: 'partner-1-name',
    image: '/img/wild-and-nuclear.jpg',
    email: 'George.h@wildandnuclear.com',
    desc: 'partner-1-desc',
    socials: [
      {
        type: 'instagram',
        link: 'https://instagram.com/Wild_and_nuclear/',
      },
      {
        type: 'vk',
        link: 'https://vk.com/wildnnuclear',
      },
    ],
  },
  {
    name: 'partner-2-name',
    image: '/img/kultpress.png',
    link: 'http://kultpress.ru/',
    email: 'hello@kultpress.ru',
    desc: 'partner-2-desc',
    phoneNumber: '8 499 393 38 56',
    phoneNumberMobile: '8 925 393 38 56',
    telegram: 'Telegram/WhatsApp: 8 909 166 63 62',
    socials: [
      {
        type: 'instagram',
        link: 'https://www.instagram.com/kultpechati/',
      },
    ],
  },
] as const
