import { BACKEND_URL } from 'src/config'

import {
  getFieldName,
  getFieldEmail,
  getFieldMessage,
} from 'src/redux/selectors/feedback-fields'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
} from 'src/redux/actions/fields'

import type { FieldsAction } from 'src/types/common'
import type { ServerSuccess, ServerError } from 'src/types/api'

const path = ['feedback'] as const

export function sendMessage(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const name = getFieldName(state).value
    const email = getFieldEmail(state).value
    const message = getFieldMessage(state).value

    const data = new FormData()

    data.append('name', name)
    data.append('email', email)
    data.append('message', message)

    return fetch(`${BACKEND_URL}/api/feedback`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ServerSuccess | ServerError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(fetchSuccess({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('sendMessage error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}
