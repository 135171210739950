import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import {
  getError,
  getIsFetching,
  getIsLoggedIn,
} from 'src/redux/selectors/auth'

import { activateUser, clearAuthError } from 'src/redux/actions/auth'

import LoadingPage from 'src/components/loading'

import * as styles from 'src/styles/auth-activate.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  isLoggedIn: boolean
  error: string
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    isLoggedIn: getIsLoggedIn(state),
    error: getError(state),
  }
}

interface OwnProps {
  location: Location
}

function ActivateView({ location: { search } }: OwnProps): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'activate' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(activateUser(search))

      return function cleanup() {
        dispatch(clearAuthError())
      }
    },
    [search, dispatch]
  )

  const { isFetching, isLoggedIn, error }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  if (isFetching) {
    return <LoadingPage />
  }

  if (isLoggedIn) {
    return <Redirect to={`/${intl.locale}/profile/`} />
  }

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage id='activate' defaultMessage='Activate' />
      </h2>

      <p className={styles.text}>{error}</p>
    </div>
  )
}

export default React.memo(ActivateView)
