import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getFieldMessage } from 'src/redux/selectors/feedback-fields'

import InputTextarea from 'src/components/common/input-textarea'

import * as styles from 'src/styles/feedback.module.css'

function InputMessage(): JSX.Element {
  const field = useSelector(getFieldMessage)

  return (
    <div className={styles.field}>
      <label htmlFor={field.id}>
        <FormattedMessage id='message' defaultMessage='Message' />
      </label>

      <InputTextarea className={styles.textarea} field={field} />
    </div>
  )
}

export default React.memo(InputMessage)
