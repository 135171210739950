import * as React from 'react'

function SvgSocialInstagram(): JSX.Element {
  return (
    <svg viewBox='0 0 64 64'>
      <path d='M42.5,11.1H21.5c-5.7,0-10.3,4.6-10.3,10.3v21.1c0,5.7,4.6,10.3,10.3,10.3h21.1c5.7,0,10.3-4.6,10.3-10.3V21.4 C52.8,15.7,48.2,11.1,42.5,11.1z M32,45.4c-7.4,0-13.5-6-13.5-13.5c0-7.4,6-13.5,13.5-13.5s13.5,6,13.5,13.5S39.4,45.4,32,45.4z' />

      <circle cx='32' cy='31.9' r='8.2' />

      <path d='M60.5,0H3.6C1.6,0,0.1,1.6,0.1,3.5v56.9c0,1.9,1.6,3.5,3.5,3.5h56.9c1.9,0,3.5-1.6,3.5-3.5V3.5 C64,1.6,62.4,0,60.5,0z M58.1,42.5c0,8.6-7,15.5-15.5,15.5H21.5c-8.6,0-15.5-7-15.5-15.5V21.4c0-8.6,7-15.5,15.5-15.5h21.1 c8.6,0,15.5,7,15.5,15.5V42.5z' />
    </svg>
  )
}

export default React.memo(SvgSocialInstagram)
