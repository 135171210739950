import * as React from 'react'
import { useSelector } from 'react-redux'

import { getProductCountByProjectAndCategory } from 'src/redux/selectors/shop'

import * as styles from 'src/styles/shop-sections.module.css'

import type { ReduxState } from 'src/types/common'

interface OwnProps {
  name: string
  selected: boolean
  projectId: number
  categoryId: number
  onSelect(projectId: number, categoryId: number): void
}

function ShopSectionsItem({
  name,
  selected,
  projectId,
  categoryId,
  onSelect,
}: OwnProps): JSX.Element {
  const count = useSelector(function selector(state: ReduxState): number {
    return getProductCountByProjectAndCategory(state, {
      projectId,
      categoryId,
    })
  })

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      onSelect(projectId, categoryId)
    },
    [projectId, categoryId, onSelect]
  )

  return (
    <button
      role='radio'
      aria-checked={selected}
      className={styles.item}
      onClick={onClick}
      type='button'
    >
      {name} ({count})
    </button>
  )
}

export default React.memo(ShopSectionsItem)
