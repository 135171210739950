import type { ComboboxOption } from 'src/types/fields'

export enum OrderStatus {
  Current = 0,
  Pending = 1,
  Canceled = 2,
  Processed = 3,
  Shipping = 4,
  Shipped = 5,
  Special = 6,
}

export function orderStatusAsString(status: OrderStatus): string {
  switch (status) {
    case OrderStatus.Current:
      return 'Текущий'
    case OrderStatus.Pending:
      return 'В обработке'
    case OrderStatus.Canceled:
      return 'Отменён'
    case OrderStatus.Processed:
      return 'Обработан'
    case OrderStatus.Shipping:
      return 'Готов к отправке'
    case OrderStatus.Shipped:
      return 'Отправлен'
    case OrderStatus.Special:
      return 'Особый'
    default:
      return `Неизвестный (${status})`
  }
}

export function orderStatusAsEmoji(status: OrderStatus): string {
  switch (status) {
    case OrderStatus.Current:
      return '🛒 '
    case OrderStatus.Pending:
      return '🚦 '
    case OrderStatus.Canceled:
      return '❌ '
    case OrderStatus.Processed:
      return '✅ '
    case OrderStatus.Shipping:
      return '📦 '
    case OrderStatus.Shipped:
      return '🚀 '
    case OrderStatus.Special:
      return '🧶 '
    default:
      return '❓ '
  }
}

export const orderStatus: readonly Readonly<ComboboxOption>[] = [
  { value: '', name: '—' },
  { value: '1', name: 'В обработке' },
  { value: '2', name: 'Отменён' },
  { value: '3', name: 'Обработан' },
  { value: '4', name: 'Готов к отправке' },
  { value: '5', name: 'Отправлен' },
  { value: '6', name: 'Особый' },
] as const

export const orderSearchOptions: readonly Readonly<ComboboxOption>[] = [
  { value: '1', name: 'В обработке' },
  { value: '2', name: 'Отменён' },
  { value: '>2', name: '[Любой оплаченный]' },
  { value: '3', name: 'Обработан' },
  { value: '4', name: 'Готов к отправке' },
  { value: '5', name: 'Отправлен' },
  { value: '6', name: 'Особый' },
] as const
