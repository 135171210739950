import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import SvgLoading from 'src/components/svg/loading'

interface Props {
  label: string
  disabled: boolean
  className: string
  loadingClassName: string
}

function ButtonSubmit({
  label,
  disabled,
  className,
  loadingClassName,
}: Props): JSX.Element {
  return (
    <button className={className} disabled={disabled} type='submit'>
      {disabled ? (
        <div role='img' aria-label='Loading…' className={loadingClassName}>
          <SvgLoading />
        </div>
      ) : (
        <FormattedMessage id={label} />
      )}
    </button>
  )
}

export default React.memo(ButtonSubmit)
