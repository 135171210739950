import * as React from 'react'
import { useIntl } from 'react-intl'
import * as showdown from 'showdown'
import classnames from 'classnames'

import * as styles from 'src/styles/privacy.module.css'
import * as utility from 'src/styles/utility.module.css'

const classList: readonly Readonly<[string, string]>[] = [
  ['h2', utility.defaultPageHeader],
] as const

const converter = new showdown.Converter({
  extensions: classList.map(function mapper([key, className]) {
    return {
      type: 'output',
      regex: new RegExp(`<${key}(.*?)>`, 'g'),
      replace: `<${key} class="${className}" $1>`,
    }
  }),
})

function PrivacyPolicyView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'privacy-policy' })
    },
    [intl]
  )

  const contentHtml = React.useMemo(
    function memo() {
      return {
        __html: converter.makeHtml(
          intl.formatMessage({ id: 'privacy-policy-content' })
        ),
      }
    },
    [intl]
  )

  return (
    <div
      className={classnames(utility.defaultPage, styles.container)}
      dangerouslySetInnerHTML={contentHtml}
    />
  )
}

export default React.memo(PrivacyPolicyView)
