import type {
  FieldsPayload,
  InputTextField,
  InputLinkField,
  InputCheckboxField,
} from 'src/types/fields'

import type { ApiAdminCategory } from 'src/types/api'

export interface AdminCategoryFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  id: number
  // Fields
  name: InputTextField
  link: InputLinkField
  visible: InputCheckboxField
}

export const initialFields: AdminCategoryFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  id: 0,
  // Fields
  name: {
    path: ['adminCategory', 'name'],
    id: 'name',
    label: 'Название',
    required: true,
    value: '',
  },
  link: {
    path: ['adminCategory', 'link'],
    id: 'link',
    link: '/category/',
    label: 'Ссылка',
    required: true,
    value: '',
  },
  visible: {
    path: ['adminCategory', 'visible'],
    id: 'visible',
    label: 'Видимость',
    description: 'Показывать на сайте',
    checked: true,
    value: '1',
  },
}

export function fetchSuccess(
  draft: AdminCategoryFieldsState,
  { category }: FieldsPayload<ApiAdminCategory>
): void {
  draft.isFetching = false
  draft.error = ''

  draft.id = category.id || 0
  draft.name.value = category.name ? category.name.value : ''
  draft.link.value = category.link || ''
  draft.visible.checked = Boolean(Number(category.status) & 1)
}

export function reducerAdminClearCategoryFields(
  draft: AdminCategoryFieldsState,
  _payload: FieldsPayload
): void {
  Object.assign(draft, initialFields)
}
