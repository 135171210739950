import * as React from 'react'

import InputText from 'src/components/common/input-text'

import type { InputTextField } from 'src/types/fields'

export interface AdminInputTextProps {
  field: Readonly<InputTextField>
  disabled: boolean
  divClassName?: string
  labelClassName?: string
  inputDivClassName?: string
  inputClassName?: string
}

export default function AdminInputText({
  field,
  disabled,
  divClassName = 'form-group row',
  labelClassName = 'col-sm-2 text-md-right col-form-label',
  inputDivClassName = 'col-sm-10',
  inputClassName = 'form-control',
}: AdminInputTextProps): JSX.Element {
  return (
    <div className={divClassName}>
      <label className={labelClassName} htmlFor={field.id}>
        {field.label}
      </label>

      <div className={inputDivClassName}>
        <InputText
          className={inputClassName}
          disabled={disabled}
          field={field}
        />
      </div>
    </div>
  )
}
