import { createSelector } from 'reselect'

import type { AdminProjectsFieldsState } from 'src/redux/reducers/admin-projects'

import type { Project } from 'src/types/admin'
import type { ReduxState } from 'src/types/common'
import type { ComboboxOption } from 'src/types/fields'

function getState(state: ReduxState): AdminProjectsFieldsState {
  return state.fields.adminProjects
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getProjects(state: ReduxState): readonly Readonly<Project>[] {
  return getState(state).list
}

export const getProjectsOptions = createSelector(
  [getProjects],
  function getProjectsOptions(list): readonly Readonly<ComboboxOption>[] {
    return list.map(function mapper({ id, name }): ComboboxOption {
      return {
        value: id.toString(),
        name,
      }
    })
  }
)
