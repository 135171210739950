import * as React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useDispatch } from 'react-redux'

import { changeInput } from 'src/redux/actions/fields'

import type { InputTextField } from 'src/types/fields'

const initEditor = {
  height: '20rem',
  convert_urls: false,
  relative_urls: true,
  plugins: [
    'advlist autolink lists link image charmap print preview hr anchor pagebreak',
    'searchreplace wordcount visualblocks visualchars code fullscreen', // codemirror
    'insertdatetime media nonbreaking save table contextmenu directionality',
    'template paste textcolor colorpicker textpattern imagetools toc help',
  ],
  toolbar1:
    'undo redo | styleselect | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | preview fullscreen',
  codemirror: {
    path: 'codemirror',
    indentOnInit: true,
  },
  paste_retain_style_properties: 'none',
  paste_webkit_styles: 'none',
} as const

interface OwnProps {
  field: InputTextField
  disabled?: boolean
}

export default function InputTinyMce({
  field,
  disabled,
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onChange = React.useCallback(
    function callback(content: string) {
      dispatch(
        changeInput({
          path: field.path,
          value: content,
        })
      )
    },
    [field.path, dispatch]
  )

  return (
    <Editor
      init={initEditor}
      value={field.value}
      disabled={disabled}
      onEditorChange={onChange}
    />
  )
}
