import * as React from 'react'
import { match } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { getIsFetching } from 'src/redux/selectors/shop'

import { setShopSearchFlag, fetchSearchProducts } from 'src/redux/actions/shop'

import LoadingPage from 'src/components/loading'

import ShopHeader from 'src/components/shop-header'
import ShopProductsList from 'src/components/shop/products-list'
import ShopSectionsSearch from 'src/components/shop-sections/search'

import SelectSorting from './select-sorting'

import * as styles from 'src/styles/shop.module.css'

interface MatchProps {
  search: string
}

interface OwnProps {
  match: match<MatchProps>
}

function SearchView({ match }: OwnProps): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'search' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchSearchProducts(match.params.search))

      dispatch(setShopSearchFlag(true))
    },
    [match.params.search, dispatch]
  )

  React.useEffect(
    function effect() {
      return function cleanup() {
        dispatch(setShopSearchFlag(false))
      }
    },
    [dispatch]
  )

  const isFetching: boolean = useSelector(getIsFetching)

  return (
    <div className={styles.container}>
      <ShopHeader />

      <div className={styles.row}>
        <div className={styles.content}>
          <SelectSorting />

          {isFetching ? <LoadingPage /> : <ShopProductsList />}
        </div>

        <ShopSectionsSearch />
      </div>
    </div>
  )
}

export default React.memo(SearchView)
