import * as React from 'react'

import InputTinyMce from 'src/admin/input-tinymce'

import type { InputTextField } from 'src/types/fields'

export interface AdminInputHtmlProps {
  field: InputTextField
  disabled: boolean
}

export default function AdminInputHtml({
  field,
  disabled,
}: AdminInputHtmlProps): JSX.Element {
  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={field.id}
      >
        {field.label}
      </label>

      <div className='col-sm-10'>
        <InputTinyMce disabled={disabled} field={field} />
      </div>
    </div>
  )
}
