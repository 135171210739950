import { connect } from 'react-redux'

import InputText, { AdminInputTextProps } from 'src/admin/common/input-text'

import type { ReduxState } from 'src/types/common'

type StateProps = Pick<AdminInputTextProps, 'field' | 'disabled'>

function mapStateToProps({
  fields: {
    adminOrder: { isFetching, trackCode },
  },
}: ReduxState): StateProps {
  return {
    disabled: isFetching,
    field: trackCode,
  }
}

export default connect(mapStateToProps)(InputText)
