import * as React from 'react'
import { useSelector } from 'react-redux'

import { getCountryNameFromList } from 'src/utils/order-info'

import countriesRu from 'src/const/intl/countries-ru'

import { getOrderShippingCountry } from 'src/redux/selectors/admin-order'

function InfoShippingCountry(): JSX.Element {
  const shippingCountry: string = useSelector(getOrderShippingCountry)

  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label alert-secondary'
        htmlFor='deliveryCountry'
      >
        Страна
      </label>

      <div className='col-sm-10 col-form-label bg-light'>
        <b>{shippingCountry}</b>
        &nbsp; ({getCountryNameFromList(shippingCountry, countriesRu)})
      </div>
    </div>
  )
}

export default React.memo(InfoShippingCountry)
