import * as React from 'react'

import SvgLoading from 'src/components/svg/loading'

import * as styles from 'src/styles/loading.module.css'

function LoadingPage(): JSX.Element {
  return (
    <div className={styles.container}>
      <div role='img' aria-label='Loading…' className={styles.icon}>
        <SvgLoading />
      </div>
    </div>
  )
}

export default React.memo(LoadingPage)
