import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { getLocale, getLangList } from 'src/redux/selectors/intl'

import LangListItem from './lang-list-item'

import * as styles from 'src/styles/header.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  locale: string
  langs: readonly string[]
}

function selector(state: ReduxState): StateProps {
  return {
    locale: getLocale(state),
    langs: getLangList(state),
  }
}

function LangList(): JSX.Element {
  const { langs, locale }: StateProps = useSelector(selector, shallowEqual)

  return (
    <div className={styles.langList}>
      {langs.map(function mapper(lang): JSX.Element {
        return (
          <LangListItem key={lang} slug={lang} selected={locale === lang} />
        )
      })}
    </div>
  )
}

export default React.memo(LangList)
