import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'

import { getLocale } from 'src/redux/selectors/intl'

import { fetchRequest, fetchSuccess, fetchFailure, callAction } from './fields'

import {
  reducerAddCartProduct,
  reducerRemoveCartProduct,
  reducerChangeCartProductCount,
} from 'src/redux/reducers/cart'

import type {
  Dispatch,
  FieldsAction,
  DebouncedFieldsAction,
} from 'src/types/common'

import type { ProductParametersMap } from 'src/types/user'

import type {
  ServerError,
  ApiResponseCart,
  ApiCartAddProduct,
  ApiCartChangeProductCount,
  ApiCartRemoveProduct,
} from 'src/types/api'

const RESOURCE_URL = `${BACKEND_URL}/api/basket`

const path = ['cart'] as const

export function fetchCart(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState())

    return fetch(`${RESOURCE_URL}?locale=${locale}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiResponseCart | ServerError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          return dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        }

        throw new Error(result.error)
      })
      .catch(function onrejected(err) {
        console.error('fetchCart error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function addCartProduct(
  productId: number,
  parameters?: Readonly<ProductParametersMap>
): FieldsAction {
  return function thunk(dispatch) {
    const data = new FormData()

    data.append('product', productId.toString())
    data.append('parameters', JSON.stringify(parameters))

    return fetch(RESOURCE_URL, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiCartAddProduct | ServerError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          return dispatch(
            callAction({
              ...result,
              action: getFunctionName({ reducerAddCartProduct }),
              path,
            })
          )
        }

        throw new Error(result.error)
      })
      .catch(function onrejected(err) {
        console.error('addCartProduct error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function changeCartProductCount(
  productId: number,
  parameters: Readonly<ProductParametersMap> | undefined,
  count: number
): DebouncedFieldsAction {
  const thunk = function thunk(dispatch: Dispatch): Promise<void> {
    const data = new FormData()

    data.append('product', productId.toString())
    data.append('count', count.toString())
    data.append('parameters', JSON.stringify(parameters))
    data.append('_method', 'PATCH')

    return fetch(`${RESOURCE_URL}/0`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiCartChangeProductCount | ServerError>(function onfulfilled(
        response
      ) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          if (result.force) {
            dispatch(
              callAction({
                ...result,
                action: getFunctionName({ reducerChangeCartProductCount }),
                path,
              })
            )
          }

          return
        }

        throw new Error(result.error)
      })
      .catch(function onrejected(err) {
        console.error('changeCartProductCount error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }

  thunk.meta = {
    debounce: {
      key: `cartProductCount-${productId}`,
      time: 3000,
      leading: false,
      trailing: true,
    },
  }

  return thunk
}

export function setCartProductCount(
  productId: number,
  parameters: Readonly<ProductParametersMap> | undefined,
  count: number
): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        count,
        parameters,
        product: productId,
        action: getFunctionName({ reducerChangeCartProductCount }),
        path,
      })
    )
  }
}

export function removeCartProduct(
  productId: number,
  parameters: Readonly<ProductParametersMap> | undefined
): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        product: productId,
        parameters,
        action: getFunctionName({ reducerRemoveCartProduct }),
        path,
      })
    )

    const data = new FormData()

    data.append('product', productId.toString())
    data.append('parameters', JSON.stringify(parameters))
    data.append('_method', 'DELETE')

    return fetch(`${RESOURCE_URL}/0`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiCartRemoveProduct | ServerError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          return dispatch(
            callAction({
              ...result,
              action: getFunctionName({ reducerRemoveCartProduct }),
              path,
            })
          )
        }

        throw new Error(result.error)
      })
      .catch(function onrejected(err) {
        console.error('removeCartProduct error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}
