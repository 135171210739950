import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'

import IntlLink from 'src/components/common/intl-link'

import * as styles from 'src/styles/header.module.css'

import type { NavigationLink } from 'src/types/navigation'

interface Props {
  item: Readonly<NavigationLink>
  className?: string
  onClick: React.MouseEventHandler
}

function NavPanelLink({ item, className, onClick }: Props): JSX.Element {
  return (
    <li className={classnames(styles.navLink, className)}>
      <IntlLink to={item.link} onClick={onClick}>
        <FormattedMessage id={item.name} />
      </IntlLink>
    </li>
  )
}

export default React.memo(NavPanelLink)
