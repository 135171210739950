import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getFieldPassword } from 'src/redux/selectors/auth'

import InputText from 'src/components/common/input-text'

interface Props {
  disabled?: boolean
  className: string
  inputClassName: string
}

function InputPassword({
  disabled,
  className,
  inputClassName,
}: Props): JSX.Element {
  const field = useSelector(getFieldPassword)

  return (
    <div className={className}>
      <label htmlFor={field.id}>
        <FormattedMessage id='password' defaultMessage='Password' />
      </label>

      <InputText className={inputClassName} disabled={disabled} field={field} />
    </div>
  )
}

export default React.memo(InputPassword)
