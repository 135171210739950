import * as React from 'react'

import SearchField from './search-field'
import ShopNavigation from './navigation'

import * as styles from 'src/styles/shop-header.module.css'

function ShopHeader(): JSX.Element {
  return (
    <div className={styles.container}>
      <SearchField />

      <ShopNavigation />
    </div>
  )
}

export default React.memo(ShopHeader)
