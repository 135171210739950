import { connect } from 'react-redux'

import { getFieldZip } from 'src/redux/selectors/delivery'

import { getShippingCost } from 'src/redux/actions/delivery'

import InputText, { Props } from './input-text'

import type { ReduxState } from 'src/types/common'

function mapStateToProps(state: ReduxState): Pick<Props, 'field'> {
  return {
    field: getFieldZip(state),
  }
}

const mapDispatchToProps: Pick<Props, 'onChange'> = {
  onChange: getShippingCost,
}

export default connect(mapStateToProps, mapDispatchToProps)(InputText)
