import * as React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

import history from 'src/const/history'

import { rangeMap } from 'src/utils/range'

import { getLastPage, getCurrentPage } from 'src/redux/selectors/admin-orders'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  current: number
  last: number
}

function selector(state: ReduxState): StateProps {
  return {
    current: getCurrentPage(state),
    last: getLastPage(state),
  }
}

function AdminOrdersPagination(): JSX.Element {
  const { current, last }: StateProps = useSelector(selector, shallowEqual)

  const onChange = React.useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >(
    function callback(event) {
      const value = Number(event.target.value) || 1

      if (value !== current) {
        history.replace(`/admin/orders/?page=${value}`)
      }
    },
    [current]
  )

  return (
    <nav aria-label='Orders pagination'>
      <ul className='pagination input-group flex-wrap justify-content-center'>
        {current > 1 ? (
          <li className='page-item'>
            <Link
              className='page-link'
              to={`/admin/orders/?page=${current - 1}`}
            >
              &laquo;
            </Link>
          </li>
        ) : (
          <li className='page-item disabled'>
            <span className='page-link'>&laquo;</span>
          </li>
        )}

        <select
          className='form-control flex-grow-0 flex-shrink-0 w-auto'
          value={current}
          onChange={onChange}
          onBlur={onChange}
        >
          {rangeMap(last, function mapper(index) {
            index += 1

            return (
              <option key={index} value={index}>
                {index}
              </option>
            )
          })}
        </select>

        {current < last ? (
          <li className='page-item'>
            <Link
              className='page-link'
              to={`/admin/orders/?page=${current + 1}`}
            >
              &raquo;
            </Link>
          </li>
        ) : (
          <li className='page-item disabled'>
            <span className='page-link'>&raquo;</span>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default React.memo(AdminOrdersPagination)
