import { createAction } from 'redux-actions'

import history from 'src/const/history'
import { BACKEND_URL } from 'src/config'

import { getLaravelErrorMessage } from 'src/utils/laravel'

import { AUTH_LOGIN, AUTH_LOGOUT } from 'src/redux/action-types'

import {
  getFieldZip,
  getFieldCity,
  getFieldPhone,
  getFieldAddress,
  getFieldCountry,
  getFieldLastName,
  getFieldFirstName,
} from 'src/redux/selectors/personal-fields'

import {
  getFieldEmail,
  getFieldPassword,
  getFieldPrivacyPolicy,
  getFieldPasswordConfirm,
  getFieldIsDone,
} from 'src/redux/selectors/auth'

import { getLocale } from 'src/redux/selectors/intl'

import {
  fetchRequest,
  fetchFailure,
  fetchComplete,
  changeCheckbox,
} from './fields'

import type { FieldsAction } from 'src/types/common'
import type { ApiAuth, LaravelResponse, ServerError } from 'src/types/api'

const authLogin = createAction<ApiAuth>(AUTH_LOGIN)
const authLogout = createAction<void>(AUTH_LOGOUT)

const path = ['auth'] as const

export function fetchUser(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(`${BACKEND_URL}/api/user`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAuth | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(authLogin(result))
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchUser error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function updateUserPersonalDetails(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()

    const isDone = getFieldIsDone(state)

    dispatch(changeCheckbox({ path: isDone.path, checked: false }))

    const fields = {
      zip: getFieldZip(state).value,
      city: getFieldCity(state).value,
      phone: getFieldPhone(state).value,
      country: getFieldCountry(state).value,
      address: getFieldAddress(state).value,
      last_name: getFieldLastName(state).value,
      first_name: getFieldFirstName(state).value,
    }

    const data = new FormData()

    for (const [key, value] of Object.entries(fields)) {
      if (value.length > 0) {
        data.append(key, value)
      }
    }

    data.append('_method', 'PATCH')

    return fetch(`${BACKEND_URL}/api/user/0`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiAuth | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(authLogin(result))

          dispatch(changeCheckbox({ path: isDone.path, checked: true }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('updateUserPersonalDetails error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function loginUser(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const email = getFieldEmail(state).value
    const password = getFieldPassword(state).value

    return fetch(`${BACKEND_URL}/api/login`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, remember: 1 }),
    })
      .then<ApiAuth | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(authLogin(result))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('loginUser error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function logoutUser(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(`${BACKEND_URL}/api/logout`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        dispatch(authLogout(result))

        return
      })
      .catch(function onrejected(err) {
        console.error('logoutUser error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function restoreUser(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const email = getFieldEmail(state).value

    const data = new FormData()

    data.append('email', email)

    return fetch(`${BACKEND_URL}/api/restore`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          // TODO: redirect to message page
          /* dispatch({
            type: REGISTER_USER
          }) */

          dispatch(fetchComplete({ path }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('restoreUser error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function resetUserPassword(token: string): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const email = getFieldEmail(state).value
    const password = getFieldPassword(state).value
    const passwordConfirm = getFieldPasswordConfirm(state).value
    const isDone = getFieldIsDone(state)

    const data = new FormData()

    data.append('email', email)
    data.append('password', password)
    data.append('password_confirmation', passwordConfirm)
    data.append('token', token)

    return fetch(`${BACKEND_URL}/api/reset`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ServerError | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(changeCheckbox({ path: isDone.path, checked: true }))

          history.replace('/')
        } else if ('error' in result) {
          dispatch(fetchFailure({ path, error: result.error }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('resetUserPassword error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function registerUser(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const isDone = getFieldIsDone(state)
    const locale = getLocale(state)
    const email = getFieldEmail(state).value
    const password = getFieldPassword(state).value
    const passwordConfirm = getFieldPasswordConfirm(state).value
    const privacyPolicy = getFieldPrivacyPolicy(state).checked

    return fetch(`${BACKEND_URL}/api/registration`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        locale,
        email,
        password,
        password_confirmation: passwordConfirm,
        privacy_policy: privacyPolicy ? 1 : 0,
      }),
    })
      .then<LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(changeCheckbox({ path: isDone.path, checked: true }))

          dispatch(fetchComplete({ path }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('registerUser error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function activateUser(params: string): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(`${BACKEND_URL}/api/verify${params}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAuth | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(authLogin(result))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('activateUser error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function clearAuthError(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchComplete({ path }))
  }
}
