import { connect } from 'react-redux'

import { getOrderLocale } from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

const mapStateToProps = (state: ReduxState): InfoFieldProps => ({
  id: 'locale',
  name: 'Язык',
  value: getOrderLocale(state),
})

const InfoLocale = connect(mapStateToProps)(InfoField)

export default InfoLocale
