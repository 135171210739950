import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getIsFetching,
  getFieldTrackCode,
} from 'src/redux/selectors/admin-order'

import { notifyOrderTrackCode } from 'src/redux/actions/admin-order'

import type { ReduxState } from 'src/types/common'

function selector(state: ReduxState): boolean {
  return getIsFetching(state) || getFieldTrackCode(state).value === ''
}

function ButtonTrackCodeNotify(): JSX.Element {
  const dispatch = useDispatch()

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      dispatch(notifyOrderTrackCode())
    },
    [dispatch]
  )

  const disabled: boolean = useSelector(selector)

  return (
    <div className='col-sm-10'>
      <button
        title={
          'Отправить покупателю письмо с трек-кодом посылки.\n(Перед отправкой рекомендуется нажать Применить)'
        }
        className='btn btn-info'
        disabled={disabled}
        onClick={onClick}
        type='button'
      >
        Уведомить
      </button>
    </div>
  )
}

export default React.memo(ButtonTrackCodeNotify)
