import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { BACKEND_URL } from 'src/config'

import { getProduct, getProductCurrentImage } from 'src/redux/selectors/shop'

import LazyImage from 'src/components/lazyimage'

import ProductImage from './image'

import * as styles from 'src/styles/product.module.css'

import type { ReduxState } from 'src/types/common'
import type { ShopProduct } from 'src/types/user'

interface StateProps {
  product: Readonly<ShopProduct>
  currentImage: string
}

function selector(state: ReduxState): StateProps {
  return {
    currentImage: getProductCurrentImage(state),
    product: getProduct(state),
  }
}

function ProductImages(): JSX.Element {
  const { product, currentImage }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  const imageSrc = React.useMemo<string>(
    function memo() {
      return `${BACKEND_URL}/img/products/thumb/${currentImage}`
    },
    [currentImage]
  )

  return (
    <div className={styles.image}>
      <LazyImage src={imageSrc} alt={product.name} />

      {product.images.length > 1 && (
        <div className={styles.imageList}>
          {product.images.map(function mapper(image, index): JSX.Element {
            return (
              <ProductImage
                key={image}
                index={index}
                image={image}
                name={product.name}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default React.memo(ProductImages)
