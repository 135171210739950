import { combineReducers } from 'redux'

import intl, { IntlState } from './intl'
import fields, { FieldsStateType } from './fields'

export interface AppState {
  intl: IntlState
  fields: FieldsStateType
}

export default combineReducers<AppState>({
  intl,
  fields,
})
