import * as React from 'react'

function SvgSocialVk(): JSX.Element {
  return (
    <svg viewBox='0 0 64 64'>
      <path d='M60.5,0H3.5C1.6,0,0,1.6,0,3.5v56.9c0,2,1.6,3.5,3.5,3.5h56.9c2,0,3.5-1.6,3.5-3.5V3.5C64,1.6,62.4,0,60.5,0z M51.6,44.2l-5.7,0.1c0,0-1.2,0.2-2.9-0.9c-2.1-1.5-4.2-5.3-5.8-4.8c-1.6,0.5-1.6,4-1.6,4s0,0.7-0.4,1.1c-0.4,0.4-1.2,0.5-1.2,0.5 h-2.6c0,0-5.7,0.3-10.7-4.9c-5.5-5.7-10.3-16.9-10.3-16.9s-0.3-0.7,0-1.1c0.3-0.4,1.3-0.4,1.3-0.4l6.1,0c0,0,0.6,0.1,1,0.4 c0.3,0.3,0.5,0.7,0.5,0.7s1,2.5,2.3,4.8c2.6,4.4,3.8,5.4,4.6,4.9c1.3-0.7,0.9-6.3,0.9-6.3s0-2-0.6-2.9c-0.5-0.7-1.5-0.9-1.9-1 c-0.3,0,0.2-0.8,1-1.2c1.1-0.5,3.1-0.6,5.4-0.5c1.8,0,2.3,0.1,3,0.3c2.1,0.5,1.4,2.5,1.4,7.2c0,1.5-0.3,3.7,0.8,4.4 c0.5,0.3,1.6,0,4.5-4.8c1.4-2.3,2.4-5,2.4-5s0.2-0.5,0.6-0.7c0.4-0.2,0.8-0.1,0.8-0.1l6.5,0c0,0,1.9-0.2,2.3,0.6 c0.3,0.9-0.7,3.1-3.4,6.6c-4.3,5.8-4.8,5.2-1.2,8.6c3.4,3.2,4.2,4.8,4.3,4.9C54.6,44,51.6,44.2,51.6,44.2z' />
    </svg>
  )
}

export default React.memo(SvgSocialVk)
