import * as React from 'react'
import { Link } from 'react-router-dom'

function AdminView(): JSX.Element {
  return (
    <div>
      <div className='jumbotron'>
        <div className='container'>
          <h1 className='display-3'>Добро пожаловать!</h1>
          <hr />
          <p className='lead'>
            Чуть ниже описаны текущие возможности администраторской панели.
          </p>
        </div>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <h2>Товары</h2>
            <p>Товары это то что люди продают и покупают.</p>
            <p>
              Вы можете создать электронную сущность товара, чтобы люди смогли
              его найти и узнали о свойствах реального объекта. Что,
              впоследствии, может породить желание приобрести его.
            </p>

            <Link className='btn btn-primary mb-3' to='/admin/products/'>
              Перейти »
            </Link>
          </div>

          <div className='col-md-4'>
            <h2>Проекты</h2>
            <p>
              С помощью проектов товары связываются между собой общей тематикой
              (например по играм).
            </p>
            <p>Проекты можно создавать и редактировать!</p>

            <Link className='btn btn-primary mb-3' to='/admin/projects/'>
              Перейти »
            </Link>
          </div>

          <div className='col-md-4'>
            <h2>Категории</h2>
            <p>
              Категории связывают товары в общие группы по их типу (например:
              майки, кружки и т.д.)
            </p>
            <p>Категории можно создавать и редактировать!</p>

            <Link className='btn btn-primary mb-3' to='/admin/categories/'>
              Перейти »
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AdminView)
