import { connect } from 'react-redux'

import { orderStatus } from 'src/const/order-status'

import InputSelect, {
  AdminInputSelectProps,
} from 'src/admin/common/input-select'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminOrder: { isFetching, status },
  },
}: ReduxState): AdminInputSelectProps {
  return {
    disabled: isFetching,
    options: orderStatus,
    field: status,
  }
}

export default connect(mapStateToProps)(InputSelect)
