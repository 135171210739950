import history from 'src/const/history'
import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'
import { getLaravelErrorMessage } from 'src/utils/laravel'

import { reducerAdminClearCategoryFields } from 'src/redux/reducers/admin-category'

import { getLocale } from 'src/redux/selectors/admin-locale'

import {
  getFieldName,
  getFieldLink,
  getCategoryId,
  getFieldVisible,
} from 'src/redux/selectors/admin-category-edit'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchComplete,
  callAction,
} from './fields'

import type { FieldsAction } from 'src/types/common'

import type {
  ServerSuccess,
  LaravelResponse,
  ApiAdminCategory,
  ApiAdminCategories,
} from 'src/types/api'

const RESOURCE_URL = `${BACKEND_URL}/api/admin/categories`

const path = ['adminCategory'] as const

export function saveCategory(): FieldsAction {
  return function thunk(dispatch, getState) {
    const categoryId = getCategoryId(getState())

    const action = categoryId !== 0 ? updateCategory() : createCategory()

    dispatch(action)
  }
}

function createCategory(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const locale = getLocale(state).value
    const name = getFieldName(state).value
    const link = getFieldLink(state).value
    const visible = getFieldVisible(state).checked

    const data = new FormData()

    data.append('locale', locale)
    data.append('name', name)
    data.append('link', link)

    if (visible) {
      data.append('visible', '1')
    }

    return fetch(RESOURCE_URL, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiAdminCategory | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )

          history.push(`/admin/categories/${result.category.id}/edit`)
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('createCategory error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

function updateCategory(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const id = getCategoryId(state)
    const locale = getLocale(state).value
    const name = getFieldName(state).value
    const link = getFieldLink(state).value
    const visible = getFieldVisible(state).checked

    const data = new FormData()

    data.append('id', id.toString())
    data.append('locale', locale)
    data.append('name', name)
    data.append('link', link)

    if (visible) {
      data.append('visible', '1')
    }

    data.append('_method', 'PATCH')

    return fetch(`${RESOURCE_URL}/${id}`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ServerSuccess | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(fetchComplete({ path }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('updateCategory error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchCategory(categoryId: string): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState()).value

    fetch(`${RESOURCE_URL}/${categoryId}/edit?locale=${locale}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAdminCategory | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchCategory error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function clearAdminCategoryFields(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        action: getFunctionName({ reducerAdminClearCategoryFields }),
        path,
      })
    )
  }
}

const pathAdminCategories = ['adminCategories'] as const

export function fetchCategoriesList(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path: pathAdminCategories }))

    return fetch(RESOURCE_URL, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAdminCategories | LaravelResponse>(function onfulfilled(
        response
      ) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path: pathAdminCategories,
            })
          )
        } else {
          dispatch(
            fetchFailure({
              path: pathAdminCategories,
              error: getLaravelErrorMessage(result),
            })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchCategoriesList error:', err)

        dispatch(
          fetchFailure({
            path: pathAdminCategories,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}
