import { connect } from 'react-redux'

import {
  getProjectsOptions,
  getIsFetching as getIsFetchingProjects,
} from 'src/redux/selectors/admin-projects'

import {
  getIsFetching,
  getFieldProject,
} from 'src/redux/selectors/admin-product-edit'

import InputSelect, {
  AdminInputSelectProps,
} from 'src/admin/common/input-select'

import type { ReduxState } from 'src/types/common'

function mapStateToProps(state: ReduxState): AdminInputSelectProps {
  return {
    disabled: getIsFetching(state) || getIsFetchingProjects(state),
    options: getProjectsOptions(state),
    field: getFieldProject(state),
  }
}

export default connect(mapStateToProps)(InputSelect)
