import * as React from 'react'

function SvgMail(): JSX.Element {
  return (
    <svg viewBox='0 0 64 64'>
      <path d='M60.5 0H3.6C1.6 0 .1 1.6.1 3.5v56.9c0 1.9 1.6 3.5 3.5 3.5h56.9c1.9 0 3.5-1.6 3.5-3.5V3.5C64 1.6 62.4 0 60.5 0zm-48 15.7h39.1c.9 0 1.7.3 2.2.9L32 35.2 10.2 16.6c.6-.6 1.4-.9 2.3-.9zm-3.3 2.8L25 32.2 9.2 45.6V18.5zm42.4 29.8H12.5c-.9 0-1.7-.3-2.2-.9l16.1-13.8 5.7 5 5.6-5 16.1 13.8c-.6.5-1.4.9-2.2.9zm3.2-2.7L39.1 32.2l15.8-13.7-.1 27.1z' />
    </svg>
  )
}

export default React.memo(SvgMail)
