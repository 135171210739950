import { connect } from 'react-redux'

import { getOrderLastName } from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

const mapStateToProps = (state: ReduxState): InfoFieldProps => ({
  id: 'lastName',
  name: 'Фамилия',
  value: getOrderLastName(state),
})

const InfoLastName = connect(mapStateToProps)(InfoField)

export default InfoLastName
