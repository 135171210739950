import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { getIsFetching } from 'src/redux/selectors/order'
import { getCountryByValue } from 'src/redux/selectors/intl'

import OrderHistoryProduct from './product'
import ButtonOrderRepeat from './button-order-repeat'

import * as styles from 'src/styles/order-history.module.css'

import type { ReduxState } from 'src/types/common'
import type { ComboboxOption } from 'src/types/fields'
import type { Order } from 'src/types/user'

function getPriceWithCurrency(locale: string, ...prices: number[]): string {
  const price = prices.reduce(function reducer(sum, str) {
    return sum + parseFloat(str.toString())
  }, 0)

  return locale === 'ru' ? `${price} ₽` : `$${price}`
}

interface StateProps {
  isFetching: boolean
  country: Readonly<ComboboxOption> | undefined
}

interface OwnProp {
  order: Readonly<Order>
}

function OrderHistoryListItemInfo({ order }: OwnProp): JSX.Element {
  const deliveryCost = React.useMemo(
    function setDeliveryCost() {
      return getPriceWithCurrency(order.locale, order.delivery_cost)
    },
    [order]
  )

  const productsCost = React.useMemo(
    function setProductsCost() {
      return getPriceWithCurrency(order.locale, order.products_cost)
    },
    [order]
  )

  const totalCost = React.useMemo(
    function setTotalCost() {
      return getPriceWithCurrency(
        order.locale,
        order.products_cost,
        order.delivery_cost
      )
    },
    [order]
  )

  const { isFetching, country } = useSelector(function selector(
    state: ReduxState
  ): StateProps {
    return {
      isFetching: getIsFetching(state),
      country: getCountryByValue(state, order.delivery_country),
    }
  },
  shallowEqual)

  return (
    <section>
      <div>
        {order.products.map(function mapper(product): JSX.Element {
          return <OrderHistoryProduct key={product.id} product={product} />
        })}
      </div>

      <div className={styles.infoSummary}>
        <div className={styles.infoAddress}>
          <FormattedMessage id='shipping-cost' defaultMessage='Shipping cost' />{' '}
          {country !== undefined ? country.name : order.delivery_country},{' '}
          {order.delivery_city}, {order.delivery_address}, {order.delivery_zip}
        </div>

        <div className={styles.infoCost}>
          <div>
            <FormattedMessage
              id='shipping-cost'
              defaultMessage='Shipping cost'
            />{' '}
            {deliveryCost}
          </div>

          <div className={styles.infoCostProducts}>
            <FormattedMessage
              id='products-cost'
              defaultMessage='Products cost'
            />{' '}
            {productsCost}
          </div>
        </div>
      </div>

      <div className={styles.infoTotal}>
        <ButtonOrderRepeat
          className={styles.infoTotalButton}
          disabled={isFetching}
          orderId={order.id}
        />

        <div className={styles.infoTotalCost}>
          <FormattedMessage id='total-cost' defaultMessage='Total cost' />{' '}
          {totalCost}
        </div>
      </div>
    </section>
  )
}

export default React.memo(OrderHistoryListItemInfo)
