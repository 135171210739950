import type { AdminOrdersListFieldsState } from 'src/redux/reducers/admin-orders'

import type { ReduxState } from 'src/types/common'
import type { OrderShort } from 'src/types/admin'

function getState(state: ReduxState): AdminOrdersListFieldsState {
  return state.fields.adminOrders
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getOrders(state: ReduxState): readonly Readonly<OrderShort>[] {
  return getState(state).list
}

export function getOrdersTotal(state: ReduxState): number {
  return getState(state).total
}

export function getProductCount(state: ReduxState): number {
  return getState(state).productCount
}

export function getCurrentPage(state: ReduxState): number {
  return getState(state).currentPage
}

export function getLastPage(state: ReduxState): number {
  return getState(state).lastPage
}
