import type { PersonalFieldsState } from 'src/redux/reducers/personal'

import type { ReduxState } from 'src/types/common'
import type { InputTextField, ComboboxField } from 'src/types/fields'

function getState(state: ReduxState): PersonalFieldsState {
  return state.fields.personal
}

export function getFieldFirstName(state: ReduxState): InputTextField {
  return getState(state).firstName
}

export function getFieldLastName(state: ReduxState): InputTextField {
  return getState(state).lastName
}

export function getFieldPhone(state: ReduxState): InputTextField {
  return getState(state).phone
}

export function getFieldCountry(state: ReduxState): ComboboxField {
  return getState(state).country
}

export function getFieldCity(state: ReduxState): InputTextField {
  return getState(state).city
}

export function getFieldAddress(state: ReduxState): InputTextField {
  return getState(state).address
}

export function getFieldZip(state: ReduxState): InputTextField {
  return getState(state).zip
}
