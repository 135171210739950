import { connect } from 'react-redux'

import { getPriceWithCurrency } from 'src/utils/currency'

import {
  getOrderLocale,
  getOrderProductsCost,
} from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

function mapStateToProps(state: ReduxState): InfoFieldProps {
  const locale: string = getOrderLocale(state)
  const productsCost: number = getOrderProductsCost(state)

  return {
    id: 'productsCost',
    name: 'Стоимость товаров',
    value: productsCost
      ? getPriceWithCurrency(locale, productsCost.toString())
      : '—',
  }
}

const InfoProductsCost = connect(mapStateToProps)(InfoField)

export default InfoProductsCost
