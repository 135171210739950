import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { getProduct } from 'src/redux/selectors/shop'

import { addCartProduct } from 'src/redux/actions/cart'

import SvgCart from 'src/components/svg/cart'

import * as styles from 'src/styles/product.module.css'

import type { ShopProduct } from 'src/types/user'

function ButtonAddToCart(): JSX.Element {
  const dispatch = useDispatch()

  const product: Readonly<ShopProduct> = useSelector(getProduct)

  const onClick = React.useCallback(
    function callback() {
      const params = product.currentParameters
        .slice()
        .sort(function sort(a, b) {
          return a[0] - b[0]
        })

      dispatch(addCartProduct(product.id, params))
    },
    [product, dispatch]
  )

  return (
    <button
      className={styles.buttonCart}
      disabled={!product.inStock}
      onClick={onClick}
      type='button'
    >
      <div role='img' aria-hidden className={styles.buttonCartIcon}>
        <SvgCart />
      </div>

      <FormattedMessage id='add-to-cart' defaultMessage='Add to cart' />
    </button>
  )
}

export default React.memo(ButtonAddToCart)
