import history from 'src/const/history'

import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'
import { getLaravelErrorMessage } from 'src/utils/laravel'

import { reducerSetCartUnavailable } from 'src/redux/reducers/cart'
import { reducerFetchOrderStatus } from 'src/redux/reducers/order'

import { getLocale } from 'src/redux/selectors/intl'
import { getPaymentCurrentMethod } from 'src/redux/selectors/order'
import { getDeliverySelectedMethod } from 'src/redux/selectors/delivery'

import {
  getFieldZip,
  getFieldCity,
  getFieldEmail,
  getFieldPhone,
  getFieldCountry,
  getFieldAddress,
  getFieldLastName,
  getFieldFirstName,
} from 'src/redux/selectors/delivery'

import { getFieldComment } from 'src/redux/selectors/order'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchComplete,
  callAction,
} from 'src/redux/actions/fields'

import type { FieldsAction } from 'src/types/common'

import type {
  ApiOrderMake,
  ApiOrderMakeError,
  ServerSuccess,
  ServerError,
} from 'src/types/api'

const path = ['order'] as const

export function makeOrder(form: HTMLFormElement): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()

    const locale = getLocale(state)
    const zip = getFieldZip(state).value
    const city = getFieldCity(state).value
    const email = getFieldEmail(state).value
    const phone = getFieldPhone(state).value
    const country = getFieldCountry(state).value
    const address = getFieldAddress(state).value
    const comment = getFieldComment(state).value
    const lastName = getFieldLastName(state).value
    const firstName = getFieldFirstName(state).value
    const deliveryMethod = getDeliverySelectedMethod(state)
    const paymentMethod = getPaymentCurrentMethod(state)

    const data = new FormData()

    data.append('locale', locale)
    data.append('zip', zip)
    data.append('city', city)
    data.append('email', email)
    data.append('phone', phone)
    data.append('country', country)
    data.append('address', address)
    data.append('lastName', lastName)
    data.append('firstName', firstName)
    data.append('deliveryMethod', deliveryMethod)
    data.append('paymentMethod', paymentMethod)

    if (comment.length > 0) {
      data.append('comment', comment)
    }

    return fetch(`${BACKEND_URL}/api/order`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiOrderMake | ApiOrderMakeError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )

          window.setTimeout(function timer() {
            form.submit()
          }, 0)
        } else if (result.unavailable) {
          dispatch(
            callAction({
              unavailable: result.unavailable,
              action: getFunctionName({ reducerSetCartUnavailable }),
              path: ['cart'],
            })
          )
        } else if (result.intlError) {
          dispatch(
            fetchFailure({ path, error: '', intlError: result.intlError })
          )
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('makeOrder error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function repeatOrder(orderId: number): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    const data = new FormData()

    data.append('order', orderId.toString())

    return fetch(`${BACKEND_URL}/api/order/repeat`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ServerSuccess>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(fetchComplete({ path }))

          history.push('/cart/')
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('repeatOrder error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchPaypalTransactionStatus(params: string): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(`${BACKEND_URL}/api/paypal/status`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    })
      .then<ServerSuccess | ServerError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        dispatch(
          callAction({
            ...result,
            action: getFunctionName({ reducerFetchOrderStatus }),
            path,
          })
        )

        return
      })
      .catch(function onfulfilled(err) {
        console.error('fetchPaypalTransactionStatus error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function cancelPaypalOrder(params: string): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(`${BACKEND_URL}/api/paypal/cancel`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    })
      .then<ServerSuccess | ServerError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        dispatch(
          callAction({
            ...result,
            action: getFunctionName({ reducerFetchOrderStatus }),
            path,
          })
        )

        return
      })
      .catch(function onrejected(err) {
        console.error('cancelPaypalOrder error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchRobokassaInvoiceStatus(params: string): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(`${BACKEND_URL}/api/robokassa/status`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    })
      .then<ServerSuccess | ServerError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        dispatch(
          callAction({
            ...result,
            action: getFunctionName({ reducerFetchOrderStatus }),
            path,
          })
        )

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchRobokassaInvoiceStatus error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function cancelRobokassaOrder(params: string): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path }))

    return fetch(`${BACKEND_URL}/api/robokassa/cancel`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    })
      .then<ServerSuccess | ServerError>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        dispatch(
          callAction({
            ...result,
            action: getFunctionName({ reducerFetchOrderStatus }),
            path,
          })
        )

        return
      })
      .catch(function onrejected(err) {
        console.error('cancelRobokassaOrder error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function clearOrderError(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchComplete({ path }))
  }
}
