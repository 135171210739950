import { BACKEND_URL } from 'src/config'

import { getLaravelErrorMessage } from './laravel'

import type { LaravelResponse } from 'src/types/api'

export const FILE_MANAGER_URL = `${BACKEND_URL}/api/admin/filemanager`

export interface FileManagerListResult {
  success: true
  list: string[]
}

export function resultHandler(
  result: FileManagerListResult | LaravelResponse
): string[] {
  if (result.success) {
    return result.list
  }

  throw getLaravelErrorMessage(result)
}

export interface FileManagerUploadResult {
  success: true
  file: string
}

export function uploadHandler(
  result: FileManagerUploadResult | LaravelResponse
): string {
  if (result.success) {
    return result.file
  }

  throw getLaravelErrorMessage(result)
}
