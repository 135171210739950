import type { InputTextField } from 'src/types/fields'

export interface FeedbackFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Fields
  name: InputTextField
  email: InputTextField
  message: InputTextField
}

export const initialFields: FeedbackFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Fields
  name: {
    path: ['feedback', 'name'],
    id: 'name',
    required: true,
    value: '',
  },
  email: {
    path: ['feedback', 'email'],
    id: 'email',
    type: 'email',
    required: true,
    value: '',
  },
  message: {
    path: ['feedback', 'message'],
    id: 'message',
    required: true,
    value: '',
  },
}

export function fetchSuccess(draft: FeedbackFieldsState): void {
  Object.assign(draft, initialFields)
}
