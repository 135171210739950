import * as React from 'react'

interface Props {
  disabled: boolean
  onClose: React.MouseEventHandler
}

function Header({ disabled, onClose }: Props): JSX.Element {
  return (
    <header className='modal-header'>
      <h5 className='modal-title'>Файловый менеджер</h5>

      <button
        className='close'
        data-dismiss='modal'
        aria-label='Close'
        disabled={disabled}
        onClick={onClose}
        type='button'
      >
        <span aria-hidden='true'>×</span>
      </button>
    </header>
  )
}

export default React.memo(Header)
