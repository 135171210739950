import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getFieldSearch,
  getProductsCount,
} from 'src/redux/selectors/shop'

import { changeInput } from 'src/redux/actions/fields'

import SvgSearch from 'src/components/svg/search'

import * as styles from 'src/styles/shop-header.module.css'

import type { ReduxState } from 'src/types/common'
import type { InputTextField, FieldLoading } from 'src/types/fields'

interface StateProps {
  count: number
  field: Readonly<InputTextField & FieldLoading>
  isFetching: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    field: getFieldSearch(state),
    count: getProductsCount(state),
    isFetching: getIsFetching(state),
  }
}

function SearchField(): JSX.Element {
  const intl = useIntl()

  const placeholder = React.useMemo<string>(
    function memo() {
      return intl.formatMessage({ id: 'search.placeholder' })
    },
    [intl]
  )

  const history = useHistory()
  const dispatch = useDispatch()

  const { field, count, isFetching } = useSelector(selector, shallowEqual)

  const onChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    function callback({ target: { value } }) {
      dispatch(changeInput({ path: field.path, value }))
    },
    [field.path, dispatch]
  )

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      if (field.value.length > 0) {
        history.push(`/${intl.locale}/search/${field.value}`)
      }
    },
    [field.value, intl, history]
  )

  const countValues = React.useMemo(
    function memo() {
      return { count }
    },
    [count]
  )

  return (
    <form className={styles.search} onSubmit={onSubmit}>
      {field.isFetching && !isFetching && (
        <span role='status' className={styles.searchLabel}>
          <FormattedMessage
            id='found-count'
            defaultMessage='Count of founded products'
            values={countValues}
          />
        </span>
      )}

      <input
        id='search-input'
        type='search'
        value={field.value}
        className={styles.searchInput}
        placeholder={placeholder}
        onChange={onChange}
      />

      <button
        aria-label='Search'
        className={styles.searchSubmit}
        disabled={isFetching}
        type='submit'
      >
        <SvgSearch />
      </button>
    </form>
  )
}

export default React.memo(SearchField)
