import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import {
  getLocale,
  getLocaleDataBySlug,
} from 'src/redux/selectors/admin-locale'

import { changeInput } from 'src/redux/actions/fields'

import type { AdminLocaleMenuData } from 'src/redux/reducers/admin-locale'
import type { ReduxState } from 'src/types/common'
import type { TextField } from 'src/types/fields'

interface StateProps {
  field: Readonly<TextField>
  locale: Readonly<AdminLocaleMenuData>
}

interface OwnProps {
  slug: string
  selected: boolean
  disabled: boolean
  onChange?(locale: string): void
}

function Locale({ slug, selected, disabled, onChange }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const { field, locale } = useSelector(function selector(
    state: ReduxState
  ): StateProps {
    return {
      field: getLocale(state),
      locale: getLocaleDataBySlug(state, slug),
    }
  },
  shallowEqual)

  const onButtonClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      dispatch(changeInput({ path: field.path, value: slug }))

      if (typeof onChange === 'function') {
        onChange(slug)
      }
    },
    [field.path, slug, onChange, dispatch]
  )

  return (
    <button
      className={classnames('btn btn-outline-primary', {
        active: selected,
      })}
      disabled={disabled}
      onClick={onButtonClick}
      type='button'
    >
      {locale.name}
    </button>
  )
}

export default React.memo(Locale)
