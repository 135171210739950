import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getFieldPasswordConfirm } from 'src/redux/selectors/auth'

import InputText from 'src/components/common/input-text'

interface Props {
  disabled: boolean
  className: string
  inputClassName: string
}

function InputPasswordConfirm({
  disabled,
  className,
  inputClassName,
}: Props): JSX.Element {
  const field = useSelector(getFieldPasswordConfirm)

  return (
    <div className={className}>
      <label htmlFor={field.id}>
        <FormattedMessage
          id='password-confirm'
          defaultMessage='Password confirm'
        />
      </label>

      <InputText className={inputClassName} disabled={disabled} field={field} />
    </div>
  )
}

export default React.memo(InputPasswordConfirm)
