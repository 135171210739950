import * as React from 'react'
import { Link } from 'react-router-dom'

import { CategoryStatus } from 'src/const/category-status'

import type { Category } from 'src/types/admin'

interface Props {
  category: Readonly<Category>
}

function AdminCategory({ category }: Props): JSX.Element {
  return (
    <tr>
      <td>
        {category.status & CategoryStatus.Visible ? '' : '❌ '}

        <Link to={`/admin/categories/${category.id}/edit`}>
          {category.name}
        </Link>
      </td>

      <td>{category.link}</td>
    </tr>
  )
}

export default React.memo(AdminCategory)
