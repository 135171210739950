// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getIn<T = any>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  path: readonly string[]
): T {
  for (const key of path) {
    if (obj !== undefined) {
      obj = obj[key]
    } else {
      break
    }
  }

  return obj as T
}

function isObject<T>(obj: T): boolean {
  return obj && typeof obj === 'object' && !Array.isArray(obj)
}

export function mergeDeep<T, S>(target: T, source: S): T & S {
  if (isObject(target) && isObject(source)) {
    for (const [key, value] of Object.entries(source)) {
      if (isObject(value) && key in target) {
        target[key as keyof T] = mergeDeep(target[key as keyof T], value)
      } else {
        target[key as keyof T] = value
      }
    }
  }

  return target as T & S
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty(obj: Record<string, any>): boolean {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

export function getFunctionName<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, (...args: any[]) => any>
>(container: Readonly<T>): keyof T {
  for (const name in container) {
    if (Object.prototype.hasOwnProperty.call(container, name)) {
      return name
    }
  }

  return ''
}
