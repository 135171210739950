import { connect } from 'react-redux'

import { getOrderDate } from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

const mapStateToProps = (state: ReduxState): InfoFieldProps => ({
  id: 'date',
  name: 'Дата',
  value: getOrderDate(state),
})

const InfoDate = connect(mapStateToProps)(InfoField)

export default InfoDate
