import * as React from 'react'
import { useDispatch } from 'react-redux'

import { changeProductParameter } from 'src/redux/actions/shop'

import type { ProductParameter } from 'src/types/user'

interface OwnProps {
  value: number
  parameter: Readonly<ProductParameter>
}

function SelectParameter({ parameter, value }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onParameterSelect = React.useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >(
    function callback({ target: { value } }) {
      dispatch(changeProductParameter(parameter, parameter.id, Number(value)))
    },
    [parameter, dispatch]
  )

  return (
    <div>
      <b>{parameter.name}</b>:{' '}
      <select
        value={value}
        onChange={onParameterSelect}
        onBlur={onParameterSelect}
      >
        {parameter.values.map(function mapper({ id, name }): JSX.Element {
          return (
            <option value={id} key={id}>
              {name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default React.memo(SelectParameter)
