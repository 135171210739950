import * as React from 'react'

interface Props {
  error: string
}

function ErrorMessage({ error }: Props): JSX.Element {
  if (error === '') {
    return <></>
  }

  return (
    <div className='modal-header'>
      <div className='alert alert-danger' role='alert'>
        {error}
      </div>
    </div>
  )
}

export default React.memo(ErrorMessage)
