import { createSelector } from 'reselect'

import type { Shipping } from 'src/const/shipping'

import type { AdminOrderFieldsState } from 'src/redux/reducers/admin-order'

import type { ReduxState } from 'src/types/common'
import type { OrderProduct } from 'src/types/admin'
import type { InputTextField, ComboboxField } from 'src/types/fields'

function getState(state: ReduxState): AdminOrderFieldsState {
  return state.fields.adminOrder
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getOrderId(state: ReduxState): number {
  return getState(state).id
}

export function getOrderDate(state: ReduxState): string {
  return getState(state).date
}

export function getOrderEmail(state: ReduxState): string {
  return getState(state).email
}

export function getOrderFirstName(state: ReduxState): string {
  return getState(state).firstName
}

export function getOrderLastName(state: ReduxState): string {
  return getState(state).lastName
}

export function getOrderPhone(state: ReduxState): string {
  return getState(state).phone
}

export function getOrderLocale(state: ReduxState): string {
  return getState(state).locale
}

export function getOrderShippingMethod(state: ReduxState): Shipping | '' {
  return getState(state).deliveryMethod
}

export function getOrderShippingCountry(state: ReduxState): string {
  return getState(state).deliveryCountry
}

export function getOrderShippingCity(state: ReduxState): string {
  return getState(state).deliveryCity
}

export function getOrderShippingAddress(state: ReduxState): string {
  return getState(state).deliveryAddress
}

export function getOrderShippingZip(state: ReduxState): string {
  return getState(state).deliveryZip
}

export function getOrderShippingCost(state: ReduxState): number {
  return getState(state).deliveryCost
}

export function getOrderProducts(
  state: ReduxState
): readonly Readonly<OrderProduct>[] {
  return getState(state).products
}

export function getOrderProductsCost(state: ReduxState): number {
  return getState(state).productsCost
}

export function getOrderComment(state: ReduxState): string {
  return getState(state).comment
}

export const getOrderProductsWeight = createSelector(
  [getOrderProducts],
  function getOrderProductsWeight(products): number {
    return products.reduce(function reducer(sum, product) {
      return sum + (product.weight || 0)
    }, 0)
  }
)

// Fields

export function getFieldTrackCode(state: ReduxState): InputTextField {
  return getState(state).trackCode
}

export function getFieldShippingCost(state: ReduxState): InputTextField {
  return getState(state).deliveryCostActual
}

export function getFieldComment(state: ReduxState): InputTextField {
  return getState(state).managerComment
}

export function getFieldStatus(state: ReduxState): ComboboxField {
  return getState(state).status
}
