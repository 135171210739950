import * as React from 'react'

function SvgSocialSteam(): JSX.Element {
  return (
    <svg viewBox='0 0 64 64'>
      <path d='M23,42.8l-3-1.3c0.7,1.7,2.4,2.9,4.4,2.9c2.7,0,4.8-2.2,4.7-4.8c0-2.6-2.2-4.7-4.8-4.7 c-0.6,0-1.1,0.1-1.7,0.3l3,1.3c1.8,0.7,2.6,2.8,1.8,4.5C26.8,42.7,24.8,43.5,23,42.8z' />

      <path d='M32,9.1C20.3,9.1,10.5,18,9.2,29.5L21,34.4c1-0.7,2.2-1,3.5-1l5.2-7.3c0.1-4.4,3.7-7.9,8-7.9 c4.4,0,8,3.6,8,8.1c0,4.4-3.6,8.1-8.1,8l-7.1,5.3c0,0,0,0,0,0c0,3.4-2.7,6.1-6.1,6.2c-3.1,0-5.6-2.2-6.1-5l-8.6-3.6 C12.1,47.3,21.2,54.9,32,54.9C44.7,54.9,55,44.7,55,32C55,19.3,44.7,9.1,32,9.1z' />

      <path d='M60.5,0H3.6C1.6,0,0.1,1.6,0.1,3.5v56.9c0,1.9,1.6,3.5,3.5,3.5h56.9c1.9,0,3.5-1.6,3.5-3.5V3.5 C64,1.6,62.4,0,60.5,0z M32,59.5C16.8,59.5,4.5,47.2,4.5,32C4.5,16.8,16.8,4.5,32,4.5c15.2,0,27.5,12.3,27.5,27.5 C59.6,47.2,47.3,59.5,32,59.5z' />

      <path d='M43.2,26.3c0-3-2.5-5.5-5.5-5.5c-3,0-5.5,2.5-5.5,5.5c0,3,2.5,5.5,5.5,5.5C40.8,31.8,43.2,29.3,43.2,26.3z M33.6,26.3c0-2.3,1.9-4.1,4.1-4.1s4.1,1.9,4.1,4.1c0,2.3-1.9,4.1-4.1,4.1C35.5,30.4,33.6,28.6,33.6,26.3z' />
    </svg>
  )
}

export default React.memo(SvgSocialSteam)
