import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { useIntl } from 'react-intl'

function RouteLocale(): JSX.Element {
  const intl = useIntl()

  return <Redirect to={`/${intl.locale}`} />
}

export default React.memo(RouteLocale)
