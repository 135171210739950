import * as React from 'react'
import { useSelector } from 'react-redux'

import { getFieldId } from 'src/redux/selectors/admin-order-search'

import InputText from 'src/components/common/input-text'

interface Props {
  disabled: boolean
  onChange(): void
}

function SearchByEmail({ disabled, onChange }: Props): JSX.Element {
  const field = useSelector(getFieldId)

  return (
    <InputText
      field={field}
      disabled={disabled}
      className='form-control'
      onChange={onChange}
    />
  )
}

export default React.memo(SearchByEmail)
