export default `Мы вышлем ваш товар не более чем через неделю после заказа — и сразу же отправим вам письмо с трекингом. Скорость его дальнейших перемещений зависит от службы доставки.

Мы отправляем товары в любые регионы России и мира. Способ доставки выбирается при оформлении заказа. Стоимость зависит от вашего региона и выбранного способа доставки.

Способы доставки:

* почта России;
* курьерская служба (до квартиры или до пункта выдачи).

Доставка Почтой России обычно занимает от недели до месяца — в зависимости от времени года, праздников, региона и т.д.

В последний год мы много работали с Почтой России — и ни разу не сталкивались с затруднениями (потерянными посылками, огромными проволочками и т.д.). Тем не менее, мы не несём ответственности за работу службы доставки. Если вы опасаетесь осложнений, за дополнительную плату посылку можно застраховать. Для этого нужно написать нам на [shop@ice-pick.com](mailto:shop@ice-pick.com).

Если вы очень-очень хотите, чтобы вам товар доставили каким-нибудь особым способом, пожалуйста, напишите нам на [shop@ice-pick.com](mailto:shop@ice-pick.com). Мы постараемся вам помочь.`
