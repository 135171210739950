import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getWeekProducts } from 'src/redux/selectors/shop'

import Separator from 'src/components/separator'

import GoodsListItem from './goods-list-item'

import * as styles from 'src/styles/home.module.css'

import type { Product } from 'src/types/user'

type StateProps = readonly Readonly<Product>[]

function GoodsList(): JSX.Element {
  const list: StateProps = useSelector(getWeekProducts)

  if (list.length === 0) {
    return <></>
  }

  return (
    <div
      className={styles.goods}
      itemScope
      itemType='http://schema.org/OfferCatalog'
    >
      <h2 className={styles.header} itemProp='name'>
        <FormattedMessage id='featured' defaultMessage='Featured' />
      </h2>

      <Separator />

      <section className={styles.goodsContainer}>
        {list.map(function mapper(product): JSX.Element {
          return <GoodsListItem key={product.id} product={product} />
        })}
      </section>
    </div>
  )
}

export default React.memo(GoodsList)
