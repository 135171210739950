import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getImages,
  getIsFetching,
} from 'src/redux/selectors/admin-product-edit'

import { addImagesToProduct } from 'src/redux/actions/admin-product'

import Image from './image'
import FileManager from './filemanager'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  disabled: boolean
  images: readonly string[]
}

function selector(state: ReduxState): StateProps {
  return {
    disabled: getIsFetching(state),
    images: getImages(state),
  }
}

function Images(): JSX.Element {
  // File manager
  const [open, setOpen] = React.useState(false)

  const openFileManager = React.useCallback<React.MouseEventHandler>(
    function callback() {
      setOpen(true)
    },
    []
  )

  const onFileManagerClose = React.useCallback(function callback() {
    setOpen(false)
  }, [])

  const dispatch = useDispatch()

  const onSelect = React.useCallback(
    function callback(files: string[]) {
      dispatch(addImagesToProduct(files))

      setOpen(false)
    },
    [dispatch]
  )

  // Drag and drop
  const onDragOver = React.useCallback<React.DragEventHandler>(
    function callback(event) {
      event.preventDefault()
    },
    []
  )

  const { images, disabled } = useSelector(selector, shallowEqual)

  // Render
  return (
    <div className='form-group row'>
      <div className='col-sm-2 text-md-right col-form-label'>Изображения</div>

      <div className='col-sm-10'>
        <button
          className='btn btn-primary'
          disabled={disabled}
          onClick={openFileManager}
          type='button'
        >
          Добавить
        </button>

        <div className='row col-12'>
          {images.map(function mapper(image, index): JSX.Element {
            return (
              <Image
                key={image}
                index={index}
                image={image}
                onDragOver={onDragOver}
              />
            )
          })}
        </div>
      </div>

      <FileManager
        open={open}
        path='/img/products'
        onSelect={onSelect}
        onClose={onFileManagerClose}
      />
    </div>
  )
}

export default React.memo(Images)
