import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { BACKEND_URL } from 'src/config'

import { getProductById } from 'src/redux/selectors/cart'

import {
  removeCartProduct,
  setCartProductCount,
  changeCartProductCount,
} from 'src/redux/actions/cart'

import LazyImage from 'src/components/lazyimage'
import Price from 'src/components/price'

import IntlLink from 'src/components/common/intl-link'
import ButtonToWishlist from 'src/components/common/button-to-wishlist'

import SvgDelete from 'src/components/svg/delete'

import * as styles from 'src/styles/cart.module.css'

import type { ReduxState } from 'src/types/common'
import type { ProductParametersMap } from 'src/types/user'

interface OwnProps {
  id: number
  count: number
  unavailable: boolean
  parameters: Readonly<ProductParametersMap> | undefined
}

function CartListItem({
  id,
  count,
  unavailable,
  parameters,
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onRemove = React.useCallback(
    function callback() {
      dispatch(removeCartProduct(id, parameters))
    },
    [id, parameters, dispatch]
  )

  const setCount = React.useCallback(
    function callback(count: number) {
      dispatch(setCartProductCount(id, parameters, count))

      dispatch(changeCartProductCount(id, parameters, count))
    },
    [id, parameters, dispatch]
  )

  const onChange = React.useCallback(
    function callback({ target: { value } }) {
      const count = Number(value) || 1

      setCount(count)
    },
    [setCount]
  )

  const onPlus = React.useCallback(
    function callback() {
      setCount(count + 1)
    },
    [count, setCount]
  )

  const onMinus = React.useCallback(
    function callback() {
      setCount(count > 1 ? count - 1 : 1)
    },
    [count, setCount]
  )

  const product = useSelector(function selector(state: ReduxState) {
    return getProductById(state, id)
  })

  const { name, price, image } = React.useMemo(
    function memo() {
      let price = product?.price || 0
      let image = product?.image || ''

      const list: string[] = []

      if (product !== undefined) {
        list.push(product.name)

        if (parameters !== undefined) {
          for (const [paramId, valueId] of parameters) {
            const parameter = product.parameters.find(function find({ id }) {
              return id === paramId
            })

            const value = parameter?.values.find(function find({ id }) {
              return id === valueId
            })

            if (parameter?.changes_image) {
              image = value?.image || ''
            }

            if (parameter?.affects_price) {
              price =
                parseFloat(price.toString()) +
                (value?.price_delta !== undefined
                  ? parseFloat(value.price_delta.toString())
                  : 0)
            }

            list.push(`${parameter?.name}: ${value?.name}`)
          }
        }
      }

      return { name: list.join(', '), price, image }
    },
    [parameters, product]
  )

  if (product === undefined) {
    return <></>
  }

  return (
    <article data-invalid={unavailable} className={styles.item}>
      <IntlLink className={styles.itemImage} to={`/product/${product.link}`}>
        <LazyImage
          src={`${BACKEND_URL}/img/products/thumb/${image}`}
          alt={product.name}
        />
      </IntlLink>

      <div className={styles.itemInfo}>
        <IntlLink to={`/product/${product.link}`} className={styles.itemName}>
          {name}
        </IntlLink>

        <div aria-invalid={!product.inStock} className={styles.itemInStock}>
          <FormattedMessage
            id={product.inStock ? 'in-stock' : 'out-of-stock'}
            defaultMessage='In stock / out of stock'
          />
        </div>

        <div className={styles.itemControls}>
          <div className={styles.itemActions}>
            <button
              className={styles.itemButtonDelete}
              onClick={onRemove}
              type='button'
            >
              <SvgDelete />
            </button>

            <ButtonToWishlist
              className={styles.itemButtonHeart}
              productId={product.id}
            />
          </div>

          <div className={styles.itemCount}>
            <button
              className={styles.itemCountButton}
              onClick={onMinus}
              type='button'
            >
              −
            </button>

            <input
              className={styles.itemCountInput}
              type='number'
              value={count}
              onChange={onChange}
              min='1'
            />

            <button
              className={styles.itemCountButton}
              onClick={onPlus}
              type='button'
            >
              +
            </button>
          </div>

          <div className={styles.itemPrice}>
            <div
              className={
                product.sale !== null ? styles.itemPriceSale : undefined
              }
            >
              <Price value={price} />
            </div>

            {product.sale !== null && <Price value={product.sale} />}
          </div>
        </div>
      </div>
    </article>
  )
}

export default React.memo(CartListItem)
