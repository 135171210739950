import type {
  InputTextField,
  ComboboxField,
  FieldsPayload,
} from 'src/types/fields'

import type { ApiAuth } from 'src/types/api'

export interface PersonalFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Fields
  firstName: InputTextField
  lastName: InputTextField
  phone: InputTextField
  country: ComboboxField
  city: InputTextField
  zip: InputTextField
  address: InputTextField
}

export const initialFields: PersonalFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Fields
  firstName: {
    path: ['personal', 'firstName'],
    id: 'firstName',
    value: '',
  },
  lastName: {
    path: ['personal', 'lastName'],
    id: 'lastName',
    value: '',
  },
  phone: {
    path: ['personal', 'phone'],
    id: 'phone',
    type: 'tel',
    value: '',
  },
  country: {
    path: ['personal', 'country'],
    id: 'country',
    value: '',
  },
  city: {
    path: ['personal', 'city'],
    id: 'city',
    value: '',
  },
  zip: {
    path: ['personal', 'zip'],
    id: 'zip',
    value: '',
  },
  address: {
    path: ['personal', 'address'],
    id: 'address',
    value: '',
  },
}

export function authLogin(
  draft: PersonalFieldsState,
  { user }: FieldsPayload<ApiAuth>
): void {
  draft.firstName.value = user.first_name || ''
  draft.lastName.value = user.last_name || ''
  draft.phone.value = user.phone || ''
  draft.country.value = user.country || ''
  draft.address.value = user.address || ''
  draft.city.value = user.city || ''
  draft.zip.value = user.zip || ''
}
