import * as React from 'react'

function SvgSearch(): JSX.Element {
  return (
    <svg viewBox='-98 99.4 369.5 369.5'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M252.8,410.2c-8.6,12.5-25.8,15.7-38.4,7l-113.3-78.4c-8-5.5-12.2-14.6-11.9-23.6 c-14.6,15.7-33.8,26.2-55.3,30.1C7.6,350-19,344.2-41,329c-22-15.2-36.8-38.1-41.6-64.5c-4.8-26.4,0.9-53,16.2-75.1 C-35,144,27.6,132.6,73.1,164.1c41.3,28.6,54.5,82.8,33,126.6c8.4-3.5,18.3-2.8,26.4,2.7l113.4,78.4 C258.4,380.5,261.5,397.7,252.8,410.2z M84.8,234c-3.3-18.4-13.6-34.4-29-45c-31.7-21.9-75.4-14-97.3,17.7 c-21.9,31.7-14,75.4,17.7,97.3c31.7,21.9,75.4,14,97.3-17.7C84.2,271,88.2,252.4,84.8,234z'
      />
    </svg>
  )
}

export default React.memo(SvgSearch)
