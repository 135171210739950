import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { fetchWishlist } from 'src/redux/actions/wishlist'

import WishList from './wish-list'

import * as utility from 'src/styles/utility.module.css'

function WishlistView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'wishlist' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchWishlist())
    },
    [dispatch]
  )

  return (
    <div className={utility.defaultPage}>
      <WishList />
    </div>
  )
}

export default React.memo(WishlistView)
