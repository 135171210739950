import * as React from 'react'
import { useDispatch } from 'react-redux'

import { changeInput } from 'src/redux/actions/fields'

import type { ComboboxField, ComboboxOption } from 'src/types/fields'

interface OwnProps<T> {
  field: Readonly<ComboboxField<T>>
  options: readonly Readonly<ComboboxOption<T>>[]
  className?: string
  disabled?: boolean
  onChange?(value: string, path: readonly string[]): void
}

interface ValueToString {
  toString(): string
}

export default function InputSelect<T extends ValueToString>({
  field,
  options,
  className,
  disabled,
  onChange,
  ...props
}: OwnProps<T>): JSX.Element {
  const dispatch = useDispatch()

  const onSelectChange = React.useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >(
    function callback({ target: { value } }) {
      if (value === field.value.toString()) {
        return
      }

      dispatch(
        changeInput({
          path: field.path,
          value,
        })
      )

      if (typeof onChange === 'function') {
        onChange(value, field.path)
      }
    },
    [field.value, field.path, onChange, dispatch]
  )

  return (
    <select
      {...props}
      id={field.id}
      value={field.value.toString()}
      disabled={field.disabled || disabled}
      required={field.required}
      className={className}
      onChange={onSelectChange}
      onBlur={onSelectChange}
    >
      {field.placeholder !== undefined && (
        <option value=''>{field.placeholder}</option>
      )}

      {options.map(function mapper(item): JSX.Element {
        const value = item.value.toString()

        return (
          <option key={value} value={value}>
            {item.name}
          </option>
        )
      })}
    </select>
  )
}
