import type { OrderShort } from 'src/types/admin'
import type { FieldsPayload } from 'src/types/fields'
import type { ApiAdminOrders } from 'src/types/api'

export interface AdminOrdersListFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  list: OrderShort[]
  productCount: number
  currentPage: number
  lastPage: number
  total: number
}

export const initialFields: AdminOrdersListFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  list: [],
  productCount: 0,
  currentPage: 1,
  lastPage: 0,
  total: 0,
}

export function fetchSuccess(
  draft: AdminOrdersListFieldsState,
  {
    list,
    productCount,
    currentPage,
    lastPage,
    total,
  }: FieldsPayload<ApiAdminOrders>
): void {
  draft.isFetching = false
  draft.error = ''

  draft.currentPage = currentPage || 0
  draft.lastPage = lastPage || 0
  draft.total = total || 0
  draft.productCount = productCount || 0
  draft.list = list || []
}
