import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getFieldComment } from 'src/redux/selectors/order'

import InputTextarea from 'src/components/common/input-textarea'

import * as styles from 'src/styles/order-payment.module.css'

import type { InputTextField } from 'src/types/fields'

function OrderPaymentComment(): JSX.Element {
  const field: Readonly<InputTextField> = useSelector(getFieldComment)

  return (
    <div className={styles.comment}>
      <h2 className={styles.commentHeader}>
        <label htmlFor={field.id}>
          <FormattedMessage id='order-comment' defaultMessage='Order comment' />
        </label>
      </h2>

      <InputTextarea className={styles.commentInput} field={field} />
    </div>
  )
}

export default React.memo(OrderPaymentComment)
