import * as React from 'react'

import InputCheckbox from 'src/components/common/input-checkbox'

import type { InputCheckboxField } from 'src/types/fields'

export interface AdminInputCheckboxProps {
  field: Readonly<InputCheckboxField>
  disabled: boolean
}

export default function AdminInputCheckbox({
  field,
  disabled,
}: AdminInputCheckboxProps): JSX.Element {
  return (
    <div className='form-group row'>
      <label className='col-sm-2 text-md-right' htmlFor={field.id}>
        {field.label}
      </label>

      <div className='col-sm-10'>
        <div className='form-check'>
          <label className='form-check-label' htmlFor={field.id}>
            <InputCheckbox
              className='form-check-input'
              disabled={disabled}
              field={field}
            />

            {field.description}
          </label>
        </div>
      </div>
    </div>
  )
}
