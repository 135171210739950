import * as React from 'react'

import * as styles from 'src/styles/separator.module.css'

function Separator(): JSX.Element {
  return (
    <div className={styles.sep}>
      <hr className={styles.sepHr} />
      <hr className={styles.sepHrMiddle} />
      <hr className={styles.sepHr} />
    </div>
  )
}

export default React.memo(Separator)
