import * as React from 'react'
import { useIntl } from 'react-intl'
import { NavLink, NavLinkProps } from 'react-router-dom'

function IntlNavLink(props: NavLinkProps): JSX.Element {
  const intl = useIntl()

  const to = `/${intl.locale}${props.to}`

  return <NavLink {...props} to={to} />
}

export default React.memo(IntlNavLink)
