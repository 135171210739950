import * as React from 'react'

import InputTrackCode from './input-track-code'
import ButtonTrackCodeNotify from './button-track-code-notify'

function TrackCode(): JSX.Element {
  return (
    <div className='form-group row flex-wrap py-3 alert-warning'>
      <InputTrackCode
        divClassName='form-group d-md-flex col-md-12 p-0'
        labelClassName='col-sm-2 text-md-right col-form-label'
        inputDivClassName='col-sm-10'
      />

      <div className='col-sm-2' />

      <ButtonTrackCodeNotify />
    </div>
  )
}

export default React.memo(TrackCode)
