import { createSelector } from 'reselect'

import { paymentMethods, Payment, PaymentMethod } from 'src/const/payment'

import type { OrderFieldsState } from 'src/redux/reducers/order'

import type { ReduxState } from 'src/types/common'
import type { InputTextField, TextField } from 'src/types/fields'

function getState(state: ReduxState): OrderFieldsState {
  return state.fields.order
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getOrderError(state: ReduxState): string {
  return getState(state).error
}

export function getOrderIntlError(state: ReduxState): string {
  return getState(state).intlError
}

export function getOrderId(state: ReduxState): number {
  return getState(state).id
}

export function getOrderProductsCost(state: ReduxState): number {
  return getState(state).productsCost
}

export function getOrderShippingCost(state: ReduxState): number {
  return getState(state).shippingCost
}

export function getOrderAmount(state: ReduxState): number {
  return getState(state).amount
}

export function getOrderExpires(state: ReduxState): string {
  return getState(state).expires
}

export function getOrderSignature(state: ReduxState): string {
  return getState(state).signature
}

export function getOrderStatus(state: ReduxState): number {
  return getState(state).status
}

export function getFieldComment(state: ReduxState): InputTextField {
  return getState(state).comment
}

/* Payment */

export function getFieldPayment(state: ReduxState): TextField<Payment> {
  return getState(state).payment
}

export function getPaymentSelectedMethod(state: ReduxState): Payment {
  return getFieldPayment(state).value
}

const getPaymentCurrent = createSelector(
  [getPaymentSelectedMethod],
  function getPaymentCurrent(selected): PaymentMethod {
    return paymentMethods[selected]
  }
)

export function getPaymentCurrentMethod(state: ReduxState): Payment {
  return getPaymentCurrent(state).type
}

export function getPaymentCurrentUrl(state: ReduxState): string {
  return getPaymentCurrent(state).url
}
