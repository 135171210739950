import * as React from 'react'
import { useSelector } from 'react-redux'

import { getPaymentCurrentMethod } from 'src/redux/selectors/order'

import FormRobokassa from './form-robokassa'
import FormPaypal from './form-paypal'

function FormPayment(): JSX.Element {
  const type = useSelector(getPaymentCurrentMethod)

  switch (type) {
    case 'robokassa':
      return <FormRobokassa />

    case 'paypal':
      return <FormPaypal />

    default:
      return <div />
  }
}

export default React.memo(FormPayment)
