import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import { getIsFetching, getFieldIsDone } from 'src/redux/selectors/auth'

import { changeCheckbox } from 'src/redux/actions/fields'
import { updateUserPersonalDetails } from 'src/redux/actions/auth'

import Separator from 'src/components/separator'

import PersonalLinks from 'src/components/personal-links'

import InputFirstName from './input-firstname'
import InputLastName from './input-lastname'
import InputPhone from './input-phone'
import SelectCountry from './select-country'
import InputCity from './input-city'
import InputZip from './input-zip'
import InputAddress from './input-address'
import ButtonSubmit from './button-submit'

import * as styles from 'src/styles/personal-details.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'
import type { InputCheckboxField } from 'src/types/fields'

interface StateProps {
  isDone: Readonly<InputCheckboxField>
  isFetching: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    isDone: getFieldIsDone(state),
  }
}

function PersonalDetailsView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'personal-details' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  const { isFetching, isDone } = useSelector(selector, shallowEqual)

  React.useEffect(
    function effect() {
      return function cleanup() {
        dispatch(changeCheckbox({ path: isDone.path, checked: false }))
      }
    },
    [isDone.path, dispatch]
  )

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(updateUserPersonalDetails())
    },
    [dispatch]
  )

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <PersonalLinks />

      <form className={styles.form} onSubmit={onSubmit}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage
            id='personal-details'
            defaultMessage='Personal details'
          />
        </h2>

        <Separator />

        <InputFirstName disabled={isFetching} />

        <InputLastName disabled={isFetching} />

        <InputPhone disabled={isFetching} />

        <SelectCountry disabled={isFetching} />

        <InputCity disabled={isFetching} />

        <InputZip disabled={isFetching} />

        <InputAddress disabled={isFetching} />

        <div className={styles.action}>
          <ButtonSubmit disabled={isFetching} />

          {isDone.checked && (
            <p className={styles.actionSuccess}>
              <FormattedMessage
                id='personal-details-success'
                defaultMessage='Your persnal data has been saved'
              />
            </p>
          )}
        </div>
      </form>
    </div>
  )
}

export default React.memo(PersonalDetailsView)
