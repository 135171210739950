import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as showdown from 'showdown'
import classnames from 'classnames'

import Separator from 'src/components/separator'

import * as styles from 'src/styles/shipping.module.css'
import * as utility from 'src/styles/utility.module.css'

const classList: readonly Readonly<[string, string]>[] = [
  ['p', styles.text],
  ['ul', styles.list],
  ['li', styles.item],
] as const

const converter = new showdown.Converter({
  extensions: classList.map(function mapper([key, className]) {
    return {
      type: 'output',
      regex: new RegExp(`<${key}(.*?)>`, 'g'),
      replace: `<${key} class="${className}" $1>`,
    }
  }),
})

function DeliveryInfoView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'shipping' })
    },
    [intl]
  )

  const { shippingHtml, paymentHtml } = React.useMemo(
    function memo() {
      return {
        shippingHtml: {
          __html: converter.makeHtml(
            intl.formatMessage({ id: 'shipping-info-content' })
          ),
        },
        paymentHtml: {
          __html: converter.makeHtml(
            intl.formatMessage({ id: 'payment-info-content' })
          ),
        },
      }
    },
    [intl]
  )

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <div className={styles.content}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage id='shipping' defaultMessage='Shipping' />
        </h2>

        <Separator />

        <div dangerouslySetInnerHTML={shippingHtml} />

        <h2 className={utility.defaultPageHeader} id='payment'>
          <FormattedMessage id='payment' defaultMessage='Payment' />
        </h2>

        <div dangerouslySetInnerHTML={paymentHtml} />
      </div>
    </div>
  )
}

export default React.memo(DeliveryInfoView)
