import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import { getCategories, getProjectById } from 'src/redux/selectors/shop'

import IntlLink from 'src/components/common/intl-link'

import * as styles from 'src/styles/product.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'
import type { ShopProduct, Project, Category } from 'src/types/user'

interface StateProps {
  project: Readonly<Project> | undefined
  categories: readonly Readonly<Category>[]
}

interface OwnProps {
  product: Readonly<ShopProduct>
}

function Breadcrumbs({ product }: OwnProps): JSX.Element {
  const { project, categories }: StateProps = useSelector(function selector(
    state: ReduxState
  ): StateProps {
    return {
      project: getProjectById(state, product.project_id),
      categories: getCategories(state),
    }
  },
  shallowEqual)

  const productCategories = React.useMemo<readonly Readonly<Category>[]>(
    function memo() {
      const list = product.categories

      return categories.filter(function filter({ id }): boolean {
        return list.includes(id)
      })
    },
    [product.categories, categories]
  )

  if (project === undefined || productCategories.length === 0) {
    return <></>
  }

  return (
    <div className={styles.breadcrumbs}>
      <span
        itemProp='category'
        itemScope
        itemType='http://schema.org/OfferCatalog'
      >
        <IntlLink
          className={utility.ci}
          to={`/project/${project.link}`}
          itemProp='url'
        >
          <span itemProp='name'>{project.name}</span>
        </IntlLink>
      </span>

      {' > '}

      {productCategories.map(function mapper(category): JSX.Element {
        return (
          <span
            key={category.id}
            itemProp='category'
            itemScope
            itemType='http://schema.org/OfferCatalog'
          >
            <IntlLink
              className={classnames(utility.ci, styles.breadcrumbsCategory)}
              to={`/category/${category.link}`}
              itemProp='url'
            >
              <span itemProp='name'>{category.name}</span>
            </IntlLink>
          </span>
        )
      })}
    </div>
  )
}

export default React.memo(Breadcrumbs)
