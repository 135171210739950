import * as React from 'react'
import { produce } from 'immer'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { getIsFetching } from 'src/redux/selectors/admin-product-edit'

import {
  getProductId,
  getFieldVariants,
} from 'src/redux/selectors/admin-product-edit'

import { changeList } from 'src/redux/actions/fields'

import Variant from './variant'

import type { ListField } from 'src/types/fields'
import type { ReduxState } from 'src/types/common'
import type { ProductEditVariant } from 'src/types/admin'

interface StateProps {
  productId: number
  disabled: boolean
  variants: ListField<ProductEditVariant>
}

function selector(state: ReduxState): StateProps {
  return {
    productId: getProductId(state),
    disabled: getIsFetching(state),
    variants: getFieldVariants(state),
  }
}

function Variants(): JSX.Element {
  const dispatch = useDispatch()

  const { productId, variants, disabled } = useSelector(selector, shallowEqual)

  const addParameter = React.useCallback(
    function callback() {
      const value = produce(variants.list, function producer(draft) {
        draft.push({
          affects_price: 0,
          changes_image: 0,
          id: 0,
          name: {
            value: '',
          },
          product_id: productId,
          values: [],
        })
      })

      dispatch(
        changeList({
          path: variants.path,
          list: value,
        })
      )
    },
    [productId, variants.list, variants.path, dispatch]
  )

  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={variants.id}
      >
        Параметры
      </label>

      <div className='col-sm-10'>
        {variants.list.map(function mapper(variant, paramId): JSX.Element {
          return <Variant key={paramId} paramId={paramId} variant={variant} />
        })}

        <button
          className='btn btn-primary'
          disabled={disabled}
          onClick={addParameter}
          type='button'
        >
          Добавить параметр
        </button>
      </div>
    </div>
  )
}

export default React.memo(Variants)
