import * as React from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { isEmpty } from 'src/utils/object'

import { getLocale, getMessages } from 'src/redux/selectors/intl'

import { initIntlLocale } from 'src/redux/actions/intl'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  locale: string
  messages: Record<string, string>
}

function selector(state: ReduxState): StateProps {
  return {
    locale: getLocale(state),
    messages: getMessages(state),
  }
}

interface OwnProps {
  children: React.ReactNode
}

function IntlComponent({ children }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(initIntlLocale())
    },
    [dispatch]
  )

  const { locale, messages }: StateProps = useSelector(selector, shallowEqual)

  if (isEmpty(messages)) {
    return <></>
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export default React.memo(IntlComponent)
