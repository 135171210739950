import * as React from 'react'

import InputSelect from 'src/components/common/input-select'

import type { ComboboxField, ComboboxOption } from 'src/types/fields'

interface ValueToString {
  toString(): string
}

export interface AdminInputSelectProps<T extends ValueToString = string> {
  field: ComboboxField<T>
  options: readonly Readonly<ComboboxOption<T>>[]
  disabled: boolean
}

export default function AdminInputSelect<T>({
  field,
  options,
  disabled,
}: AdminInputSelectProps<T>): JSX.Element {
  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={field.id}
      >
        {field.label}
      </label>

      <div className='col-sm-10'>
        <InputSelect
          className='form-control'
          disabled={disabled}
          options={options}
          field={field}
        />
      </div>
    </div>
  )
}
