import * as React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

import { getIsFetching, getIsLoggedIn } from 'src/redux/selectors/auth'

import LoadingPage from 'src/components/loading'
import ForbiddenView from 'src/components/forbidden'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  isLoggedIn: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    isLoggedIn: getIsLoggedIn(state),
  }
}

function RouteUser(props: RouteProps): JSX.Element {
  const { isLoggedIn, isFetching }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  if (isLoggedIn) {
    return <Route {...props} />
  }

  if (isFetching) {
    return <LoadingPage />
  }

  return <ForbiddenView />
}

export default React.memo(RouteUser)
