const observerConfig: IntersectionObserverInit = {
  rootMargin: '300px 0px',
} as const

function onIntersection(
  entries: readonly IntersectionObserverEntry[],
  observer: IntersectionObserver
): void {
  for (const entry of entries) {
    if (entry.intersectionRatio > 0) {
      observer.unobserve(entry.target)

      setImageSrc(entry.target as HTMLImageElement)
    }
  }
}

const lazyloadObserver =
  'IntersectionObserver' in window
    ? new window.IntersectionObserver(onIntersection, observerConfig)
    : null

export function addToLazyload(elem: HTMLElement): void {
  lazyloadObserver !== null
    ? lazyloadObserver.observe(elem)
    : lazyloadIter(elem)
}

function setImageSrc(elem: HTMLImageElement): void {
  const src = elem.getAttribute('data-src') || ''

  if (src !== '') {
    elem.src = src

    elem.removeAttribute('data-src')
  }
}

function lazyloadIter(elem: Element): void {
  if (elem.getBoundingClientRect().top - window.innerHeight <= 1000) {
    setImageSrc(elem as HTMLImageElement)
  }
}

function lazyload(): void {
  ;[...document.querySelectorAll('img[data-lazy]')].forEach(lazyloadIter)
}

export function initLazyload(): void {
  if (!('IntersectionObserver' in window)) {
    window.addEventListener('scroll', lazyload)
    setTimeout(lazyload, 0)
  }
}
