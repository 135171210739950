import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { getCartCost } from 'src/redux/selectors/cart'
import { getIsFetching } from 'src/redux/selectors/order'
import { getDeliveryCost } from 'src/redux/selectors/delivery'

import Price from 'src/components/price'

import SvgLoading from 'src/components/svg/loading'

import * as styles from 'src/styles/order-payment.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  deliveryCost: number
  productsCost: number
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    deliveryCost: getDeliveryCost(state),
    productsCost: getCartCost(state),
  }
}

function OrderPaymentFormSubmit(): JSX.Element {
  const { isFetching, deliveryCost, productsCost }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  return (
    <div className={styles.form}>
      <div className={styles.formContainer}>
        <div>
          <FormattedMessage id='shipping-cost' defaultMessage='Shipping cost' />
          &nbsp;
          <Price value={deliveryCost} />
        </div>

        <div className={styles.prodcutsCost}>
          <FormattedMessage id='products-cost' defaultMessage='Products cost' />
          &nbsp;
          <Price value={productsCost} />
        </div>
      </div>

      <div className={styles.totalCost}>
        <FormattedMessage id='total-cost' defaultMessage='Total cost' />
        &nbsp;
        <Price value={productsCost + deliveryCost} />
      </div>

      <button className={styles.submit} disabled={isFetching} type='submit'>
        {isFetching ? (
          <div
            role='img'
            aria-label='Loading…'
            className={styles.submitLoading}
          >
            <SvgLoading />
          </div>
        ) : (
          <FormattedMessage id='to-pay' defaultMessage='Go to pay' />
        )}
      </button>
    </div>
  )
}

export default React.memo(OrderPaymentFormSubmit)
