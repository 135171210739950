import type {
  FieldsPayload,
  InputTextField,
  InputCheckboxField,
} from 'src/types/fields'

import type { ApiAuth } from 'src/types/api'

export interface AuthFieldsState {
  // Request
  error: string
  isFetching: boolean
  isLoggedIn: boolean
  isAdmin: boolean
  emailStr: string
  // Fields
  email: InputTextField
  password: InputTextField
  passwordConfirm: InputTextField
  privacyPolicy: InputCheckboxField
  /**
   * Using only for redirect after registration (or reset password)
   */
  isDone: InputCheckboxField
}

export const initialFields: AuthFieldsState = {
  // Request
  error: '',
  isFetching: false,
  isLoggedIn: false,
  isAdmin: false,
  emailStr: '',
  // Fields
  email: {
    path: ['auth', 'email'],
    id: 'email',
    type: 'email',
    required: true,
    value: '',
  },
  password: {
    path: ['auth', 'password'],
    id: 'password',
    type: 'password',
    required: true,
    value: '',
  },
  passwordConfirm: {
    path: ['auth', 'passwordConfirm'],
    id: 'passwordConfirm',
    type: 'password',
    required: true,
    value: '',
  },
  privacyPolicy: {
    path: ['auth', 'privacyPolicy'],
    id: 'privacyPolicy',
    required: true,
    checked: true,
    value: '1',
  },
  isDone: {
    path: ['auth', 'isDone'],
    checked: false,
  },
}

export function authLogin(
  draft: AuthFieldsState,
  { user }: FieldsPayload<ApiAuth>
): void {
  Object.assign(draft, initialFields)

  draft.isLoggedIn = true
  draft.isAdmin = Boolean(user.isAdmin)
}

// export function fetchSuccess(
//   draft: AuthFieldsState,
//   _payload: FieldsPayload<ApiAuth>
// ): void {
//   Object.assign(draft, initialFields)
// }
