import type { FeedbackFieldsState } from 'src/redux/reducers/feedback'

import type { ReduxState } from 'src/types/common'
import type { InputTextField } from 'src/types/fields'

function getState(state: ReduxState): FeedbackFieldsState {
  return state.fields.feedback
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getFieldName(state: ReduxState): InputTextField {
  return getState(state).name
}

export function getFieldEmail(state: ReduxState): InputTextField {
  return getState(state).email
}

export function getFieldMessage(state: ReduxState): InputTextField {
  return getState(state).message
}
