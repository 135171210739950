import * as React from 'react'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import PartnersList from './partners-list'

import * as styles from 'src/styles/partners.module.css'
import * as utility from 'src/styles/utility.module.css'

function PartnersView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'partners' })
    },
    [intl]
  )

  return (
    <div className={classnames(styles.container, utility.defaultPage)}>
      <PartnersList />
    </div>
  )
}

export default React.memo(PartnersView)
