import aboutUsContent from './about-en'
import aboutWishlistContent from './about-wishlist-en'
import paymentInfoContent from './payment-en'
import shippingInfoContent from './shipping-en'
import privacyPolicyContent from './privacy-policy-en'
import ourLocationContent from './location-en'

export default {
  locale: 'en',
  messages: {
    title: 'Ice-Pick Shop',
    subtitle: 'The Official Ice-Pick Lodge Shop',
    menu: 'Menu',
    error: 'Error',
    next: 'Next',
    copyright: '© All rights reserved Ice-Pick Lodge 2017',
    projects: 'Projects',
    categories: 'Categories',
    featured: 'Featured',
    cart: 'Cart',
    wishlist: 'Wishlist',
    payment: 'Payment',
    shipping: 'Shipping',
    'about-us': 'About Us',
    'about-wishlist': 'About Wishlist',
    partners: 'Partners',
    administration: 'Administration',
    'privacy-policy': 'Privacy Policy',
    'our-location': 'How to find us',

    'overlay-test-info':
      'The site is currently running in test mode. If anything goes wrong, please contact us at shop@ice-pick.com.',

    'shop-is-closed': 'The shop is closed',
    'shop-is-closed-desc':
      'Alas, the Ice-Pick shop is currently closed for an indefinite period of time.',
    'shop-is-closed-info':
      'Follow the news at our various communities on the web!',
    'shop-is-closed-feedback': 'If you have any questions, please mail them to',

    account: 'Account',
    support: 'Support',
    amp: '&',

    'about-us-content': aboutUsContent,
    'about-wishlist-header': 'About the Wishlist',
    'about-wishlist-content': aboutWishlistContent,

    'our-location-content': ourLocationContent,

    'privacy-policy-content': privacyPolicyContent,
    'payment-info-content': paymentInfoContent,
    'shipping-info-content': shippingInfoContent,

    'partners-info':
      'These companies are more than partners; they’re friends. Meaning the level of craftsmanship and polish they provide is more than just professional. They make these items with a passion, and so we can vouch for the quality.',

    'page-not-found': 'Page not found',
    'page-not-found-details':
      'The page you’ve tried to access could not be found.',
    'access-denied': 'Access denied',
    'access-denied-details':
      'You don’t have a permission to access this page. Please check if you’ve signed in properly.',

    registration: 'Create an Account',
    'password-confirm': 'Confirm password',
    'accept-terms': 'I agree to the terms of the',
    'privacy-policy-name': 'PERSONAL INFORMATION PROCESSING AGREEMENT',
    'sign-up': 'Sign Up',
    success: 'Success!',
    'registration-success':
      'Account created. We’ve sent you an e-mail to confirm the registration.',
    activate: 'E-mail Confirmation',

    'sign-in-header': 'Sign In',
    email: 'E-mail',
    password: 'Password',
    'forgot-password': 'Forgot your password?',
    'forgot-password-details':
      'Please enter the e-mail address which you used during the registration. Password recovery instructions will be sent to your e-mail.',
    'sign-in': 'Sign In',
    logout: 'Logout',

    'reset-password': 'Reset Password',
    'reset-password-details': 'Введите e-mail вашего аккаунта и новый пароль.',

    feedback: 'Feedback',
    'your-name': 'Name',
    message: 'Message',
    send: 'Send',

    'sort-by': 'Sort by:',
    'sort-option-1': 'none',
    'sort-option-2': 'cheap first',
    'sort-option-3': 'expensive first',
    'sort-option-4': 'available only',

    search: 'Search',
    'search.placeholder': 'Search...',
    'found-count': `{count, plural,
      =0 {No matching items found}
      one {One item found}
      other {{count} items found}
    }`,

    'price-unavailable': 'Price unavailable',
    'in-stock': 'In stock',
    'out-of-stock': 'Out of stock',
    'add-to-cart': 'Add to Cart',
    similar: 'You may also like',
    reviews: 'Reviews',

    'wishlist-empty': 'Your wishlist is empty.',
    'wishlist-unavailable': 'Only authorized users can access the wishlist.',
    'cart-empty': 'Your cart is empty.',
    'cart-unavailable':
      'Some items in your cart are unavailable. Perhaps you’ve ordered more of them than we have in stock.',

    'order-placement': 'Order Placement',
    'total-cost': 'Total:',
    'place-order': 'Place order',

    'delivery-method': 'Shipping Options',
    'russian-post': 'Russian Post (anywhere in the world)',
    'boxberry-default':
      'Boxberry pickup point (Russia only; choose the pickup point <a href="http://boxberry.ru/find_an_office/" target="_blank">here</a> and let us know its address in the comment)',
    'boxberry-courier': 'Boxberry courier (Russia only)',
    'customer-pick-up':
      'Customer pick up<br>(address: Moscow, Vorotnikovsky lane, house 8, building 1<br><a href="/en/our-location">How to find us</a>)',

    'personal-details': 'Personal Details',
    'personal-details-success': 'Profile saved successfully',
    save: 'Save',
    'recipient-data': 'Your Info',
    'first-name': 'First name',
    'last-name': 'Last name',
    'phone-number': 'Phone number',
    country: 'Country',
    city: 'City/Town',
    zip: 'ZIP Code',
    address: 'Address',

    'phone-number-mobile': 'Mobile phone number',

    'order-history': 'Order History',
    'order-history-empty': 'You have no order history.',
    'order-number': 'Order #{number}, {date}',
    'order-repeat': 'Order this again',

    'order-status-unknown': 'Unknown',
    'order-status-0': 'Current',
    'order-status-1': 'Processing',
    'order-status-2': 'Cancelled',
    'order-status-3': 'Processed',
    'order-status-4': 'Ready for shipment',
    'order-status-5': 'Sent',
    'order-status-6': 'Special',

    'shipping-cost': 'Shipping:',
    'products-cost': 'Your purchase:',

    'payment-method': 'Choose Your Payment Option',
    'order-comment': 'Comment',
    'to-pay': 'Place Order',

    'internal-server-error': 'Server error',
    'unknown-payment-system':
      'Your payment service provider cannot be recognized. Please contact support.',
    'unknown-delivery-method':
      'Your shipping method cannot be recognized. Please contact support.',
    'invalid-currency-rate': 'Invalid currency rate. Please contact support.',
    'order-cannot-be-processed': 'Your order cannot be processed.',

    'invoice-status': 'Payment Status',
    'invoice-success':
      'Your order has been places and will be delivered to you as soon as possible via giant eagles.',
    // Paypal
    'invoice-fail-paypal-1': 'Something went wrong!',
    'invoice-fail-paypal-2':
      'The payment was cancelled, your funds were returned.',
    'invoice-fail-paypal-3':
      'Please try again. If the error recurs, please contact support.',
    // Other
    'invoice-fail-1': 'Something seems to have gone wrong.',
    'invoice-fail-2':
      'We have most likely received your money, but not the order information.',
    'invoice-fail-3':
      'Please contact our support and let us know of this issue.',

    'cancel-invoice': 'Payment Cancelled',
    'cancel-invoice-success':
      'Your payment has been cancelled. The items are still in the cart.',
    'cancel-invoice-fail':
      'Your order cannot be cancelled. Please place a new one.',

    // Partners
    'partner-1-name': 'Wild & Nuclear, workshop',
    'partner-1-desc':
      'Many-handed craftsmen who can create anything physical from every material, be it 3D printing plastic, wool, wood, or cloth. They also make cosplay and LARP items.',

    'partner-2-name': 'Press Culture, typography',
    'partner-2-desc':
      'Printing everything paperback for us since before we opened this shop. Eager to face creative challenges, unusual textures, and to die-cut odd shapes.',

    pick_up_city: 'Moscow',
    pick_up_address: 'Vorotnikovsky lane, house 8, building 1',

    'size-table': 'T-Shirt Measurements',
    'size-table-mens': 'Male',
    'size-table-womens': 'Female',
    'size-table-size-rus': 'Size',
    'size-table-chest-size': 'Chest, cm',
    'size-table-width': 'Width, cm',
    'size-table-length': 'Length, cm',
  },
}
