import { connect } from 'react-redux'

import { getFieldAddress } from 'src/redux/selectors/delivery'

import { openBoxberryByFocus } from 'src/redux/actions/boxberry'

import InputText, { Props } from './input-text'

import type { ReduxState } from 'src/types/common'

function mapStateToProps(state: ReduxState): Pick<Props, 'field'> {
  return {
    field: getFieldAddress(state),
  }
}

const mapDispatchToProps: Pick<Props, 'onFocus'> = {
  onFocus: openBoxberryByFocus,
}

export default connect(mapStateToProps, mapDispatchToProps)(InputText)
