import * as React from 'react'
import { match } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getOrderId } from 'src/redux/selectors/admin-order'

import {
  fetchOrder,
  updateOrder,
  clearAdminOrderFields,
} from 'src/redux/actions/admin-order'

import Headline from './headline'
import ErrorMessage from './error-message'
import InfoDate from './info-date'
import InfoEmail from './info-email'
import InfoFirstName from './info-first-name'
import InfoLastName from './info-last-name'
import InfoPhone from './info-phone'
import InfoLocale from './info-locale'
import InfoShippingMethod from './info-shipping-method'
import InfoShippingCountry from './info-shipping-country'
import InfoShippingCity from './info-shipping-city'
import InfoShippingAddress from './info-shipping-address'
import InfoShippingZip from './info-shipping-zip'
import InfoShippingCost from './info-shipping-cost'
import InputShippingCost from './input-shipping-cost'
import Products from './products'
import InfoProductsCost from './info-products-cost'
import InfoProductsWeight from './info-products-weight'
import InfoTotalCost from './info-total-cost'
import InfoComment from './info-comment'
import InputComment from './input-comment'
import TrackCode from './track-code'
import InputStatus from './select-status'
import ButtonSubmit from './button-submit'

interface OwnProps {
  match: match<{ id: string }>
}

function AdminOrdersEditView({ match }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const id: number = useSelector(getOrderId)

  React.useEffect(
    function effect() {
      return function cleanup() {
        if (id !== 0) {
          dispatch(clearAdminOrderFields())
        }
      }
    },
    [id, dispatch]
  )

  React.useEffect(
    function effect() {
      if (match.params.id) {
        dispatch(fetchOrder(match.params.id))
      }
    },
    [match.params.id, dispatch]
  )

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function onSubmit(event) {
      event.preventDefault()

      dispatch(updateOrder())
    },
    [dispatch]
  )

  return (
    <div className='container p-3'>
      <Headline />

      <ErrorMessage />

      <form onSubmit={onSubmit}>
        <InfoDate />

        <InfoEmail />

        <InfoFirstName />

        <InfoLastName />

        <InfoPhone />

        <InfoLocale />

        <InfoShippingMethod />

        <InfoShippingCountry />

        <InfoShippingCity />

        <InfoShippingAddress />

        <InfoShippingZip />

        <InfoShippingCost />

        <InputShippingCost />

        <Products />

        <InfoProductsCost />

        <InfoProductsWeight />

        <InfoTotalCost />

        <InfoComment />

        <InputComment />

        <TrackCode />

        <InputStatus />

        <div className='form-group row'>
          <ButtonSubmit />
        </div>
      </form>
    </div>
  )
}

export default React.memo(AdminOrdersEditView)
