import { connect } from 'react-redux'

import { getOrderProductsWeight } from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

const mapStateToProps = (state: ReduxState): InfoFieldProps => ({
  id: 'productsWeight',
  name: 'Вес товаров (грамм)',
  value: getOrderProductsWeight(state).toString(),
})

const InfoProductsWeight = connect(mapStateToProps)(InfoField)

export default InfoProductsWeight
