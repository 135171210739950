import { produce } from 'immer'

import { CHANGE_LANG, INIT_INTL_LOCALE } from 'src/redux/action-types'

import en from 'src/const/intl/en'
import ru from 'src/const/intl/ru'

import countriesEn from 'src/const/intl/countries-en'
import countriesRu from 'src/const/intl/countries-ru'

import type { ComboboxOption } from 'src/types/fields'
import type { ReduxAction } from 'src/types/common'

export interface IntlLocaleData {
  locale: string
  messages: Record<string, string>
}

export interface LocaleMenuData {
  name: string
  abbr: string
  slug: string
}

export interface IntlState {
  defaultLocale: string
  locale: string
  messages: Record<string, string>
  list: Record<string, IntlLocaleData>
  langList: string[]
  langs: Record<string, LocaleMenuData>
  countries: ComboboxOption[]
}

const initialState: IntlState = {
  // Internationalization
  defaultLocale: 'en',
  locale: 'en',
  messages: {},
  list: {
    en,
    ru,
  },
  // Language menu
  langList: ['en', 'ru'],
  langs: {
    en: {
      name: 'English',
      abbr: 'Eng',
      slug: 'en',
    },
    ru: {
      name: 'Russian',
      abbr: 'Rus',
      slug: 'ru',
    },
  },
  // Countries list
  countries: countriesEn,
}

interface ActionHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (draft: IntlState, payload: any): void
}

const handlers: Record<string, ActionHandler | undefined> = {
  [INIT_INTL_LOCALE](draft: IntlState) {
    const language =
      (navigator.languages && navigator.languages[0]) || navigator.language

    const byDefault = draft.defaultLocale

    const lang =
      window.localStorage.getItem('locale') !== null
        ? window.localStorage.getItem('locale') || ''
        : language.split(/_-+/)[0]

    Object.assign(draft, draft.list[lang] || draft.list[byDefault])

    draft.countries = lang === 'ru' ? countriesRu : countriesEn
  },

  [CHANGE_LANG](draft: IntlState, locale: string) {
    const byDefault = draft.defaultLocale

    window.localStorage.setItem('locale', locale)

    Object.assign(draft, draft.list[locale] || draft.list[byDefault])

    draft.countries = locale === 'ru' ? countriesRu : countriesEn
  },
}

export default produce(function producer(
  draft: IntlState = initialState,
  action: ReduxAction
): IntlState {
  const handler = handlers[action.type]

  if (handler !== undefined) {
    handler(draft, action.payload)
  }

  return draft
})
