import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getCategories } from 'src/redux/selectors/admin-categories'

import { fetchCategoriesList } from 'src/redux/actions/admin-category'

import AdminCategory from './item'

import type { Category } from 'src/types/admin'

type StateProps = readonly Readonly<Category>[]

function AdminCategoriesView(): JSX.Element {
  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchCategoriesList())
    },
    [dispatch]
  )

  const categories: StateProps = useSelector(getCategories)

  return (
    <div className='container p-3'>
      <div className='d-flex align-items-center mb-2'>
        <h1 className='mr-2 mb-0'>Категории</h1>

        <div>
          <NavLink
            className='btn btn-outline-primary'
            to='/admin/categories/create'
          >
            Создать
          </NavLink>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Название</th>
              <th>Ссылка</th>
            </tr>
          </thead>

          <tbody>
            {categories.map(function (category): JSX.Element {
              return <AdminCategory key={category.id} category={category} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default React.memo(AdminCategoriesView)
