import type { Shipping } from 'src/const/shipping'

import type { OrderProduct } from 'src/types/admin'
import type { ApiAdminOrder } from 'src/types/api'

import type {
  FieldsPayload,
  ComboboxField,
  InputTextField,
} from 'src/types/fields'

export interface AdminOrderFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  id: number
  date: string
  locale: string
  email: string
  firstName: string
  lastName: string
  phone: string
  productsCost: number
  deliveryCost: number
  deliveryMethod: Shipping | ''
  deliveryCountry: string
  deliveryCity: string
  deliveryAddress: string
  deliveryZip: string
  comment: string
  products: OrderProduct[]
  // Fields
  trackCode: InputTextField
  deliveryCostActual: InputTextField
  managerComment: InputTextField
  status: ComboboxField
}

export const initialFields: AdminOrderFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  id: 0,
  date: '',
  locale: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  productsCost: 0,
  deliveryCost: 0,
  deliveryMethod: '',
  deliveryCountry: '',
  deliveryCity: '',
  deliveryAddress: '',
  deliveryZip: '',
  comment: '',
  products: [],
  // Fields
  trackCode: {
    path: ['adminOrder', 'trackCode'],
    id: 'trackCode',
    label: 'Трек-код',
    value: '',
  },
  deliveryCostActual: {
    path: ['adminOrder', 'deliveryCostActual'],
    id: 'deliveryCostActual',
    type: 'number',
    label: 'Фактическая стоимость доставки',
    value: '',
  },
  managerComment: {
    path: ['adminOrder', 'managerComment'],
    id: 'managerComment',
    label: 'Комментарий менеджера',
    value: '',
  },
  status: {
    path: ['adminOrder', 'status'],
    id: 'status',
    label: 'Статус',
    value: '',
  },
}

export function fetchSuccess(
  draft: AdminOrderFieldsState,
  { order }: FieldsPayload<ApiAdminOrder>
): void {
  draft.isFetching = false
  draft.error = ''

  draft.id = order.id || 0
  draft.date = order.date || ''
  draft.locale = order.locale || ''
  draft.email = order.email || ''
  draft.firstName = order.first_name || ''
  draft.lastName = order.last_name || ''
  draft.phone = order.phone || ''
  draft.productsCost = parseFloat(order.products_cost || '0') || 0
  draft.deliveryCost = parseFloat(order.delivery_cost || '0') || 0
  draft.deliveryMethod = (order.delivery_method as Shipping) || ''
  draft.deliveryCountry = order.delivery_country || ''
  draft.deliveryCity = order.delivery_city || ''
  draft.deliveryAddress = order.delivery_address || ''
  draft.deliveryZip = order.delivery_zip || ''
  draft.comment = order.comment || ''
  draft.products = order.products || []

  draft.trackCode.value = order.track_code || ''
  draft.deliveryCostActual.value = order.delivery_cost_actual || ''
  draft.managerComment.value = order.manager_comment || ''
  draft.status.value = order.status || ''
}

export function reducerAdminClearOrderFields(
  draft: AdminOrderFieldsState,
  _payload: FieldsPayload
): void {
  Object.assign(draft, initialFields)
}
