import * as React from 'react'
import { Link } from 'react-router-dom'

import { roundToTwo } from 'src/utils/number'

import type { OrderProduct } from 'src/types/admin'
import type { ComboboxOption } from 'src/types/fields'

interface Props {
  product: Readonly<OrderProduct>
}

function Product({ product }: Props): JSX.Element {
  const [mods, price] = React.useMemo(
    function memo() {
      const mods: ComboboxOption[] = []

      let price = product.sale || product.price

      for (const parameter of product.parameters) {
        if (parameter.affects_price > 0) {
          price += parseFloat(parameter.price_delta.toString())
        }

        mods.push({
          name: parameter.name,
          value: parameter.value,
        })
      }

      return [mods, price]
    },
    [product]
  )

  return (
    <tr>
      <td>
        <Link to={`/admin/products/${product.id}/edit`}>{product.name}</Link>
      </td>
      <td>
        {mods.length > 0
          ? mods.map(function mapper(mod): JSX.Element {
              return (
                <div key={mod.name}>
                  {mod.name}:&nbsp;{mod.value}
                </div>
              )
            })
          : '—'}
      </td>
      <td>{price}&nbsp;руб.</td>
      <td>{product.count}</td>
      <td>{roundToTwo(price * product.count)}&nbsp;руб.</td>
    </tr>
  )
}

export default React.memo(Product)
