import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getCategories,
  getProductCounts,
  getProjectSelected,
  getCategorySelected,
} from 'src/redux/selectors/shop'

import { setShopSection } from 'src/redux/actions/shop'

import ShopSectionsItem from './item'

import * as styles from 'src/styles/shop-sections.module.css'

import type { ReduxState } from 'src/types/common'
import type { Project, ProductsCount, Category } from 'src/types/user'

interface StateProps {
  counts: ProductsCount
  categories: readonly Readonly<Category>[]
  projectSelected: number
  categorySelected: number
}

function selector(state: ReduxState): StateProps {
  return {
    counts: getProductCounts(state),
    categories: getCategories(state),
    projectSelected: getProjectSelected(state),
    categorySelected: getCategorySelected(state),
  }
}

interface OwnProps {
  project: Readonly<Project>
}

function ShopSearchProject({ project }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onSelect = React.useCallback(
    function callback(projectId: number, categoryId: number) {
      dispatch(setShopSection(projectId, categoryId))
    },
    [dispatch]
  )

  const { counts, categories, projectSelected, categorySelected } = useSelector(
    selector,
    shallowEqual
  )

  const catList = React.useMemo<readonly Readonly<Category>[]>(
    function memo() {
      const assoc: Map<number, number> | undefined = counts.get(project.id)

      if (assoc === undefined) {
        return []
      }

      return categories.filter(function filter(category): boolean {
        return assoc.has(category.id)
      })
    },
    [project.id, counts, categories]
  )

  return (
    <div>
      <div className={styles.listName}>{project.name}</div>

      {catList.map(function mapper(category): JSX.Element {
        return (
          <ShopSectionsItem
            key={category.id}
            name={category.name}
            projectId={project.id}
            categoryId={category.id}
            selected={
              projectSelected === project.id && categorySelected === category.id
            }
            onSelect={onSelect}
          />
        )
      })}
    </div>
  )
}

export default React.memo(ShopSearchProject)
