import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { getFieldName } from 'src/redux/selectors/feedback-fields'

import InputText from 'src/components/common/input-text'

import * as styles from 'src/styles/feedback.module.css'
import * as utility from 'src/styles/utility.module.css'

function InputName(): JSX.Element {
  const field = useSelector(getFieldName)

  return (
    <div className={classnames(utility.mt2, styles.field)}>
      <label htmlFor={field.id}>
        <FormattedMessage id='your-name' defaultMessage='Your name' />
      </label>

      <InputText className={styles.input} field={field} />
    </div>
  )
}

export default React.memo(InputName)
