import * as React from 'react'

import LazyImage from 'src/components/lazyimage'

function HeaderImage(): JSX.Element {
  return (
    <div>
      <picture>
        <source srcSet='/img/header-small.png' media='(max-width: 768px)' />
        <source srcSet='/img/header.jpg' />

        <LazyImage src='/img/header.png' alt='Main image' />
      </picture>

      <link itemProp='image' href='/img/header.jpg' />
    </div>
  )
}

export default React.memo(HeaderImage)
