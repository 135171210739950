import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getProductsOptions,
} from 'src/redux/selectors/admin-products'

import { getIsFetching as getIsFetchingOrders } from 'src/redux/selectors/admin-orders'

import { getFieldProduct } from 'src/redux/selectors/admin-order-search'

import { fetchOrderList } from 'src/redux/actions/admin-order'

import InputSelect from 'src/components/common/input-select'

import type { ReduxState } from 'src/types/common'
import type { ComboboxField, ComboboxOption } from 'src/types/fields'

interface StateProps {
  field: Readonly<ComboboxField>
  options: readonly Readonly<ComboboxOption>[]
  disabled: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    field: getFieldProduct(state),
    options: getProductsOptions(state),
    disabled: getIsFetchingOrders(state) || getIsFetching(state),
  }
}

function SearchByProduct(): JSX.Element {
  const dispatch = useDispatch()

  const { search } = useLocation()

  const onChange = React.useCallback(
    function callback() {
      dispatch(fetchOrderList(search))
    },
    [search, dispatch]
  )

  const { field, options, disabled }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  return (
    <div className='col-sm-8'>
      <div className='row'>
        <label
          className='col-form-label col-sm-2 text-left text-md-right'
          htmlFor={field.id}
        >
          Товар
        </label>

        <div className='col-sm-10'>
          <InputSelect
            field={field}
            options={options}
            disabled={disabled}
            className='form-control'
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(SearchByProduct)
