import * as React from 'react'
import { useSelector } from 'react-redux'

import { getFilteredAndSortedProducts } from 'src/redux/selectors/shop'

import ShopProductsListItem from './products-list-item'

import * as styles from 'src/styles/shop.module.css'

import type { Product } from 'src/types/user'

type StateProps = readonly Readonly<Product>[]

function ShopProductsList(): JSX.Element {
  const products: StateProps = useSelector(getFilteredAndSortedProducts)

  return (
    <section
      className={styles.list}
      itemScope
      itemType='http://schema.org/OfferCatalog'
    >
      {products.map(function mapper(product): JSX.Element {
        return <ShopProductsListItem key={product.id} product={product} />
      })}
    </section>
  )
}

export default React.memo(ShopProductsList)
