import type { AdminOrderSearchFieldsState } from 'src/redux/reducers/admin-order-search'

import type { ReduxState } from 'src/types/common'
import type { InputTextField, ComboboxField } from 'src/types/fields'

function getState(state: ReduxState): AdminOrderSearchFieldsState {
  return state.fields.adminOrderSearch
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getFieldId(state: ReduxState): InputTextField {
  return getState(state).id
}

export function getFieldEmail(state: ReduxState): InputTextField {
  return getState(state).email
}

export function getFieldFirstName(state: ReduxState): InputTextField {
  return getState(state).firstName
}

export function getFieldLastName(state: ReduxState): InputTextField {
  return getState(state).lastName
}

export function getFieldCountry(state: ReduxState): InputTextField {
  return getState(state).country
}

export function getFieldShippingMethod(state: ReduxState): ComboboxField {
  return getState(state).deliveryMethod
}

export function getFieldStatus(state: ReduxState): ComboboxField {
  return getState(state).status
}

export function getFieldDate(state: ReduxState): InputTextField {
  return getState(state).date
}

export function getFieldProduct(state: ReduxState): ComboboxField {
  return getState(state).product
}
