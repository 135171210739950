import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import InputText from 'src/components/common/input-text'

import * as styles from 'src/styles/order-shipping.module.css'

import type { InputTextField } from 'src/types/fields'

export interface Props {
  field: Readonly<InputTextField>
  onChange?(): void
  onFocus?(): void
}

export default function InputTextComponent({
  field,
  onChange,
  onFocus,
}: Props): JSX.Element {
  return (
    <div className={styles.dataField}>
      <label htmlFor={field.id}>
        {field.label !== undefined && <FormattedMessage id={field.label} />}
      </label>

      <InputText
        field={field}
        className={styles.dataInput}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  )
}
