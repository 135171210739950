import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classnames from 'classnames'

import * as styles from 'src/styles/forbidden.module.css'
import * as utility from 'src/styles/utility.module.css'

function ForbiddenView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'access-denied' })
    },
    [intl]
  )

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <h2 className={classnames(utility.defaultPageHeader, styles.header)}>
        403 Forbidden
      </h2>

      <h3 className={styles.subheader}>
        <FormattedMessage id='access-denied' defaultMessage='Access denied' />
      </h3>

      <p className={styles.text}>
        <FormattedMessage
          id='access-denied-details'
          defaultMessage='Access denied details'
        />
      </p>
    </div>
  )
}

export default React.memo(ForbiddenView)
