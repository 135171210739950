import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as showdown from 'showdown'
import classnames from 'classnames'

import Separator from 'src/components/separator'

import * as styles from 'src/styles/payment.module.css'
import * as utility from 'src/styles/utility.module.css'

const classList: readonly Readonly<[string, string]>[] = [
  ['ul', styles.list],
  ['li', styles.item],
] as const

const converter = new showdown.Converter({
  extensions: classList.map(function mapper([key, className]) {
    return {
      type: 'output',
      regex: new RegExp(`<${key}(.*?)>`, 'g'),
      replace: `<${key} class="${className}" $1>`,
    }
  }),
})

function PaymentInfoView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'payment' })
    },
    [intl]
  )

  const contentHtml = React.useMemo(
    function memo() {
      return {
        __html: converter.makeHtml(
          intl.formatMessage({ id: 'payment-info-content' })
        ),
      }
    },
    [intl]
  )

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <div className={styles.content}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage id='payment' defaultMessage='Payment' />
        </h2>

        <Separator />

        <div dangerouslySetInnerHTML={contentHtml} />
      </div>
    </div>
  )
}

export default React.memo(PaymentInfoView)
