import * as React from 'react'

import InputText from 'src/components/common/input-text'

import type { InputLinkField } from 'src/types/fields'

export interface AdminInputLinkProps {
  field: InputLinkField
  disabled: boolean
}

export default function AdminInputLink({
  field,
  disabled,
}: AdminInputLinkProps): JSX.Element {
  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={field.id}
      >
        {field.label}
      </label>

      <div className='input-group col-sm-10'>
        <div className='input-group-prepend'>
          <span className='input-group-text'>
            {`${window.location.protocol}//${window.location.host}${field.link}`}
          </span>
        </div>

        <InputText className='form-control' disabled={disabled} field={field} />
      </div>
    </div>
  )
}
