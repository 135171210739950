import * as React from 'react'
import { useSelector } from 'react-redux'

import { getError } from 'src/redux/selectors/auth'

interface Props {
  className: string
}

function ErrorMessage({ className }: Props): JSX.Element {
  const error: string = useSelector(getError)

  if (error !== '') {
    return <p className={className}>{error}</p>
  }

  return <></>
}

export default React.memo(ErrorMessage)
