import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { fetchCart } from 'src/redux/actions/cart'

import CartList from './cart-list'

import * as utility from 'src/styles/utility.module.css'

function CartView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'cart' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchCart())
    },
    [dispatch]
  )

  return (
    <div className={utility.defaultPage}>
      <CartList />
    </div>
  )
}

export default React.memo(CartView)
