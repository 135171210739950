import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { getFieldFirstName } from 'src/redux/selectors/personal-fields'

import InputText from 'src/components/common/input-text'

import * as styles from 'src/styles/personal-details.module.css'
import * as utility from 'src/styles/utility.module.css'

interface Props {
  disabled: boolean
}

function InputFirstName({ disabled }: Props): JSX.Element {
  const field = useSelector(getFieldFirstName)

  return (
    <div className={classnames(utility.mt2, styles.field)}>
      <label htmlFor={field.id}>
        <FormattedMessage id='first-name' defaultMessage='Firstname' />
      </label>

      <InputText className={styles.input} disabled={disabled} field={field} />
    </div>
  )
}

export default React.memo(InputFirstName)
