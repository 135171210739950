import * as React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getIsLoggedIn } from 'src/redux/selectors/auth'

// eslint-disable-next-line node/no-extraneous-import
import type { History } from 'history'

function RouteGuest(props: RouteProps): JSX.Element {
  const redirectTo = React.useMemo<History.LocationDescriptor>(
    function memo() {
      return {
        pathname: '/',
        state: { from: props.location },
      }
    },
    [props.location]
  )

  const isLoggedIn: boolean = useSelector(getIsLoggedIn)

  if (isLoggedIn) {
    return <Redirect to={redirectTo} />
  }

  return <Route {...props} />
}

export default React.memo(RouteGuest)
