import type { InputTextField, ComboboxField } from 'src/types/fields'

export interface AdminOrderSearchFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Fields
  id: InputTextField
  email: InputTextField
  firstName: InputTextField
  lastName: InputTextField
  country: InputTextField
  deliveryMethod: ComboboxField
  status: ComboboxField
  date: InputTextField
  product: ComboboxField
}

export const initialFields: AdminOrderSearchFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Fields
  id: {
    path: ['adminOrderSearch', 'id'],
    id: 'id',
    placeholder: '№',
    value: '',
  },
  email: {
    path: ['adminOrderSearch', 'email'],
    id: 'email',
    type: 'email',
    placeholder: 'user@domain.com',
    value: '',
  },
  firstName: {
    path: ['adminOrderSearch', 'firstName'],
    id: 'firstName',
    placeholder: 'Имя',
    value: '',
  },
  lastName: {
    path: ['adminOrderSearch', 'lastName'],
    id: 'lastName',
    placeholder: 'Фамилия',
    value: '',
  },
  country: {
    path: ['adminOrderSearch', 'country'],
    id: 'country',
    placeholder: 'Например: RU',
    value: '',
  },
  deliveryMethod: {
    path: ['adminOrderSearch', 'deliveryMethod'],
    id: 'deliveryMethod',
    placeholder: 'Любой',
    value: '',
  },
  status: {
    path: ['adminOrderSearch', 'status'],
    id: 'status',
    placeholder: 'Любой',
    value: '3',
  },
  date: {
    path: ['adminOrderSearch', 'date'],
    id: 'date',
    type: 'datetime-local',
    value: '',
  },
  product: {
    path: ['adminOrderSearch', 'product'],
    id: 'product',
    placeholder: 'Нет',
    value: '',
  },
}
