import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

// import { Shipping } from 'src/const/shipping'

import { getIsUnavailable } from 'src/redux/selectors/cart'

import {
  getDeliveryCost,
  // getDeliverySelectedMethod,
} from 'src/redux/selectors/delivery'

import { getPaymentCurrentUrl } from 'src/redux/selectors/order'

import { getOrderError, getOrderIntlError } from 'src/redux/selectors/order'

import { makeOrder } from 'src/redux/actions/order'

import Separator from 'src/components/separator'

import PaymentMethodsList from './methods-list'
import OrderPaymentComment from './order-comment'
import OrderPaymentFormSubmit from './form-submit'
import FormPayment from './form-payment'

import * as styles from 'src/styles/order-payment.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isError: boolean
  isRedirect: boolean
  paymentUrl: string
  deliveryCost: number
  // deliveryMethod: string
}

function selector(state: ReduxState): StateProps {
  return {
    isError: Boolean(getOrderIntlError(state) || getOrderError(state)),
    isRedirect: getIsUnavailable(state),

    paymentUrl: getPaymentCurrentUrl(state),

    // deliveryMethod: getDeliverySelectedMethod(state),
    deliveryCost: getDeliveryCost(state),
  }
}

function OrderPaymentView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function onLocaleChange() {
      document.title = intl.formatMessage({ id: 'payment-method' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  const onSubmit = React.useCallback(
    function onFormSubmit(event) {
      event.preventDefault()

      dispatch(makeOrder(event.target))
    },
    [dispatch]
  )

  const {
    isError,
    isRedirect,
    paymentUrl,
    // deliveryMethod,
    deliveryCost,
  }: StateProps = useSelector(selector, shallowEqual)

  if (isError) {
    return <Redirect to='error' />
  }

  if (isRedirect) {
    return <Redirect to={`/${intl.locale}/cart/`} />
  }

  if (deliveryCost === 0 /* && props.deliveryMethod !== Shipping.PickUp */) {
    return <Redirect to='shipping' />
  }

  return (
    <form
      method='post'
      action={paymentUrl}
      className={classnames(utility.defaultPage, styles.container)}
      onSubmit={onSubmit}
    >
      <div className={utility.mobileHide}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage
            id='order-placement'
            defaultMessage='Order placement'
          />
        </h2>

        <Separator />
      </div>

      <div className={styles.containerWide}>
        <PaymentMethodsList />

        <FormPayment />

        <OrderPaymentComment />
      </div>

      <OrderPaymentFormSubmit />
    </form>
  )
}

export default React.memo(OrderPaymentView)
