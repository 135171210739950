// TODO: markdown
export default `Ваши данные нужны нам лишь для того, чтобы отправить посылку. Но закон требует, чтобы вы и на это дали нам официальное разрешение.

## Соглашение на обработку персональных данных

В соответствии с требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» я выражаю согласие на обработку своих персональных данных администрацией ресурса shop.ice-pick.com без оговорок и ограничений, совершение с моими персональными данными действий, предусмотренных п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных», и подтверждаю, что, давая такое согласие, действую свободно, по своей воле и в своих интересах.

Согласие на обработку персональных данных дается мной в целях получения услуг, оказываемых ресурсом shop.ice-pick.com.

Перечень персональных данных, на обработку которых предоставляется согласие:

* Фамилия
* Имя
* Отчество
* место пребывания (страна, город, адрес, индекс)
* номера телефонов
* адреса электронной почты (E-mail)
* а также иные полученные от меня персональные данные.

Я выражаю свое согласие на осуществление со всеми указанными персональными данными следующих действий:

* сбор
* систематизация
* накопление
* хранение
* уточнение (обновление или изменение)
* использование
* обезличивание
* блокирование
* уничтожение
* а также осуществление любых иных действий с персональными данными в соответствии с действующим законодательством.

Обработка данных может осуществляться как с использованием средств автоматизации, так и без их использования (при неавтоматической обработке). При обработке персональных данных администрация ресурса shop.ice-pick.com не ограничена в применении способов их использования.

Данное согласие действует до даты его отзыва путем направления подписанного мною соответствующего письменного заявления, которое может быть направлено мной в адрес администрации ресурса shop.ice-pick.com по почте заказным письмом с уведомлением о вручении, либо вручено лично под расписку надлежаще уполномоченному представителю ресурса shop.ice-pick.com.

В случае получения моего письменного заявления об отзыве настоящего согласия на обработку персональных данных, администрация ресурса shop.ice-pick.com обязана прекратить их обработку и исключить персональные данные из базы данных, в том числе электронной, за исключением сведений о фамилии, имени, отчества.

Я осознаю, что проставление отметки «V» в поле слева от фразы «Принимаю условия „Соглашения на обработку персональных данных“» на сайте shop.ice-pick.com ниже текста настоящего Соглашения означает мое согласие с условиями, описанными в нём.`
