import * as React from 'react'

function SvgSocialTwitter(): JSX.Element {
  return (
    <svg viewBox='0 0 64 64'>
      <path d='M60.5,0H3.6C1.6,0,0.1,1.6,0.1,3.5v56.9c0,1.9,1.6,3.5,3.5,3.5h56.9c1.9,0,3.5-1.6,3.5-3.5V3.5 C64,1.6,62.4,0,60.5,0z M49.7,23l0,1.2c0,12-9.1,25.9-25.9,25.9c-5.1,0-9.9-1.5-13.9-4.1C10.6,46,11.3,46,12,46 c4.3,0,8.2-1.5,11.3-3.9c-4-0.1-7.3-2.7-8.5-6.3c0.6,0.1,1.1,0.2,1.7,0.2c0.8,0,1.6-0.1,2.4-0.3c-4.1-0.8-7.3-4.5-7.3-8.9v-0.1 c1.2,0.7,2.6,1.1,4.1,1.1c-2.4-1.6-4-4.4-4-7.6c0-1.7,0.5-3.2,1.2-4.6c4.5,5.5,11.2,9.1,18.7,9.5c-0.2-0.7-0.2-1.4-0.2-2.1 c0-5,4.1-9.1,9.1-9.1c2.6,0,5,1.1,6.6,2.9c2.1-0.4,4-1.1,5.8-2.2c-0.7,2.1-2.1,3.9-4,5c1.8-0.2,3.6-0.7,5.2-1.4 C53,20.1,51.4,21.7,49.7,23z' />
    </svg>
  )
}

export default React.memo(SvgSocialTwitter)
