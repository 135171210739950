import type { WishlistFieldsState } from 'src/redux/reducers/wishlist'

import type { ReduxState } from 'src/types/common'
import type { WishProductsMap, WishProduct } from 'src/types/user'

function getState(state: ReduxState): WishlistFieldsState {
  return state.fields.wishlist
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getProductList(state: ReduxState): readonly number[] {
  return getState(state).productList
}

export function getProductCount(state: ReduxState): number {
  return getProductList(state).length
}

export function getProducts(state: ReduxState): WishProductsMap {
  return getState(state).products
}

export function getProductById(
  state: ReduxState,
  productId: number
): Readonly<WishProduct> | undefined {
  return getState(state).products.get(productId)
}
