import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'

import { BACKEND_URL } from 'src/config'

import LazyImage from 'src/components/lazyimage'
import Price from 'src/components/price'
import PriceSchema from 'src/components/price-schema'

import IntlLink from 'src/components/common/intl-link'
import ButtonToCart from 'src/components/common/button-to-cart'
import ButtonToWishlist from 'src/components/common/button-to-wishlist'

import * as styles from 'src/styles/product.module.css'

import type { Product } from 'src/types/user'

interface Props {
  product: Readonly<Product>
}

function ProductSimilar({ product }: Props): JSX.Element {
  return (
    <article
      className={styles.similarItem}
      itemProp='itemListElement'
      itemScope
      itemType='http://schema.org/Product'
    >
      <IntlLink
        className={styles.similarItemImage}
        to={`/product/${product.link}`}
        itemProp='url'
      >
        <LazyImage
          src={`${BACKEND_URL}/img/products/thumb/${product.image}`}
          alt={product.name}
        />

        <link
          itemProp='image'
          href={`${BACKEND_URL}/img/products/${product.image}`}
        />
      </IntlLink>

      <IntlLink
        className={styles.similarItemName}
        to={`/product/${product.link}`}
        itemProp='name'
      >
        {product.name}
      </IntlLink>

      <div itemProp='offers' itemScope itemType='http://schema.org/Offer'>
        <div
          className={classnames(styles.similarItemInStock, {
            [styles.similarItemInStockOut]: !product.inStock,
          })}
        >
          <link
            itemProp='availability'
            href={
              product.inStock
                ? 'http://schema.org/InStock'
                : 'http://schema.org/OutOfStock'
            }
          />

          <FormattedMessage
            id={product.inStock ? 'in-stock' : 'out-of-stock'}
            defaultMessage='In stock / Out of stock'
          />
        </div>

        <div className={styles.similarItemPrice}>
          <span
            className={classnames({
              [styles.similarItemPriceSale]: product.sale !== null,
            })}
          >
            {product.sale !== null ? (
              <Price value={product.price} />
            ) : (
              <PriceSchema value={product.price} />
            )}
          </span>
          &nbsp;
          {product.sale !== null && <PriceSchema value={product.sale} />}
        </div>
      </div>

      <div className={styles.similarItemActions}>
        <ButtonToCart
          className={styles.similarItemActionsIcon}
          disabled={!product.inStock}
          productId={product.id}
        />

        <ButtonToWishlist
          className={styles.similarItemActionsIcon}
          productId={product.id}
        />
      </div>
    </article>
  )
}

export default React.memo(ProductSimilar)
