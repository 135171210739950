import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { changeInput } from 'src/redux/actions/fields'

import type { ComboboxField, ComboboxOption } from 'src/types/fields'

interface OwnProps {
  field: Readonly<ComboboxField>
  options: readonly Readonly<ComboboxOption>[]
  disabled?: boolean
  className?: string
  onChange?(value: string): void
}

export default function InputSelectIntl({
  field,
  options,
  disabled,
  onChange,
  ...props
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onSelectChange = React.useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >(
    function callback({ target: { value } }) {
      dispatch(
        changeInput({
          path: field.path,
          value,
        })
      )

      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [field.path, onChange, dispatch]
  )

  const intl = useIntl()

  return (
    <select
      {...props}
      id={field.id}
      value={field.value}
      disabled={field.disabled || disabled}
      required={field.required}
      onChange={onSelectChange}
      onBlur={onSelectChange}
    >
      {field.placeholder !== undefined && (
        <option value=''>
          {intl.formatMessage({ id: field.placeholder })}
        </option>
      )}

      {options.map(function mapper(item): JSX.Element {
        return (
          <option key={item.value} value={item.value}>
            {intl.formatMessage({ id: item.name })}
          </option>
        )
      })}
    </select>
  )
}
