import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getProjects } from 'src/redux/selectors/shop'

import Separator from 'src/components/separator'
import ProjectsListItem from './projects-list-item'

import * as styles from 'src/styles/home.module.css'

import type { Project } from 'src/types/user'

type StateProps = readonly Readonly<Project>[]

function ProjectsList(): JSX.Element {
  const projects: StateProps = useSelector(getProjects)

  return (
    <div
      className={styles.projects}
      itemScope
      itemType='http://schema.org/OfferCatalog'
    >
      <h2 className={styles.header} itemProp='name'>
        <FormattedMessage id='projects' defaultMessage='Projects' />
      </h2>

      <Separator />

      <section className={styles.projectsContainer}>
        {projects.map(function mapper(project): JSX.Element {
          return <ProjectsListItem key={project.id} project={project} />
        })}
      </section>
    </div>
  )
}

export default React.memo(ProjectsList)
