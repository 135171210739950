import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { sendMessage } from 'src/redux/actions/feedback'

import Separator from 'src/components/separator'

import InputName from './input-name'
import InputEmail from './input-email'
import InputMessage from './input-message'
import ButtonSubmit from './button-submit'

import * as styles from 'src/styles/feedback.module.css'
import * as utility from 'src/styles/utility.module.css'

function FeedbackView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'feedback' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(sendMessage())
    },
    [dispatch]
  )

  return (
    <form
      className={classnames(utility.defaultPage, styles.container)}
      onSubmit={onSubmit}
    >
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage id='feedback' defaultMessage='Feedback' />
      </h2>

      <Separator />

      <InputName />

      <InputEmail />

      <InputMessage />

      <ButtonSubmit />
    </form>
  )
}

export default React.memo(FeedbackView)
