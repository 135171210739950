import type {
  Product,
  ShopProduct,
  Category,
  Project,
  ProductsCount,
  ProductParameter,
  ProductParametersMap,
} from 'src/types/user'

import type {
  TextField,
  NumberField,
  ComboboxField,
  InputTextField,
  InputCheckboxField,
  FieldsPayload,
  FieldLoading,
} from 'src/types/fields'

import type {
  ApiShopProduct,
  ApiShopProducts,
  ApiResponseShop,
} from 'src/types/api'

/*
function getOverlayVisibility () {
  const visibility = window.localStorage.getItem('overlayVisibility')

  return visibility === null
    ? true
    : Boolean(visibility)
}
*/

export interface ShopFieldsState {
  // Request
  /**
   * Initial is true (loading screen)
   */
  isFetching: boolean
  error: string
  // Data
  counts: ProductsCount
  projectSelected: number
  categorySelected: number
  categories: Category[]
  projects: Project[]
  product: ShopProduct
  similar: Product[]
  products: Product[]
  week: Product[]
  // Fields
  overlayVisibility: InputCheckboxField
  currentImage: TextField
  currencyUsd: NumberField
  navigation: TextField
  sorting: ComboboxField
  search: InputTextField & FieldLoading
}

export const initialFields: ShopFieldsState = {
  // Request
  isFetching: true,
  error: '',
  // Data
  counts: new Map(),
  projectSelected: 0,
  categorySelected: 0,
  categories: [
    /* {
      name: 'Футболки',
      link: 'shirts'
    },
    {
      name: 'Блокноты',
      link: 'notes'
    },
    {
      name: 'Кружки',
      link: 'mugs'
    },
    {
      name: 'Артбуки',
      link: 'artbooks'
    },
    {
      name: 'Цацки',
      link: 'tzatski'
    },
    {
      name: 'Кольца',
      link: 'rings'
    },
    {
      name: 'Куклы',
      link: 'dolls'
    },
    {
      name: 'Маски',
      link: 'masks'
    },
    {
      name: 'Фигурки',
      link: 'figures'
    },
    {
      name: 'Амулеты',
      link: 'amulets'
  } */
  ],
  projects: [
    /* {
      name: 'Мор',
      link: 'pathologic-2',
      image: '/img/project-pathologic-2-ru.png'
    },
    {
      name: 'Тургор',
      link: 'the-void',
      image: '/img/project-turgor-ru.png'
    },
    {
      name: 'Тук-тук-тук',
      link: 'knock-knock',
      image: '/img/project-ttt-ru.png'
    },
    {
      name: 'Ice-Pick Lodge',
      link: 'ice-pick-lodge',
      image: '/img/project-ipl-ru.png'
  } */
  ],
  product: {
    id: 0,
    project_id: 0,
    categories: [],
    images: [],
    image: '',
    name: '',
    link: '',
    desc: '',
    parameters: [],
    currentParameters: [],
    price: 0,
    inStock: false,
  },
  similar: [
    /* {
      name: 'Подушка “Woof”',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: true
    },
    {
      name: 'Подушка “Woof” с очень длинным названием, которое не должно влезть в одну строку',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: false
    },
    {
      name: 'Подушка “Woof”',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: true
  } */
  ],
  products: [
    /* {
      name: 'Подушка “Woof”',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: true
    },
    {
      name: 'Подушка “Woof” с очень длинным названием, которое не должно влезть в одну строку',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: false
    },
    {
      name: 'Подушка “Woof”',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: true
  } */
  ],
  week: [
    /* {
      name: 'Подушка “Woof” с очень длинным названием, которое не должно влезть в одну строку',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: true
    },
    {
      name: 'Подушка “Woof”',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: true
    },
    {
      name: 'Подушка “Woof”',
      link: 'product-1',
      image: '/img/product-placeholder.jpg',
      price: 500,
      inStock: true
  } */
  ],
  // Fields
  overlayVisibility: {
    path: ['shop', 'overlayVisibility'],
    checked: false, // getOverlayVisibility()
  },
  currentImage: {
    path: ['shop', 'currentImage'],
    value: '',
  },
  currencyUsd: {
    path: ['shop', 'currencyUsd'],
    value: 0,
  },
  navigation: {
    path: ['shop', 'navigation'],
    value: '',
  },
  sorting: {
    path: ['shop', 'sorting'],
    id: 'sorting',
    required: true,
    value: '',
  },
  search: {
    path: ['shop', 'search'],
    isFetching: false,
    id: 'search',
    value: '',
  },
}

export function authLogout(): void {
  // noop
}

export function shopInit(
  draft: ShopFieldsState,
  result: ApiResponseShop
): void {
  draft.isFetching = false
  draft.error = ''

  draft.categories = result.categories
  draft.projects = result.projects
  draft.week = result.week
}

export function fetchSuccess(
  draft: ShopFieldsState,
  { products }: FieldsPayload<ApiShopProducts>
): void {
  draft.isFetching = false
  draft.error = ''

  const counts: ProductsCount = new Map()

  for (const product of products) {
    product.price =
      typeof product.price === 'string'
        ? parseFloat(product.price)
        : product.price || 0

    if (product.sale !== undefined) {
      product.sale =
        typeof product.sale === 'string'
          ? parseFloat(product.sale)
          : product.sale || 0
    }

    let count = counts.get(product.project_id)

    if (count === undefined) {
      count = new Map()
      counts.set(product.project_id, count)
    }

    for (const id of product.categories) {
      const value = count.get(id)

      count.set(id, value === undefined ? 1 : value + 1)
    }
  }

  draft.products = products
  draft.counts = counts
  draft.projectSelected = 0
  draft.categorySelected = 0
}

export function reducerFetchShopProductDetails(
  draft: ShopFieldsState,
  { product, similar }: FieldsPayload<ApiShopProduct>
): void {
  draft.isFetching = false
  draft.error = ''

  const item: ShopProduct = { ...initialFields.product, ...product }

  if (product !== null) {
    item.currentParameters = item.parameters.reduce<ProductParametersMap>(
      function reducer(list, param) {
        list.push([param.id, param.values[0].id])

        return list
      },
      []
    )
  }

  draft.product = item
  draft.similar = similar !== null ? similar : []
  draft.currentImage.value = item.images.length > 0 ? item.images[0] : ''
}

interface SelectShopSection {
  categoryId: number
  projectId: number
}

export function reducerSelectShopSection(
  draft: ShopFieldsState,
  { projectId, categoryId }: FieldsPayload<SelectShopSection>
): void {
  if (
    draft.projectSelected === projectId &&
    draft.categorySelected === categoryId
  ) {
    draft.projectSelected = 0
    draft.categorySelected = 0
  } else {
    draft.projectSelected = projectId
    draft.categorySelected = categoryId
  }
}

function findCurrentParameter(
  list: ProductParametersMap,
  paramId: number
): [number, number] | undefined {
  for (const item of list) {
    if (item[0] === paramId) {
      return item
    }
  }
}

interface SelectProductParameterPayload {
  parameter: ProductParameter
  paramId: number
  valueId: number
}

export function reducerSelectProductParameter(
  draft: ShopFieldsState,
  { parameter, paramId, valueId }: FieldsPayload<SelectProductParameterPayload>
): void {
  const param = findCurrentParameter(draft.product.currentParameters, paramId)

  if (param === undefined) {
    draft.product.currentParameters.push([paramId, valueId])
  } else {
    param[1] = valueId
  }

  const value = parameter.values.find(function find({ id }) {
    return id === valueId
  })

  if (parameter.changes_image && value?.image) {
    draft.currentImage.value = value.image
  }

  if (parameter.affects_price && value?.price_delta) {
    draft.product.priceDelta = parseFloat(value.price_delta)
  }
}
