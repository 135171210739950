import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { roundToTwo } from 'src/utils/number'

import { getIsFetching, getDeliveryCost } from 'src/redux/selectors/delivery'

import { getCartCost } from 'src/redux/selectors/cart'

import { openBoxberryBySubmit } from 'src/redux/actions/boxberry'

import Price from 'src/components/price'

import SvgLoading from 'src/components/svg/loading'

import * as styles from 'src/styles/order-shipping.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  deliveryCost: number
  productsCost: number
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    deliveryCost: getDeliveryCost(state),
    productsCost: getCartCost(state),
  }
}

function DeliveryTotalCost(): JSX.Element {
  const dispatch = useDispatch()

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      dispatch(openBoxberryBySubmit(event))
    },
    [dispatch]
  )

  const { isFetching, deliveryCost, productsCost }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  const totalCost = React.useMemo<number>(
    function memo() {
      return roundToTwo(productsCost + deliveryCost)
    },
    [productsCost, deliveryCost]
  )

  return (
    <section className={styles.totalCost}>
      <div className={styles.totalCostContainer}>
        {isFetching ? (
          <div
            role='img'
            aria-label='Loading…'
            className={styles.totalCostLoading}
          >
            <SvgLoading />
          </div>
        ) : (
          <div>
            <div>
              <FormattedMessage
                id='shipping-cost'
                defaultMessage='Shipping cost'
              />
              &nbsp;
              <Price value={deliveryCost} />
            </div>

            <div className={styles.totalCostProducts}>
              <FormattedMessage
                id='products-cost'
                defaultMessage='Products cost'
              />
              &nbsp;
              <Price value={productsCost} />
            </div>
          </div>
        )}
      </div>

      <div className={styles.totalCostTotal}>
        <FormattedMessage id='total-cost' defaultMessage='Total cost' />
        &nbsp;
        <Price value={totalCost} />
      </div>

      <button
        className={styles.totalCostSubmit}
        disabled={isFetching}
        onClick={onSubmit}
        type='submit'
      >
        <FormattedMessage id='next' defaultMessage='Next' />
      </button>
    </section>
  )
}

export default React.memo(DeliveryTotalCost)
