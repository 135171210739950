import { Payment } from 'src/const/payment'

import type { TextField, FieldsPayload, InputTextField } from 'src/types/fields'

export interface OrderFieldsState {
  // Request
  isFetching: boolean
  intlError: string
  error: string
  // Data
  id: number
  amount: number
  // Robokassa
  signature: string
  expires: string
  // Paypal
  productsCost: number
  shippingCost: number
  /**
   * Status order after payment: `0` — unknown, `1` — success, `-1` — fail
   */
  status: number
  // Fields
  payment: TextField<Payment>
  comment: InputTextField
}

export const initialFields: OrderFieldsState = {
  // Request
  isFetching: false,
  intlError: '',
  error: '',
  // Data
  id: 0,
  amount: 0,
  // Robokassa
  signature: '',
  expires: '',
  // Paypal
  productsCost: 0,
  shippingCost: 0,
  // ???
  status: 0,
  // Fields
  payment: {
    path: ['order', 'payment'],
    value: Payment.Robokassa,
  },
  comment: {
    path: ['order', 'comment'],
    id: 'order-comment',
    value: '',
  },
}

export function fetchSuccess(
  draft: OrderFieldsState,
  order: FieldsPayload
): void {
  draft.isFetching = false
  draft.intlError = ''
  draft.error = ''

  draft.id = order.id
  draft.amount = order.amount
  draft.signature = order.signature || ''
  draft.expires = order.expires || ''
  draft.productsCost = order.products_cost || ''
  draft.shippingCost = order.shipping_cost || ''
}

export function reducerFetchOrderStatus(
  draft: OrderFieldsState,
  { success }: FieldsPayload<{ success: boolean }>
): void {
  draft.status = success ? 1 : -1
}
