import { connect } from 'react-redux'

import InputText, { AdminInputTextProps } from 'src/admin/common/input-text'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminCategory: { isFetching, name },
  },
}: ReduxState): AdminInputTextProps {
  return {
    disabled: isFetching,
    field: name,
  }
}

export default connect(mapStateToProps)(InputText)
