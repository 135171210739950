export function getFilesFromDir<T>(apiUrl: string, path: string): Promise<T> {
  return fetch(`${apiUrl}?path=${encodeURIComponent(path)}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  }).then<T>(function asJson(response) {
    return response.json()
  })
}

interface UploadFileProps {
  xhr: React.MutableRefObject<XMLHttpRequest | null>
  apiUrl: string
  path: string
  file: File
  thumb?: number
  setProgress: (percent: number) => void
}

export function uploadFile<T>({
  xhr,
  apiUrl,
  path,
  file,
  thumb,
  setProgress,
}: UploadFileProps): Promise<T> {
  return new Promise<T>(function executor(resolve, reject) {
    const data = new FormData()

    data.append('path', path)
    data.append('file', file)

    if (thumb !== undefined) {
      data.append('thumb', thumb.toString())
    }

    xhr.current = new XMLHttpRequest()

    const request = xhr.current

    request.open('POST', apiUrl, true)
    request.setRequestHeader('Accept', 'application/json')
    request.withCredentials = true

    request.upload.onprogress = function onprogress(event) {
      setProgress((event.loaded / event.total) * 100)
    }

    request.onerror = function onerror() {
      if (request.readyState !== request.DONE) {
        return
      }

      try {
        const response = JSON.parse(request.responseText)

        reject(response)
      } catch (err) {
        reject(err.message)
      }

      xhr.current = null
    }

    request.onload = function onload() {
      if (request.readyState !== request.DONE) {
        return
      }

      let response = {} as T

      try {
        response = JSON.parse(request.responseText)
      } catch (err) {
        reject(err.message)
      }

      resolve(response)

      xhr.current = null
    }

    request.send(data)
  })
}
