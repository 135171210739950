import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'

import {
  reducerAddProductToWishlist,
  reducerRemoveProductFromWishlist,
} from 'src/redux/reducers/wishlist'

import { getLocale } from 'src/redux/selectors/intl'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchComplete,
  callAction,
} from './fields'

import type { FieldsAction } from 'src/types/common'

import type {
  ApiResponseWishlist,
  ApiWishlistAddProduct,
  ApiWishlistRemoveProduct,
} from 'src/types/api'

const RESOURCE_URL = `${BACKEND_URL}/api/wishlist`

const path = ['wishlist'] as const

export function fetchWishlist(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState())

    return fetch(`${RESOURCE_URL}?locale=${locale}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiResponseWishlist>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchWishlist error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function addWishlistProduct(productId: number): FieldsAction {
  return function thunk(dispatch) {
    const data = new FormData()

    data.append('product', productId.toString())

    return fetch(RESOURCE_URL, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiWishlistAddProduct>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            callAction({
              ...result,
              action: getFunctionName({ reducerAddProductToWishlist }),
              path,
            })
          )
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('addWishlistProduct error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function removeWishlistProduct(productId: number): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        product: productId,
        action: getFunctionName({ reducerRemoveProductFromWishlist }),
        path,
      })
    )

    const data = new FormData()

    data.append('product', productId.toString())
    data.append('_method', 'DELETE')

    return fetch(`${RESOURCE_URL}/0`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiWishlistRemoveProduct>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            callAction({
              ...result,
              action: getFunctionName({ reducerRemoveProductFromWishlist }),
              path,
            })
          )
        } else {
          dispatch(fetchComplete({ path }))
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('removeWishlistProduct error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}
