import * as React from 'react'
import { useDispatch } from 'react-redux'

import { addCartProduct } from 'src/redux/actions/cart'

import SvgCart from 'src/components/svg/cart'

interface OwnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  productId: number
}

function ButtonToCart({ productId, ...props }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      dispatch(addCartProduct(productId))
    },
    [productId, dispatch]
  )

  return (
    <button {...props} aria-label='Add to cart' type='button' onClick={onClick}>
      <SvgCart />
    </button>
  )
}

export default React.memo(ButtonToCart)
