import * as React from 'react'
import { useIntl } from 'react-intl'

import CategoriesList from './categories-list'
// import Philosophy from './philosophy'
import ProjectsList from './projects-list'
import GoodsList from './goods-list'

function HomeView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'title' })
    },
    [intl]
  )

  return (
    <div>
      <GoodsList />

      <CategoriesList />

      <ProjectsList />
    </div>
  )
}

export default React.memo(HomeView)
