import * as React from 'react'
import { match } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getProjectId,
  getIsFetching,
} from 'src/redux/selectors/admin-project-edit'

import {
  saveProject,
  fetchProject,
  clearAdminProjectFields,
} from 'src/redux/actions/admin-project'

import AdminLocaleSwitch from 'src/admin/locale-switch'

import Headline from './headline'
import ErrorMessage from './error-message'
import InputName from './input-name'
import InputLink from './input-link'
import InputImage from './input-image'
import CheckboxVisible from './checkbox-visible'
import ButtonSave from './button-save'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  id: number
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    id: getProjectId(state),
  }
}

interface MatchProps {
  id: string
}

interface OwnProps {
  match: Readonly<match<MatchProps>>
}

function AdminProjectsEditView({ match }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const { id, isFetching } = useSelector(selector, shallowEqual)

  React.useEffect(
    function effect() {
      return function cleanup() {
        if (id !== 0) {
          dispatch(clearAdminProjectFields())
        }
      }
    },
    [id, dispatch]
  )

  const onProjectChange = React.useCallback(
    function callback() {
      if (match.params.id) {
        dispatch(fetchProject(match.params.id))
      }
    },
    [match.params.id, dispatch]
  )

  React.useEffect(onProjectChange, [onProjectChange])

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(saveProject())
    },
    [dispatch]
  )

  return (
    <div className='container p-3'>
      <Headline />

      <div className='mb-3 text-center'>
        <AdminLocaleSwitch disabled={isFetching} onChange={onProjectChange} />
      </div>

      <ErrorMessage />

      <form onSubmit={onSubmit}>
        <InputName />

        <InputLink />

        <InputImage />

        <CheckboxVisible />

        <ButtonSave />
      </form>
    </div>
  )
}

export default React.memo(AdminProjectsEditView)
