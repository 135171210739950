import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getCategories } from 'src/redux/selectors/shop'

import Separator from 'src/components/separator'

import CategoriesListItem from './categories-list-item'

import * as styles from 'src/styles/home.module.css'

import type { Category } from 'src/types/user'

type StateProps = readonly Readonly<Category>[]

function CategoriesList(): JSX.Element {
  const categories: StateProps = useSelector(getCategories)

  return (
    <div
      className={styles.categories}
      itemScope
      itemType='http://schema.org/OfferCatalog'
    >
      <h2 className={styles.header} itemProp='name'>
        <FormattedMessage id='categories' defaultMessage='Categories' />
      </h2>

      <Separator />

      <div className={styles.categoriesContent}>
        <section className={styles.categoriesContainer}>
          {categories.map(function mapper(category): JSX.Element {
            return <CategoriesListItem key={category.id} category={category} />
          })}
        </section>
      </div>
    </div>
  )
}

export default React.memo(CategoriesList)
