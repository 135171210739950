import * as React from 'react'
import { Link } from 'react-router-dom'

import { getCountryNameFromList } from 'src/utils/order-info'

import { shippingMethodAsString } from 'src/const/shipping'
import { orderStatusAsString, orderStatusAsEmoji } from 'src/const/order-status'

import countriesRu from 'src/const/intl/countries-ru'

import type { OrderShort } from 'src/types/admin'

interface Props {
  item: Readonly<OrderShort>
}

function AdminOrdersItem({ item }: Props): JSX.Element {
  return (
    <tr>
      <td>
        <Link to={`/admin/orders/${item.id}/edit`}>{item.id}</Link>
      </td>

      <td>{item.email}</td>

      <td>{item.first_name}</td>

      <td>{item.last_name}</td>

      <td>
        <b>{item.country}</b> (
        {getCountryNameFromList(item.country, countriesRu)})
      </td>

      <td>{shippingMethodAsString(item.method)}</td>

      <td>
        {orderStatusAsEmoji(item.status)}

        {orderStatusAsString(item.status)}
      </td>

      <td>{item.date}</td>
    </tr>
  )
}

export default React.memo(AdminOrdersItem)
