import * as React from 'react'
import { useSelector } from 'react-redux'

import { getOrderComment } from 'src/redux/selectors/admin-order'

const styleComment: React.HTMLAttributes<HTMLPreElement>['style'] = {
  font: 'inherit',
} as const

function InfoComment(): JSX.Element {
  const comment: string = useSelector(getOrderComment)

  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label alert-secondary'
        htmlFor='comment'
      >
        Комментарий
      </label>

      <pre className='col-sm-10 col-form-label bg-light' style={styleComment}>
        {comment || '—'}
      </pre>
    </div>
  )
}

export default React.memo(InfoComment)
