import * as React from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
  children: React.ReactElement
}

function ScrollToTop({ children }: Props): JSX.Element {
  const location = useLocation()
  const [, setLocation] = React.useState(location)

  React.useEffect(
    function effect() {
      setLocation(function set(prevLocation) {
        if (location !== prevLocation) {
          if (location.hash !== '') {
            const id = location.hash.substr(1)

            window.setTimeout(function timer() {
              const element = document.getElementById(id)

              if (element !== null) {
                element.scrollIntoView()
              }
            }, 0)
          } else {
            const header = document.getElementById('primary-header')
            const panel = document.getElementById('primary-header-panel')

            const offset =
              header !== null && panel !== null
                ? header.offsetHeight - panel.offsetHeight
                : 0

            window.scrollTo(0, offset)
          }
        }

        return location
      })
    },
    [location]
  )

  return children
}

export default React.memo(ScrollToTop)
