import { connect } from 'react-redux'

import ErrorMessage, { ErrorMessageProps } from 'src/admin/error-message'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminCategory: { error },
  },
}: ReduxState): ErrorMessageProps {
  return {
    error,
  }
}

export default connect(mapStateToProps)(ErrorMessage)
