import * as React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { convertPrice } from 'src/utils/currency'

import { getCurrencyUsd } from 'src/redux/selectors/shop'
import { getLocale } from 'src/redux/selectors/intl'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  locale: string
  currency: number
}

function selector(state: ReduxState): StateProps {
  return {
    locale: getLocale(state),
    currency: getCurrencyUsd(state),
  }
}

interface OwnProps {
  value?: string | number
}

function Price({ value }: OwnProps): JSX.Element {
  const { currency, locale }: StateProps = useSelector(selector, shallowEqual)

  value = parseFloat(value as string)

  if (!value) {
    return (
      <FormattedMessage
        id='price-unavailable'
        defaultMessage='Price is unavailable'
      />
    )
  }

  if (locale === 'ru') {
    return (
      <>
        <FormattedNumber value={value} /> руб.
      </>
    )
  }

  if (currency === 0) {
    return (
      <FormattedNumber
        currencyDisplay='symbol'
        currency='RUB'
        style='currency'
        value={value}
      />
    )
  }

  return (
    <FormattedNumber
      minimumFractionDigits={2}
      currencyDisplay='symbol'
      currency='USD'
      style='currency'
      value={convertPrice(value, currency)}
    />
  )
}

export default React.memo(Price)
