export default `Your order will be processed and shipped in no more than a week. As soon as the parcel is on its way, we’ll send you an e-mail containing all the necessary tracking information. Estimated delivery time after that depends on your preferred method of shipping.

We ship orders to any country in the world. Please choose your preferred shipping method during the order placement. The shipping cost depends on your region and selected shipping option.

Shipping Options Available:

* Russian Post;
* courier company.

Shipping via Russian Post usually takes up to a month, depending on your region, the time of year, holidays, etc.

Our experience working with the Russian Post has been 100% positive. However, we disclaim all liability for any damages or losses caused by the post. if you have any concerns regarding the safety of your delivery, it can be insured for an additional fee. If you’d like to do that, please contact us at [shop@ice-pick.com](mailto:shop@ice-pick.com).

If you feel like you sorely need a particular exotic method of delivery and have something in mind, please contact us at[shop@ice-pick.com](mailto:shop@ice-pick.com). We’ll try to figure something out.`
