import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { BACKEND_URL } from 'src/config'

import { getProductById } from 'src/redux/selectors/wishlist'

import { removeWishlistProduct } from 'src/redux/actions/wishlist'

import LazyImage from 'src/components/lazyimage'
import Price from 'src/components/price'

import IntlLink from 'src/components/common/intl-link'
import ButtonToCart from 'src/components/common/button-to-cart'

import SvgDelete from 'src/components/svg/delete'

import * as styles from 'src/styles/wishlist.module.css'

import type { ReduxState } from 'src/types/common'
import type { WishProduct } from 'src/types/user'

interface OwnProps {
  id: number
}

function WishListItem({ id }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onRemove = React.useCallback<React.MouseEventHandler>(
    function callback() {
      dispatch(removeWishlistProduct(id))
    },
    [id, dispatch]
  )

  const product = useSelector(function selector(
    state: ReduxState
  ): Readonly<WishProduct> {
    return getProductById(state, id) as WishProduct
  })

  return (
    <article className={styles.item}>
      <IntlLink className={styles.itemImage} to={`/product/${product.link}`}>
        <LazyImage
          src={`${BACKEND_URL}/img/products/thumb/${product.image}`}
          alt={product.name}
        />
      </IntlLink>

      <div className={styles.itemInfo}>
        <IntlLink className={styles.itemName} to={`/product/${product.link}`}>
          {product.name}
        </IntlLink>

        <div
          className={classnames(styles.itemInStock, {
            [styles.itemInStockOut]: !product.inStock,
          })}
        >
          <FormattedMessage
            id={product.inStock ? 'in-stock' : 'out-of-stock'}
            defaultMessage='In stock / Out of stock'
          />
        </div>

        <div className={styles.itemControls}>
          <div className={styles.itemActions}>
            <button
              className={styles.itemButtonDelete}
              onClick={onRemove}
              type='button'
            >
              <SvgDelete />
            </button>

            <ButtonToCart
              className={styles.itemButtonCart}
              disabled={!product.inStock}
              productId={product.id}
            />
          </div>

          <div className={styles.itemPrice}>
            <div className={product.sale !== null ? styles.itemPriceSale : ''}>
              <Price value={product.price} />
            </div>

            {product.sale !== null && <Price value={product.sale} />}
          </div>
        </div>
      </div>
    </article>
  )
}

export default React.memo(WishListItem)
