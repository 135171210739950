import * as React from 'react'

import { BACKEND_URL } from 'src/config'

import LazyImage from 'src/components/lazyimage'

interface Props {
  image: string
  disabled: boolean
  onClick(): void
}

function VariantImage({ image, disabled, onClick }: Props): JSX.Element {
  return (
    <div className='img-thumbnail mt-3 mr-3' draggable>
      <LazyImage
        src={`${BACKEND_URL}/img/products/thumb/${image}`}
        alt={image}
      />

      <button
        className='close'
        disabled={disabled}
        onClick={onClick}
        type='button'
      >
        <span aria-hidden='true'>×</span>
      </button>
    </div>
  )
}

export default React.memo(VariantImage)
