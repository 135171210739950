import { connect } from 'react-redux'

import { getFieldLastName } from 'src/redux/selectors/delivery'

import InputText, { Props } from './input-text'

import type { ReduxState } from 'src/types/common'

function mapStateToProps(state: ReduxState): Pick<Props, 'field'> {
  return {
    field: getFieldLastName(state),
  }
}

export default connect(mapStateToProps)(InputText)
