import * as React from 'react'
import { Link } from 'react-router-dom'

import { ProjectStatus } from 'src/const/project-status'

import type { Project } from 'src/types/admin'

interface Props {
  item: Readonly<Project>
}

function AdminProjectsItem({ item }: Props): JSX.Element {
  return (
    <tr>
      <td>
        {item.status & ProjectStatus.Visible ? '' : '❌ '}

        <Link to={`/admin/projects/${item.id}/edit`}>{item.name}</Link>
      </td>

      <td>{item.link}</td>
    </tr>
  )
}

export default React.memo(AdminProjectsItem)
