import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getFieldZip } from 'src/redux/selectors/personal-fields'

import InputText from 'src/components/common/input-text'

import * as styles from 'src/styles/personal-details.module.css'

interface Props {
  disabled: boolean
}

function InputZip({ disabled }: Props): JSX.Element {
  const field = useSelector(getFieldZip)

  return (
    <div className={styles.field}>
      <label htmlFor={field.id}>
        <FormattedMessage id='zip' defaultMessage='Zip' />
      </label>

      <InputText className={styles.input} disabled={disabled} field={field} />
    </div>
  )
}

export default React.memo(InputZip)
