import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getProductId,
  getIsFetching,
  getFieldQuantity,
  getFieldQuantityHardset,
} from 'src/redux/selectors/admin-product-edit'

import { setIn } from 'src/redux/actions/fields'

import InputCheckbox from 'src/components/common/input-checkbox'

import type { ReduxState } from 'src/types/common'
import type { InputCheckboxField, InputNumberField } from 'src/types/fields'

interface StateProps {
  id: number
  field: Readonly<InputCheckboxField>
  quantity: Readonly<InputNumberField>
  disabled: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    id: getProductId(state),
    field: getFieldQuantityHardset(state),
    quantity: getFieldQuantity(state),
    disabled: getIsFetching(state),
  }
}

function CheckboxQuantityHarset(): JSX.Element {
  const dispatch = useDispatch()

  const { id, field, quantity, disabled } = useSelector(selector, shallowEqual)

  const onChange = React.useCallback(
    function callback(checked: boolean) {
      dispatch(setIn({ path: quantity.path, disabled: !checked }))
    },
    [quantity.path, dispatch]
  )

  if (id === 0) {
    return <></>
  }

  return (
    <div className='col-sm-9'>
      <div className='form-check'>
        <label className='form-check-label mt-2' htmlFor={field.id}>
          <InputCheckbox
            field={field}
            disabled={disabled}
            className='form-check-input'
            onChange={onChange}
          />

          {field.description}
        </label>
      </div>
    </div>
  )
}

export default React.memo(CheckboxQuantityHarset)
