import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { getIsFetching } from 'src/redux/selectors/auth'

import { restoreUser } from 'src/redux/actions/auth'

import Separator from 'src/components/separator'

import InputEmail from 'src/components/auth-common/input-email'
import ButtonSubmit from 'src/components/auth-common/button-submit'

import * as styles from 'src/styles/auth-restore.module.css'
import * as utility from 'src/styles/utility.module.css'

function RestoreView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'forgot-password' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  const onSubmit = React.useCallback<React.FormEventHandler>(
    function callback(event) {
      event.preventDefault()

      dispatch(restoreUser())
    },
    [dispatch]
  )

  const isFetching: boolean = useSelector(getIsFetching)

  return (
    <form
      className={classnames(styles.container, utility.defaultPage)}
      onSubmit={onSubmit}
    >
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage
          id='forgot-password'
          defaultMessage='Forgot password'
        />
      </h2>

      <Separator />

      <p className={styles.text}>
        <FormattedMessage
          id='forgot-password-details'
          defaultMessage='Forgot password details'
        />
      </p>

      <InputEmail
        disabled={isFetching}
        className={styles.field}
        inputClassName={styles.input}
      />

      <ButtonSubmit
        label='send'
        disabled={isFetching}
        className={styles.submit}
        loadingClassName={styles.submitLoading}
      />
    </form>
  )
}

export default React.memo(RestoreView)
