import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getFieldQuantity,
} from 'src/redux/selectors/admin-product-edit'

import InputNumber from 'src/components/common/input-number'

import InfoQuantity from './info-quantity'
import CheckboxQuantityHarset from './checkbox-quantity-hardset'

import type { ReduxState } from 'src/types/common'
import type { InputNumberField } from 'src/types/fields'

interface StateProps {
  disabled: boolean
  field: Readonly<InputNumberField>
}

function selector(state: ReduxState): StateProps {
  return {
    disabled: getIsFetching(state),
    field: getFieldQuantity(state),
  }
}

function InputQuantity(): JSX.Element {
  const { field, disabled } = useSelector(selector, shallowEqual)

  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label'
        htmlFor={field.id}
      >
        {field.label}
      </label>

      <InfoQuantity />

      <div className='col-sm-7 mt-3 mt-md-0'>
        <div className='row'>
          <div className='col-sm-3'>
            <InputNumber
              field={field}
              disabled={disabled}
              className='form-control'
              title={
                'Если вы хотите увеличить количество этого товара, то введите число и, после сохранения, оно прибавится к текущему количеству. Например: текущее количество 25, а вы хотите его довести до значения 75, тогда вам нужно ввести в это поле значение 50.\n\nЕсли вам нужно уменьшить количество товара, тогда вам следует ввести число со знаком минус. Например: текущее количество 500, а вам нужно получить 350, тогда вводите значение -150.'
              }
            />
          </div>

          <CheckboxQuantityHarset />
        </div>
      </div>
    </div>
  )
}

export default React.memo(InputQuantity)
