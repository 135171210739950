import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as showdown from 'showdown'
import classnames from 'classnames'

import Separator from 'src/components/separator'

import * as styles from 'src/styles/about.module.css'
import * as utility from 'src/styles/utility.module.css'

const classList: readonly Readonly<[string, string]>[] = [
  ['p', styles.text],
] as const

const converter = new showdown.Converter({
  openLinksInNewWindow: true,
  extensions: classList.map(function mapper([key, className]) {
    return {
      type: 'output',
      regex: new RegExp(`<${key}(.*?)>`, 'g'),
      replace: `<${key} class="${className}" $1>`,
    }
  }),
})

function AboutView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'about-us' })
    },
    [intl]
  )

  const { aboutHtml, wishlistHtml } = React.useMemo(
    function memo() {
      return {
        aboutHtml: {
          __html: converter.makeHtml(
            intl.formatMessage({ id: 'about-us-content' })
          ),
        },
        wishlistHtml: {
          __html: converter.makeHtml(
            intl.formatMessage({ id: 'about-wishlist-content' })
          ),
        },
      }
    },
    [intl]
  )

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <div className={styles.content}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage id='about-us' defaultMessage='About Us' />
        </h2>

        <Separator />

        <div dangerouslySetInnerHTML={aboutHtml} />

        <h3 className={utility.defaultPageHeader} id='wishlist'>
          <FormattedMessage
            id='about-wishlist-header'
            defaultMessage='Wishlist'
          />
        </h3>

        <div dangerouslySetInnerHTML={wishlistHtml} />
      </div>
    </div>
  )
}

export default React.memo(AboutView)
