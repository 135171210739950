// Auth
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

// Shop
export const SHOP_INIT = 'SHOP_INIT'

// Intl (i18n) and language
export const INIT_INTL_LOCALE = 'INIT_INTL_LOCALE'
export const CHANGE_LANG = 'CHANGE_LANG'

// Fields
export const FETCH_REQUEST = 'FETCH_REQUEST'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_FAILURE = 'FETCH_FAILURE'
export const FETCH_COMPLETE = 'FETCH_COMPLETE'

export const CALL_ACTION = 'CALL_ACTION'

export const SET_IN = 'SET_IN'

export const CHANGE_FILE = 'CHANGE_FILE'
export const CHANGE_LIST = 'CHANGE_LIST'
export const CHANGE_INPUT = 'CHANGE_INPUT'
export const CHANGE_CHECKBOX = 'CHANGE_CHECKBOX'
