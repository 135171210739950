import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { getProduct } from 'src/redux/selectors/shop'

import Price from 'src/components/price'
import PriceSchema from 'src/components/price-schema'

import * as styles from 'src/styles/product.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ShopProduct } from 'src/types/user'

function ProductPrice(): JSX.Element {
  const product: Readonly<ShopProduct> = useSelector(getProduct)

  const productPrice = React.useMemo<number>(
    function memo() {
      const price = product.price || 0

      if (product.priceDelta) {
        return price + product.priceDelta
      }

      return price
    },
    [product]
  )

  return (
    <div
      className={styles.stock}
      itemProp='offers'
      itemScope
      itemType='http://schema.org/Offer'
    >
      <div className={utility.f1}>
        <div
          className={classnames(styles.price, {
            [styles.priceSale]: product.sale !== null,
          })}
        >
          {product.sale !== null ? (
            <Price value={productPrice} />
          ) : (
            <PriceSchema value={productPrice} />
          )}
        </div>

        {product.sale !== null && (
          <div className={styles.price}>
            <PriceSchema value={product.sale} />
          </div>
        )}

        <div
          className={classnames(styles.inStock, {
            [styles.inStockOut]: !product.inStock,
          })}
        >
          <link
            itemProp='availability'
            href={
              product.inStock
                ? 'http://schema.org/InStock'
                : 'http://schema.org/OutOfStock'
            }
          />

          <FormattedMessage
            id={product.inStock ? 'in-stock' : 'out-of-stock'}
            defaultMessage='In stock / Out of stock'
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(ProductPrice)
