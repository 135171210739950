import { connect } from 'react-redux'

import { getOrderShippingZip } from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

const mapStateToProps = (state: ReduxState): InfoFieldProps => ({
  id: 'shippingZip',
  name: 'Индекс',
  value: getOrderShippingZip(state),
})

const InfoShippingZip = connect(mapStateToProps)(InfoField)

export default InfoShippingZip
