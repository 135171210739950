import * as React from 'react'
import { useIntl } from 'react-intl'
import { Link, LinkProps } from 'react-router-dom'

function IntlLink(props: LinkProps): JSX.Element {
  const intl = useIntl()

  const to = `/${intl.locale}${props.to}`

  return <Link {...props} to={to} />
}

export default React.memo(IntlLink)
