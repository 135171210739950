import { createSelector } from 'reselect'

import { shippingMethods, Shipping, ShippingMethod } from 'src/const/shipping'

import type { DeliveryFieldsState } from 'src/redux/reducers/delivery'

import type { ReduxState } from 'src/types/common'
import type { InputTextField, ComboboxField, TextField } from 'src/types/fields'

function getState(state: ReduxState): DeliveryFieldsState {
  return state.fields.delivery
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getDeliveryError(state: ReduxState): string {
  return getState(state).error
}

export function getFieldShipping(state: ReduxState): TextField<Shipping> {
  return getState(state).currentMethod
}

export function getDeliverySelectedMethod(state: ReduxState): Shipping {
  return getFieldShipping(state).value
}

export const getDeliveryCurrentMethod = createSelector(
  [getDeliverySelectedMethod],
  function getDeliveryCurrentMethod(selected): ShippingMethod {
    return shippingMethods[selected]
  }
)

export function getDeliveryCost(state: ReduxState): number {
  return getState(state).cost
}

export function getFieldEmail(state: ReduxState): InputTextField {
  return getState(state).email
}

export function getFieldFirstName(state: ReduxState): InputTextField {
  return getState(state).firstName
}

export function getFieldLastName(state: ReduxState): InputTextField {
  return getState(state).lastName
}

export function getFieldPhone(state: ReduxState): InputTextField {
  return getState(state).phone
}

export function getFieldCountry(state: ReduxState): ComboboxField {
  return getState(state).country
}

export function getFieldCity(state: ReduxState): InputTextField {
  return getState(state).city
}

export function getFieldAddress(state: ReduxState): InputTextField {
  return getState(state).address
}

export function getFieldZip(state: ReduxState): InputTextField {
  return getState(state).zip
}
