import * as React from 'react'

import Headline from './headline'
import InputFirstName from './input-first-name'
import InputLastName from './input-last-name'
import InputPhone from './input-phone'
import SelectCountry from './select-country'
import InputCity from './input-city'
import InputZip from './input-zip'
import InputAddress from './input-address'
import InputEmail from './input-email'

import * as styles from 'src/styles/order-shipping.module.css'

function RecipientData(): JSX.Element {
  return (
    <div className={styles.data}>
      <Headline />

      <div className={styles.dataContent}>
        <div className={styles.dataContainer}>
          <InputFirstName />

          <InputLastName />

          <InputPhone />

          <SelectCountry />
        </div>

        <div>
          <InputCity />

          <InputZip />

          <InputAddress />

          <InputEmail />
        </div>
      </div>
    </div>
  )
}

export default React.memo(RecipientData)
