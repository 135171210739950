import * as React from 'react'

function SvgDelete(): JSX.Element {
  return (
    <svg viewBox='-27 27.9 512 512'>
      <path d='M307.5,188.2c-4.6-0.4-8.7,3.1-9.1,7.8l-16.1,209.5c-0.4,4.6,3.1,8.7,7.8,9.1h0c4.6,0.4,8.7-3.1,9.1-7.8 l16.1-209.5C315.6,192.6,312.1,188.5,307.5,188.2z' />

      <path d='M167.8,195.9c-0.4-4.6-4.4-8.1-9.1-7.8s-8.1,4.4-7.8,9.1l16.1,209.5c0.4,4.6,4.4,8.1,9.1,7.8h0 c4.6-0.4,8.1-4.4,7.8-9.1L167.8,195.9z' />

      <path d='M235.1,187.8c-4.6,0-8.4,3.8-8.4,8.4v210.2c0,4.6,3.8,8.4,8.4,8.4s8.4-3.8,8.4-8.4V196.3 C243.6,191.6,239.8,187.8,235.1,187.8z' />

      <path d='M410.5,150.2H303.8l-22.2-46.6c-1.3-3.5-4.5-6.1-8.3-6.6c0,0,0,0,0,0c-0.3,0-0.5-0.1-0.8-0.1 c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0h-77.5c-0.1,0-0.1,0-0.2,0c-4.1-0.2-8.1,2.1-10,6l-22.6,47.3H55.7c-6.1,0-11.2,5-11.2,11.2 v0c0,6.1,5,11.2,11.2,11.2h26.1l20.8,270.6c0.4,5.5,4.9,9.8,10.2,10.3v0h244.6v-0.1c5.2-0.6,9.5-4.8,9.9-10.2l20.8-270.6h22.3 c6.1,0,11.2-5,11.2-11.2v0C421.6,155.2,416.6,150.2,410.5,150.2z M201.1,119.2h63.2l14.8,31h-92.8L201.1,119.2z M345.9,431.1H124 l-19.9-258.6h46.8h24.7h114.1h24.7h51.3L345.9,431.1z' />
    </svg>
  )
}

export default React.memo(SvgDelete)
