import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import {
  getCartCost,
  getProducts,
  getIsFetching,
  getProductList,
  getUnavailable,
} from 'src/redux/selectors/cart'

import IntlLink from 'src/components/common/intl-link'

import Separator from 'src/components/separator'
import LoadingPage from 'src/components/loading'
import Price from 'src/components/price'

import CartListItem from './cart-list-item'

import * as styles from 'src/styles/cart.module.css'

import type { ReduxState } from 'src/types/common'
import type { CartProduct, OrderProductsMap } from 'src/types/user'

interface StateProps {
  isFetching: boolean
  productList: readonly Readonly<CartProduct>[]
  products: Readonly<OrderProductsMap>
  unavailable: readonly number[]
  total: number
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    productList: getProductList(state),
    products: getProducts(state),
    unavailable: getUnavailable(state),
    total: getCartCost(state),
  }
}

function CartList(): JSX.Element {
  const {
    isFetching,
    productList,
    products,
    unavailable,
    total,
  }: StateProps = useSelector(selector, shallowEqual)

  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback(event) {
      if (unavailable.length > 0) {
        event.preventDefault()
      }
    },
    [unavailable.length]
  )

  const list = React.useMemo<readonly Readonly<CartProduct>[]>(
    function memo() {
      return productList.filter(function filter({ id }): boolean {
        return products.has(id)
      })
    },
    [productList, products]
  )

  return (
    <div>
      <h2 className={styles.header}>
        <FormattedMessage id='cart' defaultMessage='Cart' />
      </h2>

      <Separator />

      {isFetching ? (
        <LoadingPage />
      ) : unavailable.length > 0 ? (
        <div className={styles.unavailable}>
          <FormattedMessage
            id='cart-unavailable'
            defaultMessage='Cart is unavailable'
          />
        </div>
      ) : (
        productList.length === 0 && (
          <p className={styles.empty}>
            <FormattedMessage id='cart-empty' defaultMessage='Cart is empty' />
          </p>
        )
      )}

      {!isFetching && productList.length > 0 && (
        <div>
          <section className={styles.content}>
            {list.map(function mapper(product): JSX.Element {
              return (
                <CartListItem
                  key={product.id}
                  id={product.id}
                  count={product.count}
                  parameters={product.parameters}
                  unavailable={unavailable.includes(product.id)}
                />
              )
            })}
          </section>

          <div className={styles.total}>
            <FormattedMessage id='total-cost' defaultMessage='Total cost' />{' '}
            <Price value={total} />
          </div>

          <div className={styles.submitContainer}>
            <IntlLink
              to='/order/shipping'
              className={styles.submit}
              onClick={onClick}
            >
              <FormattedMessage id='place-order' defaultMessage='Place order' />
            </IntlLink>
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(CartList)
