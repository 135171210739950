import * as React from 'react'

function SvgSocialYoutube(): JSX.Element {
  return (
    <svg viewBox='0 0 64 64'>
      <path d='M27,48.9c0,1.3,0.1,1.9,0,2.2c-0.3,0.7-1.4,1.4-1.8,0.1c-0.1-0.3,0-0.9,0-2.2v-9h-3.1l0,8.9c0,1.3,0,2.4,0,2.8 c0.1,0.8,0,1.8,0.8,2.3c1.4,1,4.1-0.2,4.8-1.6v1.8h2.5V40H27V48.9z' />

      <path d='M35.2,40.5l0-5.5H32v19.1l2.6-0.1l0.2-1.2c3.3,3,5.3,0.9,5.3-2.7v-7.5C40.2,40,38,38.3,35.2,40.5z M37.8,50.8 c0,0.6-0.6,1.1-1.4,1.1c-0.8,0-1.4-0.5-1.4-1.1v-8.3c0-0.6,0.6-1.1,1.4-1.1c0.8,0,1.4,0.5,1.4,1.1V50.8z' />

      <path d='M31.5,24.2c0.8,0,1.4-0.7,1.4-1.5v-7.9c0-0.8-0.6-1.5-1.4-1.5c-0.8,0-1.4,0.7-1.4,1.5v7.9 C30.1,23.5,30.7,24.2,31.5,24.2z' />

      <polygon points='22.7,35 12.7,35 12.7,37.5 15.8,37.5 15.8,54.3 18.9,54.3 18.9,54.3 18.9,37.5 22.7,37.5' />

      <path d='M60.5,0H3.6C1.6,0,0.1,1.6,0.1,3.5v56.9c0,1.9,1.6,3.5,3.5,3.5h56.9c1.9,0,3.5-1.6,3.5-3.5V3.5 C64,1.6,62.4,0,60.5,0z M37.6,11.4h2.8v11.5c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.2-0.5,1.2-1.1V11.4h2.7v14.8H42L42,25 c-0.3,0.5-0.5,0.9-0.9,1.1c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.9-0.1-1.3-0.4c-0.3-0.2-0.6-0.6-0.8-0.9c-0.1-0.4-0.3-0.8-0.3-1.2 c0-0.4-0.1-1.3-0.1-2.6V11.4z M29,11.5c0.6-0.5,1.4-0.7,2.4-0.7c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1,0.7,1.3,1.2 c0.3,0.5,0.6,1,0.7,1.5c0.1,0.5,0.2,1.3,0.2,2.4v3.8c0,1.4,0,2.4-0.2,3c-0.1,0.6-0.3,1.2-0.7,1.8c-0.3,0.6-0.8,1-1.3,1.2 c-0.5,0.3-1.2,0.4-1.9,0.4c-0.8,0-1.4-0.1-2-0.3c-0.6-0.2-1-0.6-1.3-1c-0.3-0.4-0.5-1-0.6-1.6c-0.1-0.7-0.2-1.6-0.2-2.9v-3.9h0 c0-1.4,0.1-2.5,0.4-3.3C28,12.7,28.4,12,29,11.5z M19.9,5.5l2.1,7.2L24,5.6h3.6l-3.9,9.7l0,11.4h-3.3V15.3l-4.1-9.8H19.9z M54.5,52.5c0,3.8-3.3,7-7.3,7H16.9c-4.1,0-7.4-3.1-7.4-7V36.6c0-3.8,3.3-6.9,7.4-6.9h30.2c4.1,0,7.3,3.1,7.3,6.9V52.5z' />

      <path d='M47.6,49.3v1.6c0,0.7-0.6,1.3-1.3,1.3h-0.4c-0.7,0-1.3-0.6-1.3-1.3v-3.5h5.4v-2c0-1.4-0.1-3-0.2-3.8 c-0.4-2.7-4.1-3.1-6-1.7c-0.6,0.4-1.1,1-1.3,1.8c-0.3,0.8-0.4,1.8-0.4,3.2v4.4c0,7.4,9,6.3,7.9,0L47.6,49.3z M44.6,43.3 c0-0.8,0.6-1.4,1.4-1.4h0.2c0.8,0,1.4,0.6,1.4,1.4L47.6,45h-3L44.6,43.3z' />
    </svg>
  )
}

export default React.memo(SvgSocialYoutube)
