import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getNavigationLinks } from 'src/redux/selectors/shop'

import IntlLink from 'src/components/common/intl-link'

import * as styles from 'src/styles/shop-header.module.css'

import type { ReduxState } from 'src/types/common'

import type {
  ShopNavigationLink,
  ShopNavigationLinkItem,
} from 'src/types/navigation'

interface OwnProps {
  item: Readonly<ShopNavigationLink>
  current: boolean
  onSelect(path: string): void
}

type StateProps = readonly Readonly<ShopNavigationLinkItem>[]

function ShopNavigationItem({
  item,
  current,
  onSelect,
}: OwnProps): JSX.Element {
  const onClick = React.useCallback<React.MouseEventHandler>(
    function callback() {
      onSelect(item.path)
    },
    [item.path, onSelect]
  )

  const links = useSelector(function selector(state: ReduxState): StateProps {
    return getNavigationLinks(state, item.path)
  })

  return (
    <button
      aria-haspopup='true'
      aria-expanded={current}
      className={styles.navItem}
      onClick={onClick}
      type='button'
    >
      <FormattedMessage id={item.name} defaultMessage='Navigation section' />

      {current && (
        <div className={styles.navItemLinks}>
          {links.map(function mapper(obj): JSX.Element {
            return (
              <IntlLink
                key={obj.link}
                role='menuitem'
                className={styles.navItemLinksItem}
                to={`/${item.link}/${obj.link}`}
              >
                {obj.name}
              </IntlLink>
            )
          })}
        </div>
      )}
    </button>
  )
}

export default React.memo(ShopNavigationItem)
