import * as React from 'react'
import { produce } from 'immer'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import {
  getIsFetching,
  getFieldVariants,
} from 'src/redux/selectors/admin-product-edit'

import { changeList } from 'src/redux/actions/fields'

import VariantValue from './variant-value'

import type { ListField } from 'src/types/fields'
import type { ReduxState } from 'src/types/common'
import type { ProductEditVariant } from 'src/types/admin'

interface StateProps {
  disabled: boolean
  variants: ListField<ProductEditVariant>
}

function selector(state: ReduxState): StateProps {
  return {
    variants: getFieldVariants(state),
    disabled: getIsFetching(state),
  }
}

interface OwnProps {
  variant: ProductEditVariant
  paramId: number
}

function Variant({ variant, paramId }: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const { variants, disabled }: StateProps = useSelector(selector, shallowEqual)

  const updateParameters = React.useCallback(
    function callback(list: ProductEditVariant[]) {
      dispatch(
        changeList({
          path: variants.path,
          list,
        })
      )
    },
    [variants.path, dispatch]
  )

  const removeParameter = React.useCallback(
    function callback() {
      if (
        window.confirm(
          'Вы действительно хотите удалить данный параметр?\nВосстановление будет невозможно!'
        )
      ) {
        const list = produce(variants.list, function producer(draft) {
          draft.splice(paramId, 1)
        })

        updateParameters(list)
      }
    },
    [paramId, variants.list, updateParameters]
  )

  const addValue = React.useCallback(
    function callback() {
      const value = produce(variants.list, function producer(draft) {
        draft[paramId].values.push({
          name: {
            value: '',
          },
          image: '',
          price_delta: 0,
        })
      })

      updateParameters(value)
    },
    [variants.list, paramId, updateParameters]
  )

  const changeName = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    function changeName({
      target: {
        value: nameValue,
        dataset: { param },
      },
    }) {
      if (param === undefined) {
        return
      }

      const list = produce(variants.list, function producer(draft) {
        const value = { value: nameValue }

        if (param === 'name') {
          if (typeof value === 'object') {
            if (typeof draft[paramId][param] !== 'object') {
              draft[paramId][param] = { value: '' }
            }

            draft[paramId][param] = Object.assign(draft[paramId][param], value)
          } else {
            draft[paramId][param] = value
          }
        }
      })

      updateParameters(list)
    },
    [paramId, variants.list, updateParameters]
  )

  const toggleParam = React.useCallback<React.ChangeEventHandler<HTMLElement>>(
    function toggleParam({
      target: {
        dataset: { param },
      },
    }) {
      if (param === undefined) {
        return
      }

      const list = produce(variants.list, function producer(draft) {
        const item = draft[paramId]

        if (param === 'changes_image' || param === 'affects_price') {
          item[param] = item[param] !== 0 ? 0 : 1
        }
      })

      updateParameters(list)
    },
    [paramId, variants.list, updateParameters]
  )

  return (
    <div className='form-inline'>
      <div className='form-group row w-100 mb-3'>
        <div className='col-sm-12 d-flex'>
          <span className='font-weight-bold mr-2 d-flex align-items-center'>
            Название параметра:
          </span>

          <input
            id={`variant-${paramId}-name`}
            data-param='name'
            className='form-control inline flex-fill'
            disabled={disabled}
            value={variant.name ? variant.name.value : ''}
            onChange={changeName}
          />

          <button
            className='btn btn-danger float-right ml-3'
            disabled={disabled}
            onClick={removeParameter}
            type='button'
          >
            Удалить параметр
          </button>
        </div>
      </div>

      <div className='form-group row w-100 mb-3'>
        <div className='col-sm-6'>
          <label>
            <input
              type='checkbox'
              disabled={disabled}
              data-param='changes_image'
              onChange={toggleParam}
              checked={variant.changes_image > 0}
            />
            &nbsp;Параметр изменяет изображение товара
          </label>
        </div>

        <div className='col-sm6'>
          <label>
            <input
              type='checkbox'
              disabled={disabled}
              data-param='affects_price'
              onChange={toggleParam}
              checked={variant.affects_price > 0}
            />
            &nbsp;Параметр влияет на цену
          </label>
        </div>
      </div>

      <table className='table table-bordered table-striped'>
        <thead>
          <tr>
            <th>Название варианта</th>

            {variant.changes_image > 0 && <th>Изображение</th>}

            {variant.affects_price > 0 && (
              <th>
                Изменение цены
                <br />
                (+ или - относительно текущей)
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {variant.values.map(function mapper(value, valueId): JSX.Element {
            return (
              <VariantValue
                key={valueId}
                valueId={valueId}
                paramId={paramId}
                affects_price={variant.affects_price}
                changes_image={variant.changes_image}
                value={value}
              />
            )
          })}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan={3}>
              <button
                className='btn btn-primary'
                disabled={disabled}
                onClick={addValue}
                type='button'
              >
                Добавить вариант
              </button>
            </td>
          </tr>
        </tfoot>
      </table>

      <hr />
    </div>
  )
}

export default React.memo(Variant)
