import type { ComboboxOption } from 'src/types/fields'

export function getCountryNameFromList(
  code: string,
  list: readonly Readonly<ComboboxOption>[]
): string {
  const country = list.find(function find({ value }): boolean {
    return value === code
  })

  return country !== undefined ? country.name : ''
}
