import * as React from 'react'
import { useSelector } from 'react-redux'

import { getOrderList } from 'src/redux/selectors/order-history'

import OrderHistoryListItem from './orders-list-item'

import * as styles from 'src/styles/order-history.module.css'

import type { OrderHistory } from 'src/types/user'

type StateProps = readonly Readonly<OrderHistory>[]

function OrderHistoryList(): JSX.Element {
  const orderList: StateProps = useSelector(getOrderList)

  return (
    <section className={styles.list}>
      {orderList.map(function mapper(order): JSX.Element {
        return <OrderHistoryListItem key={order.id} order={order} />
      })}
    </section>
  )
}

export default React.memo(OrderHistoryList)
