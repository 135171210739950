import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getIsFetching } from 'src/redux/selectors/feedback-fields'

import SvgLoading from 'src/components/svg/loading'

import * as styles from 'src/styles/feedback.module.css'

function ButtonSubmit(): JSX.Element {
  const isFetching: boolean = useSelector(getIsFetching)

  return (
    <button className={styles.submit} disabled={isFetching} type='submit'>
      {isFetching ? (
        <div role='img' aria-label='Loading…' className={styles.submitLoading}>
          <SvgLoading />
        </div>
      ) : (
        <FormattedMessage id='send' defaultMessage='Send' />
      )}
    </button>
  )
}

export default React.memo(ButtonSubmit)
