import * as React from 'react'
import { useSelector } from 'react-redux'

import { getOrderId } from 'src/redux/selectors/admin-order'

function Headline(): JSX.Element {
  const id: number = useSelector(getOrderId)

  return <h1 className='mb-3 text-center'>Заказ №{id > 0 ? id : '…'}</h1>
}

export default React.memo(Headline)
