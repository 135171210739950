import history from 'src/const/history'
import { BACKEND_URL } from 'src/config'

import { getFunctionName } from 'src/utils/object'
import { getLaravelErrorMessage } from 'src/utils/laravel'

import { reducerAdminClearProjectFields } from 'src/redux/reducers/admin-project'

import { getLocale } from 'src/redux/selectors/admin-locale'

import {
  getProjectId,
  getFieldName,
  getFieldLink,
  getFieldImage,
  getFieldVisible,
} from 'src/redux/selectors/admin-project-edit'

import {
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchComplete,
  callAction,
} from './fields'

import type { FieldsAction } from 'src/types/common'

import type {
  ServerSuccess,
  LaravelResponse,
  ApiAdminProject,
  ApiAdminProjects,
} from 'src/types/api'

const RESOURCE_URL = `${BACKEND_URL}/api/admin/projects`

const path = ['adminProject'] as const

export function saveProject(): FieldsAction {
  return function thunk(dispatch, getState) {
    const projectId = getProjectId(getState())

    const action = projectId === 0 ? createProject() : updateProject()

    dispatch(action)
  }
}

function createProject(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const locale = getLocale(state).value
    const name = getFieldName(state).value
    const link = getFieldLink(state).value
    const file = getFieldImage(state).file
    const visible = getFieldVisible(state).checked

    const data = new FormData()

    data.append('locale', locale)
    data.append('name', name)
    data.append('link', link)

    if (file !== null) {
      data.append('image', file)
    }

    if (visible) {
      data.append('visible', '1')
    }

    return fetch(RESOURCE_URL, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ApiAdminProject | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )

          history.push(`/admin/projects/${result.project.id}/edit`)
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('createProject error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

function updateProject(): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const state = getState()
    const projectId = getProjectId(state)
    const locale = getLocale(state).value
    const name = getFieldName(state).value
    const link = getFieldLink(state).value
    const file = getFieldImage(state).file
    const visible = getFieldVisible(state).checked

    const data = new FormData()

    data.append('id', projectId.toString())
    data.append('locale', locale)
    data.append('name', name)
    data.append('link', link)

    if (file !== null) {
      data.append('image', file)
    }

    if (visible) {
      data.append('visible', '1')
    }

    data.append('_method', 'PATCH')

    return fetch(`${RESOURCE_URL}/${projectId}`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: data,
    })
      .then<ServerSuccess | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(fetchComplete({ path }))
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('updateProject error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function fetchProject(projectId: string): FieldsAction {
  return function thunk(dispatch, getState) {
    dispatch(fetchRequest({ path }))

    const locale = getLocale(getState()).value

    return fetch(`${RESOURCE_URL}/${projectId}/edit?locale=${locale}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAdminProject | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path,
            })
          )
        } else {
          dispatch(
            fetchFailure({ path, error: getLaravelErrorMessage(result) })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchProject error:', err)

        dispatch(
          fetchFailure({
            path,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}

export function clearAdminProjectFields(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(
      callAction({
        action: getFunctionName({ reducerAdminClearProjectFields }),
        path,
      })
    )
  }
}

const pathAdminProjects = ['adminProjects'] as const

export function fetchProjectList(): FieldsAction {
  return function thunk(dispatch) {
    dispatch(fetchRequest({ path: pathAdminProjects }))

    return fetch(RESOURCE_URL, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then<ApiAdminProjects | LaravelResponse>(function onfulfilled(response) {
        return response.json()
      })
      .then(function onfulfilled(result) {
        if (result.success) {
          dispatch(
            fetchSuccess({
              ...result,
              path: pathAdminProjects,
            })
          )
        } else {
          dispatch(
            fetchFailure({
              path: pathAdminProjects,
              error: getLaravelErrorMessage(result),
            })
          )
        }

        return
      })
      .catch(function onrejected(err) {
        console.error('fetchProjectList error:', err)

        dispatch(
          fetchFailure({
            path: pathAdminProjects,
            error: err instanceof Error ? err.message : err,
          })
        )
      })
  }
}
