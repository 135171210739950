import type { AdminProductFieldsState } from 'src/redux/reducers/admin-product'

import type { ReduxState } from 'src/types/common'
import type { ProductEditVariant } from 'src/types/admin'

import type {
  ListField,
  ComboboxField,
  InputTextField,
  InputLinkField,
  InputNumberField,
  InputCheckboxField,
} from 'src/types/fields'

function getState(state: ReduxState): AdminProductFieldsState {
  return state.fields.adminProduct
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getProductId(state: ReduxState): number {
  return getState(state).id
}

export function getWishlistCount(state: ReduxState): number {
  return getState(state).wishlistCount
}

export function getFieldName(state: ReduxState): InputTextField {
  return getState(state).name
}

export function getFieldDesc(state: ReduxState): InputTextField {
  return getState(state).desc
}

export function getFieldLink(state: ReduxState): InputLinkField {
  return getState(state).link
}

export function getFieldVisible(state: ReduxState): InputCheckboxField {
  return getState(state).visible
}

export function getFieldProject(state: ReduxState): ComboboxField {
  return getState(state).project
}

export function getFieldCategory(state: ReduxState): ComboboxField {
  return getState(state).category
}

export function getCategories(state: ReduxState): readonly number[] {
  return getState(state).categories
}

export function getImages(state: ReduxState): readonly string[] {
  return getState(state).images
}

export function getFieldPrice(state: ReduxState): InputNumberField {
  return getState(state).price
}

export function getFieldSale(state: ReduxState): InputNumberField {
  return getState(state).sale
}

export function getFieldWeight(state: ReduxState): InputTextField {
  return getState(state).weight
}

export function getCurrentQuantity(state: ReduxState): number {
  return getState(state).currentQuantity
}

export function getFieldQuantity(state: ReduxState): InputNumberField {
  return getState(state).quantity
}

export function getFieldQuantityHardset(state: ReduxState): InputCheckboxField {
  return getState(state).quantityHardset
}

export function getFieldWeekSale(state: ReduxState): InputCheckboxField {
  return getState(state).weekSale
}

export function getFieldVariants(
  state: ReduxState
): ListField<ProductEditVariant> {
  return getState(state).variants
}
