import * as React from 'react'
import { match } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import { getProduct, getIsFetching } from 'src/redux/selectors/shop'

import { fetchProduct } from 'src/redux/actions/shop'

import LoadingPage from 'src/components/loading'
import ShopHeader from 'src/components/shop-header'

import ProductInfo from './product'
import ProductSimilars from './similars'
import ProductReviews from './reviews'

import * as styles from 'src/styles/product.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'
import type { Product } from 'src/types/user'

interface StateProps {
  product: Readonly<Product>
  isFetching: boolean
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    product: getProduct(state),
  }
}

interface MatchProps {
  link: string
}

interface OwnProps {
  match: match<MatchProps>
}

function ProductView({ match }: OwnProps): JSX.Element {
  const intl = useIntl()

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchProduct(match.params.link))
    },
    [intl.locale, match.params.link, dispatch]
  )

  const { product, isFetching }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  React.useEffect(
    function effect() {
      if (product.name !== undefined) {
        document.title = product.name
      } else {
        document.title = intl.formatMessage({ id: 'title' })
      }
    },
    [intl, product.name]
  )

  return (
    <div className={classnames(utility.defaultPage, styles.productPage)}>
      <ShopHeader />

      {isFetching ? (
        <LoadingPage />
      ) : (
        <>
          {product.id !== undefined && <ProductInfo />}

          <ProductSimilars />

          {product.id !== undefined && <ProductReviews />}
        </>
      )}
    </div>
  )
}

export default React.memo(ProductView)
