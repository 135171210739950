import type { AdminCategoryFieldsState } from 'src/redux/reducers/admin-category'

import type { ReduxState } from 'src/types/common'

import type {
  InputTextField,
  InputLinkField,
  InputCheckboxField,
} from 'src/types/fields'

function getState(state: ReduxState): AdminCategoryFieldsState {
  return state.fields.adminCategory
}

export function getIsFetching(state: ReduxState): boolean {
  return getState(state).isFetching
}

export function getCategoryId(state: ReduxState): number {
  return getState(state).id
}

export function getFieldName(state: ReduxState): InputTextField {
  return getState(state).name
}

export function getFieldLink(state: ReduxState): InputLinkField {
  return getState(state).link
}

export function getFieldVisible(state: ReduxState): InputCheckboxField {
  return getState(state).visible
}
