import { connect } from 'react-redux'

import { getOrderPhone } from 'src/redux/selectors/admin-order'

import InfoField, { InfoFieldProps } from './info-field'

import type { ReduxState } from 'src/types/common'

const mapStateToProps = (state: ReduxState): InfoFieldProps => ({
  id: 'phone',
  name: 'Номер телефона',
  value: getOrderPhone(state),
})

const InfoPhone = connect(mapStateToProps)(InfoField)

export default InfoPhone
