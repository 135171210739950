// TODO: mardown
export default `We only need your personal information to send the parcel. But for legal reasons we need an official permission from you to store and process it.

## Personal Information Processing Agreement

In keeping with the Russian Federation law № 152-ФЗ, “On personal information” (27.07.2006), I hereby agree to grant the staff of shop.ice-pick.com the rights to process my personal information without restriction and use it in the ways described in Section 3.1.3 of the Russian Federation law № 152-ФЗ, “On personal information” (27.07.2006). I hereby also confirm that I grant this permission freely, acting of my own will and interests.

I grant this permission in order to receive the services provided by shop.ice-pick.com.

The information that will be processed is as follows:

* First &amp; last name
* Patronymic (if available)
* Place of residence (country, city/town, address, zip code)
* Phone number
* E-mail
* Other personal data (if provided)

I hereby permit this information to be:

* collected
* catalogued
* stored
* held
* clarified (changed or refreshed)
* used
* depersonalized
* blocked
* deleted
* and manipulated in any other fashion in accordance with current law.

The information can be processed either procedurally or manually. The staff of shop.ice-pick.com is not limited in any way in choosing the way of processing said information.

This agreement remains in action until a written claim is sent to the staff of shop.ice-pick.com. The claim can be either sent by a double registered letter or delivered personally to an authorized representative of shop.ice-pick.com against signed ackonwledgement.

Upon receiving such a claim, the staff of shop.ice-pick.com is obliged to stop processing the data of the claimant and delete all their information from the database, including the digital one, save for the full name.

I understand that putting a “V” mark to the left of the phrase “I agree to the terms of the Personal Information Processing Agreement” below this box means that I agree to them.`
