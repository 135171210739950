import { connect } from 'react-redux'

import Textarea, { AdminTextareaProps } from 'src/admin/common/textarea'

import type { ReduxState } from 'src/types/common'

function mapStateToProps({
  fields: {
    adminOrder: { isFetching, managerComment },
  },
}: ReduxState): AdminTextareaProps {
  return {
    disabled: isFetching,
    field: managerComment,
  }
}

export default connect(mapStateToProps)(Textarea)
