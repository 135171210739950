import { ProductStatus } from 'src/const/product-status'

import type {
  FieldsPayload,
  InputTextField,
  InputNumberField,
  InputLinkField,
  InputCheckboxField,
  ComboboxField,
  ListField,
} from 'src/types/fields'

import type { ProductEditVariant } from 'src/types/admin'
import type { ApiAdminProduct } from 'src/types/api'

export interface AdminProductFieldsState {
  // Request
  isFetching: boolean
  error: string
  // Data
  id: number
  images: string[]
  categories: number[]
  wishlistCount: number
  currentQuantity: number
  // Fields
  project: ComboboxField
  category: ComboboxField
  name: InputTextField
  desc: InputTextField
  link: InputLinkField
  price: InputNumberField
  sale: InputNumberField
  weight: InputTextField
  quantity: InputNumberField
  quantityHardset: InputCheckboxField
  visible: InputCheckboxField
  weekSale: InputCheckboxField
  variants: ListField<ProductEditVariant>
}

export const initialFields: AdminProductFieldsState = {
  // Request
  isFetching: false,
  error: '',
  // Data
  id: 0,
  images: [],
  categories: [],
  wishlistCount: 0,
  currentQuantity: 0,
  // Fields
  project: {
    path: ['adminProduct', 'project'],
    id: 'project',
    label: 'Проект',
    required: true,
    placeholder: 'Выберите проект',
    value: '',
  },
  category: {
    path: ['adminProduct', 'category'],
    id: 'category',
    placeholder: 'Добавить категорию',
    value: '',
  },
  name: {
    path: ['adminProduct', 'name'],
    id: 'name',
    label: 'Название',
    required: true,
    value: '',
  },
  desc: {
    path: ['adminProduct', 'desc'],
    id: 'desc',
    label: 'Описание',
    value: '',
  },
  link: {
    path: ['adminProduct', 'link'],
    id: 'link',
    link: '/product/',
    label: 'Ссылка',
    required: true,
    value: '',
  },
  price: {
    path: ['adminProduct', 'price'],
    id: 'price',
    type: 'number',
    label: 'Цена',
    value: 0,
  },
  sale: {
    path: ['adminProduct', 'sale'],
    id: 'sale',
    type: 'number',
    label: 'Цена со скидкой',
    value: 0,
  },
  weight: {
    path: ['adminProduct', 'weight'],
    id: 'weight',
    type: 'number',
    label: 'Вес (в граммах)',
    value: '',
  },
  quantity: {
    path: ['adminProduct', 'quantity'],
    id: 'quantity',
    type: 'number',
    label: 'Количество',
    disabled: false,
    value: 0,
  },
  quantityHardset: {
    path: ['adminProduct', 'quantityHardset'],
    id: 'quantityHardset',
    description: 'Изменить (добавить/отнять от текущего количества)',
    checked: false,
    value: '1',
  },
  visible: {
    path: ['adminProduct', 'visible'],
    id: 'visible',
    label: 'Видимость',
    description: 'Показывать на сайте',
    checked: true,
    value: '1',
  },
  weekSale: {
    path: ['adminProduct', 'weekSale'],
    id: 'weekSale',
    label: 'Товар недели',
    description: 'Показывать на главной странице',
    checked: false,
    value: '1',
  },
  variants: {
    path: ['adminProduct', 'variants'],
    id: 'variants',
    list: [],
  },
}

export function fetchSuccess(
  draft: AdminProductFieldsState,
  { product, wishlist }: FieldsPayload<ApiAdminProduct>
): void {
  draft.isFetching = false
  draft.error = ''

  draft.wishlistCount = wishlist || 0

  const status = Number(product.status) || 0

  draft.id = product.id || 0
  draft.project.value = product.project_id ? product.project_id.toString() : ''

  draft.categories = product.categories
    ? product.categories.map(function mapper({ category_id }) {
        return Number(category_id)
      })
    : []

  draft.name.value = product.name ? product.name.value : ''
  draft.desc.value = product.desc ? product.desc.value : ''
  draft.link.value = product.link || ''

  draft.images = product.images
    ? product.images.map(function mapper(item) {
        return item.image
      })
    : []

  draft.price.value = product.price || NaN
  draft.sale.value = product.sale || NaN
  draft.weight.value = product.weight || ''

  draft.currentQuantity = product.quantity || 0
  draft.quantity.value = 0
  draft.quantity.disabled = true

  draft.visible.checked = Boolean(status & ProductStatus.Visible)
  draft.weekSale.checked = Boolean(status & ProductStatus.WeekSale)

  draft.variants.list = product.parameters || []
}

export function reducerAdminClearProductFields(
  draft: AdminProductFieldsState,
  _payload: FieldsPayload
): void {
  Object.assign(draft, initialFields)
}

export function reducerAdminAddCategoryToProduct(
  draft: AdminProductFieldsState,
  { categoryId }: FieldsPayload<{ categoryId: number }>
): void {
  draft.category.value = ''

  const { categories } = draft

  if (!categories.includes(categoryId)) {
    categories.push(categoryId)
  }
}

export function reducerAdminRemoveCategoryFromProduct(
  draft: AdminProductFieldsState,
  { index }: FieldsPayload<{ index: number }>
): void {
  draft.categories.splice(index, 1)
}

export function reducerAdminAddImagesToProduct(
  draft: AdminProductFieldsState,
  { files }: FieldsPayload<{ files: readonly string[] }>
): void {
  const { images } = draft

  for (const file of files) {
    if (!images.includes(file)) {
      images.push(file)
    }
  }
}

export function reducerAdminRemoveImageFromProduct(
  draft: AdminProductFieldsState,
  { index }: FieldsPayload<{ index: number }>
): void {
  draft.images.splice(index, 1)
}

export function reducerAdminSwapImagesProduct(
  draft: AdminProductFieldsState,
  { from, to }: FieldsPayload<{ from: number; to: number }>
): void {
  const { images } = draft

  const temp = images[from]

  images[from] = images[to]
  images[to] = temp
}
