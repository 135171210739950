import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import {
  getIsFetching,
  getOrdersCount,
} from 'src/redux/selectors/order-history'

import { fetchOrderHistory } from 'src/redux/actions/order-history'

import Separator from 'src/components/separator'
import LoadingPage from 'src/components/loading'
import PersonalLinks from 'src/components/personal-links'

import OrderHistoryList from './orders-list'

import * as styles from 'src/styles/order-history.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  isFetching: boolean
  ordersCount: number
}

function selector(state: ReduxState): StateProps {
  return {
    isFetching: getIsFetching(state),
    ordersCount: getOrdersCount(state),
  }
}

function OrderHistoryView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'order-history' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      dispatch(fetchOrderHistory())
    },
    [dispatch]
  )

  const { isFetching, ordersCount }: StateProps = useSelector(
    selector,
    shallowEqual
  )

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <PersonalLinks />

      <div className={styles.content}>
        <h2 className={utility.defaultPageHeader}>
          <FormattedMessage id='order-history' defaultMessage='Order history' />
        </h2>

        <Separator />

        {isFetching && ordersCount === 0 ? (
          <LoadingPage />
        ) : ordersCount === 0 ? (
          <p className={styles.message}>
            <FormattedMessage
              id='order-history-empty'
              defaultMessage='Order history is empty'
            />
          </p>
        ) : (
          <OrderHistoryList />
        )}
      </div>
    </div>
  )
}

export default React.memo(OrderHistoryView)
