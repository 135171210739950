import * as React from 'react'
import { Link } from 'react-router-dom'

import { ProductStatus } from 'src/const/product-status'

import type { Product } from 'src/types/admin'

interface Props {
  product: Readonly<Product>
}

function AdminProductsItem({ product }: Props): JSX.Element {
  return (
    <tr>
      <td>
        {product.status & ProductStatus.Visible ? '' : '❌ '}

        {product.status & ProductStatus.WeekSale ? '⭐ ' : ''}

        <Link to={`/admin/products/${product.id}/edit`}>{product.name}</Link>
      </td>

      <td>{product.link}</td>

      <td>{product.price}</td>

      <td>{product.sale ? product.sale : '—'}</td>

      <td>{product.quantity > 0 ? product.quantity : '—'}</td>
    </tr>
  )
}

export default React.memo(AdminProductsItem)
