import * as React from 'react'
import { useDispatch } from 'react-redux'

import { changeInput } from 'src/redux/actions/fields'

import type { InputTextField } from 'src/types/fields'

interface OwnProps {
  field: Readonly<InputTextField>
  className?: string
  disabled?: boolean
  onChange?(value: string, path: readonly string[]): void
}

export default function InputTextarea({
  field,
  disabled,
  onChange,
  ...props
}: OwnProps): JSX.Element {
  const dispatch = useDispatch()

  const onInputChange = React.useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >(
    function callback({ target: { value } }) {
      dispatch(
        changeInput({
          path: field.path,
          value,
        })
      )

      if (typeof onChange === 'function') {
        onChange(value, field.path)
      }
    },
    [field.path, onChange, dispatch]
  )

  return (
    <textarea
      {...props}
      id={field.id}
      value={field.value}
      disabled={field.disabled || disabled}
      required={field.required}
      placeholder={field.placeholder}
      onChange={onInputChange}
    />
  )
}
