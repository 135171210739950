import { createAction } from 'redux-actions'

import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_COMPLETE,
  CALL_ACTION,
  SET_IN,
  CHANGE_FILE,
  CHANGE_LIST,
  CHANGE_INPUT,
  CHANGE_CHECKBOX,
} from 'src/redux/action-types'

import type {
  FieldsPayload,
  FieldsCallAction,
  FetchFailurePayload,
  ChangeListField,
  ChangeInputField,
  ChangeInputFileField,
  ChangeInputCheckboxField,
} from 'src/types/fields'

export const fetchRequest = createAction<FieldsPayload>(FETCH_REQUEST)
export const fetchSuccess = createAction<FieldsPayload>(FETCH_SUCCESS)
export const fetchFailure = createAction<FieldsPayload<FetchFailurePayload>>(
  FETCH_FAILURE
)

export const fetchComplete = createAction<FieldsPayload>(FETCH_COMPLETE)

export const callAction = createAction<FieldsPayload<FieldsCallAction>>(
  CALL_ACTION
)

export const setIn = createAction<FieldsPayload>(SET_IN)

export const changeFile = createAction<FieldsPayload<ChangeInputFileField>>(
  CHANGE_FILE
)

export const changeList = createAction<FieldsPayload<ChangeListField>>(
  CHANGE_LIST
)

export const changeInput = createAction<FieldsPayload<ChangeInputField>>(
  CHANGE_INPUT
)

export const changeCheckbox = createAction<
  FieldsPayload<ChangeInputCheckboxField>
>(CHANGE_CHECKBOX)
