import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'
import { DiscussionEmbed } from 'disqus-react'

import { getLocale } from 'src/redux/selectors/intl'
import { getProduct } from 'src/redux/selectors/shop'

import * as styles from 'src/styles/product.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  locale: string
  productId: number
}

function selector(state: ReduxState): StateProps {
  return {
    productId: getProduct(state).id,
    locale: getLocale(state),
  }
}

function ProductReviews(): JSX.Element {
  const { locale, productId }: StateProps = useSelector(selector, shallowEqual)

  const disqusConfig = React.useMemo(
    function memo() {
      return {
        identifier: `product-${productId}`,
        language: locale,
      }
    },
    [locale, productId]
  )

  return (
    <div className={styles.productReviews}>
      <h4 className={styles.productReviewsHeader}>
        <FormattedMessage id='reviews' defaultMessage='Reviews' />
      </h4>

      <div className={styles.productReviewsContent}>
        <DiscussionEmbed shortname='ice-pick-shop' config={disqusConfig} />
      </div>
    </div>
  )
}

export default React.memo(ProductReviews)
