import { enableMapSet, enableES5 } from 'immer'
import { createStore, compose, applyMiddleware, Store } from 'redux'
import createDebounce from 'redux-debounced'
import thunk from 'redux-thunk'

import { isES6 } from 'src/utils/es6'

import reducers from 'src/redux/reducers'

if (!isES6()) {
  enableES5()
}

enableMapSet()

function configureStore(): Store {
  return createStore(
    reducers,
    undefined,
    compose(applyMiddleware(createDebounce(), thunk))
  )
}

export const store = configureStore()
