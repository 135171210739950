export enum Payment {
  Robokassa = 'robokassa',
  PayPal = 'paypal',
}

export interface PaymentMethod {
  readonly name: string
  readonly type: Payment
  readonly image: string
  readonly account: string
  readonly url: string
  readonly notifyUrl?: string
  readonly returnUrl?: string
  readonly cancelUrl?: string
}

export const paymentMethods: Record<Payment, PaymentMethod> = {
  [Payment.Robokassa]: {
    name: 'Robokassa',
    type: Payment.Robokassa,
    image: '/img/payment-robokassa.png',
    account: 'IcePickShop',
    url: 'https://auth.robokassa.ru/Merchant/Index.aspx',
  },
  [Payment.PayPal]: {
    name: 'Paypal',
    type: Payment.PayPal,
    image: '/img/payment-paypal.png',
    account: 'pp@ice-pick.com',
    url: 'https://www.paypal.com/cgi-bin/websc',
    notifyUrl: `http://shop.ice-pick.com/api/paypal/ipn`,
    returnUrl: 'http://shop.ice-pick.com/order/result/paypal',
    cancelUrl: 'http://shop.ice-pick.com/order/cancel/paypal',
  },
} as const
