import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames'

import { getOrderError, getOrderIntlError } from 'src/redux/selectors/order'

import { clearOrderError } from 'src/redux/actions/order'

import Separator from 'src/components/separator'

import * as styles from 'src/styles/order-error.module.css'
import * as utility from 'src/styles/utility.module.css'

import type { ReduxState } from 'src/types/common'

interface StateProps {
  intlError: string
  error: string
}

function selector(state: ReduxState): StateProps {
  return {
    intlError: getOrderIntlError(state),
    error: getOrderError(state),
  }
}

function OrderErrorView(): JSX.Element {
  const intl = useIntl()

  React.useEffect(
    function effect() {
      document.title = intl.formatMessage({ id: 'internal-server-error' })
    },
    [intl]
  )

  const dispatch = useDispatch()

  React.useEffect(
    function effect() {
      return function cleanup() {
        dispatch(clearOrderError())
      }
    },
    [dispatch]
  )

  const { intlError, error }: StateProps = useSelector(selector, shallowEqual)

  return (
    <div className={classnames(utility.defaultPage, styles.container)}>
      <h2 className={utility.defaultPageHeader}>
        <FormattedMessage
          id='internal-server-error'
          defaultMessage='Internal server error'
        />
      </h2>

      <Separator />

      <div className={styles.message}>
        {intlError !== '' ? (
          <FormattedMessage id={intlError} defaultMessage={intlError} />
        ) : (
          error
        )}
      </div>
    </div>
  )
}

export default React.memo(OrderErrorView)
