export default `**Адрес:**  
г.Москва,  
Воротниковский пер., д.8, с.1

**Контакты:**  
Телефон: +7 (953) 358-98-84  
Почта: shop@ice-pick.com  
[https://vk.com/icepickshop](https://vk.com/icepickshop)

**Время работы:**  
Пн-Пт — с 12 до 20  
Сб-Вск — выходной

**Как добраться:**  
Станция метро «Маяковская». Первый вагон. Выход №2: Тверская ул., ул. Садовая-Триумфальная (это который после эскалатора налево!). От метро по Тверской-Ямской до «Мосхосторга». Поверните направо и войдите в арку перед аптекой. После арки — снова направо. Увидите крыльцо с серой дверью — вам сюда.`
