import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'

import LazyImage from 'src/components/lazyimage'

import IntlLink from 'src/components/common/intl-link'

import FooterSocials from './socials'

import * as styles from 'src/styles/footer.module.css'
import * as utility from 'src/styles/utility.module.css'

function Footer(): JSX.Element {
  return (
    <footer
      className={styles.footer}
      itemScope
      itemType='http://schema.org/WPFooter'
    >
      <div
        className={styles.container}
        itemProp='author'
        itemScope
        itemType='http://schema.org/Organization'
      >
        <meta itemProp='name' content='Ice-Pick Lodge' />

        <link itemProp='url' href='http://ice-pick.com' />

        <div className={styles.logo}>
          <LazyImage src='/img/logo.png' alt='Ice-Pick Lodge Logo' />

          <link itemProp='logo' href='/img/logo.png' />
        </div>

        <div className={styles.content}>
          <a
            className={classnames(utility.ci, styles.email)}
            href='mailto:shop@ice-pick.com'
            itemProp='email'
          >
            shop@ice-pick.com
          </a>

          <div className={utility.desktopHide}>
            <FooterSocials className={styles.social} />
          </div>

          <div>
            <FormattedMessage id='copyright' defaultMessage='Copyright' />
          </div>
        </div>

        <div className={styles.linksContainer}>
          <ul className={styles.links}>
            <h4 className={styles.linksHeader}>
              <FormattedMessage id='account' defaultMessage='Account' />
            </h4>

            <li>
              <IntlLink className={utility.ci} to='/profile/'>
                <FormattedMessage
                  id='personal-details'
                  defaultMessage='Personal details'
                />
              </IntlLink>
            </li>

            <li>
              <IntlLink className={utility.ci} to='/cart/'>
                <FormattedMessage id='cart' defaultMessage='Cart' />
              </IntlLink>
            </li>

            <li>
              <IntlLink className={utility.ci} to='/privacy-policy/'>
                <FormattedMessage
                  id='privacy-policy'
                  defaultMessage='Privacy policy'
                />
              </IntlLink>
            </li>
          </ul>

          <ul className={styles.links}>
            <h4 className={styles.linksHeader}>
              <FormattedMessage id='support' defaultMessage='Support' />
            </h4>

            <li>
              <IntlLink className={utility.ci} to='/shipping/#payment'>
                <FormattedMessage id='payment' defaultMessage='Payment' />
              </IntlLink>{' '}
              <FormattedMessage id='amp' defaultMessage='&' />{' '}
              <IntlLink className={utility.ci} to='/shipping/'>
                <FormattedMessage id='shipping' defaultMessage='Shipping' />
              </IntlLink>
            </li>

            <li>
              <IntlLink className={utility.ci} to='/about/'>
                <FormattedMessage id='about-us' defaultMessage='About Us' />
              </IntlLink>
            </li>

            <li>
              <IntlLink className={utility.ci} to='/partners/'>
                <FormattedMessage id='partners' defaultMessage='Partners' />
              </IntlLink>
            </li>
          </ul>
        </div>

        <div className={utility.mobileHide}>
          <FooterSocials className={styles.social} />
        </div>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
