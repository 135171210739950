import * as React from 'react'
import { useSelector } from 'react-redux'

import { getProductId } from 'src/redux/selectors/admin-product-edit'

function Headline(): JSX.Element {
  const id: number = useSelector(getProductId)

  return (
    <h1 className='mb-3 text-center'>
      {id > 0 ? 'Редактировать товар' : 'Создать новый товар'}
    </h1>
  )
}

export default React.memo(Headline)
