import * as React from 'react'

export interface InfoFieldProps {
  id: string
  name: string
  value: string
}

export default function InfoField(props: InfoFieldProps): JSX.Element {
  return (
    <div className='form-group row'>
      <label
        className='col-sm-2 text-md-right col-form-label alert-secondary'
        htmlFor={props.id}
      >
        {props.name}
      </label>

      <div className='col-sm-10 col-form-label bg-light'>{props.value}</div>
    </div>
  )
}
