import * as React from 'react'

interface Props {
  disabled: boolean
  onClick: React.MouseEventHandler
}

function ButtonUploadingCancel({ disabled, onClick }: Props): JSX.Element {
  if (disabled) {
    return <></>
  }

  return (
    <button className='btn btn-danger' onClick={onClick} type='button'>
      Отменить
    </button>
  )
}

export default React.memo(ButtonUploadingCancel)
